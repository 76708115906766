<template>
  <div>
    <ui-popup-col
      ref="columnsPopup"
      :width="1300"
      :title="
        $t(
          `dashboards.other.${
            typePopup === 'add' ? 'add_metric' : 'edit_metric'
          }`
        )
      "
      :actions-disabled="
        actionsDisabled || isDisableSelectFilter || addDashbordIsLoading
      "
      class="add-dashboard-metrics columns-popup"
      :class="{ 'add-dashboard-metrics__edit': typePopup !== 'add' }"
      :esc-close="false"
      @save="applyColumnsSettings"
      @cancel="
        () => {
          isEdit ? alertClosePopup() : cancelColumnsSettings();
        }
      "
    >
      <div :class="{ 'is-show': false }" class="columns-popup__wr">
        <div class="columns-popup__left">
          <div class="columns-popup__title">
            {{ $t('dashboards.popup_column.configure_metric') }}
          </div>
          <div>
            <div v-if="selectedMetric" class="columns-popup__input-name">
              <ui-input
                :label="$t('dashboards.other.panel_name')"
                autosize
                size="big"
                :placeholder="$t('dashboards.other.panel_name_placeholder')"
                class="form-input"
                type="text"
                :value="setNamePanel"
                @input="
                  (val) => {
                    panelName = val;
                    countCalcQuery();
                  }
                "
              />
            </div>

            <adm-ui-scrollbar
              wrap-style="height: 55vh;"
              :native="false"
              :outside="true"
            >
              <div
                v-if="selectedMetric"
                class="controls columns-popup__right-control"
              >
                <div
                  v-if="typePopup === 'add'"
                  class="columns-popup__right-view-wr"
                >
                  <div class="columns-popup__right-select-wr">
                    <span class="label">{{
                      $t('dashboards.popup_column.view')
                    }}</span>
                    <el-radio-group
                      v-model="typeMetric"
                      size="small"
                      @change="setTypeMetric"
                    >
                      <el-radio-button :disabled="loading" label="stat">
                        {{ $t('dashboards.popup_column.card') }}
                      </el-radio-button>
                      <el-radio-button :disabled="loading" label="chart">
                        {{ $t('dashboards.popup_column.chart') }}
                      </el-radio-button>
                      <el-radio-button :disabled="loading" label="table">
                        {{ $t('dashboards.popup_column.table') }}
                      </el-radio-button>
                    </el-radio-group>
                  </div>
                </div>
                <div
                  v-if="typeMetric === 'chart'"
                  class="columns-popup__right-select-wr"
                >
                  <span class="label">{{
                    $t('dashboards.popup_column.type')
                  }}</span>
                  <adm-ui-select
                    :data="chartTypeData"
                    :default="chartType"
                    @select="handlerChartType"
                  />
                </div>
              </div>

              <select-metrics
                v-if="
                  (typeMetric !== 'pie' && groupingSwitch) ||
                    typeMetric === 'stat' ||
                    typeMetric === 'table'
                "
                :label="$t('dashboards.popup_column.metrics')"
                :value="selectedMetric"
                @change="handlerSelectMetric"
              />

              <dashboard-switch
                v-if="typeMetric !== 'chart'"
                :title="$t('dashboards.other.enable_comparison')"
                :value-switch="enableComparison"
                @handlerChangeSwitch="handlerComparison"
              />
              <div v-if="selectedMetric">
                <dashboard-switch
                  v-if="
                    typeMetric !== 'stat' &&
                      typeMetric !== 'table' &&
                      chartType !== 'pie'
                  "
                  :title="$t('dashboards.other.forecast')"
                  :value-switch="forecastValue"
                  @handlerChangeSwitch="handlerForecast"
                />
                <dashboard-grouping-metric
                  v-if="typeMetric !== 'stat'"
                  :grouping-switch="groupingSwitch"
                  :series-group-by-dimension-name="groupByGrouping"
                  :series-group-by-limit="groupingCount"
                  :series-group-by-add-others="groupingOther"
                  :disabled="disabledGroupingSwitch || addDashbordIsLoading"
                  :groups-all="groupsAll"
                  @handlerChangeSwitch="handlerChangeSwitch"
                  @handlerSelectGroup="handlerSelectGroup"
                  @handlerSelectCount="handlerSelectCount"
                  @handlerShowOther="handlerShowOther"
                />

                <dashboard-filter
                  :data="filterTab"
                  :init-filters="initFiltetsdashboard"
                  @handlerDashboardTabFilter="handlerDashboardTabFilter"
                  @handlerDashboardFilter="handlerDashboardFilter"
                  @isDisableSelectFilter="handlerDisableSelectFilter"
                />

                <dashboard-series
                  v-if="typeMetric === 'chart' && !groupingSwitch"
                  :series="computedSeries"
                  :disabled="
                    actionsDisabled ||
                      isDisableSelectFilter ||
                      addDashbordIsLoading
                  "
                  @updateSeries="updateSeries"
                  @isDisableSelectFilter="handlerDisableSelectFilter"
                />
              </div>
            </adm-ui-scrollbar>
          </div>
        </div>

        <!-- right -->
        <div class="columns-popup__right">
          <div v-if="selectedMetric">
            <div class="columns-popup__title">
              {{ $t('dashboards.popup_column.preview_panel') }}
            </div>

            <adm-ui-scrollbar
              wrap-style="height: 55vh;"
              :native="false"
              :outside="true"
            >
              <div class="columns-popup__right-content-wr">
                <dashboard-metric-stat
                  v-if="typeMetric === 'stat'"
                  ref="stat"
                  style="width: 260px"
                  :stat="{
                    ...dashboardStatComputed,
                    name: getTranslateMetrics(dashboardStatComputed.metric_name)
                      .title,
                  }"
                  :is-removed="false"
                  :period="period"
                  @emitDataReport="setReportData"
                />

                <dashboard-chart
                  v-if="typeMetric === 'chart'"
                  ref="chart"
                  :key="chartType"
                  :chart="{
                    ...dashboardStatComputed,
                    name: getTranslateMetrics(
                      dashboardStatComputed.series_group_by_metric_name ||
                        dashboardStatComputed.series[0].metric_name
                    ).title,
                  }"
                  preview
                  :animation="animation"
                  :is-loading="loading"
                  :period="period"
                  @changeSelectType="changeSelectType"
                  @emitDataReport="setReportData"
                />

                <dashboard-metric-table
                  v-if="typeMetric === 'table'"
                  ref="table"
                  :is-loading="loading"
                  :table="{
                    ...dashboardStatComputed,
                    name: getTranslateMetrics(dashboardStatComputed.metric_name)
                      .title,
                  }"
                  :period="period"
                />
              </div>
            </adm-ui-scrollbar>
          </div>
        </div>
      </div>
    </ui-popup-col>
    <adm-ui-confirm ref="confirm" v-bind="confirmConfig" />
  </div>
</template>


<script>
import { mapGetters, mapState } from 'vuex';
import { debounce } from 'lodash';
import Config from '@/views/Reports/reportSettings';
import DashboardMetricStat from './DashboardMetricStat.vue';
import DashboardChart from './DashboardChart.vue';
import DashboardGroupingMetric from './DashboardGroupingMetric.vue';
import DashboardFilter from './DashboardFilter.vue';
import DashboardSeries from './DashboardSeries.vue';
import DashboardSwitch from './DashboardSwitch.vue';
import DashboardMetricTable from './DashboardMetricTable.vue';
import { ADD_DASHBOARD_IS_LOADING } from '@/store/action_types/dashboards';
import SelectMetrics from './SelectMetrics.vue';

export default {
  name: 'AddDashboardMetrics',
  components: {
    DashboardMetricStat,
    DashboardChart,
    DashboardMetricTable,
    DashboardGroupingMetric,
    DashboardFilter,
    DashboardSeries,
    DashboardSwitch,
    SelectMetrics,
  },

  props: {
    isRemoved: {
      type: Boolean,
      default: false,
    },

    period: {
      type: Array,
      default() {
        return [];
      },
    },
  },

  data() {
    return {
      typePopup: 'add',
      indexEditMetric: null,
      rowIndex: null,
      actionsDisabled: true,
      searchColumns: '',
      activeNames: [],
      arrayHideSearch: [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
      noData: false,

      selectedMetric: '',
      typeMetric: 'stat',
      allChartType: ['line', 'bar', 'pie'],
      chartType: 'line',
      allDimensions: Config.allDimensions,
      animation: true,

      comparedType: 'this_vs_last',

      allDateType: [
        'date_formatted',
        'start_of_week_formatted',
        'start_of_month_formatted',
        'year_formatted',
        'day_of_week_formatted',
        'day_of_month_formatted',
        'hour_formatted',
      ],
      dateType: 'date_formatted',

      allDateTypeRange: [
        'this_day',
        'this_week',
        'this_month',
        'this_year',
        'rolling_30_days',
      ],
      allDimensionSelect: [
        'affiliate_email',
        'sub_affiliate_email',
        'player_country_name',
        'player_region_name',
        'payment_system_name',
      ],
      dateTypeRange: 'this_week',
      dateTypeRangeChart: 'last_week',
      dateTypeRangeTable: 'last_week',

      report: {},
      dashboardStat: {},
      panelName: null,
      filterSelect: {},
      filterTab: {},

      groupingSwitch: false,
      disabledGroupingSwitch: false,
      groupByGrouping: null,
      groupingCount: 5,
      groupingOther: false,
      series: [],
      initFiltetsdashboard: {},

      loading: false,
      isDisableSelectFilter: false,
      countQuery: 0,

      forecastValue: false,
      enableComparison: false,
    };
  },

  computed: {
    ...mapGetters('reports', ['reportsSettings']),
    ...mapState('dashboards', ['stateDashboardFilter', 'addDashbordIsLoading']),

    supportedDimenison() {
      return (this.reportsSettings.dimensions || []).map(dim => dim.column_name);
    },

    groupsAll() {
      return this.allDimensionSelect
        .filter(dim => this.supportedDimenison.includes(dim))
        .map(dim => ({
          value: dim,
          label: this.$t(`dashboards.grouping.${dim}`),
        }));
    },

    setNamePanel() {
      return (
        this.panelName ?? this.getTranslateMetrics(this.selectedMetric).title
      );
    },

    computedSeries() {
      if (this.series.length === 0) {
        return [
          {
            name: this.$t('dashboards.dashboards_filters.name_series'),
            metric_name: this.selectedMetric,
            y_axis: 'left',
            filters: {},
          },
        ];
      }

      return this.series.map((val) => {
        this.$set(val, 'metric_name', val.metric_name);
        return val;
      });
    },

    paramsData() {
      const filtersPanel = {
        ...this.filterSelect,
        search: {
          ...this.filterTab,
        },
      };

      const period = {
        from: this.$moment(this.period[0]).format('YYYY-MM-DD HH:mm:ss'),
        to: this.$moment(this.period[1]).format('YYYY-MM-DD HH:mm:ss'),
      };

      let panel = {};
      if (this.typeMetric === 'stat') {
        panel = {
          name: this.setNamePanel,
          type: this.typeMetric,
          metric_name: this.selectedMetric,
          filters: filtersPanel,
          enable_comparison: this.enableComparison,
        };
      }
      if (this.typeMetric === 'chart') {
        panel = {
          name: this.setNamePanel,
          type: this.typeMetric,
          filters: filtersPanel,
          chart_visualization: this.chartType,
          enable_forecast: this.forecastValue,
          enable_comparison: this.enableComparison,

          series_group_by_dimension_name: this.groupingSwitch
            ? this.groupByGrouping
            : null,
          series_group_by_metric_name: this.groupingSwitch
            ? this.selectedMetric
            : null,
          series_group_by_limit: this.groupingSwitch
            ? this.groupingCount
            : null,
          series_group_by_add_others: this.groupingSwitch
            ? this.groupingOther
            : null,
          series: this.groupingSwitch ? [] : this.computedSeries,
        };
      }

      if (this.typeMetric === 'table') {
        panel = {
          name: this.setNamePanel,
          type: this.typeMetric,
          filters: filtersPanel,
          metric_name: this.selectedMetric,
          comparison_period: this.comparedType,
          dimension_name: this.groupingSwitch ? this.groupByGrouping : null,
          limit: this.groupingSwitch ? this.groupingCount : null,
          group_by_add_others: this.groupingSwitch ? this.groupingOther : false,
          enable_comparison: this.enableComparison,
        };
      }

      return { panel, filters: this.stateDashboardFilter, ...period };
    },

    dashboardStatComputed() {
      return {
        ...this.paramsData.panel,
        report: this.report,
      };
    },

    columnGroup() {
      return this.reportsSettings.grouped_metrics;
    },

    chartTypeData() {
      return this.allChartType.map(type => ({
        value: type,
        label: this.$t(`dashboards.type_chart.${type}`),
      }));
    },

    dateTypeRangeData() {
      const selectObj = this.allDateTypeRange.map(elem => ({
        value: elem,
        label: this.$t(`dashboards.peroid_stat.${elem}`),
      }));
      return selectObj;
    },

    isEdit() {
      if (this.countQuery > 1) return true;
      return false;
    },

    confirmConfig() {
      return {
        title: this.$t('dashboards.confirm.cancel_title'),
        icon: 'fas fa-exclamation-triangle',
        text: this.$t('dashboards.confirm.cancel_text'),
        buttonsData: [
          {
            title: this.$t('dashboards.confirm.cancel_btn_cancel'),
            key: 'no',
            type: 'primary',
            plain: true,
            icon: 'fas fa-times',
            clickHandler: this.cancelHandler,
          },
          {
            title: this.$t('dashboards.confirm.cancel_btn_сonfirm'),
            key: 'yes',
            type: 'danger',
            icon: 'fas fa-trash',
            clickHandler: this.confirmHandler,
          },
        ],
      };
    },
  },

  watch: {
    searchColumns() {
      setTimeout(() => {
        const el = this.$el.querySelectorAll(
          '.el-collapse-item.hide-search',
        ).length;
        this.noData = el === this.columnGroup.length;
      });

      /* expand all when searching  */
      this.activeNames = this.columnGroup.map(elem => elem.group_name);
      this.hideCollapseSearch();
    },
  },

  methods: {
    async handlerComparison(value) {
      this.enableComparison = value;
      await this.changeTypeMetric(this.typeMetric);
    },

    async handlerForecast(value) {
      this.forecastValue = value;
      await this.changeTypeMetric(this.typeMetric);
    },

    confirmHandler() {
      this.cancelHandler();
      this.cancelColumnsSettings();
    },

    cancelHandler() {
      this.$refs.confirm.close();
    },

    alertClosePopup() {
      this.$refs.confirm.open();
    },

    handlerDisableSelectFilter(value) {
      this.isDisableSelectFilter = value;
    },

    async setTypeMetric(type) {
      this.loading = true;

      this.disabledGroupingSwitch = false;
      if (type === 'chart' && this.chartType === 'pie') {
        this.disabledGroupingSwitch = true;
        this.groupingSwitch = true;
      }

      if (type === 'table') {
        this.comparedType = this.comparedType === 'this_vs_last_td'
          ? 'this_vs_last'
          : this.comparedType;
      }
      await this.changeTypeMetric(type);
    },

    async updateSeries(series) {
      this.series = series;
      await this.changeTypeMetric(this.typeMetric);
    },

    async handlerShowOther(value) {
      this.groupingOther = value;
      await this.changeTypeMetric(this.typeMetric);
    },

    async handlerSelectCount(value) {
      this.groupingCount = value;
      await this.changeTypeMetric(this.typeMetric);
    },

    async handlerSelectGroup(value, silent) {
      this.groupByGrouping = value;
      if (silent) return;
      await this.changeTypeMetric(this.typeMetric);
    },

    async handlerChangeSwitch(value) {
      this.groupingSwitch = value;
      await this.changeTypeMetric(this.typeMetric);
    },

    async handlerDashboardTabFilter(value) {
      this.filterTab = value;
      await this.changeTypeMetric(this.typeMetric);
    },

    async handlerDashboardFilter(value) {
      this.filterSelect = value;
      await this.changeTypeMetric(this.typeMetric);
    },

    async changeTypeMetric(type) {
      await this.getDashboardsStats(this.paramsData);
      if (type === 'stat') this.updateStat('getLocalData');
      if (type === 'chart') this.updateChart('getLocalData');
      if (type === 'table') this.updateTable('getLocalData');
    },

    updateStat(type) {
      if (this.$refs.stat) this.$refs.stat.getStat(type);
    },

    updateChart(type) {
      this.$refs.chart.getReport(type);
    },

    updateTable(type) {
      this.$refs.table.getReport(type);
    },

    setReportData(rowIndex, type, index, report) {
      this.report = report;
    },

    getTranslateMetrics(metric) {
      return this.reportsSettings.metrics.find(e => e.column_name === metric);
    },

    getTranslateGroup(groupName) {
      return this.reportsSettings.metrics_groups.find(
        e => e.group_name === groupName,
      ).title;
    },

    async handlerСomparedType(type) {
      this.comparedType = type;
      await this.getDashboardsStats(this.paramsData);
      if (this.typeMetric === 'stat') this.updateStat('getLocalData');
      if (this.typeMetric === 'table') this.updateTable('getLocalData');
    },

    async changeSelectType(type) {
      this.dateTypeRangeChart = type;
      await this.getDashboardsStats(this.paramsData);
      this.updateChart('getLocalData');
    },

    async handlerDateTypeRange(type) {
      this.dateTypeRange = type;
      await this.getDashboardsStats(this.paramsData);
      this.updateStat('getLocalData');
    },

    async handlerDateTypeRangeTable(type) {
      this.dateTypeRangeTable = type;
      await this.getDashboardsStats(this.paramsData);
      this.updateTable('getLocalData');
    },

    async handlerChartType(type) {
      this.animation = true;
      this.countCalcQuery();
      this.disabledGroupingSwitch = false;
      if (this.chartType === 'pie' || type === 'pie') {
        this.chartType = type;
        if (type === 'pie') {
          this.groupingSwitch = true;
          this.disabledGroupingSwitch = true;
        }
        await this.getDashboardsStats(this.paramsData);
      } else {
        this.chartType = type;
      }
      this.$nextTick(() => this.updateChart('getLocalData'));
    },

    async handlerSelectMetric(value) {
      this.selectedMetric = value;
      this.actionsDisabled = false;
      await this.getDashboardsStats(this.paramsData);
      if (this.typeMetric === 'stat') {
        this.updateStat('getLocalData');
        return;
      }
      if (this.typeMetric === 'chart') {
        this.updateChart('getLocalData');
        return;
      }
      if (this.typeMetric === 'table') {
        this.updateTable('getLocalData');
      }
    },

    countCalcQuery: debounce(function countCalcQuery(count = 1) {
      this.countQuery += count;
    }, 100),

    async getDashboardsStats(params) {
      this.countCalcQuery();
      this.$store.commit(`dashboards/${ADD_DASHBOARD_IS_LOADING}`, true);
      try {
        const {
          data: { payload },
        } = await this.$api.dashboardsStats(params);
        this.report = payload;
      } finally {
        this.$store.commit(`dashboards/${ADD_DASHBOARD_IS_LOADING}`, false);
        this.loading = false;
      }
    },

    filtrableInner(group, name) {
      const s = this.$_.find(this.columnGroup, o => this.$_.find(o.items, e => e.name === name));
      const gg = this.$_.filter(s.items, item => item.name === name);
      const fin = this.$_.filter(
        gg[0].items,
        el => this.$t(`reports.fields.${el.name}.title`)
          .toLowerCase()
          .indexOf(this.searchColumns.toLowerCase()) > -1
          || this.$t(`reports.fields.${el.name}.description`)
            .toLowerCase()
            .indexOf(this.searchColumns.toLowerCase()) > -1,
      );
      return fin.length !== 0;
    },

    hideCollapseSearch() {
      if (this.searchColumns !== '') {
        this.arrayHideSearch = [];
        this.$nextTick(() => {
          this.$el.querySelectorAll('.wrap-item').forEach((e) => {
            this.arrayHideSearch.push(e.childElementCount);
          });
        });
      } else {
        this.arrayHideSearch = Array(this.columnGroup.length).fill(1);
      }
    },

    applyColumnsSettings() {
      this.$emit(
        'applyColumnsSettings',
        this.typePopup,
        this.rowIndex,
        this.dashboardStatComputed,
        this.indexEditMetric,
      );

      this.reset();
      this.close();
    },

    cancelColumnsSettings() {
      this.reset();
      this.close();
    },

    reset() {
      this.animation = true;
      this.actionsDisabled = true;
      this.xAxisFormat = {};
      this.selectedMetric = '';
      this.typeMetric = 'stat';
      this.indexEditMetric = null;

      this.series = [];
      this.panelName = null;
      this.filterTab = {};
      this.filterSelect = {};
      this.initFiltetsdashboard = {};

      this.groupingSwitch = false;
      this.groupByGrouping = null;
      this.groupingCount = 5;
      this.groupingOther = false;
      this.series = [];
      this.chartType = 'line';
      this.isDisableSelectFilter = false;
      this.countQuery = 0;
    },

    decodeFilterSelect(dataApiFilter) {
      const noParse = ['traffic_type', 'players_filter'];

      this.filterSelect = this.$_.cloneDeep(dataApiFilter);
      delete this.filterSelect.search;
      const valueDecoding = this.$_.cloneDeep(this.filterSelect);

      const keyFilters = Object.keys(valueDecoding.filters || {});
      const reducedObj = keyFilters.reduce((acc, val) => {
        acc[val] = valueDecoding.filters[val];
        return acc;
      }, {});
      const result = { ...reducedObj, ...valueDecoding };
      delete result.filters;

      noParse.forEach((elParse) => {
        if (Object.keys(result).includes(elParse)) {
          this.$set(result, elParse, {
            value: result[elParse],
            op: null,
          });
        }
      });

      this.initFiltetsdashboard = result;
    },

    editModeOpen(data) {
      this.actionsDisabled = false;
      this.typeMetric = data.type;
      this.report = data.report;
      this.forecastValue = data.enable_forecast;
      this.enableComparison = data.enable_comparison;
      // проверка на старые метрики
      if (data.filters !== null) {
        this.filterTab = this.$_.cloneDeep(data.filters.search);
        this.decodeFilterSelect(data.filters);
      }

      this.panelName = data.name;
      if (this.typeMetric === 'stat') {
        this.selectedMetric = data.metric_name;

        this.dateTypeRange = data.current_period;
        this.comparedType = data.comparison_period;
        this.$nextTick(() => this.updateStat('getLocalData'));
        return;
      }
      if (this.typeMetric === 'chart') {
        this.chartType = data.chart_visualization;
        this.disabledGroupingSwitch = this.chartType === 'pie';
        this.dateTypeRangeChart = data.period;
        this.selectedMetric = data.series_group_by_metric_name
          ? data.series_group_by_metric_name
          : data.series[0].metric_name;
        if (data.series_group_by_metric_name) {
          this.groupingSwitch = true;
          this.groupByGrouping = data.series_group_by_dimension_name;
          this.groupingCount = data.series_group_by_limit;
          this.groupingOther = data.series_group_by_add_others;
        } else {
          this.series = this.$_.cloneDeep(data.series);
        }
        this.$nextTick(() => this.updateChart('getLocalData'));
      }

      if (this.typeMetric === 'table') {
        this.selectedMetric = data.metric_name;
        this.dateTypeRangeTable = data.current_period;
        this.comparedType = data.comparison_period;

        this.groupingSwitch = data.dimension_name !== null;

        this.groupByGrouping = data.dimension_name;
        this.groupingCount = data.limit || 5;
        this.groupingOther = data.group_by_add_others;

        this.$nextTick(() => this.updateTable('getLocalData'));
      }
    },

    installConfirmEditMode(data) {
      if (
        data.type === 'chart'
        && Array.isArray(data.series)
        && data.series.length
        && (data.filters === null || Object.keys(data.filters).length <= 1)
      ) {
        let flag = 1;
        for (let i = 0; i < data.series.length; i += 1) {
          const checkfilter = Object.keys(data.series[i].filters).length;
          if (checkfilter > flag) {
            flag = checkfilter;
          }
        }
        if (flag === 1) this.countCalcQuery();
      }

      if (
        data.type === 'chart'
        && data.series_group_by_limit !== null
        && (data.filters === null || Object.keys(data.filters).length <= 1)
      ) {
        this.countCalcQuery();
      }

      if (
        (data.filters === null || Object.keys(data.filters).length <= 1)
        && data.type !== 'chart'
      ) {
        this.countCalcQuery();
      }
    },

    async open(type, rowIndex, index, data) {
      this.typePopup = type;
      this.rowIndex = rowIndex;
      this.disabledGroupingSwitch = false;
      this.forecastValue = false;
      this.enableComparison = true;
      if (this.groupByGrouping === null && this.groupsAll.length) {
        this.groupByGrouping = this.groupsAll[0].value;
      }

      if (type === 'add') {
        this.handlerSelectMetric(this.columnGroup[0].items[0].name);
      }

      if (type === 'edit') {
        this.animation = false;
        this.installConfirmEditMode(data);
        this.typePopup = type;
        this.indexEditMetric = index;
        this.editModeOpen(data);
      }

      this.$refs.columnsPopup.open();
      this.callapseColumnDefault();
    },

    cancel() {
      this.searchColumns = '';
      this.$refs.columnsPopup.cancel();
      this.$emit('cancel');
    },

    close() {
      this.searchColumns = '';
      this.$refs.columnsPopup.close();
    },

    callapseLabel(group) {
      return this.activeNames.indexOf(group) === -1 ? 'show' : 'hide';
    },

    callapseColumnDefault() {
      this.columnGroup.forEach((e) => {
        if (this.activeNames.length === 0) {
          this.activeNames.push(e.group_name);
        }
      });
    },
  },
};
</script>

<template>
  <div class="select-metrics">
    <div class="select-metrics__label">
      {{ label }}
    </div>
    <el-select
      v-model="selectValue"
      filterable
      popper-class="select-metrics__popper"
      :filter-method="filterMethod"
      @change="changeSelect"
      @visible-change="visibleChange"
    >
      <el-option-group
        v-for="group in optionsSelect"
        :key="group.label"
        :label="group.label"
      >
        <el-option
          v-for="item in group.options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
          <span class="select-label" style="float: left">{{ item.label }}
          </span>
          <span class="select-description">({{ item.description }})
          </span>
        </el-option>
      </el-option-group>
    </el-select>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'SelectMetrics',

  props: {
    value: {
      type: String,
      required: true,
    },

    label: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      selectValue: this.value,
      searchValue: '',
    };
  },

  computed: {
    ...mapGetters('reports', ['reportsSettings']),

    columnGroup() {
      return this.reportsSettings.grouped_metrics;
    },

    optionsSelect() {
      const res = this.columnGroup.map((elem) => {
        const itemsList = elem.items.reduce((acc, el) => {
          acc.push(el.name);
          if (el.items.length) {
            el.items.forEach(subItems => acc.push(subItems.name));
          }
          return acc;
        }, []);

        const options = itemsList
          .map(metric => ({
            value: metric,
            label: this.getTranslateMetrics(metric)?.title,
            description: this.getTranslateMetrics(metric)?.description,
          }))
          .filter(
            opt => opt.label.toLowerCase().indexOf(this.searchValue.toLowerCase())
                > -1
              || opt.description
                .toLowerCase()
                .indexOf(this.searchValue.toLowerCase()) > -1,
          );

        return {
          label: this.getTranslateGroup(elem.group_name),
          options,
        };
      });

      return res.filter(f => f.options.length);
    },
  },

  created() {
    if (!this.value) {
      this.selectValue = this.optionsSelect[0].options[0].value;
      this.changeSelect(this.selectValue);
    }
  },

  methods: {
    visibleChange() {
      setTimeout(() => (this.searchValue = ''), 150);
    },

    changeSelect(value) {
      this.$emit('change', value);
    },

    filterMethod(data) {
      this.searchValue = data;
    },

    getTranslateMetrics(metric) {
      return this.reportsSettings.metrics.find(e => e.column_name === metric);
    },

    getTranslateGroup(groupName) {
      return this.reportsSettings.metrics_groups.find(
        e => e.group_name === groupName,
      ).title;
    },
  },
};
</script>

<template>
  <div
    v-loading="isDataLoading"
    class="affiliate-sub"
  >
    <affiliate-sub-popup
      v-show="isPopupOpen"
      ref="subpin"
      :is-open="isPopupOpen"
      :affiliate-data="info"
      @refresh="fetchData"
      @close="onClosePopup"
    />
    <ui-confirm
      ref="confirm"
      :width="480"
      :type="confirm.type"
      :action-name="confirm.actionName"
      :action="confirm.action"
      :data="confirm.data"
      :action-fa-icon="confirm.actionFaIcon"
      @save="actionConfirm"
    >
      <div
        slot="title"
        v-html="confirm.title"
      />
      <div class="body__wrap">
        <i
          :class="confirm.type"
          class="fas fa-exclamation-triangle"
        />
        <div
          class="body__msg affiliate-body"
          v-html="confirm.msg"
        />
      </div>
      <div class="field-error">
        {{ getError('hierarchy') || getError('referral_program_enabled') || getError('referred_by') }}
      </div>
    </ui-confirm>
    <ui-confirm
      ref="confirmEdit"
      :width="480"
      :type="confirmEdit.type"
      :action-name="$t(`affiliates.affiliateSub.save`)"
      :action="confirmEdit.action"
      :action-fa-icon="confirmEdit.actionFaIcon"
      :data="confirmEdit.data"
      class="confirm-edit confirm-edit-referral"
      @close="closeConfirmEdit"
      @save="actionConfirmEdit"
    >
      <div
        slot="title"
        v-html="confirmEdit.title"
      />
      <div class="body__wrap">
        <ui-input
          v-model="editingValue"
          :error="errors.referral_percent"
          :label="$t(`affiliates.affiliateSub.new_percent`)"
          post-text="%"
          autosize
          class="select"
        />
        <div class="row">
          <div class="field">
            <span class="label">{{ $t(`affiliates.affiliateSub.revenue_calculation`) }}</span>
            <el-select
              v-model="referralPaymentType"
              class="select"
            >
              <el-option
                v-for="(item) in listReferralPaymentType"
                :key="item"
                :label="item.toUpperCase()"
                :value="item"
              />
            </el-select>
          </div>
        </div>
      </div>
    </ui-confirm>
    <div class="wrapper">
      <div class="toolbar">
        <div class="btns">
          <div class="card">
            <div class="title">
              <span>{{ $t(`affiliates.affiliateSub.subAffProg`) }}</span>
              <span
                v-if="info.referral_program_enabled && isUpdateReferralProgram"
                :style="{color: $theme.dangerColor}"
                class="action"
                @click="checkSensitiveAction('confirmDeactivate')"
              >
                <ui-icon
                  name="times"
                  :color="$theme.dangerColor"
                  lib="fa"
                />
                {{ $t(`affiliates.affiliateSub.deactivate`) }}
              </span>
              <span
                v-else-if="!info.referral_program_enabled && isUpdateReferralProgram"
                :style="{color: $theme.accentColor}"
                class="action"
                @click="checkSensitiveAction('confirmActivate')"
              >
                <ui-icon
                  name="check"
                  :color="$theme.accentColor"
                  lib="fa"
                />
                {{ $t(`affiliates.affiliateSub.activate`) }}
              </span>
            </div>
            <div class="card-content">
              <div class="field">
                <span
                  v-if="info.referral_program_enabled"
                  class="label active"
                >{{ $t(`affiliates.affiliateSub.active`) }}</span>
                <span
                  v-else
                  class="label inactive"
                >{{ $t(`affiliates.affiliateSub.inactive`) }}</span>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="title">
              <span>{{ $t(`affiliates.affiliateSub.percentOfRevenue`) }}</span>
              <span
                v-if="isUpdateReferralProgram"
                :style="{color: $theme.accentColor}"
                class="action"
                @click="checkSensitiveAction('editDefaultPercent')"
              >
                <ui-icon
                  name="pen"
                  :color="$theme.accentColor"
                  lib="fa"
                />
                {{ $t(`affiliates.affiliateSub.change`) }}
              </span>
            </div>
            <div class="card-content">
              <div class="field">
                <span class="label percent">{{ info.referral_percent }}% {{ getReferralPaymentType }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="subaff-controls">
        <div class="section">
          <span class="title">{{ $t(`affiliates.cardView.nav.sub`) }}</span>
          <ui-input
            v-model.trim="searchQuery"
            :width="180"
            class="subaff-controls__search"
            is-search
            @search="fetchSubs"
          />
        </div>
        <div class="section">
          <div class="pagination">
            <ui-pagination
              :page="page"
              :page-size="limit"
              :count="count"
              show-size-select
              @page-change="pageChange"
            />
          </div>
          <ui-button
            v-if="isUpdateReferralProgram"
            :disabled="!info.referral_program_enabled"
            icon="plus"
            lib="fa"
            substyle="fas"
            color="green"
            class="btn pin-button"
            @click="checkSensitiveAction('pinSubAffiliate')"
          >
            {{ $t(`affiliates.affiliateSub.pin_sub_aff`) }}
          </ui-button>
        </div>
      </div>


      <div class="info">
        <ui-table
          v-loading="isDataLoading"
          :fields="fields"
          :data="subAffiliates"
          :rows-count="limit"
          :external-height="tableHeight"
          :sort="{
            prop: sortBy,
            order: sortDir,
          }"
          lazy-loading
          is-static
          class="table aff-sub"
          i18n-path="affiliates.sublist"
          element-loading-custom-class="data-loader-spinner"
          @sort="handleSort"
        >
          <template
            v-if="isUpdateReferralProgram && info.referral_program_enabled"
            slot="append"
          >
            <el-table-column width="50">
              <template slot-scope="scope">
                <ActionIcon
                  :tooltip="$t('affiliates.affiliatesInfo.unpin')"
                >
                  <div
                    class="action-ico"
                    @click="checkSensitiveAction('confirmUnpin', scope.row)"
                  >
                    <ui-icon
                      name="unlink"
                      :color="$theme.dangerColor"
                      lib="fa"
                    />
                  </div>
                </ActionIcon>
              </template>
            </el-table-column>
          </template>
        </ui-table>
      </div>
    </div>
  </div>
</template>

<script>
import AffiliateSubPopup from '@/components/Popups/AffiliateSubPopup.vue';
import errorHandleMixin from '@/views/mixins/error-hadle';
import { pageSizeMixin, resolvePageSize } from '@/service/pageSize';
import updateUrl from '../../../service/updateUrl';

const sizes = {
  1080: 15,
  1440: 25,
};

const viewName = 'affiliate/affiliate_sub/limit'; // для локалсторедж
const pageSize = resolvePageSize(viewName, {
  _default: 10,
  sizes,
});
export default {
  name: 'AffiliateSub',
  components: {
    AffiliateSubPopup,
  },
  mixins: [pageSizeMixin, errorHandleMixin],
  data() {
    return {
      viewName,
      id: '',
      info: {},
      permissions: {},
      program: {
        referral_program_enabled: true,
        referral_percent: 0,
        referred_by: '',
        referral_payment_type: '',
      },
      isEditPercentOpen: false,
      editingField: '',
      editingValue: 0,
      referralPaymentType: '',
      listReferralPaymentType: ['ggr', 'ngr'],
      isConfirmOpen: false,
      targetAffiliate: {},
      confirmAction: '',
      confirmMsg: '',
      isSelectOpen: false,
      subAffiliates: [],
      page: 1,
      limit: pageSize,
      count: 0,
      sortBy: 'created_at',
      sortDir: 'desc',
      searchQuery: '',
      isDataLoading: true,
      fields: [
        {
          name: 'first_name',
          sortable: false,
          computedClass: () => 'no-sort',
          computedValue(row) {
            return `${row.first_name || ''} ${row.last_name || ''}`;
          },
        },
        {
          name: 'email',
          sortable: 'custom',
          link: this.getAffiliateLink,
        },
        {
          name: 'messenger_id',
          computedValueHtml(row) {
            return `<div class="messenger ${row.messenger || ''}">${row.messenger_id || ''}</div>`;
          },
          minWidth: '100',
          controlType: 'messenger',
        },
        {
          name: 'created_at',
          align: 'left',
          headerAlign: 'left',
          sortable: 'custom',
          format: 'date-time',
        },
      ],
      confirm: {
        title: '',
        msg: '',
        type: 'success',
        action: '',
        actionName: '',
        actionFaIcon: '',
        data: {},
      },
      confirmEdit: {
        title: this.$t('affiliates.affiliateSub.confirm.title_1'),
        msg: this.$t('affiliates.affiliateSub.confirm.msg_1'),
        type: 'success',
        actionFaIcon: 'check',
        action: '',
        actionName: '',
        data: {},
      },
      isPopupOpen: false,
    };
  },
  computed: {
    getReferralPaymentType() {
      return this.info.referral_payment_type?.toUpperCase() || '';
    },
    isUpdateReferralProgram() {
      return this.permissions.update_referral_program;
    },
    tableHeight() {
      if (this.count === 0) {
        return 200;
      }
      return this.count < this.limit ? (this.count * 32) + 38 : (this.limit * 32) + 38;
    },
    offset() {
      return (this.limit * this.page) - this.limit;
    },
    subsQuery() {
      return {
        search: this.searchQuery,
        referred_by: this.info.referral_id,
        limit: this.limit,
        offset: this.offset,
        sort_column: this.sortBy,
        sort_dir: this.sortDir,
      };
    },
  },
  created() {
    /*
    * Проверяем hash
    * */
    const _paramsHash = updateUrl.getParseParams(this.$route.hash);

    if (!this.$_.isEmpty(_paramsHash)) {
      this.searchQuery = this.$_.get(_paramsHash, ['search']) || '';
      this.sortBy = this.$_.get(_paramsHash, ['sort_column']) || 'created_at';
      this.sortDir = this.$_.get(_paramsHash, ['sort_dir']) || 'desc';
      this.limit = Number(this.$_.get(_paramsHash, ['limit'])) || pageSize;
      this.page = Number(this.$_.get(_paramsHash, ['page'])) || 1;
    } else {
      this.limit = +this.$ls.get('affiliate/affiliate_sub/limit') || pageSize;
    }
    this.id = this.$route.params.id;
    if (!this.id) {
      this.$router.push('/affiliates/list');
    }
    this.fetchData();

    this.$eventBus.$on('confirmActivate', this.confirmActivate);
    this.$eventBus.$on('confirmDeactivate', this.confirmDeactivate);
    this.$eventBus.$on('editDefaultPercent', this.editDefaultPercent);
    this.$eventBus.$on('pinSubAffiliate', this.pinSubAffiliate);
    this.$eventBus.$on('confirmUnpin', this.confirmUnpin);
  },

  beforeDestroy() {
    this.$eventBus.$off('confirmActivate', this.confirmActivate);
    this.$eventBus.$off('confirmDeactivate', this.confirmDeactivate);
    this.$eventBus.$off('editDefaultPercent', this.editDefaultPercent);
    this.$eventBus.$off('pinSubAffiliate', this.pinSubAffiliate);
    this.$eventBus.$off('confirmUnpin', this.confirmUnpin);
  },

  methods: {
    checkSensitiveAction(action, row) {
      this.$eventBus.$emit('checkSensitiveAction', action, row);
    },

    getAffiliateLink(row) {
      return `/affiliates/${row.id}/info`;
    },

    onClosePopup() {
      this.isPopupOpen = false;
    },
    confirmDeactivate() {
      this.confirm.title = this.$t('affiliates.affiliateSub.confirm.title_2');
      this.confirm.msg = `${this.$t('affiliates.affiliateSub.confirm.msg_2', { affiliateEmail: this.info.email })}`;
      this.confirm.type = 'warning';
      this.confirm.actionFaIcon = 'check';
      this.confirm.action = 'deactivate';
      this.confirm.actionName = `${this.$t('affiliates.affiliateSub.confirm.action_2')}`;
      this.$refs.confirm.open();
    },
    confirmActivate() {
      this.confirm.title = this.$t('affiliates.affiliateSub.confirm.title_3');
      this.confirm.msg = `${this.$t('affiliates.affiliateSub.confirm.msg_3', { affiliateEmail: this.info.email })}`;
      this.confirm.type = 'success';
      this.confirm.actionFaIcon = 'check';
      this.confirm.action = 'activate';
      this.confirm.actionName = `${this.$t('affiliates.affiliateSub.confirm.action_3')}`;
      this.$refs.confirm.open();
    },
    confirmUnpin(affiliate) {
      this.targetAffiliate = this.$_.clone(affiliate);
      this.confirm.title = `${this.$t('affiliates.affiliateSub.confirm.title_5', { subAffiliateEmail: affiliate.email, affiliateEmail: this.info.email })}`;
      this.confirm.msg = `${this.$t('affiliates.affiliateSub.confirm.msg_5', { subAffiliateEmail: affiliate.email, affiliateEmail: this.info.email })}`;
      this.confirm.type = 'warning';
      this.confirm.actionFaIcon = 'unlink';
      this.confirm.action = 'Unpin';
      this.confirm.actionName = `${this.$t('affiliates.affiliateSub.confirm.action_5')}`;
      this.$refs.confirm.open();
    },
    fetchData() {
      this.isDataLoading = true;
      this.$api
        .getAffiliateInfo(this.id)
        .then((resp) => {
          this.info = this.$_.cloneDeepWith(resp.data.payload);
          this.permissions = this.$_.cloneDeepWith(resp.data.misc.permissions);
          this.program.referral_program_enabled = this.info.referral_program_enabled;
          this.program.referral_percent = this.info.referral_percent;
          this.program.referred_by = this.info.referred_by;
          this.program.referral_payment_type = this.info.referral_payment_type;
          this.fetchSubs();
        })
        .finally(() => {
          this.isDataLoading = false;
        });
    },

    fetchSubs() {
      this.isDataLoading = true;
      /*
      * Вызываем метод update URL
      * */
      this._completedFilterUrl();

      this.$api
        .getAffiliates(this.subsQuery)
        .then((response) => {
          this.count = response.data.misc.count;
          this.subAffiliates = this.$_.cloneDeep(response.data.payload);
        })
        .finally(() => {
          this.isDataLoading = false;
        });
    },
    unpinAffiliate() {
      const pr = {
        referred_by: null,
      };
      this.$api
        .updateAffiliateReferral(this.targetAffiliate.id, pr)
        .then(() => {
          this.fetchData();
          this.$refs.confirm.close();
        });
    },
    pinAffiliate() {
      const pr = {
        referred_by: this.info.referral_id,
      };
      this.$api
        .updateAffiliateReferralMass(this.info.id, pr)
        .then(() => {
          this.fetchData();
          this.$refs.confirm.close();
          this.$refs.subpin.close();
        })
        .catch((error) => {
          this.errors = error.data.errors;
        });
    },
    editDefaultPercent() {
      this.editingValue = this.info.referral_percent;
      this.referralPaymentType = this.info.referral_payment_type;
      this.editingField = 'referral_percent';
      this.$refs.confirmEdit.open();
    },
    pinSubAffiliate() {
      this.isPopupOpen = true;
    },
    actionConfirmEdit() {
      this.$api
        .updateAffiliateReferral(this.info.id, {
          referral_percent: this.editingValue,
          referral_payment_type: this.referralPaymentType,
        })
        .then(() => {
          this.isEditPercentOpen = false;
          this.fetchData();
          this.$refs.confirmEdit.close();
        })
        .catch((error) => {
          this.errors = error.data.errors;
        });
    },
    closeConfirmEdit() {
      this.errors = {};
    },
    handleSort({ prop, order }) {
      if (this.sortBy !== prop) {
        this.sortBy = prop;
      } else {
        this.sortDir = order;
      }
      this.fetchSubs();
    },
    pageChange(page, size) {
      if (this.page !== page || this.limit !== size) {
        this.page = page;
        this.limit = size;
        this.fetchSubs('pageChange');
      }
    },
    actionConfirm(action) {
      if (action === 'deactivate' || action === 'activate') {
        this.isDataLoading = true;
        this.$api
          .updateAffiliateReferral(this.info.id, {
            referral_program_enabled: !this.info.referral_program_enabled,
            referral_percent: Math.min(this.program.referral_percent, this.$store.getters['auth/currentAcl'].affiliates_sub_affiliates_max_percent),
          })
          .then(() => {
            this.fetchData();
            this.$refs.confirm.close();
          })
          .catch((error) => {
            this.errors = this.$_.cloneDeep(error.data.errors);
            this.isDataLoading = false;
          });
      }
      if (action === 'Unpin') {
        this.unpinAffiliate();
      }
    },
    addSubAffiliateHandler() {
      this.isSelectOpen = true;
    },
    /*
    * Создаем filter URL
    * */
    _completedFilterUrl() {
      const _dataFilters = {
        search: this.searchQuery,
        limit: this.limit,
        sort_column: this.sortBy,
        sort_dir: this.sortDir,
        page: this.page,
      };
      updateUrl.updateGetParams(_dataFilters);
    },
  },
};
</script>

<style lang="scss" scoped>
.affiliate-body {
  /deep/ > span {
    white-space: break-spaces!important;
  }
}
.affiliate-sub {
  .wrapper {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    min-width: 1140px;
    width: 90%;
  }
}

.field-error {
  margin-left: 36px;
}
</style>

<template>
  <div class="media-buying-reports">
    <div v-if="affiliate_email" class="toolbar">
      <drilldown-line
        :affiliate-email="affiliate_email"
        :media-buying-channel-name="root_campaign_name"
        @clearDrill="clearDrill"
      />
    </div>
    <div class="toolbar">
      <div class="wrapper">
        <div class="section">
          <div class="select-label">
            <el-date-picker
              v-model="dateRange"
              :picker-options="{
                firstDayOfWeek: 1,
                shortcuts: shortcutsDates,
              }"
              :start-placeholder="$t('reports.other.start_date')"
              :end-placeholder="$t('reports.other.end_date')"
              type="daterange"
              range-separator="-"
              format="dd MMM yyyy"
              class="date-picker"
              @change="handlerDatePicker"
            />
          </div>
          <ui-input
            v-model.trim="search"
            class="select-label"
            :width="180"
            :is-search="true"
            @search="handlerSearch"
          />
        </div>
        <div class="section">
          <ui-button
            v-if="!root_campaign_name"
            lib="fa"
            substyle="fas"
            icon="random"
            class="btn drilldown"
            :disabled="disableDrilldown"
            @click="handlerDrilldown"
          >
            {{ $t('reports.other.drilldown') }}
          </ui-button>
          <ui-pagination
            :page="page"
            :page-size="limit"
            :count="count"
            show-size-select
            @page-change="pageChange"
          />
          <ui-button
            lib="fa"
            substyle="fas"
            icon="sync-alt"
            class="btn"
            @click="refresh"
          >
            {{ $t('reports.other.refresh') }}
          </ui-button>
        </div>
      </div>
    </div>
    <div ref="wrapper" class="table-wrapper">
      <ui-table
        ref="dataTable"
        v-loading="isDataLoading"
        :fields="fields"
        :data="data"
        :currency="currencyCode"
        :rows-count="limit"
        :sort="{
          prop: sortProp,
          order: sortOrder,
        }"
        :total="totals"
        lazy-loading
        show-total
        i18n-path="mediaBuying.reports_table_title"
        element-loading-custom-class="data-loader-spinner"
        :row-selectable="!root_campaign_name"
        :select-row="!root_campaign_name"
        :column-checkbox-width="1"
        :switch-default-check="false"
        checked-row
        :checked-row-id="group_by"
        @row-click="handleCurrentRowClick"
        @sort="handleSort"
        @filter="handlerFilter"
      />
    </div>
  </div>
</template>

<script>
import { pageSizeMixin, resolvePageSize } from '@/service/pageSize';
import updateUrl from '../../service/updateUrl';
import detectPermissions from '../../service/detectPermissions';
import DrilldownLine from '@/views/MediaBuying/components/DrilldownLine.vue';

const sizes = {
  1080: 20,
  1440: 30,
};

const viewName = 'reports/media-buying-reports/limit';
const pageSize = resolvePageSize(viewName, {
  _default: 15,
  sizes,
});

export default {
  name: 'MediaBuyingReports',
  components: { DrilldownLine },
  mixin: [pageSizeMixin],

  data() {
    return {
      viewName,
      limit: pageSize,
      count: 0,
      page: 1,
      sortProp: 'affiliate_email',
      sortOrder: 'desc',
      dateRange: [],
      search: '',
      isDataLoading: true,

      data: [],
      totals: {},
      currencyCode: '',

      group_by: 'affiliate_id',
      group_col: 'affiliate_email',
      nextGrouping: '',

      affiliate_id: '',
      root_campaign_id: '',
      campaign_id: '',
      affiliate_email: '',
      root_campaign_name: '',
      campaign_name: '',
      from: '',
      to: '',

      disableDrilldown: true,
      selectedRow: {},

      blockedSearch: false,
    };
  },

  computed: {
    fields() {
      return [
        {
          name: this.group_col,
          align: 'left',
          minWidth: 160,
          sortable: 'custom',
        },
        {
          name: 'impressions_count',
          align: 'left',
          sortable: 'custom',
        },
        {
          name: 'visits_count',
          align: 'left',
          sortable: 'custom',
        },
        {
          name: 'registrations_count',
          align: 'left',
          sortable: 'custom',
        },
        {
          name: 'first_deposits_count',
          align: 'left',
          sortable: 'custom',
        },
        {
          name: 'first_deposits_sum',
          align: 'left',
          sortable: 'custom',
          format: 'formatMoney',
        },
        {
          name: 'deposits_count',
          align: 'left',
          sortable: 'custom',
        },
        {
          name: 'deposits_sum',
          align: 'left',
          sortable: 'custom',
          format: 'formatMoney',
        },
        {
          name: 'profit_sum',
          align: 'left',
          sortable: 'custom',
          format: 'formatMoney',
        },
        {
          name: 'payments_sum',
          align: 'left',
          sortable: 'custom',
          format: 'formatMoney',
        },
        {
          name: 'total_profit_sum',
          align: 'left',
          sortable: 'custom',
          format: 'formatMoney',
        },
        {
          name: 'cost_per_player',
          align: 'left',
          sortable: 'custom',
          format: 'formatMoney',
        },
      ];
    },

    shortcutsDates() {
      return [
        {
          text: this.$t('reports.date_picker.last_30_day'),
          range: [
            this.$moment().startOf('day').subtract(29, 'd'),
            this.$moment().endOf('day'),
          ],
          onClick(picker) {
            picker.$emit('pick', this.range);
          },
        },
        {
          text: this.$t('reports.date_picker.this_month'),
          range: [this.$moment().startOf('month'), this.$moment().endOf('day')],
          onClick(picker) {
            picker.$emit('pick', this.range);
          },
        },
        {
          text: this.$t('reports.date_picker.prev_month'),
          range: [
            this.$moment().subtract(1, 'months').startOf('month'),
            this.$moment().subtract(1, 'months').endOf('month'),
          ],
          onClick(picker) {
            picker.$emit('pick', this.range);
          },
        },
        {
          text: this.$t('reports.date_picker.last_90_day'),
          range: [
            this.$moment().startOf('day').subtract(89, 'd'),
            this.$moment().endOf('day'),
          ],
          onClick(picker) {
            picker.$emit('pick', this.range);
          },
        },
      ];
    },
  },

  watch: {
    dateRange: {
      deep: true,
      handler(date) {
        if (date) {
          this.page = 1;
          this.from = this.$moment(date[0]).format('YYYY-MM-DD');
          this.to = this.$moment(date[1]).format('YYYY-MM-DD');
        } else {
          this.from = undefined;
          this.to = undefined;
          this.dateRange = null;
        }
      },
    },
  },

  created() {
    this.getDataUrlOrLocalStorage();
    this.getMediaReport();
  },

  methods: {
    handlerDatePicker() {
      this.$nextTick(this.getMediaReport);
    },

    async clearDrill(action) {
      if (action === 'all') {
        this.root_campaign_name = '';
        this.root_campaign_id = '';
        this.affiliate_email = '';
        this.affiliate_id = '';
        this.group_by = 'affiliate_id';
        this.group_col = 'affiliate_email';
        this.sortProp = this.sortProp === 'root_campaign_name'
          || this.sortProp === 'campaign_name'
          ? 'affiliate_email'
          : this.sortProp;
        await this.getMediaReport();
        return;
      }
      if (this.group_by === 'campaign_id') {
        this.root_campaign_name = '';
        this.root_campaign_id = '';
        this.group_by = 'root_campaign_id';
        this.group_col = 'root_campaign_name';
        this.sortProp = this.sortProp === 'campaign_name'
          ? 'root_campaign_name'
          : this.sortProp;
        await this.getMediaReport();
        return;
      }
      if (this.group_by === 'root_campaign_id') {
        this.affiliate_email = '';
        this.affiliate_id = '';
        this.group_by = 'affiliate_id';
        this.sortProp = this.sortProp === 'root_campaign_name'
          ? 'affiliate_email'
          : this.sortProp;
        this.group_col = 'affiliate_email';
        await this.getMediaReport();
      }
    },

    async handlerDrilldown() {
      this.blockedSearch = true;
      this.search = '';
      if (this.group_by === 'affiliate_id') {
        this.affiliate_email = this.selectedRow.affiliate_email || '';
        this.affiliate_id = this.selectedRow.affiliate_id || '';
        this.nextGrouping = 'root_campaign_id';
        this.group_col = 'root_campaign_name';
      }
      if (this.group_by === 'root_campaign_id') {
        this.root_campaign_name = this.selectedRow.root_campaign_name || '';
        this.root_campaign_id = this.selectedRow.root_campaign_id || '';
        this.nextGrouping = 'campaign_id';
        this.group_col = 'campaign_name';
      }
      if (this.group_by === 'campaign_id') {
        this.campaign_name = this.selectedRow.campaign_name || '';
        this.campaign_id = this.selectedRow.campaign_id || '';
        this.nextGrouping = 'xz';
      }

      if (this.nextGrouping === 'root_campaign_id') {
        this.group_by = this.nextGrouping;
        this.sortProp = this.sortProp === 'affiliate_email'
          ? 'root_campaign_name'
          : this.sortProp;
      }
      if (this.nextGrouping === 'campaign_id') {
        this.group_by = this.nextGrouping;
        this.sortProp = this.sortProp === 'root_campaign_name'
          ? 'campaign_name'
          : this.sortProp;
      }
      try {
        await this.getMediaReport();
      } finally {
        setTimeout(() => this.blockedSearch = false, 700);
      }
    },

    handleCurrentRowClick(row) {
      if (this.$_.isEqual(this.selectedRow, row)) {
        this.selectedRow = {};
        this.$refs.dataTable.$refs.dataTable.setCurrentRow();
        this.disableDrilldown = true;
      } else {
        this.selectedRow = row;
        this.$refs.dataTable.$refs.dataTable.setCurrentRow(row);
        this.disableDrilldown = false;
      }
      this.$refs.dataTable.changeRowCheck(row);
      this.$refs.dataTable.clearCheck();

      setTimeout(() => {
        this.customCurrentRow();
      });
    },

    customCurrentRow() {
      const a = document.querySelectorAll('.el-table__row');

      a.forEach((e) => {
        if (e.querySelector('.el-checkbox.is-checked')) {
          e.classList.add('cur');
          e.classList.remove('current-row');
        } else {
          e.classList.remove('cur');
        }
      });
      /* добавил проверка для хрома, т.к. иногда срабатывает листенет
       неодевкатно когда кликаешь, например devtools
      */
      let flag = false;
      a.forEach((e) => {
        if (
          e.querySelector('.el-checkbox.is-checked') /*  || this.isPressCtrl */
        ) {
          flag = true;
        }
      });
      if (!flag) {
        this.rowSelectable = !flag;
      }
    },

    getDataUrlOrLocalStorage() {
      if (detectPermissions.checkRequest('media_buying_reports_view')) {
        // /*
        // * Считывай hash из routers
        // * */
        const _urlData = updateUrl.getParseParams(this.$route.hash);
        const _ls = this.$ls.get('media-buying/reports');

        const init = (data, action) => {
          const keyData = Object.keys(data);
          const { limit = 0, page = 1 } = data;

          this.limit = Number(limit) || pageSize;
          this.page = Number(page) || 1;

          /* для сброса datepicker в undef */
          if (keyData.includes('from') || action === 'url') {
            this.from = this.$_.get(data, ['from']);
          } else {
            this.from = this.$_.get(data, ['from'])
              || this.$moment(this.shortcutsDates[0].range[0]).format('YYYY-MM-DD');
          }
          if (keyData.includes('to') || action === 'url') {
            this.to = this.$_.get(data, ['to']);
          } else {
            this.to = this.$_.get(data, ['to'])
              || this.$moment(this.shortcutsDates[0].range[1]).format('YYYY-MM-DD');
          }
          this.dateRange = this.from ? [this.from, this.to] : null;

          this.group_by = this.$_.get(data, ['group_by']) || this.group_by;
          this.sortProp = this.$_.get(data, ['sort_by']) || this.sortProp;
          this.sortOrder = this.$_.get(data, ['sort_dir']) || this.sortOrder;
          this.search = this.$_.get(data, ['search']) || '';
          this.affiliate_id = this.$_.get(data, ['affiliate_id']) || '';
          this.root_campaign_id = this.$_.get(data, ['root_campaign_id']) || '';
          this.campaign_id = this.$_.get(data, ['campaign_id']) || '';
          this.affiliate_email = this.$_.get(data, ['affiliate_email']) || '';
          this.root_campaign_name = this.$_.get(data, ['root_campaign_name']) || '';
          this.campaign_name = this.$_.get(data, ['campaign_name']) || '';
        };

        if (!this.$_.isEmpty(_urlData)) {
          init(_urlData, 'url');
          if (this.group_by === 'campaign_id') {
            this.group_col = 'campaign_name';
          }
          if (this.group_by === 'root_campaign_id') {
            this.group_col = 'root_campaign_name';
          }
          if (this.group_by === 'affiliate_id') {
            this.group_col = 'affiliate_email';
          }
        } else {
          let from = _ls?.from;
          let to = _ls?.to;
          this.dateRange = _ls?.from ? [from, to] : null;

          if (_ls === null) {
            from = this.$moment(this.shortcutsDates[0].range[0]).format(
              'YYYY-MM-DD',
            );
            to = this.$moment(this.shortcutsDates[0].range[1]).format(
              'YYYY-MM-DD',
            );
            this.dateRange = this.shortcutsDates[0].range;
          }

          const nosort = [
            'root_campaign_name',
            'campaign_name',
          ];
          if (nosort.includes(_ls?.sort_by)) {
            this.sortProp = 'affiliate_email';
            _ls.sort_by = 'affiliate_email';
          }

          init({
            ..._ls,
            search: '',
            from,
            to,
          });
        }
      }
    },

    handleSort({ prop, order }) {
      if (order === '') return;
      if (this.sortProp !== prop) {
        this.sortProp = prop || '';
      } else {
        this.sortOrder = order;
      }

      this.getMediaReport();
    },

    handlerFilter(/* filter, action */) {
      // console.log(filter, action);
    },

    handlerSearch() {
      if (!this.blockedSearch) {
        this.page = 1;
        this.getMediaReport();
      }
    },

    refresh() {
      this.getMediaReport();
    },

    pageChange(page, size) {
      if (this.page !== page || this.limit !== size) {
        this.page = page;
        this.limit = size;
        this.$nextTick(() => {
          this.getMediaReport();
        });
      }
    },

    async getMediaReport() {
      this.isDataLoading = true;

      const params = this._query();
      try {
        const {
          data: { payload, misc },
        } = await this.$api.getMediaBuyingReports(params);
        const { data, totals } = payload;

        this.count = misc.count;
        this.currencyCode = misc.currency_code;
        this.data = data;
        this.totals = totals;
        this._updateCompletedUrl();
      } finally {
        this.isDataLoading = false;

        this.selectedRow = {};
        this.handleCurrentRowClick({});
      }
    },

    _query() {
      return {
        group_by: this.group_by,
        sort_column: this.sortProp,
        sort_dir: this.sortOrder,
        search: this.search,
        affiliate_id: this.affiliate_id,
        root_campaign_id: this.root_campaign_id,
        campaign_id: this.campaign_id,
        limit: this.limit,
        offset: this.limit * this.page - this.limit,
        from: this.from,
        to: this.to,
        metrics_format: 'pretty',
      };
    },

    _updateCompletedUrl() {
      const _data = {
        from: this.dateRange
          ? this.$moment(this.dateRange[0]).format('YYYY-MM-DD')
          : undefined,
        to: this.dateRange
          ? this.$moment(this.dateRange[1]).format('YYYY-MM-DD')
          : undefined,
        limit: this.limit,
        sort_by: this.sortProp,
        sort_dir: this.sortOrder,
      };

      const dataOnlyURL = {
        group_by: this.group_by,
        sort_by: this.sortProp,
        sort_dir: this.sortOrder,
        search: this.search,
        affiliate_id: this.affiliate_id,
        root_campaign_id: this.root_campaign_id,
        campaign_id: this.campaign_id,
        affiliate_email: this.affiliate_email,
        root_campaign_name: this.root_campaign_name,
        campaign_name: this.campaign_name,
        page: this.page,
      };

      this.$ls.set('media-buying/reports', _data);
      updateUrl.updateGetParams({ ..._data, ...dataOnlyURL });
    },
  },
};
</script>

<template>
  <div class="live-lastBets">
    <div class="content">
      <div class="column left">
        <div class="head">
          <div class="title">
            {{ $t('live.tables.last20bets') }}
          </div>
        </div>
        <div class="table-wrapper">
          <ui-table
            v-loading="last20bets.isDataLoading"
            :lazy-top="-42"
            :lazy-loading-delay="2000"
            :fields="last20bets.fields"
            :data="last20bets.data"
            :currency="last20bets.currencyCode"
            lazy-loading
            disable-sort
            i18n-path="live.list"
            element-loading-custom-class="data-loader-spinner"
          />
        </div>
      </div>
      <div class="column right">
        <div class="head">
          <div class="title title__with-refresh">
            {{ $t('live.tables.last20deposits') }}
            <ui-button
              :disabled="dasabledRefresh"
              icon="sync-alt"
              color="green"
              lib="fa"
              class="btn"
              @click="handleRefresh"
            >
              {{ $t('reports.other.refresh') }}
            </ui-button>
          </div>
        </div>

        <div class="table-wrapper">
          <ui-table
            v-loading="last20deposits.isDataLoading"
            :lazy-loading-delay="2000"
            :fields="last20deposits.fields"
            :data="last20deposits.data"
            :currency="last20deposits.currencyCode"
            lazy-loading
            disable-sort
            i18n-path="live.list"
            element-loading-custom-class="data-loader-spinner"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import detectPermissions from '../../../service/detectPermissions';

export default {
  name: 'PlayerLast',
  data() {
    return {
      id: '',
      last20bets: {
        fields: [
          {
            name: 'events',
            align: 'left',
            headerAlign: 'left',
            format: 'event',
          },
          {
            name: 'events_count',
            align: 'left',
            headerAlign: 'left',
            width: '100',
            format: 'eventType',
          },
          {
            name: 'player_country_name',
            align: 'left',
            headerAlign: 'right',
            width: '100',
          },
          {
            name: 'bet_sum',
            align: 'right',
            headerAlign: 'left',
            width: '100',
            class: 'bold',
            format: 'formatMoney',
          },
          {
            name: 'coefficient',
            align: 'right',
            headerAlign: 'left',
            width: '80',
            class: 'bold',
          },
          {
            name: 'timestamp',
            align: 'left',
            headerAlign: 'left',
            width: '120',
            class: 'bold',
            format: 'date-time',
          },
        ],
        data: [],
        currencyCode: '',
        sortProp: '',
        sortOrder: '',
        isDataLoading: false,
      },
      last20deposits: {
        fields: [
          {
            name: 'player_country_name',
            align: 'left',
            headerAlign: 'left',
          },
          {
            name: 'deposit_sum',
            align: 'right',
            headerAlign: 'left',
            width: '100',
            format: 'formatMoney',
          },
          {
            name: 'payment_system_name',
            align: 'left',
            headerAlign: 'left',
            width: '100',
            class: 'method logo',
          },
          {
            name: 'timestamp',
            align: 'left',
            headerAlign: 'left',
            width: '120',
            class: 'bold',
            format: 'date-time',
          },
        ],
        data: [],
        currencyCode: '',
        sortProp: '',
        sortOrder: '',
        isDataLoading: false,
      },
      refresh: true,
      dasabledRefresh: false,
      counterId: '',
      refreshInterval: 30000,
    };
  },

  watch: {
    refresh(val) {
      if (val) {
        this.autoRefreshOn();
      } else {
        this.autoRefreshOff();
      }
    },
  },
  created() {
    if (detectPermissions.checkRequest('last_bets_and_deposits_view')) {
      this.id = this.$route.params.id;
      this.getNewSportBets();
      this.getNewDeposits();
      this.autoRefreshOn();
    }
    detectPermissions.checkRoute('last_bets_and_deposits_view');
  },
  mounted() {
  },
  beforeDestroy() {
    this.autoRefreshOff();
  },
  methods: {
    autoRefreshOn() {
      const counterId = setInterval(() => {
        this.getNewSportBets();
        this.getNewDeposits();
      }, this.refreshInterval);
      this.counterId = counterId;
    },
    autoRefreshOff() {
      clearInterval(this.counterId);
    },
    handleRefresh() {
      this.getNewDeposits();
      this.getNewSportBets();
      this.dasabledRefresh = true;
      setTimeout(() => {
        this.dasabledRefresh = false;
      }, 8000);
    },
    getNewSportBets() {
      this.last20bets.isDataLoading = true;
      this.$api
        .getNewSportBets({ player_id: this.id })
        .then((response) => {
          this.last20bets.data = this.$_.cloneDeep(response.data.payload);
          this.last20bets.currencyCode = response.data.misc.currency_code;
        })
        .finally(() => {
          this.last20bets.isDataLoading = false;
        });
    },
    getNewDeposits() {
      this.last20deposits.isDataLoading = true;
      this.$api
        .getNewDeposits({ player_id: this.id })
        .then((response) => {
          this.last20deposits.data = this.$_.cloneDeep(response.data.payload);
          this.last20deposits.currencyCode = response.data.misc.currency_code;
        })
        .finally(() => {
          this.last20deposits.isDataLoading = false;
        });
    },
  },
};
</script>

import store from '@/store';


const stateData = () => ({
  panelSettings: {
    telegram_bot: {},
  },
});

const getters = {
  reportsSettings: state => state.panelSettings.reports || {},
  panelSettings: state => state.panelSettings || {},
};


const mutations = {
  getSettings(state, settings) {
    state.panelSettings = settings;
  },
};

const actions = {
  async getSettings({ commit }) {
    try {
      if (store.getters['auth/adminAcl'].is_superuser
      || store.getters['auth/adminAcl'].teams.length) {
        const { data: { payload } } = await this.$api.getSettings();
        commit('getSettings', payload);
      }
    } catch (error) {
      commit('getSettings', {});
    }
  },
};

export default {
  state: stateData,
  getters,
  mutations,
  actions,
  namespaced: true,
};

<template>
  <div
    v-if="detectPermissions.checkRequest('reports_view')"
    v-loading="isDataLoading"
    class="custom-report"
  >
    <custom-report-column-popup
      ref="columnPopupComponent"
      :all-metrics="allMetrics"
      :all-metrics-static="allMetricsStatic"
      :all-metrics-clone="allMetricsClone"
      :column-group="columnGroup"
      :obj-group="objGroup"
      :group-by="reports[currentReport].group_by"
      :reports-settings="reportsSettings"
      @reset="resetColumnsOrder"
      @save="applyColumnsSettings"
      @cancel="cancelColumnsSettings"
    />

    <context-menu
      ref="context"
      :is-context-open="isContextOpen"
      :select-rows="selectRows"
      :selected-row="selectedRow"
      :select-ids="selectIds"
      :disable-drilldown="disableDrilldown"
      :current-report="currentReport"
      :is-payment-group="isPaymentGroup"
      :position="{ contextLeft, contextTop }"
      @openPR="openPR"
      @showPlayersReport="showPlayersReport"
      @createReport="createReport"
      @drilldownReport="drilldownReport"
    />

    <div ref="tools">
      <div class="toolbar tabs">
        <tabs-report
          ref="tabsReport"
          :reports.sync="reports"
          :bookmarks.sync="bookmarks"
          :current-report="Number($ls.get(`currentReport/${currentGroup}`))"
          :shortcuts-dates="shortcutsDates()"
          @isVisible="isVisibleTabControl"
          @getReport="getReport"
          @selectDateRange="selectDateRange"
          @resetHomeReport="resetHomeReport"
          @updateReport="removeReport"
          @selectReport="selectReport"
          @saveBookmark="saveBookmark"
          @removeItemCurrentFilter="removeItemCurrentFilter"
          @setTabsCurrentReport="setTabsCurrentReport"
          @resetRows="resetRows"
        >
          <div class="tabs-right-btn">
            <div v-if="reports.length >= 3" @click="removeAllReport($event)">
              <span class="tabs-right-close">
                <ui-icon
                  :size="14"
                  class="tab-action"
                  :color="$theme.dangerColor"
                  lib="fa"
                  substyle="fas"
                  name="times"
                />
                {{ $t('reports.other.close_all') }}
              </span>
            </div>
            <bookmark
              ref="bookmarks"
              class="bookmark-tab"
              :bookmarks.sync="bookmarks"
              :reports="reports"
              :current-report="currentReport"
              :is-loading-bookmark="isLoadingBookmark"
              @saveBookmark="saveBookmark"
              @createReport="createReport"
            />
          </div>
        </tabs-report>
      </div>

      <drilldown-filter
        v-if="!$_.isEmpty(reports[currentReport].currentFilter)"
        :reports="reports"
        :current-report="currentReport"
        @clearCurrentFilter="clearCurrentFilter"
        @removeItemCurrentFilter="removeItemCurrentFilter"
        @removeCurrentFilter="removeCurrentFilter"
      />

      <grouping-report
        ref="groupingReport"
        :reports="reports"
        :current-report="currentReport"
        :all-dimensions="allDimensions"
        :grouping-selects="groupingSelects"
        :is-affiliate-group="isAffiliateGroup"
        :is-sub-affiliate-group="isSubAffiliateGroup"
        :is-country-group="isCountryGroup"
        :is-payment-group="isPaymentGroup"
        :supported-dimensions="supportedDimensions"
        @handleGroupingSelect="handleGroupingSelect"
      />

      <div class="toolbar tools toolbar-last">
        <div class="wrapper">
          <div class="section">
            <el-date-picker
              id="range_picker"
              ref="datepicker"
              v-model="pickerDateRange"
              :picker-options="{
                firstDayOfWeek: 1,
                shortcuts: shortcutsDates(),
              }"
              :start-placeholder="$t('reports.other.start_date')"
              :end-placeholder="$t('reports.other.end_date')"
              name="range_picker"
              type="daterange"
              range-separator="-"
              format="dd MMM yyyy"
              class="date-picker"
              @change="selectDateRange"
            />
            <el-tooltip
              class="item"
              popper-class="search-tooltip"
              effect="light"
              placement="bottom-start"
              :disabled="isSearchAvailable"
            >
              <div slot="content">
                {{ $t('reports.other.filters_is_using') }}
                <span
                  class="reset-current-filter"
                  @click="resetCurrentFilter()"
                >
                  {{ $t('reports.other.reset') }}
                </span>
              </div>
              <ui-input
                ref="searchRef"
                v-model.trim="reports[currentReport].searchQuery.value"
                :disabled="!isSearchAvailable"
                :is-search="switchSearchMod"
                class="search-input"
                @search="blockedSearch ? false : getReport()"
              />
            </el-tooltip>
          </div>
          <div class="section">
            <!-- exclude -->
            <drilldown-button
              type-action="exclude"
              :report="reports[currentReport]"
              :disabled-btn="$_.isEmpty(selectedRow) && selectIds.length === 0"
              :disabled-dropdown="disableDrilldown || currentReport === 0"
              @createReport="createReport"
              @drilldownReport="drilldownReport"
            />

            <!-- drilll -->
            <drilldown-button
              class="last"
              type-action="drilldown"
              :report="reports[currentReport]"
              :disabled-btn="$_.isEmpty(selectedRow) && selectIds.length === 0"
              :disabled-dropdown="disableDrilldown || currentReport === 0"
              @createReport="createReport"
              @drilldownReport="drilldownReport"
            />

            <ui-pagination
              ref="pagination"
              :page="reports[currentReport].page"
              :page-size="reports[currentReport].limit"
              :count="count === 0 ? 1 : count"
              show-size-select
              @page-change="pageChange"
            />

            <!-- Report settings popover -->

            <el-popover
              v-model="isSettingsVisible"
              placement="bottom"
              trigger="manual"
              transition="el-zoom-in-top"
              popper-class="report_settings"
            >
              <ui-button
                ref="settingsMain"
                slot="reference"
                :filled="isReportingSettings"
                class="btn empty filter-button"
                lib="fa"
                substyle="fas"
                icon="filter"
                @click="popoverTrigger"
              >
                <div
                  class="clear-search fa fas fa-times"
                  @click="resetReportingSettings($event)"
                />
              </ui-button>
              <div class="filter-wrapper">
                <span class="title">{{ $t('reports.other.traffic') }}</span>
                <div class="tab-settings-filter">
                  <el-radio-group v-model="currentTrafficType" size="small">
                    <el-radio-button
                      v-for="item in trafficTypes"
                      :key="item.value"
                      :label="item.value"
                    >
                      {{ $t(`reports.traffic_type.${item.value}`) }}
                    </el-radio-button>
                  </el-radio-group>
                </div>
                <span class="title">{{ $t('reports.other.player') }}</span>
                <div class="tab-settings-filter">
                  <el-radio-group v-model="currentPlayersType" size="small">
                    <el-radio-button
                      v-for="item in playersTypes"
                      :key="item.value"
                      :label="item.value"
                    >
                      {{ $t(`reports.players_type.${item.value}`) }}
                    </el-radio-button>
                  </el-radio-group>
                </div>
                <!-- Affiliate created_at global filter -->
                <span class="title">{{
                  $t('reports.other.affiliates_created_at')
                }}</span>
                <div class="tab-settings-filter created-at-filter">
                  <el-radio-group
                    v-model="currentAffiliateDateOperator"
                    size="small"
                  >
                    <el-radio-button
                      v-for="affiliateDateOperator of affiliateDateOperators"
                      :key="affiliateDateOperator"
                      :label="affiliateDateOperator"
                    />
                  </el-radio-group>

                  <el-date-picker
                    :key="`affiliate-created-at_${currentAffiliateDateInputType}`"
                    v-model="currentAffiliateDateValue"
                    :type="currentAffiliateDateInputType"
                    :start-placeholder="$t('reports.other.start_date')"
                    :end-placeholder="$t('reports.other.end_date')"
                    :placeholder="$t('reports.other.enter_date')"
                    format="dd MMM yyyy"
                    size="small"
                    class="affiliate-created-at__filter-input date-picker"
                  />
                </div>
                <span class="title">{{
                  $t('reports.other.search_by.title')
                }}</span>
                <filter-tabs
                  :filter-list="defaultFilterList"
                  :current-search="currentSearch"
                  :reports-settings="reportsSettings"
                  :search-by-list="searchByList"
                  :is-searching-filter="isSearchingFilter"
                  @deleteCurrentSearchGroup="clearReportsSearchFilter"
                  @selectedSearchFilter="selectedSearchFilter"
                />
              </div>

              <div class="report_settings-btn-wrap">
                <ui-button
                  class="btn"
                  color="red"
                  lib="fa"
                  substyle="fas"
                  icon="times"
                  @click="resetReportingSettings($event)"
                >
                  {{ $t('reports.other.reset') }}
                </ui-button>
                <ui-button
                  class="btn"
                  filled
                  lib="fa"
                  substyle="fas"
                  icon="check"
                  @click="applyReportingSettings"
                >
                  {{ $t('reports.other.apply') }}
                </ui-button>
              </div>
            </el-popover>
            <div class="select">
              <ui-button
                class="btn"
                lib="fa"
                substyle="fas"
                icon="eye"
                @click="openColumnsPopup"
              />
            </div>

            <export-data :data="configExport" @exportData="exportData" />

            <ui-button
              lib="fa"
              substyle="fas"
              icon="sync-alt"
              class="export-btn"
              @click="refrashReport"
            >
              {{ $t('reports.other.refresh') }}
            </ui-button>
          </div>
        </div>
      </div>
    </div>

    <ui-table
      ref="dataTable"
      :lazy-loading-delay="4000"
      :fields="getTableFields"
      :data="data"
      :total="totals"
      :rows-count="reports[currentReport].limit"
      :sort="{
        prop: reports[currentReport].sort_by,
        order: reports[currentReport].sort_dir,
      }"
      :custom-sorting="customSorting"
      :row-selectable="rowSelectable"
      :select-row="multiCheckedRow"
      :column-checkbox-width="1"
      :switch-default-check="false"
      checked-row
      checked-row-id="row_id"
      lazy-loading
      show-total
      custom-context
      element-loading-custom-class="data-loader-spinner"
      @checkedRows="handleCheckedRows"
      @row-click="handleCurrentRowClick"
      @sort="handleSort"
      @filter="handleFilter"
      @context="rowContextHandler"
      @headerDragend="headerDragend"
      @update="resizeHeaderDragend()"
    />
  </div>
  <!-- i18n-path="reports" -->
</template>

<script>
import _ from 'lodash/function';
import { setTimeout } from 'timers';
import { mapState, mapGetters } from 'vuex';
import semver from 'semver';
import { NAV_SET_SUB_VIEW } from '@/store/action_types/nav';
// import ReportsFilterPopup from '@/components/Popups/ReportsFilterPopup.vue';
import CustomReportColumnPopup from '@/components/Popups/CustomReportColumnPopup.vue';
import ContextMenu from '@/components/ContextMenu';
import TabsReport from '@/components/TabsReport';
import Bookmark from '@/components/Bookmark';
import DrilldownFilter from '@/components/DrilldownFilter';
import DrilldownButton from '@/components/DrilldownButton';
import ExportData from '@/components/ExportData';
import GroupingReport from '@/components/GroupingReport';
import Config from './reportSettings';
import detectPermissions from '../../service/detectPermissions';
import { version } from '../../../package.json';
import { resolvePageSize } from '@/service/pageSize';
import { reconstructionFilter } from '@/helpers/custom-reports';
import dragendColumnMixin from '@/components/UIElements/UiTableHelper/dragendColumnMixin';
import FilterTabs from '@/components/FilterTabs.vue';

const sizes = {
  1080: 20,
  1440: 30,
};
const viewName = 'report/list/limit'; // для локалсторедж
const pageSize = resolvePageSize(viewName, {
  _default: 15,
  sizes,
});

export default {
  name: 'MainReport',
  components: {
    CustomReportColumnPopup,
    ContextMenu,
    TabsReport,
    Bookmark,
    DrilldownFilter,
    GroupingReport,
    DrilldownButton,
    ExportData,
    // ReportsFilterPopup,
    FilterTabs,
  },
  mixins: [dragendColumnMixin],
  data() {
    return {
      detectPermissions,

      viewCustom: 'customReport',
      fieldsLocalStorageKey: 'customReport/width-column',
      tableFieldsEx: [...Config.allMetrics, ...Config.allDimensions],
      fields: [],
      updateTableFields: 34324,

      typeFlag: '',
      switchSearchMod: true,
      flagIsFetch: false,
      ver: '',
      isPressCtrl: false,
      selectRows: [],
      rowSelectable: false,
      selectIds: [],
      selectId: '',
      multiCheckedRow: false,
      isLoadingBookmark: false,
      customSorting: true,
      data: [],
      totals: {},
      count: 1,
      isDataLoading: false,
      isContextOpen: false,
      contextLeft: 0,
      contextTop: 0,
      exportUrls: {},
      configExport: [
        {
          command: 'csv',
          label: 'CSV',
        },
        {
          command: 'xlsx',
          label: 'XLSX',
        },
      ],
      supportedDimensions: [],
      allSupportedColumns: [],
      supportedColumns: [],
      supportedAditionalColumns: {},
      allDimensions: [],
      allMetrics: [],
      allMetricsStatic: [],
      allMetricsClone: [],
      reports: [
        {
          bookmarkName: '',
          group_by: 'date_formatted',
          nextDimension: {},
          dateRange: [],
          search: {},
          searchQuery: {
            value: '',
            group: '',
          },
          limit: pageSize,
          page: 1,
          sort_by: 'date_formatted',
          sort_dir: 'desc',
          defaultFilter: {},
          currentFilter: {},
          columnsFilter: {},
          isPinned: false,
          isPinnedBookmark: false,
          trafficType: 'all',
          playersType: 'all',
          affiliateCreatedAt: {
            currentAffiliateDateOperator: '=',
            affiliateCreatedAtStart: undefined,
            affiliateCreatedAtEnd: undefined,
          },
          reportMetrics: [],
        },
      ],
      currentReport: 0,
      singleGroupingSelect: '',
      groupingSelects: { datetime: 'date_formatted' },
      selectedRow: {},
      disableDrilldown: true,
      pickerDateRange: [],
      playersTypes: [
        {
          value: 'all',
        },
        {
          value: 'new',
        },
        {
          value: 'old',
        },
      ],
      trafficTypes: [
        {
          value: 'all',
        },
        {
          value: 'affiliates',
        },
        {
          value: 'direct',
        },
      ],
      affiliateDateOperators: ['≤', '=', '≥'],
      isSettingsVisible: false,
      currentTrafficType: 'all',
      currentPlayersType: 'all',
      currentSearch: {},
      isOpenOtherPopup: false,
      currentAffiliateDateOperator: '=',
      currentAffiliateDateValue: null,

      defaultFilterList: {
        affiliate_email: {
          icon: 'user',
        },
        sub_affiliate_email: {
          icon: 'users',
        },
        site_player_id: {
          icon: 'id-badge',
        },
        referrer_domain: {
          icon: 'globe',
        },
      },

      bookmarks: [],

      columnGroup: [],
      columns_visibility: [],
      objGroup: {},
      allMetricsFlug: false,
      randomCreate: {
        url: 0,
        storage: 1,
      },
      savePreviousReport: {
        index: Number,
        data: {},
      },
    };
  },
  computed: {
    ...mapGetters('reports', ['reportsSettings']),
    ...mapState('other', ['currentReportClone', 'reportsClone', 'isDragged']),

    configAllMetrics() {
      const arrAllGroupMetric = [];
      this.reportsSettings.grouped_metrics.forEach((e) => {
        e.items.forEach((elItem) => {
          arrAllGroupMetric.push(elItem.name);
          if (elItem.items !== undefined) {
            elItem.items.forEach(elSubItem => arrAllGroupMetric.push(elSubItem.name));
          }
        });
      });

      const configAllMetrics = this.reportsSettings.metrics.map((metric) => {
        const objMetric = Config.allMetrics.find(
          elem => elem.name === metric.column_name,
        );

        const findRecurs = (items, column) => {
          if (!items) {
            return undefined;
          }

          return items.find(item => item.name === column || findRecurs(item.items, column));
        };

        const { group_name } = this.reportsSettings.grouped_metrics.find(el => findRecurs(el.items, metric.column_name)) || {};

        const includeColumnGrouped = arrAllGroupMetric.includes(
          metric.column_name,
        );

        if (!includeColumnGrouped) {
          return null;
        }

        if (objMetric) {
          return { ...metric, ...objMetric, group: group_name };
        }
        return {
          ...Config.metricExaple,
          ...metric,
          name: metric.column_name,
          group: group_name,
        };
      }) || [];

      return configAllMetrics.filter(conf => conf !== null);
    },

    currentAffiliateDateInputType() {
      return this.currentAffiliateDateOperator === '=' ? 'daterange' : 'date';
    },
    currentGroup() {
      return !this.$_.isEmpty(this.$store.getters['auth/currentTeam'])
        ? this.$store.getters['auth/currentTeam']
        : 'allTeams';
    },
    isAffiliateGroup() {
      return this.checkGroup('reports_group_by_affiliates');
    },
    isSubAffiliateGroup() {
      return this.checkGroup('reports_group_by_sub_affiliates');
    },
    isCountryGroup() {
      return this.checkGroup('reports_group_by_countries');
    },
    isPaymentGroup() {
      return this.checkGroup('reports_group_by_payments');
    },

    searchByList() {
      return this.reportsSettings.dimensions.reduce((acc, el) => {
        if (this.defaultFilterList.hasOwnProperty(el.column_name)) {
          acc[el.column_name] = this.defaultFilterList[el.column_name];
        }
        return acc;
      }, {});
    },

    isSearchingFilter() {
      const filtersList = Object.keys(this.searchByList);
      return filtersList.reduce((acc, elem) => {
        acc[elem] = this.$_.has(this.currentSearch, elem);
        return acc;
      }, {});
    },

    isSearchAvailable() {
      const filtersList = Object.keys(this.reports[this.currentReport].search);
      const currentGroup = this.reports[this.currentReport].group_by;
      return !filtersList.includes(currentGroup);
    },

    isReportingSettings() {
      let isActiveFilter = false;
      const filtersList = Object.keys(this.searchByList);
      const reportSearch = this.reports[this.currentReport].search;

      filtersList.forEach((element) => {
        if (this.$_.has(reportSearch, element)) {
          isActiveFilter = true;
          return false;
        }
        return false;
      });

      return (
        (this.$_.has(this.reports[this.currentReport], 'trafficType')
          && this.$_.has(this.reports[this.currentReport], 'playersType')
          && (this.reports[this.currentReport].trafficType !== 'all'
            || this.reports[this.currentReport].playersType !== 'all'
            || this.reports[this.currentReport].affiliateCreatedAt
              .affiliateCreatedAtStart !== undefined
            || this.reports[this.currentReport].affiliateCreatedAt
              .affiliateCreatedAtEnd !== undefined))
        || isActiveFilter
      );
    },

    groupByField() {
      const gb = this.reports[this.currentReport].group_by;
      return this.allDimensions.find(field => field.name === gb);
    },
    getTableFields() {
      let fields = [
        ...this.$_.filter(
          this.reports[this.currentReport].reportMetrics,
          el => el.selected && this.allSupportedColumns.includes(el.name),
        ),
      ];

      fields.forEach((item) => {
        item.sortable = 'custom';
        item.align = 'right';
        if (item.name === 'profit_sum') {
          item.computedClass = this.getCurrencyClass;
        }
      });
      fields = this.$_.sortBy(fields, 'order');

      fields.unshift(this.groupByField);
      fields[0].sortable = 'custom';
      /**
       * TODO:  Надо доделать логику по ширине колонок, незабыть про дергания
       */
      const lsFields = null; /* this.$ls.get(this.fieldsLocalStorageKey) */
      if (lsFields) {
        const getColumnFixWidth = lsFields.filter(
          column => column.width || column.forceUpdateKey,
        );
        getColumnFixWidth.forEach((fixColumn) => {
          const indexColumn = fields.findIndex(
            elem => fixColumn.name === elem.name,
          );
          fields[indexColumn] = { ...fields[indexColumn], ...fixColumn };
        });
      }
      const everyWidth = fields.every(elem => elem.width !== undefined);
      if (everyWidth) delete fields[0].width;
      // eslint-disable-next-line
      this.fields = fields;

      if (this.$refs.dataTable && this.$refs.dataTable.$refs.dataTable) {
        this.$nextTick(this.$refs.dataTable.$refs.dataTable.doLayout);
      }
      if (this.updateTableFields === 323) return fields;
      return fields;
    },
  },

  watch: {
    currentAffiliateDateInputType() {
      this.currentAffiliateDateValue = null;
    },

    isSettingsVisible(v) {
      if (v) {
        this.currentTrafficType = this.reports[this.currentReport].trafficType;
        this.currentPlayersType = this.reports[this.currentReport].playersType;
        this.currentSearch = this.$_.cloneDeep(
          this.reports[this.currentReport].search,
        );
        if (
          this.reports[this.currentReport].searchQuery.value
          && Object.keys(this.currentSearch).includes(
            this.reports[this.currentReport].group_by,
          )
        ) {
          this.$delete(
            this.currentSearch,
            this.reports[this.currentReport].group_by,
          );
        }

        this.currentAffiliateDateOperator = this.reports[
          this.currentReport
        ].affiliateCreatedAt.currentAffiliateDateOperator;
        setTimeout(() => {
          if (this.currentAffiliateDateOperator === '=') {
            this.currentAffiliateDateValue = [
              this.reports[this.currentReport].affiliateCreatedAt
                .affiliateCreatedAtStart,
              this.reports[this.currentReport].affiliateCreatedAt
                .affiliateCreatedAtEnd,
            ];
          } else if (this.currentAffiliateDateOperator === '≥') {
            this.currentAffiliateDateValue = this.reports[
              this.currentReport
            ].affiliateCreatedAt.affiliateCreatedAtStart;
          } else {
            this.currentAffiliateDateValue = this.reports[
              this.currentReport
            ].affiliateCreatedAt.affiliateCreatedAtEnd;
          }
        });
      }
    },
    allMetricsFlug(v) {
      if (v) {
        this.getReport('watch');
      }
    },
    tableHeight() {
      this.computeTableRows();
    },
    reports: {
      deep: true,
      handler() {
        this.$ls.set(`reports/${this.currentGroup}`, this.reports
          ?.map(report => ({
            ...report,
            reportMetrics: report.reportMetrics?.filter(el => el.selected || el.shadowSelected) || [],
          })
          ));
      },
    },
    allMetrics: {
      deep: true,
      handler(v) {
        this.reports[this.currentReport].reportMetrics = v;

        if (v.length && this.allMetricsFlug) {
          this.$ls.set(`reportMetrics4/${this.currentGroup}`, v);
        }
      },
    },

    currentReport() {
      this.$refs.dataTable.clearCheck();
      this.generateRandom();
      this.allMetrics = this.$_.filter(
        this.$_.map(
          this.allSupportedColumns,
          i => this.$_.filter(this.allMetrics, { name: i })[0],
        ),

        n => !!n,
      );
      this.$ls.set(`currentReport/${this.currentGroup}`, this.currentReport);
      /*
       * проверка для старых букмарков в которые еще не поредовалить колонки
       * */
      if (
        this.$_.cloneDeep(this.reports[this.currentReport].reportMetrics)
        !== undefined
      ) {
        this.allMetrics = this.metricsTranslate(
          this.reports[this.currentReport].reportMetrics,
        );
      } else {
        this.allMetrics = this.$_.cloneDeep(this.defaultColumn);
      }
    },
    singleGroupingSelect() {
      this.$ls.set('singleGroupingSelect', this.singleGroupingSelect);
    },
    groupingSelects() {
      this.$refs.dataTable.clearCheck();
      this.selectRows = [];
      this.selectIds = [];
      this.isContextOpen = false;
      this.disableDrilldown = true;
      this.reports = [...this.reports];
      this.$ls.set(
        `groupingSelects/${this.currentGroup}`,
        this.groupingSelects,
      );
    },
  },

  async created() {
    this.$eventBus.$on('changeLocale', this.changeLocale);
    await this.setInit();
  },

  destroyed() {
    document.removeEventListener('click', this.eventStopSetting, true);
    document.removeEventListener('click', this.pressCtrl, true);
    document.removeEventListener('keydown', this.switchMultiSelect, true);
    document.removeEventListener('keyup', this.switchMultiSelect, true);
    document.removeEventListener('click', this.clickOutsideContext, false);
    window.removeEventListener('beforeunload', this.updatedDraggedTab);
    window.removeEventListener('resize', this.resizeTabMove);
    window.removeEventListener('keyup', this.escHandler);
    this.$eventBus.$off('emitGetBookmarksSearch');
    this.$eventBus.$off('changeLocale');
    this.hiddenScroll('remove');
  },

  beforeRouteLeave(to, from, next) {
    this.updatedDraggedTab(this.currentReport);
    this.$nextTick(() => next());
  },

  mounted() {
    this.ver = version;
    if (detectPermissions.checkRequest('reports_view')) {
      this.$eventBus.$on('emitGetBookmarksSearch', this.searchBookmark);
      this.getBookmarks();
      document.addEventListener('click', this.clickOutsideContext, false);

      this.$refs.dataTable.setColumnFilters(
        this.reports[this.currentReport].columnsFilter,
      );

      document.addEventListener('click', this.pressCtrl, true);
      document.addEventListener('keydown', this.switchMultiSelect, true);
      document.addEventListener('keyup', this.switchMultiSelect, true);
      window.addEventListener('beforeunload', this.updatedDraggedTab);
      window.addEventListener('resize', this.resizeTabMove);
      window.addEventListener('keyup', this.escHandler);
    }
  },

  methods: {
    async changeLocale() {
      this.isDataLoading = true;
      await this.$store.dispatch('reports/getSettings');
      await this.setInit();
      this.refrashReport();
    },

    resetCurrentFilter() {
      this.$delete(
        this.reports[this.currentReport].search,
        this.reports[this.currentReport].group_by,
      );
      this.getReport();
    },
    popoverTrigger() {
      this.isSettingsVisible = !this.isSettingsVisible;
    },
    escHandler(e) {
      if (
        e.key === 'Escape'
        && this.isSettingsVisible
        && !this.isOpenOtherPopup
      ) {
        this.popoverTrigger();
      }
    },

    selectedSearchFilter(search) {
      this.currentSearch = this.$_.cloneDeep(search);
    },

    bookmarksFiltredVersion(bookmarks) {
      const fromVersion = '2.29.1';

      return bookmarks.filter(bm => semver.gt(bm.version, fromVersion));
    },

    transletaGrouping() {
      const objKey = Object.keys(this.groupingSelects)[0];
      const dimCurrent = this.reports[this.currentReport].group_by;
      this.groupingSelects[objKey] = {
        ...this.groupingSelects[objKey],
        ...this.reportsSettings.dimensions.find(
          elem => elem.column_name === dimCurrent,
        ),
      };
    },

    async setInit() {
      this.hiddenScroll('add');
      if (detectPermissions.checkRequest('reports_view')) {
        if (
          !this.isAffiliateGroup
          && this.$ls.get(`groupingSelects/${this.currentGroup}`) !== null
          && this.$ls.get(`groupingSelects/${this.currentGroup}`).affiliate
          && this.$ls.get(`groupingSelects/${this.currentGroup}`).affiliate
            .name === 'affiliate_email'
        ) {
          const keysToRemove = [
            `reports/${this.currentGroup}`,
            'reports/custom/sort_by',
            `groupingSelects/${this.currentGroup}`,
          ];
          keysToRemove.forEach(k => this.$ls.remove(k));
          this.$ls.set(`currentReport/${this.currentGroup}`, 0);
        }

        if (
          !this.isSubAffiliateGroup
          && this.$ls.get(`groupingSelects/${this.currentGroup}`) !== null
          && this.$ls.get(`groupingSelects/${this.currentGroup}`).affiliate
          && this.$ls.get(`groupingSelects/${this.currentGroup}`).affiliate
            .name === 'sub_affiliate_email'
        ) {
          const keysToRemove = [
            `reports/${this.currentGroup}`,
            'reports/custom/sort_by',
            `groupingSelects/${this.currentGroup}`,
          ];
          keysToRemove.forEach(k => this.$ls.remove(k));
          this.$ls.set(`currentReport/${this.currentGroup}`, 0);
        }

        if (
          !this.isCountryGroup
          && this.$ls.get(`groupingSelects/${this.currentGroup}`) !== null
          && this.$ls.get(`groupingSelects/${this.currentGroup}`).traffic
          && this.$ls.get(`groupingSelects/${this.currentGroup}`).traffic.name
            === 'country_name'
        ) {
          const keysToRemove = [
            `reports/${this.currentGroup}`,
            'reports/custom/sort_by',
            `groupingSelects/${this.currentGroup}`,
          ];
          keysToRemove.forEach(k => this.$ls.remove(k));
          this.$ls.set(`currentReport/${this.currentGroup}`, 0);
        }
        if (
          !this.isPaymentGroup
          && this.$ls.get(`groupingSelects/${this.currentGroup}`) !== null
          && this.$ls.get(`groupingSelects/${this.currentGroup}`)
            .player_activity
          && this.$ls.get(`groupingSelects/${this.currentGroup}`).player_activity
            .name === 'payment_system_name'
        ) {
          const keysToRemove = [
            `reports/${this.currentGroup}`,
            'reports/custom/sort_by',
            `groupingSelects/${this.currentGroup}`,
          ];
          keysToRemove.forEach(k => this.$ls.remove(k));
          this.$ls.set(`currentReport/${this.currentGroup}`, 0);
        }

        // метод создоет флаг, что бы при F5 не открывался репорт

        this.getReport = this.$_.debounce(this.getReportD, 50);

        this.allDimensions = this.$_.cloneDeep(Config.allDimensions);

        this.allDimensions = this.allDimensions.filter(
          item => ((this.$store.getters['auth/currentAcl'][item.permission]
              === 'allow'
              || !item.permission)
              && !this.$store.getters['auth/adminAcl'].is_superuser)
            || this.$store.getters['auth/adminAcl'].is_superuser,
        );

        /* merged config allDimensions & api setting dimensions */
        this.allDimensions = this.mergedAllDimensions();

        this.defaultColumn = this.metricsTranslate(this.configAllMetrics);

        this.blockedSearch = true;
        if (this.$ls.get(`reports/${this.currentGroup}`)) {
          this.reports = this.$ls.get(`reports/${this.currentGroup}`);
        }

        if (
          this.$ls.get(`currentReport/${this.currentGroup}`)
          && this.$_.isArray(this.reports)
          && this.reports.length
            > Number(this.$ls.get(`currentReport/${this.currentGroup}`))
        ) {
          this.currentReport = this.$ls.get(
            `currentReport/${this.currentGroup}`,
          );
        }

        await this.$store.dispatch('reports/getSettings');
        await this.getPanelSettings(this.reportsSettings);

        if (
          this.$_.isEmpty(this.$route.query.short_url)
          && (this.$_.isEmpty(this.$route.query.promo_stats)
            || this.$_.isEmpty(this.$route.query.affiliate_stats))
        ) {
          if (
            this.$ls.get(`reports/${this.currentGroup}`) !== null
            && this.$_.isArray(
              this.$ls.get(`reports/${this.currentGroup}`)[this.currentReport]
                .reportMetrics,
            )
          ) {
            this.allMetrics = this.computedColumn(
              this.$_.cloneDeep(this.reports[this.currentReport].reportMetrics),
            );
          } else {
            this.$ls.remove(`reportMetrics4/${this.currentGroup}`);
            this.allMetrics = this.$_.cloneDeep(this.defaultColumn);
            this.allMetricsClone = this.$_.merge(
              this.allMetricsClone,
              this.$_.cloneDeep(this.defaultColumn),
            );
          }
        }

        if (this.$ls.get('reportsDatarangeDefault') === null) {
          this.$ls.set(
            'reportsDatarangeDefault',
            this.shortcutsDates()[1].range,
          );
        }

        if (this.$ls.get('singleGroupingSelect')) {
          this.singleGroupingSelect = this.$ls.get('singleGroupingSelect');
        }
        if (this.$ls.get(`groupingSelects/${this.currentGroup}`)) {
          this.groupingSelects = this.$ls.get(
            `groupingSelects/${this.currentGroup}`,
          );
        }

        this.$store.commit(`nav/${NAV_SET_SUB_VIEW}`, 'custom');

        if (this.reports[this.currentReport].dateRange.length === 0) {
          this.reports[this.currentReport].dateRange = this.shortcutsDates()[1].range;
          this.pickerDateRange = this.shortcutsDates()[1].range;
        } else {
          this.pickerDateRange = this.reports[this.currentReport].dateRange;
        }

        if (
          this.$_.find(this.allMetrics, [
            'name',
            this.reports[this.currentReport].sort_by,
          ])
          && !this.$_.find(this.allMetrics, [
            'name',
            this.reports[this.currentReport].sort_by,
          ]).selected
          && this.reports[this.currentReport].sort_by
            !== this.reports[this.currentReport].group_by
        ) {
          this.reports[this.currentReport].sort_by = this.reports[this.currentReport].group_by;
        }

        if (this.$route.query.short_url) {
          this.$router.replace('/reports/custom');
        }

        [this.currentTrafficType, this.currentPlayersType] = [
          this.reports[this.currentReport].trafficType,
          this.reports[this.currentReport].playersType,
        ];

        Object.keys(this.reports[this.currentReport].search).forEach(
          (element) => {
            if (!this.searchByList.hasOwnProperty(element)) {
              this.reports[this.currentReport].search = {};
            }
          },
        );
        this.currentSearch = this.$_.cloneDeep(
          this.reports[this.currentReport].search,
        );
        this.currentAffiliateDateOperator = this.reports[
          this.currentReport
        ].affiliateCreatedAt.currentAffiliateDateOperator;
        if (this.currentAffiliateDateOperator === '=') {
          this.currentAffiliateDateValue = [
            this.reports[this.currentReport].affiliateCreatedAt
              .affiliateCreatedAtStart,
            this.reports[this.currentReport].affiliateCreatedAt
              .affiliateCreatedAtEnd,
          ];
        } else if (this.currentAffiliateDateOperator === '≥') {
          this.currentAffiliateDateValue = this.reports[
            this.currentReport
          ].affiliateCreatedAt.affiliateCreatedAtStart;
        } else {
          this.currentAffiliateDateValue = this.reports[
            this.currentReport
          ].affiliateCreatedAt.affiliateCreatedAtEnd;
        }
      }

      /*
      Условие необходимое для создание репорта с помощью ссылки,
      при этом не заходя не разу в CustomReports
    */
      if (this.typeFlag === 'add') {
        this.selectReport(this.reports.length - 1, 'new_tab');
        this.typeFlag = '';
      }

      /*
      Условие необходимое для создание репорта из
      AffiliatePromoCodes, при этом не заходя не разу в CustomReports
    */
      if (this.$route.query.promo_stats || this.$route.query.affiliate_stats) {
        this.selectReport(this.reports.length - 1, 'new_tab');
        this.$router.replace('/reports/custom');
      }
      detectPermissions.checkRoute('reports_view');
      if (this.$refs.tabsReport) this.$refs.tabsReport.lastTab();
      this.transletaGrouping();
    },

    metricsTranslate(setMetrics) {
      return setMetrics.map((col) => {
        const findColumnInApi = this.reportsSettings.metrics.find(
          metric => metric.column_name === col.name,
        );

        return findColumnInApi === undefined
          ? { ...col }
          : { ...col, ...findColumnInApi };
      });
    },

    mergedAllDimensions() {
      const dimNameAndType = [];
      this.reportsSettings.grouped_dimensions.forEach((gr) => {
        gr.items.forEach((item) => {
          dimNameAndType.push({
            name: item.name,
            type: gr.name,
          });
        });
      });

      const mappedDimensions = dimNameAndType.map((dim) => {
        const getDim = this.allDimensions.find(
          elem => elem.name === dim.name,
        );
        const typeAndName = this.reportsSettings.dimensions.find(
          elem => elem.column_name === dim.name,
        );

        if (getDim === undefined) {
          return {
            minWidth: '140',
            name: dim.name,
            ...dim,
            ...typeAndName,
          };
        }

        return {
          ...getDim,
          ...dim,
          ...typeAndName,
        };
      });

      return mappedDimensions;
    },

    isVisibleTabControl(value) {
      if (!value) this.resizeTabMove();
    },
    resizeTabMove: _.debounce(function resizeTabMove() {
      if (this.$refs.tabsReport) this.$refs.tabsReport.lastTab();
    }, 50),

    updatedDraggedTab(index) {
      if (this.isDragged) {
        this.reports = this.$_.cloneDeep(this.reportsClone);
        this.currentReport = this.currentReportClone;
        this.$store.commit('other/isDragged', false);
        if (index !== undefined) {
          this.$refs.tabsReport.currentReportClone = index;
        }
      }
    },
    setTabsCurrentReport(current) {
      this.currentReport = current;
    },
    hiddenScroll(action) {
      if (action === 'add') {
        document.getElementById('app').classList.add('app-overflow');
      }
      if (action === 'remove') {
        document.getElementById('app').classList.remove('app-overflow');
      }
    },
    affCreatedDate(dir, date) {
      if (date === undefined) return undefined;
      if (this.currentAffiliateDateOperator === '=') {
        return dir === 'from'
          ? this.$moment(date).startOf('day').format('YYYY-MM-DD HH:mm:ss')
          : this.$moment(date).endOf('day').format('YYYY-MM-DD HH:mm:ss');
      }
      if (this.currentAffiliateDateOperator === '≥' && dir === 'from') {
        return this.$moment(date).startOf('day').format('YYYY-MM-DD HH:mm:ss');
      }
      if (this.currentAffiliateDateOperator === '≤' && dir === 'to') {
        return this.$moment(date).endOf('day').format('YYYY-MM-DD HH:mm:ss');
      }
      return undefined;
    },
    checkGroup(group) {
      if (
        !this.$store.getters['auth/adminAcl'].is_superuser
        && this.$store.getters['auth/currentAcl']
      ) {
        return this.$store.getters['auth/currentAcl'][group] === 'allow';
      }
      return !!this.$store.getters['auth/adminAcl'].is_superuser;
    },
    switchMultiSelect(event) {
      this.multiCheckedRow = event.metaKey || event.ctrlKey;
    },
    pressCtrl(event) {
      this.isPressCtrl = event.metaKey || event.ctrlKey;
    },
    resetRows() {
      /* reset column filter */
      this.$refs.dataTable.setColumnFilters();

      this.selectedRow = {};
      this.$refs.dataTable.$refs.dataTable.setCurrentRow();
      this.disableDrilldown = true;
      this.$refs.dataTable.clearCheck();
      this.selectRows = [];
      this.selectIds = [];
      this.$nextTick(this.customCurrentRow);
    },
    handleCurrentRowClick(row) {
      if (this.isPressCtrl) {
        if (!this.$_.isEmpty(this.selectedRow)) {
          if (
            this.selectRows.length === 0
            && !this.$_.isEqual(this.selectedRow, row)
          ) {
            this.$refs.dataTable.changeRowCheck(this.selectedRow);
          }
          this.$refs.dataTable.changeRowCheck(row);
          this.selectedRow = {};
          this.disableDrilldown = true;
        } else {
          this.$refs.dataTable.changeRowCheck(row);
        }
        this.disableDrilldown = !this.selectRows.length;
      } else {
        if (this.$_.isEqual(this.selectedRow, row)) {
          this.selectedRow = {};
          this.$refs.dataTable.$refs.dataTable.setCurrentRow();
          this.disableDrilldown = true;
        } else {
          this.selectedRow = row;
          this.$refs.dataTable.$refs.dataTable.setCurrentRow(row);
          this.disableDrilldown = false;
        }
        this.$refs.dataTable.changeRowCheck(row);
        this.$refs.dataTable.clearCheck();
        this.selectRows = [];
        this.selectIds = [];
        setTimeout(() => {
          this.customCurrentRow();
        });
      }
    },
    customCurrentRow() {
      const a = document.querySelectorAll('.el-table__row');

      a.forEach((e) => {
        if (e.querySelector('.el-checkbox.is-checked')) {
          e.classList.add('cur');
          e.classList.remove('current-row');
        } else {
          e.classList.remove('cur');
        }
      });
      /* добавил проверка для хрома, т.к. иногда срабатывает листенет
       неодевкатно когда кликаешь, например devtools
      */
      let flag = false;
      a.forEach((e) => {
        if (e.querySelector('.el-checkbox.is-checked') || this.isPressCtrl) {
          flag = true;
        }
      });
      if (!flag) {
        this.rowSelectable = !flag;
      }
    },
    handleCheckedRows(id, ids, row) {
      this.isContextOpen = false;
      this.rowSelectable = false;

      setTimeout(() => {
        this.customCurrentRow();
      });
      this.selectRows.push(row);
      this.selectRows = this.$_.filter(this.selectRows, e => ids.some(elem => elem === e.row_id));
      this.selectIds = ids;
      this.selectId = id;
    },
    computedColumn(metric) {
      const defColUnselected = this.$_.filter(this.defaultColumn, (e) => {
        e.selected = false;
        e.shadowSelected = false;
        return this.allSupportedColumns.includes(e.name);
      });

      defColUnselected.forEach((e, i) => {
        metric.forEach((elem) => {
          if (e.name === elem.name) {
            defColUnselected[i] = {
              ...elem,
              selected: elem.group === e.group ? elem.selected : false,
              shadowSelected: elem.group === e.group ? elem.shadowSelected : false,
              group: e.group,
            };
          }
        });
      });

      return defColUnselected;
    },
    generateRandom() {
      // метод создоет флаг, что бы при F5 не открывался репорт
      const random = Math.random();
      this.$ls.set('random', random);
      return random;
    },
    getCurrencyClass(value) {
      return value < 0 ? 'negative' : '';
    },

    resetColumnsOrder() {
      const allMetricsClone = this.allMetrics
        .map((itemMetric) => {
          const searchIndex = this.allSupportedColumns.findIndex(
            itemFindMetric => itemFindMetric === itemMetric.name,
          );
          return searchIndex >= 0
            ? { ...itemMetric, order: searchIndex, defaultOrder: searchIndex }
            : itemMetric;
        })
        .map(itemMetric => (this.columns_visibility.includes(itemMetric.name)
          ? { ...itemMetric, selected: true, shadowSelected: true }
          : { ...itemMetric, selected: false, shadowSelected: false }));
      this.allMetricsClone = this.$_.sortBy(allMetricsClone, 'order');
    },

    /**
     * Get panel settings and fill state
     */
    async getPanelSettings(newSettings) {
      // Getting pagel settings
      const panelSettingsData = newSettings;
      this.columns_visibility = panelSettingsData.metrics_visible_by_default;
      this.columnGroup = panelSettingsData.grouped_metrics;
      this.createPropColumn(this.columns_visibility);

      // Get columns from local storage if exists that
      const lsMetrics = this.$_.get(this.$ls.get(`reports/${this.currentGroup}`), [this.currentReport, 'reportMetrics'], []);
      this.allMetrics = lsMetrics?.length
        ? this.metricsTranslate(lsMetrics)
        : this.$_.cloneDeep(this.defaultColumn);
      // If short_url is exists then getting report options from api
      this.typeFlag = '';
      if (!this.$_.isEmpty(this.$route.query.short_url)) {
        /* добавляем флаг что первоначальная установка колонок происходит из короткого url */
        this.$ls.set(
          `reports/columnSettings/firstset/${this.currentGroup}`,
          true,
        );

        const { data: shortUrlData } = await this.$api.getShortUrlRequest(
          this.$route.query.short_url,
        );
        const { query: qreport } = shortUrlData.payload;
        // Generate reports settings and select report tab
        if (qreport.type === 'home') {
          this.reports[0] = qreport;
          this.selectReport(0);
        } else {
          this.reports.push(qreport);
          this.typeFlag = 'add';
        }

        this.$refs.groupingReport.selectDefaultGrouping(
          qreport.group_by,
          'block',
        );
        // this.allMetrics = qreport.reportMetrics;
        this.blockedFetch = true;
      }

      /* Пробрасываем репорт по партнеру из AffiliateList и AffiliateInfo; AffiliatePromoCodes */
      if (
        !this.$_.isEmpty(this.$route.query.affiliate_stats)
        || !this.$_.isEmpty(this.$route.query.promo_stats)
      ) {
        const reportParams = this.$_.cloneDeep(this.reports[0]);
        reportParams.type = 'add';
        reportParams.page = 1;
        reportParams.dateRange = this.$_.clone(this.shortcutsDates()[1].range);
        reportParams.sort_by = 'date_formatted';
        reportParams.group_by = 'date_formatted';

        reportParams.searchQuery = {
          value: '',
          group: '',
        };
        reportParams.search = {};
        reportParams.sort_dir = 'desc';
        reportParams.trafficType = 'all';
        reportParams.playersType = 'all';
        reportParams.columnsFilter = {};

        reportParams.reportMetrics = this.allMetrics;
        reportParams.affiliateCreatedAt = {
          currentAffiliateDateOperator: '=',
          affiliateCreatedAtStart: undefined,
          affiliateCreatedAtEnd: undefined,
        };

        /* при переходе с афилиатов */
        if (!this.$_.isEmpty(this.$route.query.affiliate_stats)) {
          const affiliateInfo = this.$qs.parse(
            this.$route.query.affiliate_stats,
          );
          reportParams.defaultFilter.affiliate_id = {
            op: '=',
            group: 'affiliate_email',
            row_id: affiliateInfo.id,
            visible: affiliateInfo.email,
            row_name: [
              {
                row_id: affiliateInfo.id,
                row_filter_column: 'affiliate_id',
                affiliate_email: affiliateInfo.email,
              },
            ],
          };
          /* при переходе из промокодов */
        } else if (!this.$_.isEmpty(this.$route.query.promo_stats)) {
          const promocodeInfo = this.$qs.parse(this.$route.query.promo_stats);
          reportParams.defaultFilter.affiliate_player_promo_code_id = {
            op: '=',
            group: 'affiliate_player_promo_code_name',
            row_id: promocodeInfo.id,
            visible: promocodeInfo.promo_code,
            row_name: [
              {
                row_id: promocodeInfo.id,
                row_filter_column: 'affiliate_player_promo_code_id',
                affiliate_player_promo_code_name: promocodeInfo.promo_code,
              },
            ],
          };
        }
        if (this.reports.length === 0) {
          this.reports[1] = reportParams;
        } else {
          this.reports.push(reportParams);
        }
        this.allMetrics = reportParams.reportMetrics;
        this.$refs.dataTable.setColumnFilters();
        this.blockedFetch = true;
      }

      // Get reports settings from api
      const reportsSettingsData = this.reportsSettings;

      const { dimensions, metrics } = reportsSettingsData;

      const groupBy = dimensions.map(dimension => dimension.column_name);
      const columns = metrics.map(metric => metric.column_name);

      // Fill state
      this.supportedDimensions = groupBy;
      this.supportedColumns = columns;
      this.allSupportedColumns = [...columns];

      // Magick... TODO: rewrite or remove
      this.allMetrics = this.$_.filter(
        this.$_.map(
          this.allSupportedColumns,
          i => this.$_.filter(this.allMetrics, { name: i })[0],
        ),
        n => !!n,
      );

      const combinedColumns = [...this.allMetrics];
      this.allMetrics = this.computedColumn(combinedColumns);
      this.allMetricsFlug = true;
      this.allMetricsClone = this.$_.cloneDeep(this.allMetrics);
      this.allMetricsStatic = this.$_.cloneDeep(this.defaultColumn);
      this.flagIsFetch = true;
    },

    createPropColumn(columns_visibility) {
      const arrayOrder = [];
      const objGroup = {};

      this.columnGroup.forEach((elem) => {
        const group = elem.group_name;
        objGroup[group] = [];
        elem.items.forEach((el) => {
          this.insertGroup(el.name, group);
          arrayOrder.push(el.name);
          objGroup[group].push(el.name);
          if (el.items.length !== 0) {
            el.items.forEach((e) => {
              this.insertGroup(e.name, group);
              arrayOrder.push(e.name);
              objGroup[group].push(e.name);
            });
          }
        });
      });

      this.objGroup = objGroup;

      this.defaultColumn = this.defaultColumn.map((col) => {
        const obj = {};

        if (columns_visibility.indexOf(col.name) !== -1) {
          this.$set(obj, 'selected', true);
          this.$set(obj, 'shadowSelected', true);
        } else {
          this.$set(obj, 'selected', false);
          this.$set(obj, 'shadowSelected', false);
        }
        if (arrayOrder.indexOf(col.name) !== -1) {
          this.$set(obj, 'defaultOrder', arrayOrder.indexOf(col.name));
          this.$set(obj, 'order', arrayOrder.indexOf(col.name));
        }

        return { ...col, ...obj };
      });
    },

    insertGroup(col, group) {
      this.defaultColumn = this.defaultColumn.map((e) => {
        if (e.name === col) {
          const obj = this.$set(e, 'group', group);
          return { ...e, obj };
        }
        return { ...e };
      });
    },

    eventStopSetting(e) {
      if (this.$refs.settingsMain.$el.contains(e.target)) return;
      if (this.$el.contains(e.target)) {
        this.isSettingsVisible = false;
      }
    },

    openPR(row, op = '=') {
      const row_filter_column = this.selectRows.length
        ? this.selectRows[0].row_filter_column
        : this.selectedRow.row_filter_column;
      const row_name = [];
      const rows = this.selectRows.length
        ? this.$_.clone(this.selectRows)
        : [this.$_.clone(this.selectedRow)];
      rows.forEach((aff) => {
        row_name.push(
          this.$_.pick(aff, [
            this.reports[this.currentReport].group_by,
            'row_filter_column',
            'row_id',
          ]),
        );
      });
      const objSelect = {};
      this.$set(objSelect, row_filter_column, {
        row_id: this.selectIds.length
          ? this.selectIds
          : this.selectedRow.row_id,
        row_name,
        group: this.reports[this.currentReport].group_by,
        op,
      });
      const objectPR = this.$_.merge(
        this.reports[this.currentReport].defaultFilter,
        this.reports[this.currentReport].currentFilter,
      );
      objectPR[row_filter_column] = objSelect[row_filter_column];
      this.$store.commit('other/DATA_PR', objectPR);
      const q = {
        from: this.$moment(this.pickerDateRange[0])
          .startOf('day')
          .format('YYYY-MM-DD HH:mm:ss'),
        to: this.$moment(this.pickerDateRange[1])
          .endOf('day')
          .format('YYYY-MM-DD HH:mm:ss'),
      };
      this.$router.push(
        `/reports/payments?${this.$qs.stringify(q, { arrayFormat: 'repeat' })}`,
      );
    },
    async searchBookmark() {
      await this.getBookmarks();
      this.$eventBus.$emit('afterSearch');
    },
    async saveBookmark() {
      this.updatedDraggedTab();
      this.isLoadingBookmark = true;
      try {
        await this.$api.addBookmarks(this.bookmarks);
        this.getBookmarks();
      } catch (error) {
        this.isLoadingBookmark = false;
      }
    },
    /**
     * проверяет и сопоставляет есть ли в табах сохраненные букмарки
     */
    checkReportPinned() {
      this.reports = this.reports.map((e) => {
        if (!e.hasOwnProperty('uid')) {
          return { ...e };
        }
        let name = '';
        const boolUid = this.bookmarks.some((el) => {
          if (e.uid === el.uid) name = el.bookmarkName;
          return e.uid === el.uid;
        });
        const resultObj = {
          ...e,
          isPinnedBookmark: boolUid,
          bookmarkName: boolUid ? name : '',
        };
        if (!boolUid) delete resultObj.uid;
        return resultObj;
      });
    },
    async getBookmarks() {
      this.isLoadingBookmark = true;
      try {
        const response = await this.$api.getBookmarks();
        this.bookmarks = this.bookmarksFiltredVersion(response.data.payload);
        this.bookmarks.forEach((e, i) => {
          if (
            this.$refs.bookmarks !== undefined
            && Array.isArray(this.$refs.bookmarks.bookmarkRenderPopover)
          ) {
            this.$refs.bookmarks.bookmarkRenderPopover[i] = false;
          }
        });
        if (this.bookmarks.length === 0) {
          this.$refs.bookmarks.isPopoverVis = false;
        }
        this.checkReportPinned();
      } finally {
        this.isLoadingBookmark = false;
      }
    },

    clearReportsSearchFilter(group) {
      this.$delete(this.currentSearch, group);
    },

    openColumnsPopup() {
      this.allMetricsClone = this.$ls.get(`reportMetrics4/${this.currentGroup}`)
        ? this.computedColumn(
          this.$ls.get(`reportMetrics4/${this.currentGroup}`),
        )
        : this.$_.cloneDeep(this.configAllMetrics);
      this.allMetricsClone = this.$_.cloneDeep(
        this.allMetricsClone.sort((a, b) => a.order - b.order),
      );

      /* добавил проверку для первого входа в колонки, дабы их ресетнуть при первом входе */
      if (
        this.$ls.get(`reports/columnSettings/firstset/${this.currentGroup}`)
        === null
      ) {
        this.resetColumnsOrder();
        this.allMetricsClone = this.allMetricsClone.map((column, index) => ({
          ...column,
          order: index,
          selected: column.shadowSelected,
        }));
        this.allMetrics = this.$_.cloneDeep(this.allMetricsClone);
        this.$ls.set(
          `reports/columnSettings/firstset/${this.currentGroup}`,
          true,
        );
      }

      this.$refs.columnPopupComponent.open();
    },
    applyColumnsSettings(v) {
      this.updatedDraggedTab();

      this.allMetrics = this.$_.cloneDeep(v);
      this.$refs.dataTable.$refs.dataTable.doLayout();
      this.getReport('applyColumnsSettings');
      this.$refs.columnPopupComponent.close();
    },
    cancelColumnsSettings() {
      this.updatedDraggedTab();

      this.allMetricsClone = this.$ls.get(`reportMetrics4/${this.currentGroup}`)
        ? this.computedColumn(
          this.$ls.get(`reportMetrics4/${this.currentGroup}`),
        )
        : this.$_.cloneDeep(this.configAllMetrics);
      this.allMetrics = this.$_.sortBy(this.allMetrics, 'order');
      this.allMetrics.forEach((column) => {
        column.shadowSelected = column.selected;
      });
      this.$refs.columnPopupComponent.close();
    },
    getDataQuery(report) {
      let sb = report.sort_by || report.group_by;
      const sd = report.sort_dir || 'desc';
      this.reports[this.currentReport].sort_dir = sd;
      const limit = report.limit || pageSize;
      this.reports[this.currentReport].limit = limit;

      const allColumns = this.$_.map(
        this.$_.filter(this.allMetrics, 'selected'),
        'name',
      );
      if (allColumns.indexOf(sb) === -1 && sb !== report.group_by) {
        this.reports[this.currentReport].sort_by = report.group_by;
        sb = report.group_by;
      }

      /* При смене группировки у которой нет, additional колонки, менять сортировку на дефолтнуую(по группировке) */
      if (
        this.$_.flatMap(this.supportedAditionalColumns).includes(sb)
        && !(
          this.supportedAditionalColumns[report.group_by]
          && this.supportedAditionalColumns[report.group_by].includes(
            report.sort_by,
          )
        )
      ) {
        this.reports[this.currentReport].sort_by = report.group_by;
        sb = report.group_by;
      }

      const columnsFilter = {};
      this.$_.forOwn(report.columnsFilter, (e, key) => {
        columnsFilter[key] = this.$_.pick(e, ['op', 'value']);
      });

      // check column filter
      Object.keys(columnsFilter).forEach((e) => {
        const flagDel = allColumns.some(el => e === el);
        if (!flagDel) delete columnsFilter[e];
      });

      const currentGroupColumn = this.groupByField.name;
      const supportedAditionalColumns = this.$_.get(
        this.supportedAditionalColumns,
        currentGroupColumn,
        [],
      );

      // Devide all columns filters
      const { additionalFilters, havingFilters } = Object.keys(
        columnsFilter,
      ).reduce(
        (acc, key) => {
          // If filter for additional columns
          if (supportedAditionalColumns.includes(key)) {
            acc.additionalFilters[key] = columnsFilter[key];
          }
          // If filter for metric columns
          if (this.supportedColumns.includes(key)) {
            acc.havingFilters[key] = columnsFilter[key];
          }

          return acc;
        },
        { additionalFilters: {}, havingFilters: {} },
      );

      // Prepare data for a request
      const filters = {
        ...reconstructionFilter({
          ...report.currentFilter,
          ...report.defaultFilter,
        }),
        ...additionalFilters,
      };
      const columns = allColumns.filter(column => this.supportedColumns.includes(column));
      // const additional_columns = allColumns.filter(column => supportedAditionalColumns.includes(column));

      const searchConstructor = this.$_.cloneDeep(report.search);
      report.searchQuery.group = '';
      if (report.searchQuery.value) {
        report.searchQuery.group = report.group_by;
        searchConstructor[report.group_by] = {
          op: 'like',
          value: [report.searchQuery.value],
        };
      } else if (
        report.searchQuery.group
        && Object.keys(searchConstructor).includes(report.searchQuery.group)
      ) {
        this.$delete(searchConstructor, report.searchQuery.group);
        report.searchQuery.group = '';
      }

      return {
        // group_by: report.group_by,
        dimensions: [report.group_by],

        search: searchConstructor,

        // columns,
        metrics: columns,

        // additional_columns,
        from: report.dateRange[0]
          ? this.$moment(report.dateRange[0])
            .startOf('day')
            .format('YYYY-MM-DD HH:mm:ss')
          : '',
        to: report.dateRange[1]
          ? this.$moment(report.dateRange[1])
            .endOf('day')
            .format('YYYY-MM-DD HH:mm:ss')
          : '',
        limit,
        offset: report.limit * report.page - report.limit,

        sorting: [
          {
            sort_by: sb,
            sort_dir: sd,
          },
        ],

        metrics_format: 'pretty',

        traffic_type: report.trafficType,
        players_filter: report.playersType,
        affiliates_created_at_from: this.affCreatedDate(
          'from',
          report.affiliateCreatedAt.affiliateCreatedAtStart,
        ),
        affiliates_created_at_to: this.affCreatedDate(
          'to',
          report.affiliateCreatedAt.affiliateCreatedAtEnd,
        ),
        having: havingFilters,
        filters,
      };
    },

    refrashReport() {
      this.updatedDraggedTab();
      this.getReport();
    },

    async getReportD() {
      if (!this.flagIsFetch) return;
      this.isDataLoading = true;
      try {
        const response = await this.$api.getMainReport(
          this.getDataQuery(this.reports[this.currentReport]),
        );
        // this.notAllowedColumns = this.$_.cloneDeep(response.data.payload.not_allowed_columns);
        this.data = this.$_.cloneDeep(response.data.payload.data);
        this.totals = this.$_.clone(response.data.payload.totals);
        this.count = response.data.misc.count;
        this.exportUrls = response.data.misc.export_urls;
        this.$refs.dataTable.$refs.dataTable.clearSort();
        setTimeout(() => {
          if (this.$refs.tabsReport) this.$refs.tabsReport.lastTab();
          this.customCurrentRow();
        });
        if (this.blockedFetch) {
          /*
           * кастыль @нужно исправить но не знаю где почему page скидываеться на 1
           * */
          this.$nextTick(() => {
            this.$refs.pagination.currentPage = this.reports[this.currentReport].page;
            this.$refs.pagination.currentPageInp = String(
              this.reports[this.currentReport].page,
            );
          });
          setTimeout(() => {
            this.blockedFetch = false;
          }, 300);
        }
        /*
         * добавил блокировку @search в инпуте, дабы убрать задваивание запроса
         * */
        setTimeout(() => {
          this.blockedSearch = false;

          if (this.$refs.tabsReport !== undefined) {
            if (this.$refs.tabsReport) this.$refs.tabsReport.lastTab();
          }
        }, 1000);
        setTimeout(() => {
          if (
            this.$refs.dataTable
            && this.$refs.dataTable.debouncedTableHeight
          ) {
            this.$refs.dataTable.debouncedTableHeight();
          }
          this.isDataLoading = false;
        }, 301);
        /*
         * Флаг для того что бы отключать debounce у поиска, дабы избежать задваивания запроса
         * */
        this.switchSearchMod = true;
      } catch (error) {
        this.isDataLoading = false;
      }
    },

    // TODO: need refactoring
    drilldownReport(op) {
      this.updatedDraggedTab();

      this.$refs.dataTable.clearCheck();
      this.isContextOpen = false;
      this.rowSelectable = false;

      const row_filter_column = this.selectRows.length
        ? this.selectRows[0].row_filter_column
        : this.selectedRow.row_filter_column;

      if (op === '=') {
        this.allDimensions.find(
          field => field.name === this.reports[this.currentReport].group_by,
        ).filterName = row_filter_column;
      }

      let row_name = [];
      const rows = this.selectRows.length
        ? this.$_.clone(this.selectRows)
        : [this.$_.clone(this.selectedRow)];

      rows.forEach((aff) => {
        row_name.push(
          this.$_.pick(aff, [
            this.reports[this.currentReport].group_by,
            'row_filter_column',
            'row_id',
          ]),
        );
      });

      row_name = row_name.map(row => ({ ...row, row_id: row.row_id }));

      if (
        this.$_.has(
          this.reports[this.currentReport].defaultFilter,
          row_filter_column,
        )
        && this.reports[this.currentReport].defaultFilter[row_filter_column].op
          === op
      ) {
        this.reports[this.currentReport].defaultFilter[
          row_filter_column
        ].row_id = this.$_.concat(
          [],
          this.reports[this.currentReport].defaultFilter[row_filter_column]
            .row_id,
          this.selectIds.length
            ? this.selectIds.map(elem => elem)
            : this.selectedRow.row_id,
        );

        this.reports[this.currentReport].defaultFilter[
          row_filter_column
        ].row_id = this.$_.uniqBy(
          this.reports[this.currentReport].defaultFilter[row_filter_column]
            .row_id,
        );

        this.reports[this.currentReport].defaultFilter[
          row_filter_column
        ].row_name = this.$_.concat(
          [],
          this.reports[this.currentReport].defaultFilter[row_filter_column]
            .row_name,
          row_name,
        );

        this.reports[this.currentReport].defaultFilter[
          row_filter_column
        ].row_name = this.$_.uniqBy(
          this.reports[this.currentReport].defaultFilter[row_filter_column]
            .row_name,
          'row_id',
        );

        this.reports[this.currentReport].defaultFilter[
          row_filter_column
        ].visible = this.reports[this.currentReport].defaultFilter[
          row_filter_column
        ].row_id.length
          ? `${
              this.reports[this.currentReport].defaultFilter[row_filter_column]
                .row_name[0][this.reports[this.currentReport].group_by]
            } ${
              this.reports[this.currentReport].defaultFilter[row_filter_column]
                .row_id.length === 1
                ? ''
                : `[+${
                    this.reports[this.currentReport].defaultFilter[
                      row_filter_column
                    ].row_id.length - 1
                  }]`
            }`
          : this.selectedRow[this.reports[this.currentReport].group_by];
      } else if (
        this.$_.has(
          this.reports[this.currentReport].currentFilter,
          row_filter_column,
        )
        && this.reports[this.currentReport].currentFilter[row_filter_column].op
          === op
      ) {
        this.reports[this.currentReport].currentFilter[
          row_filter_column
        ].row_id = this.$_.concat(
          [],
          this.reports[this.currentReport].currentFilter[row_filter_column]
            .row_id,
          this.selectIds.length
            ? this.selectIds.map(elem => elem)
            : this.selectedRow.row_id,
        );
        this.reports[this.currentReport].currentFilter[
          row_filter_column
        ].row_id = this.$_.uniqBy(
          this.reports[this.currentReport].currentFilter[row_filter_column]
            .row_id,
        );

        this.reports[this.currentReport].currentFilter[
          row_filter_column
        ].row_name = this.$_.concat(
          [],
          this.reports[this.currentReport].currentFilter[row_filter_column]
            .row_name,
          row_name,
        );
        this.reports[this.currentReport].currentFilter[
          row_filter_column
        ].row_name = this.$_.uniqBy(
          this.reports[this.currentReport].currentFilter[row_filter_column]
            .row_name,
          'row_id',
        );

        this.reports[this.currentReport].currentFilter[
          row_filter_column
        ].visible = this.reports[this.currentReport].currentFilter[
          row_filter_column
        ].row_id.length
          ? `${
              this.reports[this.currentReport].currentFilter[row_filter_column]
                .row_name[0][this.reports[this.currentReport].group_by]
            } ${
              this.reports[this.currentReport].currentFilter[row_filter_column]
                .row_id.length === 1
                ? ''
                : `[+${
                    this.reports[this.currentReport].currentFilter[
                      row_filter_column
                    ].row_id.length - 1
                  }]`
            }`
          : this.selectedRow[this.reports[this.currentReport].group_by];
      } else if (
        this.$_.has(
          this.reports[this.currentReport].defaultFilter,
          row_filter_column,
        )
      ) {
        this.$set(
          this.reports[this.currentReport].defaultFilter,
          row_filter_column,
          {
            row_id: this.selectIds.length
              ? this.selectIds.map(elem => elem)
              : this.selectedRow.row_id,
            row_name,
            visible: `${
              row_name[0][this.reports[this.currentReport].group_by]
            } ${row_name.length <= 1 ? '' : `[+${row_name.length - 1}]`}`,
            group: this.reports[this.currentReport].group_by,
            op,
          },
        );
      } else {
        this.$set(
          this.reports[this.currentReport].currentFilter,
          row_filter_column,
          {
            row_id: this.selectIds.length
              ? this.selectIds.map(elem => elem)
              : this.selectedRow.row_id,
            row_name,
            visible: `${
              row_name[0][this.reports[this.currentReport].group_by]
            } ${row_name.length <= 1 ? '' : `[+${row_name.length - 1}]`}`,
            group: this.reports[this.currentReport].group_by,
            op,
          },
        );
      }

      if (op === '=') {
        this.reports[this.currentReport].nextDimension = this.$_.clone(
          this.allDimensions.find(
            field => this.$refs.groupingReport.deleteGroup().indexOf(field.name) < 0
              && !this.reports[this.currentReport].currentFilter.hasOwnProperty(
                field.filterName,
              )
              && !this.reports[this.currentReport].defaultFilter.hasOwnProperty(
                field.filterName,
              ),
          ),
        );
      }
      if (
        this.reports[this.currentReport].sort_by
          === this.reports[this.currentReport].group_by
        && op === '='
      ) {
        this.reports[this.currentReport].sort_by = this.reports[this.currentReport].nextDimension.name;
      }
      if (op === '=') {
        this.reports[this.currentReport].group_by = this.reports[this.currentReport].nextDimension.name;
      }

      this.groupingSelects = {};
      this.singleGroupingSelect = '';
      if (this.groupByField.type === 'single') {
        this.singleGroupingSelect = this.reports[this.currentReport].group_by;
      } else {
        this.groupingSelects[this.groupByField.type] = this.groupByField;
      }

      this.$refs.dataTable.$refs.dataTable.setCurrentRow();
      this.selectedRow = {};
      this.reports[this.currentReport].page = 1;
      this.reports[this.currentReport].searchQuery = {
        value: '',
        group: '',
      };

      this.reports[this.currentReport].columnsFilter = {};
      this.$refs.dataTable.setColumnFilters();
      if (
        this.reports[this.currentReport].sort_by
        === this.reports[this.currentReport].group_by
      ) {
        this.$refs.dataTable.lastSortProp = this.reports[this.currentReport].group_by;
      }
      this.getReport('drilldown');
    },
    clearCurrentFilter() {
      this.updatedDraggedTab();

      this.$refs.dataTable.$refs.dataTable.setCurrentRow();
      this.selectedRow = {};
      this.reports[this.currentReport].currentFilter = {};
      this.reports[this.currentReport].page = 1;
      this.getReport('clear filter');
    },
    removeItemCurrentFilter(row, id, key, flag) {
      this.updatedDraggedTab();

      if (flag === 'report') {
        this.$_.remove(
          this.reports[this.currentReport].defaultFilter[key].row_id,
          e => e === id,
        );
        this.$_.remove(
          this.reports[this.currentReport].defaultFilter[key].row_name,
          e => e.row_id === id,
        );
      } else {
        this.$_.remove(
          this.reports[this.currentReport].currentFilter[key].row_id,
          e => e === id,
        );
        this.$_.remove(
          this.reports[this.currentReport].currentFilter[key].row_name,
          e => e.row_id === id,
        );
      }
      this.reports = this.$_.cloneDeep(this.reports);
      this.getReport('remove filter item');
    },
    removeCurrentFilter(filter) {
      this.updatedDraggedTab();

      this.$refs.dataTable.$refs.dataTable.setCurrentRow();
      this.selectedRow = {};

      this.reports[this.currentReport].group_by = this.reports[this.currentReport].currentFilter[filter].group;
      this.reports[this.currentReport].page = 1;
      this.groupingSelects = {};
      this.singleGroupingSelect = '';
      if (this.groupByField.type === 'single') {
        this.singleGroupingSelect = this.reports[this.currentReport].group_by;
      } else {
        this.groupingSelects[this.groupByField.type] = this.groupByField;
      }
      this.$delete(this.reports[this.currentReport].currentFilter, filter);
      this.getReport('remove filter');
    },
    selectReport(index, action) {
      this.isSettingsVisible = false;
      this.updatedDraggedTab(index);
      const fnSelect = () => {
        /*
         * добавил флаг блокировки инпута поиска, дабы убрать задваивание запроса
         * */
        this.blockedSearch = true;
        if (this.currentReport === index) {
          return;
        }
        if (this.$refs.dataTable) {
          this.$refs.dataTable.$refs.dataTable.setCurrentRow();
          this.selectedRow = {};
          this.$refs.dataTable.setColumnFilters(
            this.reports[index].columnsFilter,
          );
        }

        this.currentReport = index;
        this.groupingSelects = {};
        this.singleGroupingSelect = '';

        if (this.groupByField.type === 'single') {
          this.singleGroupingSelect = this.reports[this.currentReport].group_by;
        } else {
          this.groupingSelects[this.groupByField.type] = this.groupByField;
        }

        this.currentTrafficType = this.reports[this.currentReport].trafficType;
        this.currentPlayersType = this.reports[this.currentReport].playersType;
        this.currentSearch = this.$_.cloneDeep(
          this.reports[this.currentReport].search,
        );
        this.pickerDateRange = this.$_.cloneDeep(
          this.reports[this.currentReport].dateRange,
        );
        this.getReport(action);
      };
      if (action === 'noTick') {
        fnSelect();
      } else {
        this.$nextTick(fnSelect);
      }
    },
    createReport(customSettings, op = '=', contTab) {
      this.updatedDraggedTab();
      /*
       * добавил флаг блокировки инпута поиска, дабы убрать задваивание запроса
       * */
      this.blockedSearch = true;
      this.isContextOpen = false;
      this.rowSelectable = false;

      const report = {
        bookmarkName: '',
        group_by: '',
        nextDimension: {},
        dateRange: this.$_.cloneDeep(
          this.reports[this.currentReport].dateRange,
        ),
        limit: this.reports[this.currentReport].limit,
        page: 1,
        sort_by: '',
        sort_dir: 'desc',
        defaultFilter: {},
        currentFilter: {},
        columnsFilter: {},
        isPinnedBookmark: false,
        trafficType: 'all',
        playersType: 'all',
        search: {},
        searchQuery: {
          value: '',
          group: '',
        },
        affiliateCreatedAt: {
          currentAffiliateDateOperator: '=',
          affiliateCreatedAtStart: undefined,
          affiliateCreatedAtEnd: undefined,
        },
        reportMetrics: customSettings
          ? customSettings.reportMetrics
          : this.allMetrics,
      };
      if (contTab === 'continue') {
        this.savePreviousReport.index = this.currentReport;
        this.savePreviousReport.data = this.$_.cloneDeep(
          this.reports[this.currentReport],
        );
        this.drilldownReport(op);
      } else if (
        !this.$_.isEmpty(this.selectedRow)
        || !this.$_.isEmpty(this.selectRows)
      ) {
        const row_name = [];
        const rows = this.selectRows.length
          ? this.$_.clone(this.selectRows)
          : [this.$_.clone(this.selectedRow)];
        rows.forEach((aff) => {
          row_name.push(
            this.$_.pick(aff, [
              this.reports[this.currentReport].group_by,
              'row_filter_column',
              'row_id',
            ]),
          );
        });
        report.defaultFilter[
          this.selectRows.length
            ? this.selectRows[0].row_filter_column
            : this.selectedRow.row_filter_column
        ] = {
          row_id: this.selectIds.length
            ? this.selectIds.map(elem => elem)
            : this.selectedRow.row_id,
          row_name,
          visible: `${row_name[0][this.reports[this.currentReport].group_by]} ${
            row_name.length <= 1 ? '' : `[+${row_name.length - 1}]`
          }`,
          group: this.reports[this.currentReport].group_by,
          op,
        };
        if (op === '=') {
          this.allDimensions.find(
            field => field.name === this.reports[this.currentReport].group_by,
          ).filterName = this.selectRows.length
            ? this.selectRows[0].row_filter_column
            : this.selectedRow.row_filter_column;
          report.group_by = this.allDimensions.find(
            field => !report.defaultFilter.hasOwnProperty(field.filterName),
          ).name;
        } else {
          report.group_by = this.reports[this.currentReport].group_by;
        }
      } else if (op === '=') {
        report.group_by = this.reports[this.currentReport].nextDimension.name;
      }

      report.sort_by = this.reports[this.currentReport].sort_by
        === this.reports[this.currentReport].group_by
        ? report.group_by
        : this.reports[this.currentReport].sort_by;

      if (customSettings) {
        this.$_.merge(report, customSettings);
      }

      this.reports.push(
        contTab === 'continue' ? this.reports[this.currentReport] : report,
      );
      this.selectReport(this.reports.length - 1, 'new_tab');
      this.$nextTick(() => {
        if (contTab === 'continue') {
          this.reports[this.savePreviousReport.index] = this.savePreviousReport.data;
        }
      });
    },
    removeReport() {
      this.isContextOpen = false;
      this.rowSelectable = false;
    },
    removeAllReport(event) {
      this.selectReport(0);

      this.isContextOpen = false;
      this.rowSelectable = false;

      event.stopPropagation();

      this.$nextTick(() => {
        this.reports.splice(1);
        if (this.$refs.tabsReport) this.$refs.tabsReport.lastTab();
      });
    },
    resetHomeReport(event) {
      this.isContextOpen = false;
      this.rowSelectable = false;

      this.pickerDateRange = this.$_.clone(this.shortcutsDates()[1].range);
      this.singleGroupingSelect = '';
      this.groupingSelects = { datetime: 'date_formatted' };
      this.isSettingsVisible = false;
      this.currentTrafficType = 'all';
      this.currentPlayersType = 'all';
      this.currentSearch = {};

      this.reports[0].group_by = 'date_formatted';
      this.reports[0].nextDimension = {};
      this.reports[0].dateRange = this.pickerDateRange;
      this.reports[0].search = {};
      this.reports[0].searchQuery = {
        value: '',
        group: '',
      };
      this.reports[0].page = 1;
      this.reports[0].sort_by = 'date_formatted';
      this.reports[0].sort_dir = 'desc';
      this.reports[0].defaultFilter = {};
      this.reports[0].currentFilter = {};
      this.reports[0].columnsFilter = {};
      this.reports[0].trafficType = 'all';
      this.reports[0].playersType = 'all';

      this.$refs.dataTable.$refs.dataTable.setCurrentRow();
      this.selectedRow = {};
      if (!this.customSorting) {
        this.$refs.dataTable.$refs.dataTable.sort(
          this.reports[0].sort_by,
          `${this.reports[0].sort_dir}ending`,
        );
        this.$refs.dataTable.$refs.dataTable.doLayout();
        this.$refs.dataTable.$refs.dataTable.clearSort();
      }

      this.$refs.dataTable.setColumnFilters();
      this.resetReportingSettings(event);
      this.$refs.dataTable.lastOpenFilter = '';
    },

    async exportData(format) {
      this.updatedDraggedTab();
      window.open(this.exportUrls[format]);
    },

    showPlayersReport() {
      const report = {
        group_by: 'site_player_id',
        sort_by: 'site_player_id',
        sort_dir: 'desc',
        defaultFilter: {},
        currentFilter: {},
        reportMetrics: this.$_.cloneDeep(this.allMetrics),
      };

      this.createReport(report);
      this.isContextOpen = false;
      this.rowSelectable = false;
    },
    selectDateRange(range) {
      this.updatedDraggedTab();

      this.$refs.dataTable.$refs.dataTable.setCurrentRow();
      this.selectedRow = {};
      if (!range) {
        this.pickerDateRange = this.shortcutsDates()[1].range;
        this.$nextTick(() => {
          this.pickerDateRange = this.shortcutsDates()[1].range;
        });
      }

      this.reports[this.currentReport].dateRange = this.pickerDateRange;
      this.reports[this.currentReport].page = 1;
      this.getReport('handle date range');
    },
    handleSelectColumns(columns) {
      if (columns.indexOf(this.reports[this.currentReport].sort_by) === -1) {
        this.reports[this.currentReport].sort_by = this.reports[this.currentReport].group_by;
      }
      this.$ls.set('reports/custom/selected_columns', columns);
      this.getReport('select columns');
    },
    handleCurrentRowChange(current) {
      this.disableDrilldown = !current;
    },
    handleGroupingSelect(gb, block) {
      this.updatedDraggedTab();

      this.$refs.dataTable.$refs.dataTable.setCurrentRow();
      this.selectedRow = {};
      this.groupingSelects = {};
      this.singleGroupingSelect = '';
      this.groupingSelects[gb.type] = gb;
      if (
        this.reports[this.currentReport].sort_by
        === this.reports[this.currentReport].group_by
      ) {
        this.reports[this.currentReport].sort_by = gb.name;
        this.$refs.dataTable.lastSortProp = gb.name;
      }
      this.reports[this.currentReport].group_by = gb.name;
      this.switchSearchMod = false;
      /*
        ставим флаг дабы не сбрасывать поиск.
        т.к. в коротком url может быть сохранена и групировка и поиск
      */
      if (block !== 'block') {
        this.reports[this.currentReport].searchQuery = {
          value: '',
          group: '',
        };
        this.reports[this.currentReport].page = 1;
      }

      this.getReport('handle grouping select');
    },
    // openColumnsSelect() {
    //   this.$refs.select_group.toggleMenu();
    // },
    handleSort({ prop, order }) {
      this.updatedDraggedTab();

      this.$refs.dataTable.$refs.dataTable.setCurrentRow();
      this.selectedRow = {};
      this.reports[this.currentReport].page = 1;
      this.reports[this.currentReport].sort_by = prop;
      this.reports[this.currentReport].sort_dir = order;
      this.getReport('handle sort');
    },
    pageChange(page, size) {
      if (this.blockedFetch) {
        return;
      }

      this.updatedDraggedTab();

      if (
        this.reports[this.currentReport].page !== page
        || this.reports[this.currentReport].limit !== size
      ) {
        this.reports[this.currentReport].page = page;
        this.reports[this.currentReport].limit = size;
        this.getReport('handle page change');
      }
    },

    handleFilter(filter) {
      this.updatedDraggedTab();

      this.reports[this.currentReport].columnsFilter = {};
      Object.keys(filter).forEach((key) => {
        if (filter[key].value && filter[key].op) {
          this.reports[this.currentReport].columnsFilter[key] = {
            op: filter[key].op,
            value: filter[key].value,
            valueRange: filter[key].valueRange,
            isExclude: filter[key].isExclude,
            valueSec: filter[key].valueSec,
            dateFilter: filter[key].dateFilter,
          };
        }
      });
      this.getReport('handle columns filter');
    },

    rowContextHandler(row, event) {
      this.isSettingsVisible = false;
      this.selectedRow = row;

      if (this.selectIds.length === 0) {
        this.rowSelectable = true;
        this.$refs.dataTable.$refs.dataTable.setCurrentRow(row);
      }

      this.isContextOpen = true;

      setTimeout(() => {
        const { clientX, clientY } = event;
        this.contextLeft = clientX;
        this.contextTop = ((clientY + this.$refs.context.$el.clientHeight) < window.innerHeight) ? clientY : clientY - this.$refs.context.$el.clientHeight;
      });

      if (this.currentReport) {
        this.disableDrilldown = false;
      }
    },

    clickOutsideContext(e) {
      if (
        this.$refs.context.$refs.context
        && !this.$refs.context.$refs.context.contains(e.target)
      ) {
        this.isContextOpen = false;
        this.rowSelectable = true;
      }
    },

    applyReportingSettings() {
      this.updatedDraggedTab();

      this.reports[this.currentReport].trafficType = this.currentTrafficType;
      this.reports[this.currentReport].playersType = this.currentPlayersType;
      this.reports[this.currentReport].search = this.$_.cloneDeep(
        this.currentSearch,
      );
      if (
        Object.keys(this.currentSearch).includes(
          this.reports[this.currentReport].searchQuery.group,
        )
      ) {
        this.reports[this.currentReport].searchQuery.group = '';
        this.reports[this.currentReport].searchQuery.value = '';
      }

      // Compute parameters for filter by affiliate_created_at
      if (this.currentAffiliateDateValue) {
        if (this.currentAffiliateDateOperator === '=') {
          const [startDate, endDate] = this.currentAffiliateDateValue;
          this.reports[
            this.currentReport
          ].affiliateCreatedAt.affiliateCreatedAtStart = startDate
            ? this.$moment(startDate).startOf('day')
            : startDate;
          this.reports[
            this.currentReport
          ].affiliateCreatedAt.affiliateCreatedAtEnd = endDate
            ? this.$moment(endDate).endOf('day')
            : endDate;
        } else if (this.currentAffiliateDateOperator === '≥') {
          this.reports[
            this.currentReport
          ].affiliateCreatedAt.affiliateCreatedAtStart = this.$moment(
            this.currentAffiliateDateValue,
          ).endOf('day');
          this.reports[
            this.currentReport
          ].affiliateCreatedAt.affiliateCreatedAtEnd = undefined;
        } else if (this.currentAffiliateDateOperator === '≤') {
          this.reports[
            this.currentReport
          ].affiliateCreatedAt.affiliateCreatedAtEnd = this.$moment(
            this.currentAffiliateDateValue,
          ).startOf('day');
          this.reports[
            this.currentReport
          ].affiliateCreatedAt.affiliateCreatedAtStart = undefined;
        }
      } else {
        this.reports[
          this.currentReport
        ].affiliateCreatedAt.affiliateCreatedAtStart = undefined;
        this.reports[
          this.currentReport
        ].affiliateCreatedAt.affiliateCreatedAtEnd = undefined;
      }
      this.reports[
        this.currentReport
      ].affiliateCreatedAt.currentAffiliateDateOperator = this.currentAffiliateDateOperator;

      this.reports = [...this.reports];

      this.isSettingsVisible = false;
      this.getReport('settings');
    },

    resetReportingSettings(event) {
      event.stopPropagation();
      this.updatedDraggedTab();

      this.isSettingsVisible = false;
      this.currentTrafficType = 'all';
      this.currentPlayersType = 'all';
      this.currentSearch = {};
      this.currentAffiliateDateValue = null;
      this.currentAffiliateDateOperator = '=';

      this.reports[this.currentReport].trafficType = 'all';
      this.reports[this.currentReport].playersType = 'all';
      this.reports[this.currentReport].search = {};
      // this.reports[this.currentReport].searchQuery = {
      //   value: '',
      //   group: '',
      // };
      this.reports[
        this.currentReport
      ].affiliateCreatedAt.affiliateCreatedAtStart = undefined;
      this.reports[
        this.currentReport
      ].affiliateCreatedAt.affiliateCreatedAtEnd = undefined;
      this.reports[
        this.currentReport
      ].affiliateCreatedAt.currentAffiliateDateOperator = '=';

      this.reports = [...this.reports];

      this.getReport('settings');
    },

    shortcutsDates() {
      return [
        {
          text: this.$t('reports.date_picker.last_30_day'),
          range: [
            this.$moment().startOf('day').subtract(29, 'd'),
            this.$moment().endOf('day'),
          ],
          onClick(picker) {
            picker.$emit('pick', this.range);
          },
        },
        {
          text: this.$t('reports.date_picker.this_month'),
          range: [this.$moment().startOf('month'), this.$moment().endOf('day')],
          onClick(picker) {
            picker.$emit('pick', this.range);
          },
        },
        {
          text: this.$t('reports.date_picker.prev_month'),
          range: [
            this.$moment().subtract(1, 'months').startOf('month'),
            this.$moment().subtract(1, 'months').endOf('month'),
          ],
          onClick(picker) {
            picker.$emit('pick', this.range);
          },
        },
        {
          text: this.$t('reports.date_picker.last_90_day'),
          range: [
            this.$moment().startOf('day').subtract(89, 'd'),
            this.$moment().endOf('day'),
          ],
          onClick(picker) {
            picker.$emit('pick', this.range);
          },
        },
      ];
    },
  },
};
</script>

<style lang="scss">
.reports {
  .col-checkbox {
    visibility: hidden !important;
  }
}

.el-date-editor.affiliate-created-at__filter-input {
  width: 100% !important; // TODO: get rid of all "!important"
  margin-top: 5px;
  input::placeholder {
    font-size: 13px;
    color: #c2c2c2 !important;
  }
}
</style>

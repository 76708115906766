import betwinner from '@/config/sites/betwinner';
import casinoz from '@/config/sites/casinoz';
import demo from '@/config/sites/demo';
import sprut from '@/config/sites/sprut';
import test from '@/config/sites/test';
import marathonbet from '@/config/sites/marathonbet';
import betpari from '@/config/sites/betpari';
import spelet from '@/config/sites/spelet';
import winon from '@/config/sites/winon';
import caswino from './caswino';

export default {
  betwinner,
  casinoz,
  demo,
  sprut,
  test,
  marathonbet,
  betpari,
  spelet,
  mbetru: marathonbet,
  winon,
  caswino,
};


<template>
  <adm-ui-chart
    :key="loading"
    class="dashboard-overview__metric-chart-wrap"
    :class="[
      loading || isLoading ? 'ui-chart--loading' : '',
      index === null ? '' : classChart(index + 1),
      isRemoved
        ? `dashboard-overview__metric-card-wrap--draggable dashboard-overview__metric-card-wrap--draggable${rowIndex}`
        : '',
      !filterIsEmpty ? 'dashboard-overview__metric-chart-wrap--filtered' : '',
    ]"
    :chart-options="configChart"
  >
    <template slot="header">
      <adm-ui-header
        tag="h3"
        :tooltip="computedheaderTooltip"
      >
        {{ nameChart }}
      </adm-ui-header>
      <div class="dashboard-overview__chart-action-wrap">
        <div v-if="!isRemoved" class="chart-action-default-wrap">
          <forecast-switch
            v-if="chartVisualization !== 'pie'"
            :value="forecastValue"
            @change="handlerForecastSwitch"
          />
        </div>
        <adm-ui-controls-widget v-else :data="controlsData" />
      </div>
    </template>
  </adm-ui-chart>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import formaterY from '../helper/customFormat';
import colors from '../helper/colors';
import ForecastSwitch from './ForecastSwitch.vue';

export default {
  name: 'DashboardChart',
  components: {
    ForecastSwitch,
  },

  props: {
    chart: {
      type: Object,
      required: true,
    },

    rowIndex: {
      type: Number,
      default: null,
    },

    index: {
      type: Number,
      default: null,
    },

    preview: {
      type: Boolean,
      default: false,
    },

    isRemoved: {
      type: Boolean,
      default: false,
    },

    isLoading: {
      type: Boolean,
      default: false,
    },

    savePeriod: {
      type: Boolean,
      default: false,
    },

    animation: {
      type: Boolean,
      default: false,
    },

    dashboardId: {
      type: String,
      default: '',
    },

    period: {
      type: Array,
      default() {
        return [];
      },
    },
  },

  data() {
    return {
      isLoadingChart: true,
      loading: false,

      chartData: ['spline', 'column'],

      dataChart: [],
      report: [],

      nameChart: '',
      series: [],
      xAxis: [],
      configChart: this.configChartMethod(),
      // period: this.chart.period || 'last_week',
      formatMetricSeries: '',
      titleYSeries: '',
      chartVisualization: 'line',
      // constPeriod: null,

      forecastValue: false,
      disabledForecast: false,

      seriesYAxisNumber: [],
    };
  },

  computed: {
    ...mapGetters('reports', ['reportsSettings']),
    ...mapState('dashboards', ['stateDashboardFilter']),

    computedheaderTooltip() {
      return Array.isArray(this.computedMetricName)
        ? ''
        : (this.getTranslateMetrics(this.computedMetricName)?.description || '');
    },

    filterIsEmpty() {
      if (this.chart.filters === null) return true;
      const { search, filters } = this.chart.filters;
      const countKey = Object.keys(this.chart.filters).length;
      const func = (count, filter) => {
        const keyArray = Object.keys(filter);
        if (count === 1) {
          return keyArray.includes('filters') || keyArray.includes('search');
        }
        if (count === 2) {
          return keyArray.includes('filters') && keyArray.includes('search');
        }
        return false;
      };
      return (
        this.$_.isEmpty(search)
        && this.$_.isEmpty(filters)
        && func(countKey, this.chart.filters)
      );
    },

    controlsData() {
      return [
        {
          title: this.$t('dashboards.other.edit'),
          type: 'primary',
          icon: 'fas fa-pen',
          clickHandler: () => this.editMetric(this.rowIndex, 'chart', this.index, this.chart),
        },
        {
          title: this.$t('dashboards.other.delete'),
          type: 'danger',
          icon: 'fas fa-trash-alt',
          clickHandler: () => this.removeMetric(this.rowIndex, this.index),
        },
      ];
    },

    computedMetricName() {
      const metricSeries = this.chart.series_group_by_metric_name !== null
        ? this.chart.series_group_by_metric_name
        : this.chart.series.map(s => s.metric_name);
      return metricSeries;
    },

    paramsData() {
      const period = {
        from: this.$moment(this.period[0]).format('YYYY-MM-DD HH:mm:ss'),
        to: this.$moment(this.period[1]).format('YYYY-MM-DD HH:mm:ss'),
      };
      const panel = {
        ...this.chart,
        enable_forecast: this.forecastValue,
        series: this.chart.series ? this.chart.series : [],
      };

      return { panel, filters: this.stateDashboardFilter, ...period };
    },
  },

  methods: {
    async updateDashboardPanelForecast(enable_forecast) {
      await this.$api.updateDashboardPanelForecast(
        this.dashboardId,
        this.chart.id,
        { enable_forecast },
      );

      this.$emit(
        'changeForecast',
        this.rowIndex,
        'chart',
        this.index,
        enable_forecast,
      );
    },

    async handlerForecastSwitch(value) {
      this.disabledForecast = true;
      this.forecastValue = !value;
      await this.updateDashboardPanelForecast(this.forecastValue);
      await this.getReport(undefined, 'localeForecost');
      this.disabledForecast = false;
    },

    async getDashboardsStats(params) {
      const {
        data: { payload },
      } = await this.$api.dashboardsStats(params);
      this.report = payload;
      this.$emit(
        'emitDataReport',
        this.rowIndex,
        'chart',
        this.index,
        this.report,
      );
    },

    editMetric(rowIndex, type, index, data) {
      this.$emit('editMetric', rowIndex, type, index, data);
    },

    removeMetric(rowIndex, index) {
      this.$emit('removeMetric', rowIndex, 'chart', index);
    },

    getTranslateMetrics(metric) {
      return this.reportsSettings.metrics.find(e => e.column_name === metric);
    },

    async getReport(type, flag) {
      if (flag !== 'localeForecost') {
        this.forecastValue = !!this.chart.enable_forecast;
      }

      if (type === 'getLocalData') {
        this.report = this.chart.report;
      } else {
        await this.getDashboardsStats(this.paramsData);
        this.loading = false;
      }

      this.nameChart = this.chart.name;
      this.chartVisualization = this.chart.chart_visualization;

      this.series = this.formattedSeries(
        this.chartVisualization !== 'pie'
          ? this.report.series
          : this.report.data,
      );
      this.xAxis = this.chartVisualization !== 'pie'
        ? this.report.x_axis.map(x => x.formatted)
        : [];

      const metricSeries = this.chart.series_group_by_metric_name !== null
        ? this.chart.series_group_by_metric_name
        : this.chart.series.map(s => s.metric_name);

      this.formatMetricSeries = Array.isArray(metricSeries)
        ? metricSeries.map(s => this.getTranslateMetrics(s).column_format)
        : this.getTranslateMetrics(metricSeries).column_format;

      this.titleYSeries = Array.isArray(metricSeries)
        ? ''
        : this.getTranslateMetrics(metricSeries).title;

      this.configChart = this.configChartMethod(
        this.formatMetricSeries,
        this.$i18n.locale,
      );
    },

    formattedSeries(seriesData) {
      const fnGetForecastRange = (data) => {
        const fi = data.findIndex(e => e.opacity === 0.99);
        if (fi === -1) return data.length;
        return fi;
      };

      const typeLine = this.chartVisualization === 'bar' ? 'dash' : 'dot';
      const zoneConfig = (data) => {
        const valConf = {
          zoneAxis: 'x',
          zones: [
            {
              value: data.length - (this.chartVisualization === 'bar'
                ? (data.length - fnGetForecastRange(data))
                : (data.length - fnGetForecastRange(data) + 1)
              ),
            },
            {
              dashStyle: this.forecastValue ? typeLine : 'line',
              className: this.forecastValue ? 'forecast-section' : '',
            },
          ],
        };
        return valConf;
      };


      let series = [];
      if (this.chartVisualization === 'pie' && this.report.others) {
        series = [
          ...seriesData,
          {
            ...this.report.others,
            dimension: this.$t('dashboards.other.others'),
          },
        ];
      } else {
        series = seriesData;
      }

      return series.map((ser, i) => {
        const name = this.chartVisualization !== 'pie' ? ser.name : ser.dimension;
        const data = this.chartVisualization !== 'pie'
          ? ser.values.map(val => ({
            y:
                  val.raw_forecasted !== undefined && this.forecastValue
                    ? Number(val.raw_forecasted, 10)
                    : Number(val.raw, 10),
            marker: {
              radius: 4.5,
              lineColor: colors[i],
              lineWidth: 1,
              fillColor: '#ffffff',
            },
            opacity: (val.raw_forecasted !== undefined && this.forecastValue) ? 0.99 : 1,
          }))
          : [
            {
              y: Number(ser.raw, 10),
              marker: {
                radius: 4.5,
                lineColor: colors[i],
                lineWidth: 1,
                fillColor: '#ffffff',
              },
            },
          ];

        return {
          name,
          color: colors[i],
          borderColor: colors[i],
          data,
          ...zoneConfig(data),
        };
      });
    },

    classChart(index) {
      return [
        this.chartData.length !== 1
          ? 'dashboard-overview__metric-chart-wrap--half'
          : '',
        this.chartData.length === index && index % 2 === 1
          ? 'dashboard-overview__metric-chart-wrap--last-odd'
          : '',
      ];
    },

    configChartMethod(columnFormat, locale) {
      this.seriesYAxisNumber = [];
      if (Array.isArray(columnFormat)) {
        const uniqAxis = this.$_.uniq(columnFormat);
        this.seriesYAxisNumber = columnFormat.map(el => uniqAxis.indexOf(el));
      }

      const typeChart = () => {
        if (this.chartVisualization === 'line') {
          return 'spline';
        }
        if (this.chartVisualization === 'bar') {
          return 'column';
        }
        return 'pie';
      };

      const seriesData = () => {
        if (typeChart() === 'pie') {
          return this.series
            ? [
              {
                name: this.titleYSeries,
                data: this.series.reduce((acc, el, i) => {
                  acc[i] = {
                    name: el.name,
                    color: el.color,
                    y: el.data.reduce((sum, item) => sum + item.y, 0),
                  };
                  return acc;
                }, []),
              },
            ]
            : [];
        }

        return this.series.map((el, i) => ({
          ...el,
          yAxis: this.seriesYAxisNumber[i],
        }));
      };

      const legend = {
        enabled: true,
        align: 'center',
        verticalAlign: 'bottom',
        layout: 'horizontal',
        itemWidth: undefined,
        maxHeight: 40,
        className: 'highcharts-no-tooltip',
        navigation: {
          activeColor: '#666666',
        },
      };

      const yAxis = Array.isArray(columnFormat)
        ? this.$_.uniq(columnFormat).map((cf, i) => ({
          title: {
            text: typeChart() === 'pie' ? '' : this.titleYSeries,
          },
          labels: {
            formatter() {
              return cf ? formaterY(this.value, cf, locale) : this.value;
            },
          },
          opposite: i > 0,
          gridLineDashStyle: 'ShortDash',
          gridLineColor: '#dedede',
          gridLineWidth: 1,
        }))
        : {
          title: {
            text: typeChart() === 'pie' ? '' : this.titleYSeries,
          },
          labels: {
            formatter() {
              return columnFormat
                ? formaterY(this.value, columnFormat, locale)
                : this.value;
            },
          },
          gridLineDashStyle: 'ShortDash',
          gridLineColor: '#dedede',
          gridLineWidth: 1,
        };

      const tooltip = Array.isArray(columnFormat)
        ? {
          outside: true,
          formatter() {
            return this.points
              ? this.points.reduce(
                (s, point, i) => `${s}<br/><span  style="color:${
                        point.color
                      }; font-size: 16px">•</span>${
                        point.series.name
                      }: <span style="font-weight: 900">${
                        columnFormat[i]
                          ? formaterY(point.y, columnFormat[i], locale)
                          : point.y
                      }</span>`,
                    `<b>${this.x}</b>`,
              )
              : `${this.key}<br/>${this.point.series.name}: ${
                    columnFormat
                      ? formaterY(this.point.y, columnFormat, locale)
                      : this.point.y
                  }`;
          },
          shared: true,
        }
        : {
          outside: true,
          formatter() {
            return this.points
              ? this.points.reduce(
                (s, point) => `${s}<br/>${point.series.name}: ${
                        columnFormat
                          ? formaterY(point.y, columnFormat, locale)
                          : point.y
                      }`,
                    `<b>${this.x}</b>`,
              )
              : `${this.key}<br/>${this.point.series.name}: ${
                    columnFormat
                      ? formaterY(this.point.y, columnFormat, locale)
                      : this.point.y
                  }`;
          },
          shared: true,
        };

      return {
        chart: {
          type: typeChart(),
          renderTo: 'container',
        },
        xAxis: {
          visible: typeChart() !== 'pie',
          categories: this.xAxis,
        },
        yAxis,
        title: {
          text: '',
        },
        credits: {
          enabled: false,
        },
        legend,
        tooltip,
        series: seriesData(),
        plotOptions: {
          spline: {
            marker: {
              symbol: 'curcle',
              radius: 3.5,
              lineWidth: 1,

              states: {
                hover: {
                  enabledThreshold: 2,
                  radius: 4.5,
                  radiusPlus: 0,
                  lineWidthPlus: 0.5,
                },
              },
            },
          },
          line: {
            marker: {
              symbol: 'curcle',
              radius: 4.5,
              lineWidth: 1,
              states: {
                hover: {
                  enabledThreshold: 2,
                  radius: 4.5,
                  radiusPlus: 0,
                  lineWidthPlus: 0.5,
                },
              },
            },
          },
          pie: {
            animation: {
              defer: 0,
              duration: this.animation ? 1000 : 0,
            },
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
              distance: 20,
            },
          },
          column: {
            borderRadius: 4,
          },
        },
      };
    },
  },
};
</script>

<style lang="scss">
.dashboard-overview__metric-chart-wrap {
  .highcharts-legend-item {
    tspan {
      font-weight: 500;
    }
  }
}
</style>

<template>
  <div class="ui-filter popover-filter">
    <ui-button
      ref="mainBtn"
      :filled="!!filtersOpen || !$_.isEmpty(currentFilters)"
      :disabled="disabled"
      icon="filter"
      lib="fa"
      substyle="fas"
      color="green"
      class="main-btn"
      @click="toggleFilters"
    >
      {{ $t('ui.filters.inner.filter') }}
    </ui-button>
    <transition name="fade">
      <div
        v-show="filtersOpen"
        ref="pop"
        class="pop"
      >
        <div class="arrow" />
        <div
          v-for="(item, index) in currentFilters"
          :key="index"
          class="filter-row"
          :class="{'hide-filter': item.type === 'availabilityAffiliateId'}"
        >
          <ui-button
            v-if="item.type !== 'availabilityAffiliateId'"
            :icon-size="12"
            color="red"
            lib="fa"
            substyle="fas"
            icon="minus"
            class="btn"
            @click="removeFilter(index)"
          />
          <ui-select
            v-if="item.type !== 'availabilityAffiliateId'"
            v-model="item.type"
            :options="isOutFilters"
            :exclude-options="excludeFilters"
            :label-i18n="'ui.filters'"
            value-prop="type"
            label-prop="type"
            class="filter_type"
            @select="item.values = getFilter(item.type).multiple ? [] : ''"
            @input="inputSelect"
          />
          <ui-select
            v-if="item.type !== 'lastActivity' && item.type !== 'profitCategory' && item.type !== 'availabilityMaterials' && item.type !== 'availabilityAffiliateId'"
            v-model="item.values"
            :options="getFilter(item.type).options"
            :value-prop="getFilter(item.type).valueProp"
            :label-prop="getFilter(item.type).labelProp"
            :remote="getFilter(item.type).remote"
            :filter-method="getFilter(item.type).method"
            :disabled="!item.type"
            :multiple="getFilter(item.type).multiple"
            :filterable="getFilter(item.type).filterable"
            class="filter_type"
          />
          <el-radio-group
            v-if="item.type === 'availabilityMaterials'"
            v-model="item.values"
            class="radio-operator visibility-operator"
            size="small"
            @change="changeVisibility(item.values)"
          >
            <el-radio-button label="public">
              {{ $t('ui.filters.select.public') }}
            </el-radio-button>
            <el-radio-button label="private">
              {{ $t('ui.filters.select.private') }}
            </el-radio-button>
          </el-radio-group>
          <ui-select
            v-if="item.type === 'availabilityMaterials'"
            v-model="availabilityMaterialsValues.values"
            :options="getFilterAvailabilityAffiliateId.options"
            :value-prop="getFilterAvailabilityAffiliateId.valueProp"
            :label-prop="getFilterAvailabilityAffiliateId.labelProp"
            :remote="getFilterAvailabilityAffiliateId.remote"
            :filter-method="getFilterAvailabilityAffiliateId.method"
            :multiple="getFilterAvailabilityAffiliateId.multiple"
            :filterable="getFilterAvailabilityAffiliateId.filterable"
            class="filter_type"
            :class="{'filter-select--disabled': item.values === 'public'}"
          />


          <div
            v-if="item.type === 'lastActivity'"
            class="f-day"
          >
            <div class="f-day__tabs">
              <div
                :class="{'f-day__tab--active': item.values[1] === 'from'}"
                class="f-day__tab"
                @click="item.values[1] = 'from'; clone()"
              >
                &lt;
              </div>
              <div
                :class="{'f-day__tab--active': item.values[1] === 'to'}"
                class="f-day__tab "
                @click="item.values[1] = 'to'; clone()"
              >
                &gt;
              </div>
            </div>
            <ui-input
              v-model="item.values[0]"
              placeholder=""
              class="f-day__input"
            />
            <span class="f-day__text">{{ $t('ui.filters.inner.days') }}</span>
          </div>
          <div
            v-if="item.type === 'profitCategory'"
            class="f-day"
          >
            <div class="f-day__tabs">
              <div
                :class="{'f-day__tab--active': allFilters.profitCategory.field === 'max_profit_category'}"
                class="f-day__tab"
                @click="allFilters.profitCategory.field = 'max_profit_category'; clone()"
              >
                &le;
              </div>
              <div
                :class="{'f-day__tab--active': allFilters.profitCategory.field === 'min_profit_category'}"
                class="f-day__tab "
                @click="allFilters.profitCategory.field = 'min_profit_category'; clone()"
              >
                &ge;
              </div>
            </div>
            <ui-select
              v-if="item.type === 'profitCategory'"
              v-model="item.values"
              :options="getFilter(item.type).options"
              :value-prop="getFilter(item.type).valueProp"
              :label-prop="getFilter(item.type).labelProp"
              :sufix-prop="getFilter(item.type).sufixProp"
              :remote="getFilter(item.type).remote"
              :filter-method="getFilter(item.type).method()"
              :disabled="!item.type"
              :multiple="getFilter(item.type).multiple"
              :filterable="getFilter(item.type).filterable"
              class="select-class"
              sufix
            />
          </div>
        </div>
        <div class="controls">
          <ui-button
            :disabled="currentFilters.length === availableFiltersLength"
            icon="plus"
            lib="fa"
            substyle="fas"
            color="green"
            class="btn"
            @click="addFilter"
          />
          <ui-button
            filled
            lib="fa"
            substyle="fas"
            color="green"
            icon="check"
            class="btn"
            :disabled="disabledActionBtn && isDisabled"
            @click="applyFilters"
          >
            {{ $t('ui.filters.inner.apply_filter') }}
          </ui-button>
          <ui-button
            color="red"
            lib="fa"
            substyle="fas"
            icon="times"
            class="btn"
            @click="resetFilters"
          >
            {{ $t('ui.filters.inner.reset_filter') }}
          </ui-button>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import updateUrl from '../../service/updateUrl';

export default {
  name: 'UiFilter',
  props: {
    disabledActionBtn: {
      type: Boolean,
      default() {
        return true;
      },
    },

    disabled: {
      type: Boolean,
      default() {
        return false;
      },
    },
    value: {
      type: Object,
      default() {
        return {};
      },
    },
    useFilters: {
      type: Array,
      default() {
        return [
          'affiliate',
          'affiliateReport',
          'player',
          'playerReport',
          'country',
          'playerCountry',
          'trafficType',
        ];
      },
    },
    from: {
      type: Object,
      default() {
        return this.$moment().startOf('day').subtract(30, 'd');
      },
    },
    to: {
      type: Object,
      default() {
        return this.$moment().endOf('day');
      },
    },
  },
  data() {
    return {
      isDisabled: true,
      tab: 'from',
      visibleTab: 'public',
      countInput: 30,
      filtersOpen: false,
      allFilters: {
        playerFilter: {
          type: 'playerFilter',
          field: 'players_filter',
          options: [
            {
              type: 'all',
              name: this.$t('reports.players_type.all'),
            },
            {
              type: 'new',
              name: this.$t('reports.players_type.new'),
            },
            {
              type: 'old',
              name: this.$t('reports.players_type.old'),
            },
          ],
          labelProp: 'name',
          valueProp: 'type',
          multiple: false,
          filterable: false,
          remote: false,
          method: null,
        },
        trafficType: {
          type: 'trafficType',
          field: 'traffic_type',
          options: [
            {
              type: 'all',
              name: this.$t('ui.filters.select.all'),
            },
            {
              type: 'affiliates',
              name: this.$t('ui.filters.select.partners'),
            },
            {
              type: 'direct',
              name: this.$t('ui.filters.select.direct'),
            },
          ],
          labelProp: 'name',
          valueProp: 'type',
          multiple: false,
          filterable: false,
          remote: false,
          method: null,
        },

        playerCountryNameFilter: {
          type: 'playerCountryNameFilter',
          field: 'player_country_code',
          options: [],
          labelProp: 'player_country_name',
          valueProp: 'row_id',
          multiple: true,
          filterable: true,
          remote: true,
          method: (q) => {
            const dateFrom = this.$moment().startOf('day').subtract(1, 'month');
            const dateTo = this.$moment().format('YYYY-MM-DD HH:mm:ss');
            const query = {
              search: q ? {
                player_country_name: {
                  op: 'like',
                  value: [q],
                },
              } : undefined,
              dimensions: ['player_country_name'],
              limit: 100,
              offset: 0,
              sorting: [{
                sort_by: 'player_country_name',
                sort_dir: 'asc',
              }],
              metrics: ['deposits_all_sum'],
              from: this.$moment(dateFrom).startOf('day').format('YYYY-MM-DD HH:mm:ss'),
              to: this.$moment(dateTo).endOf('day').format('YYYY-MM-DD HH:mm:ss'),
            };
            return new Promise((resolve, reject) => {
              this.$api.getMainReport(query)
                .then((response) => {
                  resolve(response.data.payload.data);
                })
                .catch((error) => {
                  reject(error);
                });
            });
          },
        },
        playerRegionNameFilter: {
          type: 'playerRegionNameFilter',
          field: 'player_region_name',
          options: [],
          labelProp: 'player_region_name',
          valueProp: 'row_id',
          multiple: true,
          filterable: true,
          remote: true,
          method: (q) => {
            const dateFrom = this.$moment().startOf('day').subtract(1, 'month');
            const dateTo = this.$moment().format('YYYY-MM-DD HH:mm:ss');
            const query = {
              search: q ? {
                player_region_name: {
                  op: 'like',
                  value: [q],
                },
              } : undefined,
              dimensions: ['player_region_name'],
              limit: 100,
              offset: 0,
              sorting: [{
                sort_by: 'player_region_name',
                sort_dir: 'asc',
              }],
              metrics: ['deposits_all_sum'],
              from: this.$moment(dateFrom).startOf('day').format('YYYY-MM-DD HH:mm:ss'),
              to: this.$moment(dateTo).endOf('day').format('YYYY-MM-DD HH:mm:ss'),
            };
            return new Promise((resolve, reject) => {
              this.$api.getMainReport(query)
                .then((response) => {
                  resolve(response.data.payload.data);
                })
                .catch((error) => {
                  reject(error);
                });
            });
          },
        },
        trafficRegionNameFilter: {
          type: 'trafficRegionNameFilter',
          field: 'country_code',
          options: [],
          labelProp: 'country_name',
          valueProp: 'row_id',
          multiple: true,
          filterable: true,
          remote: true,
          method: (q) => {
            const dateFrom = this.$moment().startOf('day').subtract(1, 'month');
            const dateTo = this.$moment().format('YYYY-MM-DD HH:mm:ss');
            const query = {
              search: q ? {
                country_name: {
                  op: 'like',
                  value: [q],
                },
              } : undefined,
              dimensions: ['country_name'],
              limit: 100,
              offset: 0,
              sorting: [{
                sort_by: 'country_name',
                sort_dir: 'asc',
              }],
              metrics: ['deposits_all_sum'],
              from: this.$moment(dateFrom).startOf('day').format('YYYY-MM-DD HH:mm:ss'),
              to: this.$moment(dateTo).endOf('day').format('YYYY-MM-DD HH:mm:ss'),
            };
            return new Promise((resolve, reject) => {
              this.$api.getMainReport(query)
                .then((response) => {
                  resolve(response.data.payload.data);
                })
                .catch((error) => {
                  reject(error);
                });
            });
          },
        },
        /* end filter dash  */


        affiliate: {
          type: 'affiliate',
          field: 'affiliate_id',
          options: [],
          labelProp: 'email',
          valueProp: 'id',
          multiple: true,
          filterable: true,
          remote: true,
          method: (q) => {
            const query = {
              limit: 100,
              offset: 0,
              search: q,
              account_status: ['approved'],
            };
            return new Promise((resolve, reject) => {
              this.$api.getAffiliates(query)
                .then((response) => {
                  resolve(response.data.payload);
                })
                .catch((error) => {
                  reject(error);
                });
            });
          },
        },
        affiliatePaymentsReport: {
          type: 'affiliatePaymentsReport',
          field: 'affiliate_payments_report_id',
          options: [],
          labelProp: 'affiliate_email',
          valueProp: 'row_id',
          multiple: true,
          filterable: true,
          remote: true,
          method: (q) => {
            const _dateParams = updateUrl.getParseParams(this.$route.hash, ['date']);
            const dateFrom = this.$moment(this.$_.get(_dateParams, ['date', 'from']));
            const dateTo = this.$moment(this.$_.get(_dateParams, ['date', 'to']));
            const query = {
              search: q ? {
                affiliate_email: {
                  op: 'like',
                  value: [q],
                },
              } : undefined,
              dimensions: ['affiliate_email'],
              limit: 100,
              offset: 0,
              sorting: [{
                sort_by: 'affiliate_email',
                sort_dir: 'asc',
              }],
              metrics: ['deposits_withdrawals_count', 'deposits_withdrawals_sum', 'deposits_all_count', 'deposits_all_sum'],
              from: this.$moment(dateFrom).startOf('day').format('YYYY-MM-DD HH:mm:ss'),
              to: this.$moment(dateTo).endOf('day').format('YYYY-MM-DD HH:mm:ss'),
            };
            return new Promise((resolve, reject) => {
              this.$api.getMainReport(query)
                .then((response) => {
                  resolve(response.data.payload.data);
                })
                .catch((error) => {
                  reject(error);
                });
            });
          },
        },
        affiliateLastBets: {
          type: 'affiliateLastBets',
          field: 'affiliate_last_bets_id',
          options: [],
          labelProp: 'affiliate_email',
          valueProp: 'row_id',
          multiple: true,
          filterable: true,
          remote: true,
          method: (q) => {
            const query = {
              search: q ? {
                affiliate_email: {
                  op: 'like',
                  value: [q],
                },
              } : undefined,
              dimensions: ['affiliate_email'],
              limit: 100,
              offset: 0,
              sorting: [{
                sort_by: 'affiliate_email',
                sort_dir: 'asc',
              }],
              metrics: ['bet_new_count', 'bet_new_sum'],
              from: this.$moment(new Date()).subtract(1, 'd').format('YYYY-MM-DD HH:mm:ss'),
              to: this.$moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
            };
            return new Promise((resolve, reject) => {
              this.$api.getMainReport(query)
                .then((response) => {
                  resolve(response.data.payload.data);
                })
                .catch((error) => {
                  reject(error);
                });
            });
          },
        },
        affiliateLastDeposits: {
          type: 'affiliateLastDeposits',
          field: 'affiliate_last_deposits_id',
          options: [],
          labelProp: 'affiliate_email',
          valueProp: 'row_id',
          multiple: true,
          filterable: true,
          remote: true,
          method: (q) => {
            const query = {
              search: q ? {
                affiliate_email: {
                  op: 'like',
                  value: [q],
                },
              } : undefined,
              dimensions: ['affiliate_email'],
              limit: 100,
              offset: 0,
              sorting: [{
                sort_by: 'affiliate_email',
                sort_dir: 'asc',
              }],
              metrics: ['deposits_all_count', 'deposits_all_sum'],
              from: this.$moment(new Date()).subtract(1, 'd').format('YYYY-MM-DD HH:mm:ss'),
              to: this.$moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
            };
            return new Promise((resolve, reject) => {
              this.$api.getMainReport(query)
                .then((response) => {
                  resolve(response.data.payload.data);
                })
                .catch((error) => {
                  reject(error);
                });
            });
          },
        },
        profitCategory: {
          type: 'profitCategory',
          field: 'min_profit_category',
          options: [
            {
              type: 'A++',
              name: 'A++',
              sufix: '<span class="green">(+ 90-100%)</span>',
            },
            {
              type: 'A+',
              name: 'A+',
              sufix: '<span class="green">(+ 70-90%)</span>',
            },
            {
              type: 'A',
              name: 'A',
              sufix: '<span class="green">(+ 50-70%)</span>',
            },
            {
              type: 'B',
              name: 'B',
              sufix: '<span class="green">(+ 25-50%)</span>',
            },
            {
              type: 'C',
              name: 'C',
              sufix: '<span class="green">(+ 10-25%)</span>',
            },
            {
              type: 'D',
              name: 'D',
              sufix: '<span class="green">(+ 0-10%)</span>',
            },
            {
              type: 'E',
              name: 'E',
              sufix: '<span class="red">(- 0-50%)</span>',
            },
            {
              type: 'F',
              name: 'F',
              sufix: '<span class="red">(- 50-100%)</span>',
            },
            {
              type: 'G',
              name: 'G',
              sufix: `<span class="red">(${this.$t('ui.filters.select.and_more')})</span>`,
            },
          ],
          valueProp: 'type',
          labelProp: 'name',
          sufixProp: 'sufix',
          multiple: false,
          filterable: false,
          remote: false,
          method: () => null,
        },
        lastActivity: {
          type: 'lastActivity',
          field: 'activity',
          values: [
            '30',
            'from',
          ],
          method: null,
        },
        availabilityMaterials: {
          type: 'availabilityMaterials',
          field: 'visibility',
          values: 'public',
          method: null,
        },
        availabilityAffiliateId: {
          type: 'availabilityAffiliateId',
          field: 'affiliate_id',
          options: [],
          labelProp: 'email',
          valueProp: 'id',
          multiple: true,
          filterable: true,
          remote: true,
          method: (q) => {
            const query = {
              limit: 100,
              offset: 0,
              search: q,
              account_status: ['approved'],
            };
            return new Promise((resolve, reject) => {
              this.$api.getAffiliates(query)
                .then((response) => {
                  resolve(response.data.payload);
                })
                .catch((error) => {
                  reject(error);
                });
            });
          },
        },

        contacts: {
          type: 'contacts',
          field: 'contacts',
          options: [
            {
              type: 'only',
              name: this.$t('ui.filters.select.only_content'),
            },
            {
              type: 'all',
              name: this.$t('ui.filters.select.all'),
            },
          ],
          labelProp: 'name',
          valueProp: 'type',
          multiple: false,
          filterable: false,
          remote: false,
          method: null,
        },
        deposits: {
          type: 'deposits',
          field: 'deposits',
          options: [
            {
              type: 'without',
              name: this.$t('ui.filters.select.without'),
            },
            {
              type: 'first',
              name: this.$t('ui.filters.select.only_first_dep'),
            },
            {
              type: 'more',
              name: this.$t('ui.filters.select.more_one'),
            },
            {
              type: 'onlydep',
              name: this.$t('ui.filters.select.only_with_dep'),
            },
          ],
          labelProp: 'name',
          valueProp: 'type',
          multiple: false,
          filterable: false,
          remote: false,
          method: null,
        },
        pinnedAffiliate: {
          type: 'pinnedAffiliate',
          field: 'belongs_to_affiliate',
          options: [
            {
              type: false,
              name: this.$t('ui.filters.select.no'),
            },
            {
              type: true,
              name: this.$t('ui.filters.select.yes'),
            },
          ],
          valueProp: 'type',
          labelProp: 'name',
          multiple: false,
          filterable: false,
          remote: false,
          method: null,
        },
        affiliateReport: {
          type: 'affiliateReport',
          field: 'affiliate_id',
          options: [],
          labelProp: 'affiliate_email',
          valueProp: 'row_id',
          multiple: true,
          filterable: true,
          remote: true,
          method: (q) => {
            const query = {
              search: q ? {
                affiliate_email: {
                  op: 'like',
                  value: [q],
                },
              } : undefined,
              dimensions: ['affiliate_email'],
              limit: 100,
              offset: 0,
              sorting: [{
                sort_by: 'deposits_all_count',
                sort_dir: 'desc',
              }],
              metrics: ['deposits_all_count'],
              from: this.$moment(this.from)
                .startOf('day')
                .format('YYYY-MM-DD HH:mm:ss'),
              to: this.$moment(this.to)
                .endOf('day')
                .format('YYYY-MM-DD HH:mm:ss'),
            };
            return new Promise((resolve, reject) => {
              this.$api.getMainReport(query)
                .then((response) => {
                  resolve(response.data.payload.data);
                })
                .catch((error) => {
                  reject(error);
                });
            });
          },
        },
        player: {
          type: 'player',
          field: 'player_id',
          options: [],
          labelProp: 'site_player_id',
          valueProp: 'row_id',
          multiple: true,
          filterable: true,
          remote: true,
          method: (q) => {
            const _dateParams = updateUrl.getParseParams(this.$route.hash, ['date']);
            const dateFrom = this.$moment(this.$_.get(_dateParams, ['date', 'from']));
            const dateTo = this.$moment(this.$_.get(_dateParams, ['date', 'to']));
            const query = {
              limit: 100,
              offset: 0,
              search: q ? {
                site_player_id: {
                  op: 'like',
                  value: [q],
                },
              } : undefined,
              dimensions: ['site_player_id'],
              sorting: [{
                sort_by: 'site_player_id',
                sort_dir: 'asc',
              }],
              metrics: ['deposits_all_count', 'deposits_all_sum'],
              from: this.$moment(dateFrom).startOf('day').format('YYYY-MM-DD HH:mm:ss'),
              to: this.$moment(dateTo).endOf('day').format('YYYY-MM-DD HH:mm:ss'),
            };
            return new Promise((resolve, reject) => {
              this.$api.getMainReport(query)
                .then((response) => {
                  resolve(response.data.payload.data);
                })
                .catch((error) => {
                  reject(error);
                });
            });
          },
        },
        playerReport: {
          type: 'playerReport',
          field: 'player_id',
          options: [],
          labelProp: 'site_player_id',
          valueProp: 'row_id',
          multiple: true,
          filterable: true,
          remote: true,
          method: (q) => {
            const query = {
              search: q,
              dimensions: ['site_player_id'],
              limit: 100,
              offset: 0,
              sorting: [{
                sort_by: 'deposits_all_sum',
                sort_dir: 'asc',
              }],
              metrics: ['deposits_all_sum'],
              from: this.$moment(this.from)
                .startOf('day')
                .format('YYYY-MM-DD HH:mm:ss'),
              to: this.$moment(this.to)
                .endOf('day')
                .format('YYYY-MM-DD HH:mm:ss'),
            };
            return new Promise((resolve, reject) => {
              this.$api.getMainReport(query)
                .then((response) => {
                  resolve(response.data.payload.data);
                })
                .catch((error) => {
                  reject(error);
                });
            });
          },
        },
        countryPaymentsReport: {
          type: 'countryPaymentsReport',
          field: 'country_code_payments_report',
          options: [],
          labelProp: 'country_name',
          valueProp: 'row_id',
          multiple: true,
          filterable: true,
          remote: true,
          method: (q) => {
            const _dateParams = updateUrl.getParseParams(this.$route.hash, ['date']);
            const dateFrom = this.$moment(this.$_.get(_dateParams, ['date', 'from']));
            const dateTo = this.$moment(this.$_.get(_dateParams, ['date', 'to']));
            const query = {
              search: q ? {
                country_name: {
                  op: 'like',
                  value: [q],
                },
              } : undefined,
              dimensions: ['country_name'],
              limit: 100,
              offset: 0,
              sorting: [{
                sort_by: 'country_name',
                sort_dir: 'asc',
              }],
              metrics: ['deposits_withdrawals_count', 'deposits_withdrawals_sum', 'deposits_all_count', 'deposits_all_sum'],
              from: this.$moment(dateFrom).startOf('day').format('YYYY-MM-DD HH:mm:ss'),
              to: this.$moment(dateTo).endOf('day').format('YYYY-MM-DD HH:mm:ss'),
            };
            return new Promise((resolve, reject) => {
              this.$api.getMainReport(query)
                .then((response) => {
                  resolve(response.data.payload.data);
                })
                .catch((error) => {
                  reject(error);
                });
            });
          },
        },
        country: {
          type: 'country',
          field: 'country_code',
          options: [],
          labelProp: 'name',
          valueProp: 'code',
          multiple: true,
          filterable: true,
          remote: false,
          method: null,
        },
        playerCountry: {
          type: 'playerCountry',
          field: 'player_country_code',
          options: [],
          labelProp: 'name',
          valueProp: 'code',
          multiple: true,
          filterable: true,
          remote: false,
          method: null,
        },
        playerCountryLastBets: {
          type: 'playerCountryLastBets',
          field: 'player_country_code_last_bets',
          options: [],
          labelProp: 'player_country_name',
          valueProp: 'row_id',
          multiple: true,
          filterable: true,
          remote: true,
          method: (q) => {
            const query = {
              search: q ? {
                player_country_name: {
                  op: 'like',
                  value: [q],
                },
              } : undefined,
              dimensions: ['player_country_name'],
              limit: 100,
              offset: 0,
              sorting: [{
                sort_by: 'player_country_name',
                sort_dir: 'asc',
              }],
              metrics: ['bet_new_count', 'bet_new_sum'],
              from: this.$moment(new Date()).subtract(1, 'd').format('YYYY-MM-DD HH:mm:ss'),
              to: this.$moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
            };
            return new Promise((resolve, reject) => {
              this.$api.getMainReport(query)
                .then((response) => {
                  resolve(response.data.payload.data);
                })
                .catch((error) => {
                  reject(error);
                });
            });
          },
        },
        playerCountryLastDeposits: {
          type: 'playerCountryLastDeposits',
          field: 'player_country_code_last_deposits',
          options: [],
          labelProp: 'player_country_name',
          valueProp: 'row_id',
          multiple: true,
          filterable: true,
          remote: true,
          method: (q) => {
            const query = {
              search: q ? {
                player_country_name: {
                  op: 'like',
                  value: [q],
                },
              } : undefined,
              limit: 100,
              offset: 0,
              dimensions: ['player_country_name'],
              sorting: [{
                sort_by: 'player_country_name',
                sort_dir: 'asc',
              }],
              metrics: ['deposits_all_count', 'deposits_all_sum'],
              from: this.$moment(new Date()).subtract(1, 'd').format('YYYY-MM-DD HH:mm:ss'),
              to: this.$moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
            };
            return new Promise((resolve, reject) => {
              this.$api.getMainReport(query)
                .then((response) => {
                  resolve(response.data.payload.data);
                })
                .catch((error) => {
                  reject(error);
                });
            });
          },
        },
        playerCountryPaymentsReport: {
          type: 'playerCountryPaymentsReport',
          field: 'player_country_code_payments_report',
          options: [],
          labelProp: 'player_country_name',
          valueProp: 'row_id',
          multiple: true,
          filterable: true,
          remote: true,
          method: (q) => {
            const _dateParams = updateUrl.getParseParams(this.$route.hash, ['date']);
            const dateFrom = this.$moment(this.$_.get(_dateParams, ['date', 'from']));
            const dateTo = this.$moment(this.$_.get(_dateParams, ['date', 'to']));
            const query = {
              search: q ? {
                player_country_name: {
                  op: 'like',
                  value: [q],
                },
              } : undefined,
              dimensions: ['player_country_name'],
              limit: 100,
              offset: 0,
              sorting: [{
                sort_by: 'player_country_name',
                sort_dir: 'asc',
              }],
              metrics: ['deposits_withdrawals_count', 'deposits_withdrawals_sum', 'deposits_all_count', 'deposits_all_sum'],
              from: this.$moment(dateFrom).startOf('day').format('YYYY-MM-DD HH:mm:ss'),
              to: this.$moment(dateTo).endOf('day').format('YYYY-MM-DD HH:mm:ss'),
            };
            return new Promise((resolve, reject) => {
              this.$api.getMainReport(query)
                .then((response) => {
                  resolve(response.data.payload.data);
                })
                .catch((error) => {
                  reject(error);
                });
            });
          },
        },

        autoDnsManagement: {
          type: 'autoDnsManagement',
          field: 'auto_dns_management',
          options: [
            {
              type: true,
              name: this.$t('ui.filters.select.auto'),
            },
            {
              type: false,
              name: this.$t('ui.filters.select.manual'),
            },
          ],
          labelProp: 'name',
          valueProp: 'type',
          multiple: false,
          filterable: false,
          remote: false,
          method: null,
        },
        dnsHostingId: {
          type: 'dnsHostingId',
          field: 'dns_hosting_id',
          options: [],
          labelProp: 'name',
          valueProp: 'id',
          multiple: false,
          filterable: false,
          remote: false,
          method: null,
        },
        autoNsManagement: {
          type: 'autoNsManagement',
          field: 'auto_ns_management',
          options: [
            {
              type: true,
              name: this.$t('ui.filters.select.auto'),
            },
            {
              type: false,
              name: this.$t('ui.filters.select.manual'),
            },
          ],
          labelProp: 'name',
          valueProp: 'type',
          multiple: false,
          filterable: false,
          remote: false,
          method: null,
        },
        domainRegistrarId: {
          type: 'domainRegistrarId',
          field: 'domain_registrar_id',
          options: [],
          labelProp: 'name',
          valueProp: 'id',
          multiple: false,
          filterable: false,
          remote: false,
          method: null,
        },
        nsManagement: {
          type: 'nsManagement',
          field: 'ns_management',
          options: [
            {
              type: 'auto',
              name: this.$t('ui.filters.select.auto'),
            },
            {
              type: 'manual',
              name: this.$t('ui.filters.select.manual'),
            },
          ],
          labelProp: 'name',
          valueProp: 'type',
          multiple: false,
          filterable: false,
          remote: false,
          method: null,
        },
        dnsManagement: {
          type: 'dnsManagement',
          field: 'dns_management',
          options: [
            {
              type: 'auto',
              name: this.$t('ui.filters.select.auto'),
            },
            {
              type: 'manual',
              name: this.$t('ui.filters.select.manual'),
            },
          ],
          labelProp: 'name',
          valueProp: 'type',
          multiple: false,
          filterable: false,
          remote: false,
          method: null,
        },
        sslIssuance: {
          type: 'sslIssuance',
          field: 'ssl_issuance',
          options: [
            {
              type: 'auto',
              name: this.$t('ui.filters.select.auto'),
            },
            {
              type: 'manual',
              name: this.$t('ui.filters.select.manual'),
            },
          ],
          labelProp: 'name',
          valueProp: 'type',
          multiple: false,
          filterable: false,
          remote: false,
          method: null,
        },
        platformServiceOrigin: {
          type: 'platformServiceOrigin',
          field: 'platform_service_origin_id',
          labelProp: 'name',
          valueProp: 'id',
          multiple: false,
          filterable: true,
          remote: true,
          method: async (search) => {
            const { data: { payload } } = await this.$api.getServiceOrigins({ limit: 25, search });
            return payload;
          },
        },
        platformServer: {
          type: 'platformServer',
          field: 'platform_server_id',
          labelProp: 'name',
          valueProp: 'id',
          multiple: false,
          filterable: true,
          remote: true,
          method: async (search) => {
            const { data: { payload } } = await this.$api.getPlatformServers({ limit: 25, search });
            return payload;
          },
        },
        domainRegistrarAccount: {
          type: 'domainRegistrarAccount',
          field: 'domain_registrar_account_id',
          labelProp: 'name',
          valueProp: 'id',
          multiple: false,
          filterable: true,
          remote: true,
          method: async (search) => {
            const { data: { payload } } = await this.$api.getDomainRegistrarAccounts({ limit: 25, search });
            return payload;
          },
        },
        dnsHostingAccount: {
          type: 'dnsHostingAccount',
          field: 'dns_hosting_account_id',
          labelProp: 'name',
          valueProp: 'id',
          multiple: false,
          filterable: true,
          remote: true,
          method: async (search) => {
            const { data: { payload } } = await this.$api.getDNSHostingAccounts({ limit: 25, search });
            return payload;
          },
        },
        marketingMaterialType: {
          type: 'marketingMaterialType',
          field: 'type',
          options: [
            {
              type: 'html5_banner',
              name: this.$t('ui.filters.select.html5_banner'),
            },
            {
              type: 'simple_banner',
              name: this.$t('ui.filters.select.simple_banner'),
            },
            {
              type: 'landing',
              name: this.$t('ui.filters.select.landing'),
            },
          ],
          labelProp: 'name',
          valueProp: 'type',
          multiple: false,
          filterable: false,
          remote: false,
          method: null,
        },
        marketingMaterialStatus: {
          type: 'marketingMaterialStatus',
          field: 'status',
          options: [
            {
              type: 'active',
              name: this.$t('ui.filters.select.active'),
            },
            {
              type: 'inactive',
              name: this.$t('ui.filters.select.inactive'),
            },
          ],
          labelProp: 'name',
          valueProp: 'type',
          multiple: false,
          filterable: false,
          remote: false,
          method: null,
        },
        languageCode: {
          type: 'languageCode',
          field: 'language_code',
          labelProp: 'name',
          valueProp: 'code',
          multiple: false,
          filterable: true,
          remote: true,
          async method(search) {
            const { data: { payload } } = await this.$api.getLanguages({ search });
            return payload;
          },
        },
        usedInAnyGroup: {
          type: 'usedInAnyGroup',
          field: 'used_in_any_group',
          options: [
            {
              type: true,
              name: this.$t('ui.filters.select.yes'),
            },
            {
              type: false,
              name: this.$t('ui.filters.select.no'),
            },
          ],
          labelProp: 'name',
          valueProp: 'type',
          multiple: false,
          filterable: false,
          remote: false,
          method: null,
        },
        blockedInAnyCountry: {
          type: 'blockedInAnyCountry',
          field: 'blocked_in_any_country',
          options: [
            {
              type: true,
              name: this.$t('ui.filters.select.yes'),
            },
            {
              type: false,
              name: this.$t('ui.filters.select.no'),
            },
          ],
          labelProp: 'name',
          valueProp: 'type',
          multiple: false,
          filterable: false,
          remote: false,
          method: null,
        },
        brand: {
          type: 'brand',
          field: 'site_id',
          options: [],
          labelProp: 'name',
          valueProp: 'id',
          multiple: true,
          filterable: true,
          remote: true,
          method: async (q) => {
            const query = {
              search: q,
            };
            const { data: { payload } } = await this.$api.getSiteSettings(query);
            return payload;
          },
        },
        brandLive: {
          type: 'brandLive',
          field: 'live_site_id',
          options: [],
          labelProp: 'name',
          valueProp: 'id',
          multiple: true,
          filterable: true,
          remote: true,
          method: async () => {
            const { data: { payload } } = await this.$api.getSiteSettings();
            return payload;
          },
        },
      },
      currentFilters: [],
    };
  },
  computed: {
    availableFiltersLength() {
      return this.useFilters.includes('availabilityMaterials') ? this.availableFilters.length - 1 : this.availableFilters.length;
    },
    getCurrentFilterAvailabilityMaterials() {
      return this.currentFilters.find(item => item.type === 'availabilityMaterials') || {};
    },
    availabilityMaterialsValues() {
      return this.currentFilters.find(item => item.type === 'availabilityAffiliateId') || {};
    },
    getFilterAvailabilityAffiliateId() {
      return this.getFilter('availabilityAffiliateId');
    },

    isOutFilters() {
      return this.availableFilters.filter(item => item.type !== 'availabilityAffiliateId');
    },

    isAffiliatesFilter() {
      if (!this.$store.getters['auth/adminAcl'].is_superuser && this.$store.getters['auth/currentAcl']) {
        return this.$store.getters['auth/currentAcl'].affiliates_view === 'allow';
      } if (this.$store.getters['auth/adminAcl'].is_superuser) {
        return true;
      }
      return false;
    },
    isAffiliatesLiveFilter() {
      if (!this.$store.getters['auth/adminAcl'].is_superuser && this.$store.getters['auth/currentAcl']) {
        return this.$store.getters['auth/currentAcl'].reports_group_by_affiliates === 'allow';
      } if (this.$store.getters['auth/adminAcl'].is_superuser) {
        return true;
      }
      return false;
    },

    isPlayerCountryFilter() {
      if (!this.$store.getters['auth/adminAcl'].is_superuser && this.$store.getters['auth/currentAcl']) {
        return this.$store.getters['auth/currentAcl'].reports_group_by_countries === 'allow';
      } if (this.$store.getters['auth/adminAcl'].is_superuser) {
        return true;
      }
      return false;
    },
    availableFilters() {
      return this.$_.filter(this.allFilters, filter => this.useFilters.indexOf(filter.type) !== -1);
    },
    excludeFilters() {
      return this.$_.map(this.currentFilters, filter => filter.type);
    },
    ...mapGetters('misc', ['countries', 'domainRegistrarProviders', 'dnsHostingProviders']),
  },
  watch: {

    currentFilters: {
      deep: true,
      handler(v) {
        this.handleDisabled(v);
      },
    },
    countries() {
      this.initCountries();
    },

    domainRegistrarProviders() {
      this.allFilters.domainRegistrarId.options = this.domainRegistrarProviders;
    },

    dnsHostingProviders() {
      this.allFilters.dnsHostingId.options = this.dnsHostingProviders;
    },
  },
  destroyed() {
    document.removeEventListener('click', this.clickOutside, true);
  },
  created() {
    if (this.value.hasOwnProperty('max_profit_category')) {
      this.allFilters.profitCategory.field = 'max_profit_category';
    }

    this.allFilters.domainRegistrarId.options = this.domainRegistrarProviders;
    this.allFilters.dnsHostingId.options = this.dnsHostingProviders;

    this.initCountries();
    let affiliateType = '';
    let playerCountryType = '';

    if (this.value.hasOwnProperty('affiliate_id')) {
      affiliateType = 'affiliate';
    }
    if (this.value.hasOwnProperty('affiliate_last_bets_id')) {
      affiliateType = 'affiliateLastBets';
    }
    if (this.value.hasOwnProperty('affiliate_last_deposits_id')) {
      affiliateType = 'affiliateLastDeposits';
    }
    if (this.value.hasOwnProperty('affiliate_payments_report_id')) {
      affiliateType = 'affiliatePaymentsReport';
    }

    if ((this.value.hasOwnProperty('affiliate_id') && this.isAffiliatesFilter) || (this.value.hasOwnProperty('affiliate_last_bets_id')
        && this.isAffiliatesLiveFilter) || (this.value.hasOwnProperty('affiliate_last_deposits_id') && this.isAffiliatesLiveFilter)
        || (this.value.hasOwnProperty('affiliate_payments_report_id') && this.isAffiliatesLiveFilter)) {
      this.allFilters[affiliateType].method('')
        .then((response) => {
          this.allFilters[affiliateType].options = response;
        });
    }

    if (this.value.hasOwnProperty('player_country_code_last_bets')) {
      playerCountryType = 'playerCountryLastBets';
    }
    if (this.value.hasOwnProperty('player_country_code_last_deposits')) {
      playerCountryType = 'playerCountryLastDeposits';
    }
    if (this.value.hasOwnProperty('player_country_code_payments_report')) {
      playerCountryType = 'playerCountryPaymentsReport';
    }
    if (this.value.hasOwnProperty('country_code_payments_report')) {
      playerCountryType = 'countryPaymentsReport';
    }

    if ((this.value.hasOwnProperty('country_code_payments_report') && this.isPlayerCountryFilter) || (this.value.hasOwnProperty('player_country_code_last_bets') && this.isPlayerCountryFilter)
        || (this.value.hasOwnProperty('player_country_code_last_deposits') && this.isPlayerCountryFilter)) {
      this.allFilters[playerCountryType].method('')
        .then((response) => {
          this.allFilters[playerCountryType].options = response;
        });
    }
  },
  mounted() {
    document.addEventListener('click', this.clickOutside, true);
    if (!this.$_.isEmpty(this.value)) {
      this.transformValue();
    }
  },
  methods: {
    changeVisibility(type) {
      if (type !== 'public') {
        this.currentFilters.push({
          type: 'availabilityAffiliateId',
          values: [],
        });
      } else {
        const element = this.$_.findIndex(this.currentFilters, ['type', 'availabilityAffiliateId']);
        if (element !== -1) {
          this.currentFilters.splice(element, 1);
        }
      }
    },

    handleDisabled(v) {
      this.isDisabled = false;
      if (v.length === 0) {
        this.isDisabled = true;
        return;
      }
      this.isDisabled = v.some(e => e.type === '' || e.values.length === 0);
    },
    clickOutside(e) {
      if (this.$refs.mainBtn.$el.contains(e.target)) return;
      if (!this.$refs.pop.contains(e.target) && this.filtersOpen === true) {
        this.toggleFilters();
      }
    },
    inputSelect(v) {
      if (v === 'lastActivity') {
        this.currentFilters.forEach((e, i) => {
          if (e.type === 'lastActivity') {
            this.currentFilters[i].values = [30, this.tab];
          }
        });
      }
      if (v === 'availabilityMaterials') {
        this.currentFilters.forEach((e, i) => {
          if (e.type === 'availabilityMaterials') {
            this.currentFilters[i].values = this.visibleTab;
          }
        });
      }
      if (!this.currentFilters.includes('availabilityMaterials')) {
        const element = this.$_.findIndex(this.currentFilters, ['type', 'availabilityAffiliateId']);
        if (element !== -1) {
          this.currentFilters.splice(element, 1);
        }
      }
    },
    clone() {
      this.currentFilters = this.$_.clone(this.currentFilters);
    },
    getFilter(type) {
      const filter = this.allFilters[type];
      if (filter) {
        if (typeof filter.options === 'function') {
          filter.options = filter.options();
        }
        return filter;
      }
      return { multiple: true };
    },
    initCountries() {
      this.allFilters.playerCountry.options = this.countries;
      this.allFilters.country.options = this.countries;
    },
    toggleFilters() {
      this.filtersOpen = !this.filtersOpen;
      if (this.filtersOpen === false) {
        setTimeout(() => {
          this.transformValue();
        }, 300);
        /* при открытии добавляем в фильтр дефортное поле пустых инпутов */
      } else if (this.filtersOpen && this.currentFilters.length === 0) {
        this.addFilter();
      }
      this.$emit('toggle', this.filtersOpen);
    },
    addFilter() {
      this.currentFilters.push({
        type: '',
        values: [],
      });
    },
    removeFilter(i) {
      if (this.currentFilters[i].type === 'availabilityMaterials') {
        const element = this.$_.findIndex(this.currentFilters, ['type', 'availabilityAffiliateId']);
        if (element !== -1) {
          this.currentFilters.splice(element, 1);
        }
      }
      this.currentFilters.splice(i, 1);
    },
    applyFilters() {
      const val = {};
      this.$_.forEach(this.currentFilters, (filter) => {
        this.$_.assign(val, { [this.getFilter(filter.type).field]: filter.values });
      });
      this.$emit('input', val);
      this.toggleFilters();
    },
    resetFilters() {
      this.currentFilters = [];
      this.$emit('input', {});
      this.toggleFilters();
    },
    reset() {
      this.currentFilters = [];
      this.$emit('input', {});
    },
    transformValue() {
      let fs = [];
      fs = this.$_.map(Object.keys(this.value), key => ({
        type: this.$_.find(this.availableFilters, f => f.field === key).type,
        values: this.value[key],
      }));
      if (!this.$_.isEqual(fs, this.currentFilters)) {
        this.currentFilters = fs;
      }
    },
  },
};
</script>

<style lang="scss">
.popover-filter{
  .pop{

    .ui-select .control-wrapper .tags .value {
      max-width: 188px;
      .label {
        max-width: 155px;
        line-height: 16px;
      }
    }
    .f-day{
      .ui-select {
        .pop{
          width: 108% !important;
        }
      }
      .del{
        display: none !important;
      }
      .tags{
        .value{
          justify-content: flex-start !important;
        }
        .sufix{
          span{
            width: 78px;
            height: 14.5px;
            word-break: break-all;
            overflow: hidden;
            display: block;
          }
        }
      }
    }

  }

}
</style>


<style lang="scss" scoped>
.ui-filter{
  position: relative;
  line-height: 0;
  .f-day{
    width: 230px;
    height: 34px;
    display: flex;
    align-items: center;
    padding-left: 8px;
    .sufix{
      color: #334444;
    }
    &__tabs{
      width: 70px;
      min-width: 70px;
      height: 34px;
      border-radius: 4px;
      display: flex;
      margin-right: 8px;
      .select-class{
        margin-left: 0 !important;
        margin-right: 0 !important;
      }
    }
    &__tab{
      height: 32px;
      width: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      background-color: #fff;
      cursor: pointer;
      &:first-child{
        border: solid 1px #d3d3d3;
        border-right-color: transparent;
        border-radius: 5px 0 0 5px;
        // background-image: linear-gradient(white, rgba(211, 211, 211, 0.4));
        // &.disabled{
        //   pointer-events: none;
        //   background-color: #bfbfbf;
        // }
      }
      &:last-child{
        border: solid 1px #d3d3d3;
        border-left-color: transparent;
        border-radius: 0 5px 5px 0;
      }
      &--active{
        cursor: pointer;
        color: #fff;
        // background-color: #20815e;
        &:first-child{
          // border: solid 1px #20815e;
          border-right-color: transparent;
        }
        &:last-child{
          // border: solid 1px #20815e;
          border-left-color: transparent;
        }
      }
    }
    &__input{
      // pointer-events: none;
      width: 100% !important;
      max-width: 62px !important;
    }
    &__text{
      padding-left: 8px;
      font-size: 12px;
      color: #000000;
    }
  }
  .pop{
    position: absolute;
    z-index: 9999;
    top: 44px;
    left: -20px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 16px;
    background-color: #fff;
    box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    user-select: none;
    .ui-select.filter_type.medium{
      width: 200px !important;
      .tag .value {
        max-width: 181px !important;
      }
    }
    &.fade-enter-active, &.fade-leave-active{
      transition: all .4s;
    }
    &.fade-enter, &.fade-leave-to{
      opacity: 0;
      transform: translateY(8px);
    }
    .arrow{
      position: absolute;
      top: -5px;
      left: 56px;
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-bottom: 5px solid #fff;
    }
    .filter-row{
      display: flex;
      align-items: center;
      margin-bottom: 8px;
      .filter_type{
        width: 160px;
        margin-left: 8px !important;
      }
      .filter_value{
        width: 280px;
      }
      .btn{
        margin-left: 0 !important;
      }
    }
    .controls{
      display: inline-flex;
      justify-content: flex-start;
      flex-wrap: nowrap;
      width: auto;
      .btn{
        margin-left: 0 !important;
      }
      .btn + .btn {
        margin-left: 8px !important;
      }
    }
  }
}

</style>

<style lang="scss">

.ui-filter-new{
  .pop .ui-select .control-wrapper .tags .value:first-child{
    width: 100% !important;
  }
  .pop .ui-select .control-wrapper .tags .value.with-count{
    max-width: 154px !important;
  }
  .ui-icon.btn-icon.tick-icon{
    font-size: 10px !important;
  }
  .control-wrapper{

    flex-wrap: initial !important;
    .value{
      .label{
        margin-bottom: 1px;
      }
      .sufix {
        margin-bottom: 0px !important;
      }
      .ui-icon.del.fa-times{
        display: none;
      }
    }
  }
  .ui-select.filterable.multiple{
    .ui-icon.del.fa-times{
      display: flex;
    }
  }
}
</style>

export default {
  teams: {
    name: 'Teams',
    teams_fields: {
      title: 'Teams',
      default_team: 'All Users',
      team_id: {
        title: 'ID',
      },
    },
    teams_buttons: {
      add_team: 'Add Team',
      add_users: 'Add Users',
      add_user: 'Add User',
      create_user: 'Create User',
    },
    sub_nav: {
      users: 'Users',
      permissions: 'Permissions',
    },
  },
  team_users: {
    title: '0 Users | 1 User | {count} Users | {count} Users',
    list: {
      fields: {
        name: {
          title: 'Name',
        },
        first_name: {
          title: 'Name',
        },
        full_name: {
          title: 'Name',
        },
        email: {
          title: 'E-Mail',
        },
        login: {
          title: 'Login',
        },
        last_active: {
          title: 'Last Login',
        },
        teams: {
          title: 'Teams',
        },
        team: {
          title: 'Team',
        },
        members_count: {
          title: 'Members',
        },
        messenger: {
          title: 'Messenger',
        },
      },
      actions: {
        name: 'Actions',
        view_profile: 'View Profile',
        edit_profile: 'Edit Profile',
        permissions: 'Permissions',
        reset_password: 'Reset Password',
        reset_2fa: 'Reset 2FA',
        unblock: 'Unblock',
        block: 'Block',
        delete_from_team: 'Delete From Team',
        delete_from_system: 'Delete From System',
        login: 'Log In',
      },
      alert: {
        last_seen: 'Last Seen ',
        days_ago: '{days} Days Ago',
      },
    },
  },
  user_info: {
    sub_nav: {
      info: 'Information',
    },
    personal_information: 'Personal Information',
    edit: 'Edit',
    not_specified: 'Not Specified',
    login_reg: 'Login & Registration',
    last_login: 'Last Login',
    reg_date: 'Registration Date',
    teams: 'Teams',
    add: 'Add',
    add_team: 'Add Team',
    delete: 'Delete',
    affiliates: 'Affiliates',
    pin: 'Pin',
    unpin: 'Unpin',
    no_pinned_affiliates: 'No pinned affiliates',
    no_teams: 'No Teams',
    note: 'Note',
    see_stats: 'See Stats',
    see_stats_from: 'From',
    see_stats_to: 'Until',
    see_stats_overall: 'Overall',
  },
  permissions: {
    teams_select: 'Teams',
    save_changes: 'Save Changes for {teamName}?',
    depends: 'Depends on: ',
    geo: {
      title: 'Geo Restrictions',
      geo_card_title: 'Geo Restrictions',
      geo_statistics: 'Statistics by Country',

    },
    others: {
      title: 'Others',
    },
    live: {
      title: 'Live',
      live_card_title: 'Live',
    },
    reports: {
      title: 'Reports',
      reports_card_title: 'Reports',
      reports_affiliates_types: 'Report Affiliates Types',
      reports_see_stats_after_cpa_conversion: 'View Stats After CPA Conversion ',
      reports_group_by: 'Group by',
      reports_view_columns: 'Columns View',
    },
    affiliates: {
      title: 'Affiliates',
      affiliates_card_title: 'Affiliates',
      affiliates_actions_title: 'Actions',
      affiliates_security_title: 'Security',
      affiliates_status_change_title: 'Status Change',
      affiliates_types_view: 'View Affiliates Types',
      affiliates_types_edit: 'Edit Affiliates Types',
      affiliates_sub_affiliates: 'Sub-Affiliates Program',
      affiliates_sub_affiliates_edit_settings: 'Enable Sub-Affiliates Program',
      affiliates_sub_affiliates_max_percent: 'Max Sub-Affiliates Percent',
      affiliates_activity_log: 'Activity Log',
      affiliates_notes: 'Notes',
    },
    offers: {
      title: 'Offers',
      offers_edit: 'Edit Offers',
      offers_card_title: 'Offers',
      offers_revshare: 'RevShare',
      offers_revshare_max_percent_sport: 'Max RS Sport',
      offers_revshare_max_percent_casino: 'Max RS Casino',
      offers_cpa: 'CPA',
      offers_cpa_max_payout: 'Max CPA Payout',
      offers_cpa_max_payout_currency: 'Max CPA Payout Currency',
      offers_cpa_min_payout_deposits_sum_ratio: 'Minimum Deposit to Payout Ratio',
      not_selected: 'Not selected',
    },
    players: {
      title: 'Players',
      players_card_title: 'Players',
      players_card_other: 'Other Options',
      players_view_columns: 'View Players Columns',
      players_view_affiliate_id: 'Affiliate ID',
      players_view_affiliate_email: 'Affiliate Email',
      players_view_external_id: 'External ID',
      players_view_country_name: 'Country Name',
      players_view_country_code: 'Country code',
      players_view_phone: 'Phone Number',
      players_view_email: 'Email',
      players_view_name_first: 'First Name',
      players_view_name_last: 'Last Name',
      players_view_virtual_acc: 'Virtual Account',
      players_view_created_at: 'Created At',
      players_view_updated_at: 'Updated At',
      players_view_last_active_at: 'Last Active At',
      players_view_subid1: 'SubID 1',
      players_view_subid2: 'SubID 2',
      players_view_subid3: 'SubID 3',
      players_view_subid4: 'SubID 4',
      players_view_subid5: 'SubID 5',
      players_view_profit_category: 'Profit Category',
    },
    payments: {
      title: 'Payments',
      payments_card_title: 'Payments',
    },
    tools: {
      title: 'Tools',
      tools_promo_codes_checker: 'Promo Codes Checker',
      tools_promo_landings: 'Landing Leagues',

    },
    crm: {
      title: 'CRM',
      segments: 'Segments',
      activities: 'Activities',

      segments_view: 'View Segments',
      segments_review: 'Review Segments',
      segments_edit: 'Edit Segments',

      activities_view: 'View Activities',
      activities_review: 'Review Activities',
      activities_edit: 'Edit Activities',
      activities_run: 'Run Activities',
    },
    teams: {
      title: 'Teams',
      teams_card_title: 'Teams',
      teams_view: 'View Teams',
      teams_edit: 'Edit Teams',
      teams_assign_admins: 'Assign Admins to Teams',
      teams_change_permissions: 'Change Permissions',

    },
    independent: 'Independent Affiliates',
    own_affiliates: 'Own Affiliates',
    teammates_affiliates: 'Teammates Affiliates',
    other_teams_affiliates: 'Other Teams Affiliates',
    allow_all: 'All',
    deny_all: 'Nothing Allowed',
    allow_selected: 'Only Selected',
    deny_selected: 'All Except',
    add_country: 'Select Countries',
    add_teammates: 'Select teammates',
    on_review: 'On Review',
    for_rework: 'For Rework',
    approved: 'Approved',
    declined: 'Declined',
    not_paid: 'Not Paid',
    processing: 'Processing',
    paid: 'Paid Out',
    failed: 'Failed',
    status: 'Status',
    allow: 'Allow',
    deny: 'Deny',
    all: 'All',
    own: 'Own',
    teammates: 'Teammates',
    deny_in: '<span>Deny</span> in {city}',
    inherit: 'Inherit',
    media_buying: {
      title: 'Media Buying',
      payments: 'Payments Requests',
      payments_moderation: 'Payments Moderation',
      payments_payout: 'Payments Payout',
      posts: 'Posts',
      reports: 'Reports',
      statuses: 'Visible Statuses',
      media_buying_payments_affiliates_types_view: 'View Payments Types',
      media_buying_payments_affiliates_types_review: 'Review Payments Types',
      media_buying_reports_affiliates_types_view: 'Report Affiliates Types',
    },
    dms: {
      title: 'Domain Management System',
      dns_hosting_accounts: 'DNS Hosting Accounts',
      domain_registrar_accounts: 'Domain Registrar Accounts',
      dedicated_site_domains: 'Dedicated Site Domains',
      platform_servers: 'Platform Servers',
      platform_service_domains: 'Platform Service Domains',
      service_rotation_groups: 'Service Rotation Groups',
    },

    list: {
      last_bets_and_deposits_view: 'View Last Bets & Deposits',
      top_events_view: 'View Top Events',

      reports_view: 'View Report',
      reports_group_by_affiliates: 'Affiliates',
      reports_group_by_countries: 'Countries',
      reports_group_by_sub_affiliates: 'Sub-Affiliates',
      reports_group_by_payments: 'Payment Methods',
      reports_view_revenue_columns: 'Revenue',
      reports_view_profit_columns: 'Profit',
      reports_view_deposits_sum_columns: 'Deposits Sum',
      reports_view_deposits_count_columns: 'Deposits Count',
      reports_view_withdrawals_sum_columns: 'Withdrawals Sum',
      reports_view_withdrawals_count_columns: 'Withdrawals Count',
      reports_view_sport_bets_sum_columns: 'Sport Bets Sum',
      reports_view_sport_bets_count_columns: 'Sport Bets Count',
      reports_view_casino_bets_sum_columns: 'Casino Bets Sum',
      reports_view_casino_bets_count_columns: 'Casino Bets Count',

      offers_edit: 'Edit Offers',
      offers_revshare_edit_settings: 'Set RevShare',
      offers_cpa_edit_settings: 'Set CPA',

      affiliates_sub_affiliates_edit_settings: 'Enable Sub-Affiliates Program',
      affiliates_rotation_groups_edit: 'Edit Affiliates Rotation Groups',
      affiliates_edit: 'Edit Affiliates',
      affiliates_view: 'View Affiliates',

      players_view: 'View Players',
      players_virtual_edit_settings: 'Edit Virtual Accounts',
      players_assign_affiliate: 'Edit Linking to Affiliates',

      payments_view: 'View Payments',
      payments_review_actions: 'Review Payments',
      payments_chargebacks_actions: 'Make Chargebacks',

      media_buying_posts_affiliates_types_view: 'View Posts Types',
      media_buying_posts_affiliates_types_review: 'Review Posts Types',
      media_buying_posts_view: 'View Posts',
      media_buying_posts_review: 'Review Posts',
      media_buying_payments_view: 'View Payments',
      media_buying_payments_review: 'Review Payments',
      media_buying_payments_affiliates_types_view: 'View Payments Types',
      media_buying_reports_view: 'View Report',
      media_buying_reports_affiliates_types_view: 'Report Affiliates Types',

      tools_promo_codes_checker_view: 'View Promo Codes Checker',
      tools_promo_landings_view: 'View Landing Leagues',
      tools_promo_landings_edit: 'Edit Landing Leagues',
      marketing_materials_view: 'View Promo Materials',
      marketing_materials_edit: 'Edit Promo Materials',

      dns_hosting_accounts_view: 'View Hosting Accounts',
      dns_hosting_accounts_edit: 'Edit Hosting Accounts',
      domain_registrar_accounts_view: 'View Registrar Accounts',
      domain_registrar_accounts_edit: 'Edit Registrar Accounts',
      platform_servers_view: 'View Servers',
      platform_servers_edit: 'Edit Servers',
      platform_servers_can_run_jobs: 'Run Jobs',
      platform_service_domains_view: 'View Domains',
      platform_service_domains_edit: 'Edit Domains',
      platform_service_domains_can_run_jobs: 'Run Jobs',
      platform_betting_domains_view: 'View Dedicated Domains',
      platform_betting_domains_edit: 'Edit Dedicated Domains',
      service_rotation_groups_view: 'View Rotation Groups',
      service_rotation_groups_edit: 'Edit Rotation Groups',
      service_rotation_groups_domains_view: 'View Domains',
      service_rotation_groups_domains_edit: 'Edit Domains',

      affiliates_activity_log_view: 'View Activity Log',
      affiliates_notes_view: 'View Notes',
      affiliates_notes_edit: 'Edit Notes',

      affiliates_can_login: 'Login as Affiliate',
      affiliates_password_edit: 'Edit Password',
      affiliates_payment_info_edit: 'Edit Payment Info',
      affiliates_domain_change_notifications_edit: 'Edit Domain Change Notifications',
      affiliates_can_approve: 'Approve',
      affiliates_can_decline: 'Decline',
      affiliates_can_block: 'Block',
      affiliates_can_unblock: 'Unblock',
      affiliates_can_revoke: 'To Review',
      affiliates_reporting_settings_rs_profit_visibility_edit: 'Edit RS Statistics Visibility',
      affiliates_reporting_settings_cpa_profit_visibility_edit: 'Edit CPA Statistics Visibility',
      affiliates_email_edit: 'Edit Email',
      affiliates_payments_can_create: 'Create Payments',
      affiliates_2fa_can_reset: 'Reset 2FA',
      affiliates_media_buying_edit: 'Edit Media Buying Settings',
      affiliates_promo_codes_edit: 'Edit Promo Codes',
      affiliates_balance_edit: 'Edit Balance',
      affiliates_limits_edit: 'Edit Limits',
      affiliates_commissions_edit: 'Edit Commissions',

      crm_segments_view: 'View Segments',
      crm_segments_review: 'Review Segments',
      crm_segments_edit: 'Edit Segments',
      crm_activities_view: 'View Activities',
      crm_activities_review: 'Review Activities',
      crm_activities_edit: 'Edit Activities',
      crm_activities_run: 'Run Activities',
    },
  },
  team_popup: {
    delete_title_system: 'Delete {name}?',
    delete_msg_system: 'Are you sure you want to delete <span>{name}</span> from the system?',
    delete_action_system: 'Delete',

    delete_title_team: 'Delete {name} From {teamName}?',
    delete_msg_team: 'Delete <span>{name}</span> from <span>{teamName}</span> and unpin all affiliates?',
    delete_action_team: 'Delete',

    block_title: 'Block {name}',
    block_msg: 'Are you sure you want to block <span>{name}</span>?',
    block_action: 'Block',

    unblock_title: 'Unblock {name}',
    unblock_msg: 'Are you sure you want to unblock <span>{name}</span>?',
    unblock_action: 'Unblock',

    reset_2fa_title: 'Reset 2FA For {name}?',
    reset_2fa_msg: 'Are you sure you want to reset 2FA for <span>{name}</span>?',
    reset_2fa_action: 'Reset 2FA',

    unpin_title: 'Unpin {affiliateName} from {name}?',
    unpin_msg: 'Are you sure you want to unpin <span>{affiliateName}</span> from <span>{name}</span>?',
    unpin_action: 'Unpin',

    reset_pass_title: 'Reset Password For {name}?',
    reset_pass_msg: 'Are you sure you want to reset password for <span>{name}</span>?',
    reset_pass_action: 'Reset Password',

    delete_team_title: 'Delete {name}?',
    delete_team_msg: 'Are you sure you want to delete <span>{name}</span>?',
    delete_team_action: 'Delete',

    edit_user_title: 'Edit User {name}',

    name_label: 'Name',
    name_placeholder: 'Name',

    email_label: 'E-Mail',
    email_placeholder: 'E-Mail',

    login_label: 'Login',
    login_placeholder: 'Login',

    timezone_label: 'Timezone',
    timezone_placeholder: 'Timezone',

    see_stats_label: 'See Stats',
    see_stats_placeholder: 'Select Date',
    see_stats_overall: 'Overall',

    messenger_label: 'Messenger',
    messenger_placeholder: 'Messenger',

    messenger_id_label: 'Messenger ID',
    messenger_id_placeholder: 'Messenger ID',

    note_label: 'Note',
    note_placeholder: 'Notes About User',

    new_pass_title: 'New Password',
    create_user_title: 'Create User',

    login_input: 'Login',
    pass_input: 'Password',

    team_name_label: 'Team Name',
    team_name_placeholder: 'Team Name',

    copy_button: 'Copy',
    close_button: 'Close',
    cancel_button: 'Cancel',
    save_button: 'Save',
    create_button: 'Create',
    add_teams_button: 'Add Teams',
    add_users_button: 'Add Users',
    add_user_button: 'Add User',
    add_team_button: 'Add Team',
    edit_team_button: 'Edit Team',
    add_affiliates_button: 'Add Affiliates',
    add_affiliate_button: 'Add Affiliate',

    add_user_in_teams_title: 'Add {name} to Following Teams',
    add_users_in_team_title: 'Add Users In {teamName}',
    add_user_in_team_title: 'Add User In {teamName}',
    add_affiliates_to_user_title: 'Pin Affiliates to {name}',
    user_permissions_title: '{name} Permissions',
  },
};

<template>
  <div class="promo-checker">
    <ui-confirm
      ref="uploadPromoCodes"
      :action="uploadPromoCodes.action"
      :action-fa-icon="actionFaIcon"
      :action-icon="actionIcon"
      :action-name="uploadPromoCodes.actionName"
      :width="480"
      class="upload-promo-codes"
      type="success"
      @close="cancelCodes"
      @save="handleCodes"
    >
      <div
        slot="title"
        v-html="uploadPromoCodes.title"
      />
      <div class="body__wrap">
        <div
          class="body__msg"
          v-html="uploadPromoCodes.msg"
        />
        <el-input
          v-model="codes"
          :placeholder="$t(`tools.promoChecker.input_promocode`)"
          :rows="6"
          resize="none"
          type="textarea"
        />
      </div>
    </ui-confirm>
    <!-- $t(`tools.promoChecker.players.table.${item.name}`) -->

    <div
      v-loading="isDataLoading.overall"
      class="toolbar"
    >
      <div class="wrapper">
        <div class="section">
          <div class="field">
            <label class="label">{{ $t(`tools.promoChecker.form_promocode`) }}:</label>
            <el-radio-group v-model="type">
              <el-radio-button label="period">
                {{ $t(`tools.promoChecker.period`) }}
              </el-radio-button>
              <el-radio-button label="list">
                {{ $t(`tools.promoChecker.list`) }}
              </el-radio-button>
            </el-radio-group>
          </div>
          <div
            v-show="type === 'period'"
            class="field"
          >
            <el-date-picker
              v-model="dateRange"
              :end-placeholder="$t('reports.other.end_date')"
              :picker-options="{
                firstDayOfWeek: 1,
                shortcuts: shortcutsDates,
              }"
              :start-placeholder="$t('reports.other.start_date')"
              class="date-picker"
              clear-icon="fas fa-times-circle"
              format="dd MMM yyyy"
              prefix-icon="far fa-calendar-alt"
              range-separator="-"
              type="daterange"
            />
          </div>
          <div
            v-show="type === 'list'"
            class="field"
          >
            <ui-button
              class="btn"
              icon="plus"
              lib="fa"
              substyle="fas"
              @click="handleUploadCodes"
            >
              {{ $t(`tools.promoChecker.unload_promo`) }}
            </ui-button>
          </div>
          <div
            v-show="type === 'list' && !$_.isEmpty(overallData) && overallData.promo_codes_unused_count > 0"
            class="field"
          >
            <ui-button
              class="btn"
              icon="long-arrow-down"
              lib="fa"
              substyle="fas"
              @click="downloadReport('unused_promo')"
            >
              {{ $t(`tools.promoChecker.download`) }}
            </ui-button>
          </div>
          <div
            v-show="type === 'list'"
            class="info"
          >
            <ui-icon
              color="#a6b2c4"
              lib="fa"
              name="info-circle"
              substyle="fas"
            />
            <span>{{ $t(`tools.promoChecker.last_180`) }}</span>
          </div>
        </div>
        <div class="section">
          <div class="metric">
            <span class="label">{{ $t(`tools.promoChecker.promo_codes`) }}</span>
            <span class="value">{{ $_.isEmpty(overallData) ? 0 : overallData.promo_codes_total_count }}</span>
          </div>
          <div class="metric">
            <span class="label">{{ $t(`tools.promoChecker.used`) }}</span>
            <span class="value">{{ $_.isEmpty(overallData) ? 0 : overallData.promo_codes_used_count }}</span>
          </div>
          <div
            v-show="type === 'list'"
            class="metric"
          >
            <span class="label">{{ $t(`tools.promoChecker.not_used`) }}</span>
            <span class="value">{{ $_.isEmpty(overallData) ? 0 : overallData.promo_codes_unused_count }}</span>
          </div>
          <div class="metric">
            <span class="label">{{ $t(`tools.promoChecker.players1`) }}</span>
            <span class="value">{{ $_.isEmpty(overallData) ? 0 : overallData.players_count }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="info">
      <div class="wrapper">
        <div
          v-loading="isDataLoading.overall"
          class="card winners"
        >
          <div class="head">
            <span class="title-promo">{{ $t(`tools.promoChecker.win`) }}{{ $_.isEmpty(overallData) ? '' :
              ` - ${$_.round(overallData.win_percent)}% / ${overallData.win_count}` }}</span>
            <div class="tools">
              <ui-button
                class="btn"
                icon="long-arrow-down"
                lib="fa"
                substyle="fas"
                @click="downloadReport('results_win')"
              />
            </div>
          </div>
          <div class="report">
            <div class="metric">
              <span class="label">{{ $t(`tools.promoChecker.players1`) }}</span>
              <span class="value">{{ $_.isEmpty(overallData) ? 0 :
                `${overallData.old_players_win_count} / ${overallData.new_players_win_count} / ${overallData.players_win_count}` }}</span>
              <span class="tip">{{ $t(`tools.promoChecker.player_sub`) }}</span>
            </div>
            <div class="metric">
              <span class="label">{{ $t(`tools.promoChecker.depos`) }}</span>
              <span class="value">{{ $_.isEmpty(overallData) ? 0 :
                `${overallData.win_first_deposits_count} / ${formatCurrency(overallData.win_first_deposits_sum)}` }}</span>
              <span class="tip">{{ $t(`tools.promoChecker.depos_sub`) }}</span>
            </div>
            <div class="metric">
              <span class="label">{{ $t(`tools.promoChecker.avg_depos`) }}</span>
              <span class="value">{{ $_.isEmpty(overallData) ? 0 : `${formatCurrency(overallData.win_avg_deposit)}` }}</span>
            </div>
          </div>
          <div class="report">
            <div class="metric">
              <span class="label">{{ $t(`tools.promoChecker.caff`) }}</span>
              <span class="value">{{ $_.isEmpty(overallData) ? 0 :
                `${overallData.avg_win_coefficient} / ${overallData.max_win_coefficient}` }}</span>
              <span class="tip">{{ $t(`tools.promoChecker.avg_max`) }}</span>
            </div>
            <div class="metric">
              <span class="label">{{ $t(`tools.promoChecker.event`) }}</span>
              <span class="value">{{ $_.isEmpty(overallData) ? 0 :
                `${overallData.avg_win_events_count} / ${overallData.max_win_events_count}` }}</span>
              <span class="tip">{{ $t(`tools.promoChecker.avg_max`) }}</span>
            </div>
            <div class="metric">
              <span class="label">{{ $t(`tools.promoChecker.win_sum`) }}</span>
              <span class="value">{{ $_.isEmpty(overallData) ? 0 :
                `${formatCurrency(overallData.avg_win_sum)} / ${formatCurrency(overallData.max_win_sum)}` }}</span>
              <span class="tip">{{ $t(`tools.promoChecker.avg_max`) }}</span>
            </div>
            <div class="metric">
              <span class="label">{{ $t(`tools.promoChecker.total`) }}</span>
              <span class="value total">{{ $_.isEmpty(overallData) ? 0 : `${formatCurrency(overallData.win_sum)}` }}</span>
            </div>
          </div>
        </div>
        <div
          v-loading="isDataLoading.overall"
          class="card loosers"
        >
          <div class="head">
            <span class="title-promo">{{ $t(`tools.promoChecker.looser`) }}{{ $_.isEmpty(overallData) ? '' :
              ` - ${$_.round(overallData.lost_percent)}% / ${overallData.lost_count}` }}</span>
            <div class="tools">
              <ui-button
                class="btn"
                icon="long-arrow-down"
                lib="fa"
                substyle="fas"
                @click="downloadReport('results_lost')"
              />
            </div>
          </div>
          <div class="report">
            <div class="metric">
              <span class="label">{{ $t(`tools.promoChecker.players1`) }}</span>
              <span class="value">{{ $_.isEmpty(overallData) ? 0 :
                `${overallData.old_players_lost_count} / ${overallData.new_players_lost_count} / ${overallData.players_lost_count}` }}</span>
              <span class="tip">{{ $t(`tools.promoChecker.player_sub`) }}</span>
            </div>
            <div class="metric">
              <span class="label">{{ $t(`tools.promoChecker.depos`) }}</span>
              <span class="value">{{ $_.isEmpty(overallData) ? 0 :
                `${overallData.lost_first_deposits_count} / ${formatCurrency(overallData.lost_first_deposits_sum)}` }}</span>
              <span class="tip">{{ $t(`tools.promoChecker.depos_sub`) }}</span>
            </div>
            <div class="metric">
              <span class="label">{{ $t(`tools.promoChecker.avg_depos`) }}</span>
              <span class="value">{{ $_.isEmpty(overallData) ? 0 : `${formatCurrency(overallData.lost_avg_deposit)}` }}</span>
            </div>
          </div>
          <div class="report">
            <div class="metric">
              <span class="label">{{ $t(`tools.promoChecker.caff`) }}</span>
              <span class="value">{{ $_.isEmpty(overallData) ? 0 :
                `${overallData.avg_lost_coefficient} / ${overallData.max_lost_coefficient}` }}</span>
              <span class="tip">{{ $t(`tools.promoChecker.avg_max`) }}</span>
            </div>
            <div class="metric">
              <span class="label">{{ $t(`tools.promoChecker.event`) }}</span>
              <span class="value">{{ $_.isEmpty(overallData) ? 0 :
                `${overallData.avg_lost_events_count} / ${overallData.max_lost_events_count}` }}</span>
              <span class="tip">{{ $t(`tools.promoChecker.avg_max`) }}</span>
            </div>
          </div>
        </div>
        <div class="card players">
          <div class="head">
            <span class="title-promo">{{ $t(`tools.promoChecker.players1`) }}</span>
            <div class="tools">
              <ui-pagination
                :count="count"
                :page="page"
                :page-size="limit"
                class="pagination"
                show-size-select
                @page-change="pageChange"
              />
            </div>
          </div>
          <div
            v-show="!lazyLoadingBlocked"
            ref="wrapper"
            v-loading="isDataLoading.players"
            class="table-wrapper"
          >
            <el-table
              ref="dataTable"
              :data="playersData"
              :height="tableHeight || 'auto'"
              :max-height="tableHeight || 'auto'"
              border
              @sort-change="handleSort"
            >
              <el-table-column
                v-for="item in fields"
                :key="item.name"
                :align="item.align || 'right'"
                :formatter="item.formatter"
                :header-align="item.headerAlign || 'right'"
                :label="`${$t(`tools.promoChecker.players.table.${item.name}`)}${item.headerCurrency ? item.headerCurrency() : ''}`"
                :min-width="item.minWidth"
                :prop="item.name"
                :resizable="true"
                :show-overflow-tooltip="true"
                :sortable="item.sortable || 'custom'"
                :width="item.width"
              >
                <template
                  slot="header"
                  slot-scope="scope"
                >
                  <div class="custom-header">
                    <div class="text">
                      <span class="title-promo">{{ scope.column.label }}</span>
                      <span
                        v-if="$te(`tools.promoChecker.players.table.${scope.column.property}.tip`)"
                        class="tip"
                      >
                        {{ $t(`tools.promoChecker.players.table.${scope.column.property}.tip`) }}
                      </span>
                      <el-tooltip
                        v-if="$te(`tools.promoChecker.players.table.${scope.column.property}.tooltip`)"
                        class="tooltip"
                        :content="$t(`tools.promoChecker.players.table.${scope.column.property}.tooltip`)"
                        placement="top"
                        effect="light"
                      >
                        <span class="tooltip-btn" />
                      </el-tooltip>
                    </div>
                    <span
                      class="filters-wrapper"
                      :class="{ 'open-filters': columnFilters[scope.column.property]
                        && (lastOpenFilter === scope.column.property
                          || (columnFilters[scope.column.property].value && columnFilters[scope.column.property].op))}"
                    >
                      <el-popover
                        v-if="filtrable(scope.column.property) && isFiltersInit"
                        class="col-filter"
                        trigger="manual"
                        :value="columnFilters[scope.column.property].visible"
                      >
                        <div class="col-filter-wrapper check-btn">
                          <el-checkbox-group
                            :value="columnFilters[scope.column.property].op"
                            @input="(event) => {
                              columnFilters[scope.column.property].op = event;
                              parseArr(event, scope.column.property);
                            }"
                          >
                            <el-checkbox label="<">&lt; </el-checkbox>
                            <el-checkbox label="=">=</el-checkbox>
                            <el-checkbox label=">">></el-checkbox>
                          </el-checkbox-group>
                          <ui-input
                            v-model="columnFilters[scope.column.property].value"
                            class="filter-input filter-input-num"
                            :class="{'filter-input__hide': customFilterColumn.indexOf(scope.column.property) >= 0}"
                          />
                          <div class="btns">
                            <ui-button
                              lib="fa"
                              substyle="fas"
                              icon="times"
                              color="red"
                              @click="filterBtnReset(scope.column.property)"
                            >
                              {{ $t('tools.promoChecker.reset') }}
                            </ui-button>
                            <ui-button
                              lib="fa"
                              substyle="fas"
                              icon="check"
                              :disabled="!(columnFilters[scope.column.property].value && columnFilters[scope.column.property].op.length)"
                              @click="filterBtnApply(scope.column.property)"
                            >
                              {{ $t('tools.promoChecker.apply') }}
                            </ui-button>
                          </div>
                        </div>
                        <div
                          slot="reference"
                          class="filter-opener"
                          :class="{'open': lastOpenFilter === scope.column.property
                            || (columnFilters[scope.column.property].value && columnFilters[scope.column.property].op)}"
                          @click="(event) => {
                            event.stopPropagation();
                            const set = !columnFilters[scope.column.property].visible;
                            columnFilters[scope.column.property].visible = set;
                            if (set) {
                              if (lastOpenFilter) {
                                columnFilters[lastOpenFilter].visible = false;
                              }
                              lastOpenFilter = scope.column.property;
                            } else {
                              lastOpenFilter = '';
                            }
                          }"
                        >
                          <ui-icon
                            lib="fa"
                            substyle="fas"
                            name="filter"
                            :size="8"
                          />
                        </div>
                      </el-popover>
                    </span>
                  </div>
                </template>
                <template slot-scope="scope">
                  <router-link
                    v-if="item.name === 'site_player_id' && isViewPlayers"
                    :to="`/players/${scope.row[item.player_id]}/info`"
                    class="row-value link"
                  >
                    {{ scope.row[item.name] }}
                  </router-link>
                  <div
                    v-else
                    :class="[item.class, item.computedClass ? item.computedClass(scope.row[item.name]) : '']"
                    class="row-value"
                    @click="item.click ? item.click(scope.row) : ''"
                  >
                    <span v-if="item.computedValue">{{ item.computedValue(scope.row) }}</span>
                    <span v-else>{{ item.formatter ? item.formatter(scope.row[item.name], item.formatOptions) : scope.row[item.name] }}</span>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <content-loader
            v-if="lazyLoadingBlocked"
            :height="300"
            :speed="2"
            :width="990"
            primary-color="#f3f3f3"
            secondary-color="#ecebeb"
          >
            <rect
              height="30"
              rx="4"
              ry="4"
              width="930"
              x="29.69"
              y="75"
            />
            <rect
              height="15"
              rx="4"
              ry="4"
              width="930"
              x="29.69"
              y="110"
            />
            <rect
              height="15"
              rx="4"
              ry="4"
              width="930"
              x="29.69"
              y="135"
            />
            <rect
              height="15"
              rx="4"
              ry="4"
              width="930"
              x="29.69"
              y="160"
            />
            <rect
              height="15"
              rx="4"
              ry="4"
              width="930"
              x="29.69"
              y="185"
            />
            <rect
              height="15"
              rx="4"
              ry="4"
              width="930"
              x="29.69"
              y="210"
            />
          </content-loader>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash/function';
import { ContentLoader } from 'vue-content-loader';
import { setTimeout } from 'timers';
import { pageSizeMixin, resolvePageSize } from '@/service/pageSize';
import detectPermissions from '@/service/detectPermissions';
import updateUrl from '@/service/updateUrl';
import formatCurrencyMixin from '@/views/mixins/format-currency';

const sizes = {
  1080: 10,
  1440: 20,
};

const viewName = 'promochecker/limit'; // для локалсторедж
const pageSize = resolvePageSize(viewName, {
  _default: 5,
  sizes,
});

export default {
  name: 'PromoChecker',
  components: { ContentLoader },
  mixins: [pageSizeMixin, formatCurrencyMixin],
  data() {
    return {
      viewName,
      type: 'period',
      codes: '',
      isDataLoading: {
        overall: false,
        players: false,
      },
      shortcutsDates: [
        {
          text: this.$t('reports.date_picker.last_30_day'),
          range: [
            this.$moment().startOf('day').subtract(30, 'd'),
            this.$moment().endOf('day'),
          ],
          onClick(picker) {
            picker.$emit('pick', this.range);
          },
        },
        {
          text: this.$t('reports.date_picker.this_month'),
          range: [
            this.$moment().startOf('month'),
            this.$moment().endOf('day'),
          ],
          onClick(picker) {
            picker.$emit('pick', this.range);
          },
        },
        {
          text: this.$t('reports.date_picker.prev_month'),
          range: [
            this.$moment().subtract(1, 'months').startOf('month'),
            this.$moment().subtract(1, 'months').endOf('month'),
          ],
          onClick(picker) {
            picker.$emit('pick', this.range);
          },
        },
        {
          text: this.$t('reports.date_picker.last_90_day'),
          range: [
            this.$moment().startOf('day').subtract(90, 'd'),
            this.$moment().endOf('day'),
          ],
          onClick(picker) {
            picker.$emit('pick', this.range);
          },
        },
      ],
      dateRange: [],
      listId: '',
      isCodesOpen: false,
      overallData: {},
      exportUrls: {},
      playersData: [],
      fields: [
        {
          name: 'site_player_id',
          headerAlign: 'left',
          align: 'left',
          player_id: 'player_id',
        },
        {
          name: 'promo_codes_used_count',
          align: 'right',
          headerAlign: 'left',
          width: '180',
          filterable: true,
        },
        {
          name: 'win_count',
          headerAlign: 'left',
          align: 'right',
          width: '110',
        },
        {
          name: 'lost_count',
          headerAlign: 'left',
          align: 'right',
          width: '110',
        },
        {
          name: 'win_sum',
          headerAlign: 'left',
          align: 'right',
          width: '110',
          formatter: this.formatCurrency,
          computedClass: this.getCurrencyClass,
          headerCurrency: () => `, ${this.currencyLabel.trim()}`,
        },
      ],
      tableHeight: 0,
      count: 0,
      page: 1,
      limit: pageSize,
      actionName: '',
      actionFaIcon: '',
      actionIcon: '',
      sortProp: 'promo_codes_used_count',
      sortOrder: 'desc',
      pageSizes: [
        10,
        20,
        50,
        100,
      ],
      filtersVisible: false,
      filterOperators: [
        {
          label: '=',
          value: 'eq',
        },
        {
          label: '!=',
          value: 'neq',
        },
        {
          label: '>',
          value: 'gt',
        },
        {
          label: '>=',
          value: 'gte',
        },
        {
          label: '<',
          value: 'lt',
        },
        {
          label: '=<',
          value: 'lte',
        },
      ],
      filters: {
        promo_codes_used_value: 0,
        promo_codes_used_operator: 'gte',
      },
      uploadPromoCodes: {
        type: 'success',
        title: '',
        msg: '',
        action: '',
        actionName: this.$t('tools.promoChecker.edit'),
      },
      // ////
      columnFilters: {},
      isFiltersInit: false,
      lastOpenFilter: '',
      customFilterColumn: [
        'players_avg_lifetime_seconds',
        'players_median_lifetime_seconds',
        'players_avg_fd_since_registration_seconds',
        'players_median_fd_since_registration_seconds',
      ],
      reports: [
        {
          columnsFilter: {},
        },
      ],
      /*
        * Флаг для проверки page c URL
        * */
      checkPageUrl: false,
      // lazy loading blocked
      lazyLoadingBlocked: true,
      /*
      * для сброса фильтров по колонкам при закрытии
      * */
      checkApplyFilter: false,
      activeFilter: '',
      activeColumnFilters: {},
    };
  },
  computed: {
    isViewPlayers() {
      const isUser = !this.$store.getters['auth/adminAcl'].is_superuser && this.$store.getters['auth/currentAcl'].players_view === 'allow';
      const isSuperUser = this.$store.getters['auth/adminAcl'].is_superuser;
      return isSuperUser || isUser;
    },
    isFiltered() {
      return this.filters.promo_codes_used_value !== 0 || this.filters.promo_codes_used_operator !== 'gte';
    },
    query: {
      get() {
        const q = {};
        switch (this.type) {
        case 'period':
          q.from = this.dateRange[0] ? this.$moment(this.dateRange[0]).startOf('day').format('YYYY-MM-DD HH:mm:ss') : '';
          q.to = this.dateRange[1] ? this.$moment(this.dateRange[1]).endOf('day').format('YYYY-MM-DD HH:mm:ss') : '';
          break;
        case 'list':
          q.list_id = this.listId;
          break;
        default:
          break;
        }
        return q;
      },
      /*
      * Для фильтра URL
      * */
      set(data) {
        if (this.type === 'period') {
          this.dateRange[0] = this.$moment(this.$_.get(data, 'from')).isValid() ? this.$_.get(data, 'from') : '';
          this.dateRange[1] = this.$moment(this.$_.get(data, 'to')).isValid() ? this.$_.get(data, 'to') : '';
        }
      },
    },
  },
  watch: {
    lastOpenFilter(v) {
      if (v === '') {
        Array.from(document.querySelectorAll('.cell.hover-once')).map(e => e.classList.remove('hover-once'));
        if (!this.checkApplyFilter) {
          this.columnFilters[this.activeFilter] = this.activeColumnFilters[this.activeFilter];
          this.columnFilters[this.activeFilter].visible = false;
        }
      } else {
        this.activeFilter = v;
        this.activeColumnFilters = this.$_.cloneDeep(this.columnFilters);
      }
    },
    fields: {
      deep: true,
      handler() {
        this.isFiltersInit = false;
        this.fields.forEach((field) => {
          if (field.filterable && (this.$_.isEmpty(this.columnFilters[field.name]) || !this.columnFilters[field.name].op)) {
            this.$set(this.columnFilters, field.name, {
              visible: false,
              op: [],
              value: '',
              valueSec: '',
              dateFilter: '',
            });
            this.isFiltersInit = true;
          }
        });
      },
    },
    colFilters: {
      deep: true,
      handler(nv) {
        this.setColumnFilters(nv);
      },
    },
    sortProp(v) {
      this.$ls.set('promochecker/list/sortProp', JSON.stringify(v));
    },
    sortOrder(v) {
      this.$ls.set('promochecker/list/sortOrder', JSON.stringify(v));
    },
    limit(v) {
      this.$ls.set('promochecker/list/limit', JSON.stringify(v));
    },
    filters: {
      deep: true,
      handler(v) {
        this.$ls.set('promochecker/filter', JSON.stringify(v));
      },
    },

  },

  created() {
    if (detectPermissions.checkRequest('tools_promo_codes_checker_view')) {
      this.dateRange = this.shortcutsDates[1].range;
      this.$watch('dateRange', (nv, ov) => {
        if (this.type !== 'period') {
          return;
        }
        if (!nv) {
          this.dateRange = this.shortcutsDates[1].range;
        } else if (nv !== ov) {
          this.getStats();
        }
      });
      this.$watch('type', (nv, ov) => {
        if (nv && nv !== ov) {
          this.dateRange = this.shortcutsDates[1].range;
          this.listId = '';
          this.overallData = {};
          this.playersData = [];
        }
        if (nv === 'period') {
          if (this.dateRange !== this.shortcutsDates[1].range) {
            this.dateRange = this.shortcutsDates[1].range;
          } else {
            this.getStats();
          }
        } else {
          /*
          * Очищаем hash
          * */
          this._completedUrl(true);
        }
      });
      document.addEventListener('click', (e) => {
        if (this.lastOpenFilter) {
          let targetEl = e.target;
          do {
            if (targetEl.classList && targetEl.classList.contains('el-popover')) {
              return;
            }
            targetEl = targetEl.parentNode;
          } while (targetEl);
          this.columnFilters[this.lastOpenFilter].visible = false;
          this.lastOpenFilter = '';
        }
      });
      if (!this.$_.isEmpty(this.colFilters)) {
        this.columnFilters = this.$_.cloneDeep(this.colFilters);
      }

      /*
      *
      * Берем hash
      * */
      const _dataUrl = updateUrl.getParseParams(this.$route.hash);

      if (!this.$_.isEmpty(_dataUrl)) {
        this.sortProp = this.$_.get(_dataUrl, ['sort_column']) || 'promo_codes_used_count';
        this.sortOrder = this.$_.get(_dataUrl, ['sort_dir']) || 'desc';
        this.limit = Number(this.$_.get(_dataUrl, ['limit'])) || pageSize;
        this.page = Number(this.$_.get(_dataUrl, ['page'])) || 1;
        this.filters = this.$_.get(_dataUrl, ['filters']) || this.filters;
        this.query = this.$_.get(_dataUrl, ['query']) || {};
        this.checkPageUrl = true;
      } else {
        this.limit = JSON.parse(this.$ls.get('promochecker/list/limit')) || pageSize;
        this.sortProp = JSON.parse(this.$ls.get('promochecker/list/sortProp')) || 'promo_codes_used_count';
        this.sortOrder = JSON.parse(this.$ls.get('promochecker/list/sortOrder')) || 'desc';


        this.filters = JSON.parse(this.$ls.get('promochecker/filter')) || this.filters;
      }

      setTimeout(() => {
        this.$set(this.columnFilters, 'promo_codes_used_count', {
          visible: (this.$_.get(_dataUrl, ['columnFilters', 'promo_codes_used_count', 'visible']) === 'true') || false,
          op: this.$_.get(_dataUrl, ['columnFilters', 'promo_codes_used_count', 'op'])
              || (JSON.parse(this.$ls.get('promochecker/promo_codes_used_count')) ? JSON.parse(this.$ls.get('promochecker/promo_codes_used_count')).op : []),
          value: this.$_.get(_dataUrl, ['columnFilters', 'promo_codes_used_count', 'value'])
              || (JSON.parse(this.$ls.get('promochecker/promo_codes_used_count')) ? JSON.parse(this.$ls.get('promochecker/promo_codes_used_count')).value : ''),
          valueSec: '',
          dateFilter: '',
        });

        this.$watch('columnFilters', (v) => {
          this.$ls.set('promochecker/promo_codes_used_count', JSON.stringify(v.promo_codes_used_count));
        }, { deep: true });
      }, 300);
    }
    detectPermissions.checkRoute('tools_promo_codes_checker_view');
  },

  mounted() {
    if (detectPermissions.checkRequest('tools_promo_codes_checker_view')) {
      this.$refs.dataTable.clearSort();
      this.$refs.dataTable.sort(this.sortProp, `${this.sortOrder}ending`);
      this.$refs.dataTable.doLayout();
      this.getStats();
      this.fields = this.$_.cloneDeep(this.fields);
      this.$eventBus.$on('changeLocaleGlobal', this.changeLocaleGlobal);
    }
  },

  destroyed() {
    this.$eventBus.$off('changeLocaleGlobal', this.changeLocaleGlobal);
  },

  updated() {
    const th = window.innerHeight - this.$refs.wrapper.offsetTop - 80;
    this.tableHeight = th < 300 ? 300 : th;
  },

  methods: {
    changeLocaleGlobal() {
      this.getOverallStats('silent');
    },

    setColumnFilters(filters) {
      this.isFiltersInit = false;
      this.columnFilters = {};
      this.fields.forEach((field) => {
        if (field.filterable) {
          this.$set(this.columnFilters, field.name, {
            visible: false,
            op: [],
            value: '',
            valueSec: '',
            dateFilter: '',
          });
        }
      });
      if (filters) {
        this.columnFilters = this.$_.assign({}, this.columnFilters, filters);
      }
      this.$_.forEach(this.columnFilters, filter => filter.visible = false);
      this.isFiltersInit = true;
    },

    getStats() {
      this.getOverallStats();
      this.getPlayerStats();
    },

    getOverallStats(action) {
      if (!this.listId && this.type === 'list') {
        return;
      }

      if (action !== 'silent') {
        this.isDataLoading.overall = true;
      }

      this.$api.getPromoCheckerOverallStats(this.query)
        .then((response) => {
          this.currencyCode = response.data.misc.currency_code;
          this.exportUrls = response.data.misc.export_urls;
          this.overallData = this.$_.clone({
            promo_codes_unused_count: 0,
            ...response.data.payload,
          });
        })
        .finally(() => {
          if (action !== 'silent') {
            this.isDataLoading.overall = false;
          }
        });
    },
    /*
    * исправляем два запроса при hash из за @change
    * */
    getPlayerStats: _.debounce(function getPlayerStatsD() {
      if (!this.listId && this.type === 'list') {
        return;
      }
      /*
      * Формируем запрос
      * */
      this._completedUrl();

      this.isDataLoading.players = true;
      this.$api.getPromoCheckerPlayersStats({
        sort_column: this.sortProp,
        sort_dir: this.sortOrder,
        limit: this.limit,
        offset: (this.limit * this.page) - this.limit,
        ...this.filters,
        ...this.query,
      })
        .then((response) => {
          this.playersData = this.$_.clone(response.data.payload.data);
          this.count = response.data.misc.count;
          if (this.lazyLoadingBlocked) {
            this.$nextTick(() => {
              this.lazyLoadingBlocked = false;
            });
          }
        })
        .finally(() => {
          this.isDataLoading.players = false;
        });
    }, 300),
    resetFilter() {
      this.filters = {
        promo_codes_used_value: 0,
        promo_codes_used_operator: 'gte',
      };
      this.getStats();
    },
    handleUploadCodes() {
      this.uploadPromoCodes.title = this.$t('tools.confirm.title');
      this.uploadPromoCodes.actionName = this.$t('tools.confirm.action');
      this.uploadPromoCodes.msg = this.$t('tools.confirm.msg');
      this.$refs.uploadPromoCodes.open();
      this.actionFaIcon = 'plus';
    },
    cancelCodes() {
      this.codes = '';
    },
    handleCodes() {
      this.$api.promoCheckerUploadCodes({ promo_codes: this.codes })
        .then((response) => {
          this.handleListProcessed(response.data.payload.id);
          this.codes = '';
          this.$refs.uploadPromoCodes.close();
        });
    },
    handleListProcessed(id) {
      this.listId = id;
      this.isCodesOpen = false;
      this.getStats();
    },
    downloadReport(type) {
      window.open(this.exportUrls[type]);
    },
    handleSort({ prop, order }) {
      // eslint-disable-next-line
      function fooprop() {
        if (order === 'descending') return 'desc';
        if (order === 'ascending') return 'asc';
      }

      if (order === null) {
        this.$refs.dataTable.clearSort();
        this.$refs.dataTable.sort(this.sortProp, 'ascending');
        this.$refs.dataTable.doLayout();
        return;
      }
      if (this.sortProp !== prop) {
        const { sortOrder } = this;
        this.sortOrder = fooprop();
        this.sortProp = prop;
        this.$refs.dataTable.sort(prop, `${sortOrder}ending`);

        this.page = 1;
        this.getPlayerStats();
      } else {
        this.sortOrder = fooprop();
        if (this.checkPageUrl) {
          this.checkPageUrl = false;
          this.getPlayerStats();
          return;
        }

        this.page = 1;
        this.getPlayerStats();
      }

      if (this.lastOpenFilter) {
        this.columnFilters[this.lastOpenFilter].visible = false;
        this.lastOpenFilter = '';
      }
      // /////////
    },
    pageChange(page, size) {
      if (this.page !== page || this.limit !== size) {
        this.page = page;
        this.limit = size;
        this.getPlayerStats();
      }
    },
    handleFilter(filter) {
      this.reports[0].columnsFilter = {};
      const op = filter.promo_codes_used_count.op || [];

      if (op.length === 2 && op.indexOf('<') >= 0) {
        this.filters.promo_codes_used_operator = 'lte';
      } else if (op.length === 2 && op.indexOf('>') >= 0) {
        this.filters.promo_codes_used_operator = 'gte';
      } else if (op.length === 1 && op[0] === '=') {
        this.filters.promo_codes_used_operator = 'eq';
      } else if (op.length === 1 && op[0] === '>') {
        this.filters.promo_codes_used_operator = 'gt';
      } else if (op.length === 1 && op[0] === '<') {
        this.filters.promo_codes_used_operator = 'lt';
      } else {
        this.filters.promo_codes_used_operator = 'gte';
      }
      this.filters.promo_codes_used_value = filter.promo_codes_used_count.value || 0;

      this.getPlayerStats();
    },
    getCurrencyClass(value) {
      return value < 0 ? 'negative' : '';
    },
    parseArr(val, prop) {
      if (val[val.length - 1] === '>') {
        if (val.indexOf('<') >= 0) val.splice(val.indexOf('<'), 1);
        this.columnFilters[prop].op = val;
      }
      if (val[val.length - 1] === '<') {
        if (val.indexOf('>') >= 0) val.splice(val.indexOf('>'), 1);
        this.columnFilters[prop].op = val;
      }
    },
    /*
              * Формируем URL
              * */
    _completedUrl(clear = false) {
      if (clear) {
        updateUrl.updateGetParams({});
        return;
      }

      if (this.type !== 'period') {
        return;
      }

      const _dataUrl = {
        sort_column: this.sortProp,
        sort_dir: this.sortOrder,
        limit: this.limit,
        page: this.page,
        filters: { ...this.filters },
        query: { ...this.query },
        columnFilters: this.columnFilters,
      };

      updateUrl.updateGetParams(_dataUrl);
    },
    filtrable(property) {
      const { filterable } = this.fields.find(el => el.name === property);
      return filterable;
    },
    filterBtnReset(property) {
      this.columnFilters[property].op = [];
      this.columnFilters[property].value = '';
      this.columnFilters[property].valueSec = '';
      this.columnFilters[property].dateFilter = '';
      this.columnFilters[property].visible = false;
      this.lastOpenFilter = '';
      this.handleFilter(this.columnFilters);
      this.checkApplyFilter = true;
      this.$nextTick(() => {
        this.checkApplyFilter = false;
      });
    },
    filterBtnApply(property) {
      this.columnFilters[property].visible = false;
      this.lastOpenFilter = '';
      this.handleFilter(this.columnFilters);
      this.checkApplyFilter = true;
      this.$nextTick(() => {
        this.checkApplyFilter = false;
      });
    },
  },
};
</script>

<style lang="scss" scoped>

</style>

<style lang="scss">
  .pc-controls {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 172px;

    .filter_type, .filter_value {
      width: 80px;
      margin-bottom: 6px;
    }
  }

  // .promo-checker{

  // }
</style>

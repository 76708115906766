import { mapGetters } from 'vuex';
import numeral from 'numeral';

export default {
  data() {
    return {
      currencyCode: '',
      defaultCurrency: '$',
    };
  },
  computed: {
    ...mapGetters({
      currencies: 'misc/currencies',
    }),
    currencyLabel() {
      if (this.currencyCode === undefined) return this.defaultCurrency;

      const currency = this.currencies.find((el => el.code === this.currencyCode));

      return currency?.symbol || `${this.currencyCode} `;
    },
  },
  methods: {
    formatCurrency(value) {
      return `${this.currencyLabel}${value < 0 ? '-' : ''}${numeral(Math.abs(value)).format('0,0.00')}`;
    },
  },
};

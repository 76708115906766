<template>
  <div class="ui-filter popover-filter">
    <ui-button
      ref="mainBtn"
      :filled="!!filtersOpen || hasFilters"
      icon="filter"
      lib="fa"
      substyle="fas"
      color="green"
      class="main-btn"
      @click="toggleFilters"
    >
      {{ $t('ui.filters.inner.filter') }}
    </ui-button>
    <transition name="fade">
      <div v-show="filtersOpen" ref="popup" class="pop" @keyup.enter.stop>
        <div class="arrow" />

        <div class="filter-row">
          <span class="label">{{ $t('affiliates.filters.status') }}</span>
          <el-checkbox-group
            v-model="currentFilters.account_status"
            class="status"
          >
            <el-checkbox label="approved">
              {{ $t('affiliates.listView.toolbar[1].statusFilter.approved') }}
            </el-checkbox>
            <el-checkbox label="blocked">
              {{ $t('affiliates.listView.toolbar[1].statusFilter.blocked') }}
            </el-checkbox>
            <el-checkbox label="rejected">
              {{ $t('affiliates.listView.toolbar[1].statusFilter.rejected') }}
            </el-checkbox>
          </el-checkbox-group>
        </div>

        <div class="filter-row">
          <span class="label">{{ $t('affiliates.filters.promoCode') }}</span>
          <ui-input
            v-model="currentFilters.promo_code"
            :placeholder="$t('affiliates.filters.promoCode')"
            autosize
            :error="getError('promo_code')"
          />
        </div>

        <div class="filter-row">
          <span class="label">{{ $t('affiliates.filters.country') }}</span>
          <el-select
            ref="countryFilter"
            v-model="currentFilters.country_code"
            :placeholder="$t('affiliates.filters.country')"
            class="select"
            filterable
            clearable
          >
            <el-option
              v-for="item in countries"
              :key="item.code"
              :label="item.name"
              :value="item.code"
            />
          </el-select>
        </div>

        <div class="filter-row">
          <span class="label">{{
            $t('affiliates.filters.onlyMultiaccounts')
          }}</span>
          <el-switch
            v-model="currentFilters.with_multiaccounts"
            :active-color="$theme.accentColor"
            :inactive-color="$theme.dangerColor"
          />
        </div>

        <div class="filter-row">
          <span class="label">{{ $t('affiliates.filters.introducedBy') }}</span>
          <el-select
            ref="introducedByFilter"
            v-model="currentFilters.referred"
            value-key="id"
            :placeholder="$t('affiliates.filters.introducedBy')"
            class="select"
            :remote-method="searchMethod"
            remote
            :loading="loading"
            filterable
            clearable
            @visible-change="handleToggleSelect"
          >
            <el-option
              v-for="item in affiliateOptions"
              :key="item.id"
              :label="item.email"
              :value="item"
            />
          </el-select>
        </div>

        <div class="filter-row">
          <span class="label">{{
            $t('affiliates.filters.subAffiliatesProgram')
          }}</span>
          <el-radio-group
            v-model="currentFilters.referral_program_enabled"
            class="manual-radio"
          >
            <el-radio>
              {{ $t('ui.filters.select.all') }}
            </el-radio>
            <el-radio :label="false">
              {{ $t('ui.filters.select.inactive') }}
            </el-radio>
            <el-radio :label="true">
              {{ $t('ui.filters.select.active') }}
            </el-radio>
          </el-radio-group>
        </div>

        <div class="filter-row">
          <span class="label">{{ $t('affiliates.filters.mediaBuying') }}</span>
          <el-radio-group
            v-model="currentFilters.media_buying_enabled"
            class="manual-radio"
          >
            <el-radio>
              {{ $t('ui.filters.select.all') }}
            </el-radio>
            <el-radio :label="false">
              {{ $t('ui.filters.select.disabled') }}
            </el-radio>
            <el-radio :label="true">
              {{ $t('ui.filters.select.enabled') }}
            </el-radio>
          </el-radio-group>
        </div>

        <div class="filter-row">
          <span class="label">{{
            $t('affiliates.filters.createPayments')
          }}</span>
          <el-radio-group
            v-model="currentFilters.payments_enabled"
            class="manual-radio"
          >
            <el-radio>
              {{ $t('ui.filters.select.all') }}
            </el-radio>
            <el-radio :label="false">
              {{ $t('ui.filters.select.disabled') }}
            </el-radio>
            <el-radio :label="true">
              {{ $t('ui.filters.select.enabled') }}
            </el-radio>
          </el-radio-group>
        </div>

        <div class="filter-row">
          <span class="label">{{
            $t('affiliates.filters.paymentMethod')
          }}</span>
          <el-select
            ref="paymentMethod"
            v-model="currentFilters.site_payment_method_id"
            class="select"
            filterable
            :filter-method="filterMethod"
            clearable
            :placeholder="$t(`affiliates.cardView.info.paymentMethod`)"
            @change="currentFilters.payment_wallet = null"
            @visible-change="filterValue = ''"
          >
            <el-option
              v-for="item in sortedPaymentMethods"
              :key="item.id"
              class="select-options"
              :label="item.name"
              :value="item.id"
              :disabled="item.is_disabled"
            />
          </el-select>
        </div>

        <div class="filter-row">
          <span class="label">{{ $t('affiliates.filters.wallet') }}</span>
          <ui-input
            v-model="currentFilters.payment_wallet"
            :disabled="!currentFilters.site_payment_method_id"
            :placeholder="placeholderWallet || ''"
            autosize
            :error="getError('payment_wallet')"
          />
        </div>

        <div class="filter-row">
          <span class="label">{{
            $t('affiliates.filters.paymentPeriod')
          }}</span>
          <el-select
            ref="paymentPeriod"
            v-model="currentFilters.payments_net"
            clearable
            :placeholder="$t(`affiliates.cardView.info.paymentPeriod`)"
          >
            <el-option
              v-for="item in paymentPeriods"
              :key="item"
              :label="item | uppercase"
              :value="item"
            />
          </el-select>
        </div>

        <div class="filter-row">
          <span class="label">{{
            $t('affiliates.filters.negativeCarryover')
          }}</span>
          <el-radio-group
            v-model="currentFilters.payments_negative_carryover"
            class="manual-radio"
          >
            <el-radio>
              {{ $t('ui.filters.select.all') }}
            </el-radio>
            <el-radio :label="false">
              {{ $t('ui.filters.select.no') }}
            </el-radio>
            <el-radio :label="true">
              {{ $t('ui.filters.select.yes') }}
            </el-radio>
          </el-radio-group>
        </div>
        <div class="controls">
          <ui-button
            color="red"
            lib="fa"
            substyle="fas"
            icon="times"
            class="btn"
            @click="resetFilters"
          >
            {{ $t('ui.table.reset') }}
          </ui-button>
          <ui-button
            filled
            :disabled="isDisabledGroup"
            lib="fa"
            substyle="fas"
            color="green"
            icon="check"
            class="btn"
            @click="applyFilters"
          >
            {{ $t('ui.table.apply') }}
          </ui-button>
        </div>
      </div>
    </transition>
    <span
      v-if="!$_.isEmpty($_.omit(value, 'account_status')) || !(value.account_status.length === 1 && value.account_status[0] === 'approved')"
      class="reset_link"
      @click="resetFilters"
    >
      {{ $t('ui.filters.inner.reset_filter') }}
    </span>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import hash from 'object-hash';
import errorHandleMixin from '@/views/mixins/error-hadle';

const filterFn = v => v !== undefined && v !== '' && v !== null;

export default {
  name: 'AffiliatesFilter',
  mixins: [
    errorHandleMixin,
  ],
  props: {
    value: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      loading: false,
      currentFilters: {
        account_status: ['approved'],
      },
      affiliateOptions: [],
      filtersOpen: false,
      oldHash: null,
      newHash: null,
      filterValue: '',
    };
  },
  computed: {
    ...mapGetters({
      paymentMethods: 'misc/paymentMethods',
      paymentPeriods: 'misc/paymentPeriods',
    }),
    sortedPaymentMethods() {
      return this.$_.cloneDeep(this.paymentMethods).sort((x, y) => {
        if (x.id === this.currentFilters.site_payment_method_id) {
          return -1;
        }
        return x.is_disabled - y.is_disabled;
      }).filter(el => el.name.toLowerCase().includes(this.filterValue.toLowerCase()));
    },
    hasFilters() {
      const { account_status, ...restFilters } = this.currentFilters;
      return !this.$_.isEmpty(restFilters) || !(account_status.length === 1 && account_status[0] === 'approved');
    },
    placeholderWallet() {
      if (this.paymentMethods.length !== 0 && this.currentFilters.site_payment_method_id) {
        return this.$_.find(this.paymentMethods, e => e.id === this.currentFilters.site_payment_method_id).wallet_placeholder;
      }
      return '';
    },
    isDisabledGroup() {
      return this.oldHash === this.newHash;
    },
    ...mapGetters('misc', ['countries']),
  },
  watch: {
    currentFilters: {
      deep: true,
      handler(value) {
        const data = {
          with_multiaccounts: false,
          ...this.$_.pickBy(value, filterFn),
        };

        this.newHash = hash(data, { algorithm: 'md5' });
      },
    },
  },
  destroyed() {
    document.removeEventListener('click', this.clickOutside, true);
  },
  created() {
    const { referred_by, affiliate_referral_id, ...restParams } = this.value;
    this.currentFilters = this.$_.cloneDeep({
      account_status: ['approved'],
      ...restParams,
      ...(referred_by && affiliate_referral_id && {
        referred: {
          referral_id: referred_by,
          id: affiliate_referral_id,
        },
      }),
    });
  },
  mounted() {
    document.addEventListener('click', this.clickOutside, true);
    this.getAffiliates();
  },
  methods: {
    filterMethod(v) {
      this.filterValue = v;
    },
    handleToggleSelect(v) {
      if (v) {
        this.loading = true;
        this.getAffiliates('').then(() => {
          this.loading = false;
        });
      }
    },
    searchMethod(v) {
      this.$_.debounce(this.getAffiliates, 800)(v);
    },
    getAffiliates(search) {
      const query = {
        limit: 100,
        offset: 0,
        search,
        account_status: ['approved'],
        referral_program_enabled: true,
      };
      return new Promise((resolve, reject) => {
        this.$api.getAffiliates(query)
          .then((response) => {
            this.affiliateOptions = response.data.payload;
            resolve(response.data.payload);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    clickOutside(e) {
      if (this.$refs.mainBtn.$el?.contains(e.target)) return;
      const filters = [
        this.$refs.popup,
        this.$refs.introducedByFilter.$children[1].$el,
        this.$refs.countryFilter.$children[1].$el,
        this.$refs.paymentMethod.$children[1].$el,
        this.$refs.paymentPeriod.$children[1].$el,
      ].filter(el => !!el);

      if (this.filtersOpen === true && !filters.some(el => e.target && el?.contains(e.target))) {
        this.toggleFilters();
      }
    },
    toggleFilters() {
      this.filtersOpen = !this.filtersOpen;
      if (this.filtersOpen) {
        const { referred_by, affiliate_referral_id, ...restParams } = this.value;
        this.currentFilters = this.$_.cloneDeep({
          account_status: ['approved'],
          ...restParams,
          ...(referred_by && affiliate_referral_id && {
            referred: {
              referral_id: referred_by,
              id: affiliate_referral_id,
            },
          }),
        });

        const data = {
          with_multiaccounts: false,
          ...this.$_.pickBy(this.value, filterFn),
        };

        this.oldHash = hash(data, { algorithm: 'md5' });
      }

      this.$emit('toggle', this.filtersOpen);
    },
    applyFilters() {
      const {
        payment_wallet,
        site_payment_method_id,
        referred,
        with_multiaccounts,
        ...restFilters
      } = this.currentFilters;
      const { referral_id, id } = referred || {};

      this.$emit('submit', {
        ...(payment_wallet && { payment_wallet }),
        ...(site_payment_method_id && { site_payment_method_id }),
        ...(with_multiaccounts && { with_multiaccounts }),
        affiliate_referral_id: id,
        referred_by: referral_id,
        ...restFilters,
      });
    },
    resetFilters() {
      this.currentFilters = {
        account_status: ['approved'],
      };
      this.$emit('submit', this.currentFilters);
      this.filtersOpen = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.ui-filter {
  position: relative;
  line-height: 0;

  .pop {
    position: absolute;
    width: 476px;
    z-index: 9999;
    top: 44px;
    left: -20px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 16px;
    background-color: #fff;
    box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    user-select: none;

    .ui-select.filter_type.medium {
      width: 200px !important;
      .tag .value {
        max-width: 181px !important;
      }
    }

    &.fade-enter-active,
    &.fade-leave-active {
      transition: all 0.4s;
    }

    &.fade-enter,
    &.fade-leave-to {
      opacity: 0;
      transform: translateY(8px);
    }

    .arrow {
      position: absolute;
      top: -5px;
      left: 56px;
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-bottom: 5px solid #fff;
    }
    .filter-row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 54px;
      border-bottom: 1px solid #eaeaea;

      > * {
        flex: 1;
      }

      > div {
        justify-content: flex-end;
      }

      .status {
        gap: 16px;
        justify-content: end !important;
      }

      &.disabled {
        opacity: 0.5;
        pointer-events: none;
      }

      .label {
        font-size: 14px;
        font-weight: 500;
        color: #303634;
      }

      .manual-radio {
        /deep/ {
          .el-radio {
            margin-right: 0 !important;

            .el-radio__label {
              color: #303634;
              font-size: 12px;
              font-weight: 400;
            }
          }

          .el-radio + .el-radio {
            margin-left: 16px !important;
          }
        }
      }

      .filter_type {
        width: 160px;
        margin-left: 8px !important;
      }
      .btn {
        margin-left: 0 !important;
      }
    }
    .controls {
      display: inline-flex;
      justify-content: flex-end;
      flex-wrap: nowrap;
      width: auto;
      margin-top: 16px;

      .btn {
        margin-left: 0 !important;
      }
      .btn + .btn {
        margin-left: 8px !important;
      }
    }
  }
}

.select-options {
  display: block!important;
  max-width: 400px!important;
}
</style>

<template>
  <div class="payments-history">
    <ui-confirm
      ref="confirm"
      :width="480"
      :type="confirm.type"
      :action-name="confirm.actionName"
      :action="confirm.action"
      :action-fa-icon="'check'"
      @save="actionPayment"
    >
      <div slot="title">
        {{ confirm.title }}
      </div>
      <div class="body__wrap">
        <i
          :class="confirm.type"
          class="fas fa-exclamation-triangle"
        />
        <div
          class="body__msg"
          v-html="confirm.msg"
        />
      </div>
    </ui-confirm>
    <div class="head">
      <div class="section">
        <div
          v-if="fromPage === 'payment_dashboard'"
          class="breadcrumbs"
        >
          <router-link
            class="back"
            to="/payments"
          >
            {{ $t('payments.history.payments_dashboard') }}
          </router-link>
          <span class="divider">></span>
          <span class="title breadcrumbs-title">{{ $t('payments.history.paidAt') }} {{ formatDate(date) }}</span>
        </div>
        <span
          v-else
          class="title"
        >{{ $t('payments.page_title.payments_payout.name') }} {{ $t(`payments.page_title.payments_payout.${currentPaymentPageStatus}`) }}</span>
        <ui-input
          v-model.trim="searchQuery"
          :width="180"
          class="select-label"
          is-search
          @search="searchPaymentsReview"
        />
      </div>
      <div class="control">
        <div class="pagination">
          <ui-pagination
            :page="page"
            :page-size="limit"
            :count="count"
            show-size-select
            @page-change="pageChange"
          />
        </div>
      </div>
    </div>
    <div
      ref="wrapper"
      class="table-wrapper"
    >
      <ui-table
        ref="dataTable"
        v-loading="isDataLoading"
        :fields="fields"
        :data="data"
        :rows-count="limit"
        :sort="{
          prop: sortProp,
          order: sortOrder,
        }"
        :total="totals"
        lazy-loading
        show-total
        i18n-path="payments.history"
        element-loading-custom-class="data-loader-spinner"
        @sort="handleSort"
        @filter="handlerFilter"
      >
        <template slot="append">
          <el-table-column
            :width="calcWidth()"
            align="center"
            header-align="center"
          >
            <template slot-scope="scope">
              <div class="row-value actions">
                <ActionIcon
                  :tooltip="$t('payments.history.info')"
                >
                  <router-link
                    :to="handleRowClick(scope.row)"
                    class="action-ico"
                  >
                    <ui-icon
                      name="eye"
                      :color="$theme.accentColor"
                      lib="fa"
                    />
                  </router-link>
                </ActionIcon>
                <ActionIcon
                  :tooltip="$t('payments.history.toReview')"
                >
                  <span
                    v-if="reviewActions() && currentPaymentPageStatus === 'not_paid'"
                    class="action-ico"
                    @click="reviewHandler(scope.row, $event)"
                  >
                    <ui-icon
                      name="check-double"
                      :color="$theme.accentColor"
                      lib="fa"
                    />
                  </span>
                </ActionIcon>
                <ActionIcon
                  :tooltip="$t('payments.history.decline')"
                >
                  <span
                    v-if="reviewActions() && currentPaymentPageStatus === 'not_paid'"
                    class="action-ico"
                    @click="declineHandler(scope.row, $event)"
                  >
                    <ui-icon
                      name="times"
                      :color="$theme.dangerColor"
                      lib="fa"
                    />
                  </span>
                </ActionIcon>
              </div>
            </template>
          </el-table-column>
        </template>
      </ui-table>
    </div>
  </div>
</template>

<script>
import updateUrl from '../../service/updateUrl';
import detectPermissions from '../../service/detectPermissions';
import { pageSizeMixin, resolvePageSize } from '../../service/pageSize';

const sizes = {
  1080: 20,
  1440: 30,
};

const viewName = 'payments/not_paid/limit';
const pageSize = resolvePageSize(viewName, {
  _default: 15,
  sizes,
});

export default {
  name: 'PaymentsHistory',
  mixins: [pageSizeMixin],
  data() {
    return {
      viewName,
      fields: [
        {
          name: 'incrementing_id',
          align: 'left',
          sortable: 'custom',
          width: '70',
        },
        {
          name: 'affiliate_email',
          align: 'left',
          minWidth: '200',
          sortable: 'custom',
          overflow: true,
          link: this.getAffiliateLink,
        },
        {
          name: 'team_name',
          width: '120',
          minWidth: '120',
        },
        {
          name: 'approved_by_admin_name',
          align: 'left',
          minWidth: '200',
          sortable: 'custom',
          overflow: true,
        },
        {
          name: 'amount',
          align: 'right',
          width: '92',
          class: 'bold',
          sortable: 'custom',
          format: 'formatMoney',
          computedClass: this.getCurrencyClass,
        },
        {
          name: 'available_balance',
          align: 'right',
          width: '92',
          sortable: 'custom',
          format: 'formatMoney',
          computedClass: this.getCurrencyClass,
        },
        {
          name: 'hold_balance',
          align: 'right',
          width: '92',
          sortable: 'custom',
          format: 'formatMoney',
          computedClass: this.getCurrencyClass,
        },
        {
          name: 'payment_period_end',
          align: 'left',
          width: '170',
          sortable: 'custom',
          computedValue: this.computePaymentPeriod,
        },
        {
          name: 'created_at',
          width: '120',
          align: 'left',
          headerAlign: 'left',
          sortable: 'custom',
          format: 'date-time',
          filterable: true,
          allowedOperators: ['≤', '=', '≥'],
        },
        {
          name: 'site_payment_method_name',
          align: 'center',
          headerAlign: 'center',
          width: '120',
          minWidth: '120',
          class: 'text',
          computedImg: row => row.site_payment_method_logo_base64,
          computedImgNull: (_, row) => row.site_payment_method_name || this.$t('affiliates.affiliatesInfo.not_specefied'),
        },
        {
          name: 'payment_wallet',
          align: 'left',
          headerAlign: 'center',
          width: '120',
          minWidth: '120',
          actionLib: 'fa',
          controlType: 'icon',
          computedClass: (_, row) => (row?.payment_wallet ? 'copy-reverse' : 'ui-jc-center-i cursor-default'),
          action: () => {},
          controlColorIco: () => this.$theme.accentColor,
          actionName: row => (row.payment_wallet ? 'clone' : ''),
          computedValueHtml: row => row.payment_wallet || this.$t('affiliates.affiliatesInfo.not_specefied'),
          click: this.copyWallet,
        },
      ],
      data: [],
      totals: {},
      permissions: {},
      count: 0,
      page: 1,
      limit: pageSize,
      isDataLoading: false,
      blockedRequest: false,
      sortProp: '',
      sortOrder: '',
      defaultSort: {},
      date: '',
      searchQuery: '',
      rowPaid: '',
      currentPaymentPageName: '',
      currentPaymentPageStatus: '',
      fromPage: '',
      columnsFilter: {},
      createdAtFrom: undefined,
      createdAtTo: undefined,
      confirm: {
        title: '',
        msg: '',
        type: 'success',
        actionName: '',
      },
    };
  },

  watch: {
    sortProp(newVal) {
      this.$ls.set(`Payments/${this.currentPaymentPageName}/${this.currentPaymentPageStatus}/sort_column`, newVal || 'incrementing_id');
    },
    sortOrder(newVal) {
      this.$ls.set(`Payments/${this.currentPaymentPageName}/${this.currentPaymentPageStatus}/sort_dir`, newVal || 'desc');
    },
    limit(newVal) {
      this.$ls.set(`Payments/${this.currentPaymentPageName}/${this.currentPaymentPageStatus}/limit`, newVal || pageSize);
    },

    columnsFilter(newVal) {
      this.$ls.set(`Payments/${this.currentPaymentPageName}/${this.currentPaymentPageStatus}/columns_filter`, newVal || {});
    },

    $route() {
      this.date = '';
      this.searchQuery = '';
      this.currentPaymentPageName = this.$route.name;
      this.currentPaymentPageStatus = this.$route.params.status;
      this.fromPage = this.$route.query.from ? this.$route.query.from : '';

      if (this.fromPage && this.fromPage === 'payment_dashboard') {
        this.checkHistory();
      }

      const _urlData = updateUrl.getParseParams(this.$route.hash);
      this.page = Number(this.$_.get(_urlData, ['page'])) || 1;
      this.limit = this.$ls.get(`Payments/${this.currentPaymentPageName}/${this.currentPaymentPageStatus}/limit`) || pageSize;
      this.sortProp = this.$ls.get(`Payments/${this.currentPaymentPageName}/${this.currentPaymentPageStatus}/sort_column`) || 'incrementing_id';
      this.sortOrder = this.$ls.get(`Payments/${this.currentPaymentPageName}/${this.currentPaymentPageStatus}/sort_dir`) || 'desc';
      this.columnsFilter = this.$ls.get(`Payments/${this.currentPaymentPageName}/${this.currentPaymentPageStatus}/columns_filter`) || {};

      this.parseFilter(this.columnsFilter, this.$_.isEmpty(this.columnsFilter) === true ? 'reset' : undefined);

      this.getPaymentsReview();
    },
  },

  created() {
    this.currentPaymentPageName = this.$route.name;
    this.currentPaymentPageStatus = this.$route.params.status;
    this.fromPage = this.$route.query.from ? this.$route.query.from : '';

    if (this.fromPage && this.fromPage === 'payment_dashboard') {
      this.checkHistory();
    }

    if (detectPermissions.checkRequest('payments_view')) {
      const _urlData = updateUrl.getParseParams(this.$route.hash);
      if (!this.$_.isEmpty(_urlData)) {
        this.limit = Number(this.$_.get(_urlData, ['limit'])) || pageSize;
        this.page = Number(this.$_.get(_urlData, ['page'])) || 1;

        this.sortProp = this.$_.get(_urlData, ['sort_column']) || 'incrementing_id';
        this.sortOrder = this.$_.get(_urlData, ['sort_dir']) || 'desc';
        this.searchQuery = this.$_.get(_urlData, ['search']) || null;
        this.columnsFilter = this.$_.get(_urlData, ['columns_filter']) || {};
      } else {
        this.limit = this.$ls.get(`Payments/${this.currentPaymentPageName}/${this.currentPaymentPageStatus}/limit`) || pageSize;
        this.sortProp = this.$ls.get(`Payments/${this.currentPaymentPageName}/${this.currentPaymentPageStatus}/sort_column`) || 'incrementing_id';
        this.sortOrder = this.$ls.get(`Payments/${this.currentPaymentPageName}/${this.currentPaymentPageStatus}/sort_dir`) || 'desc';
        this.columnsFilter = this.$ls.get(`Payments/${this.currentPaymentPageName}/${this.currentPaymentPageStatus}/columns_filter`) || {};
      }

      this.parseFilter(this.columnsFilter, this.$_.isEmpty(this.columnsFilter) === true ? 'reset' : undefined);
      this.getPaymentsReview();
    }
    detectPermissions.checkRoute('payments_view');
  },

  methods: {
    async copyWallet(row) {
      if (!row.payment_wallet) return;

      try {
        await this.$copyText(row.payment_wallet);
        this.$noty.info(`${this.$t('reports.other.copied_to_clipboard')}`);
      } catch (error) {
        this.$noty.error(`${this.$t('reports.other.failed_to_copy')}`);
      }
    },
    searchPaymentsReview() {
      this.page = 1;
      this.getPaymentsReview();
    },

    handlerFilter(filter, action) {
      this.parseFilter(filter, action);

      this.getPaymentsReview();
    },

    parseFilter(filter, action) {
      const columnProp = 'created_at';
      if (action === 'reset') {
        this.createdAtFrom = undefined;
        this.createdAtTo = undefined;
        this.columnsFilter = {};
        return;
      }

      if (filter[columnProp].op === '≥') {
        this.createdAtFrom = this.$moment(filter[columnProp].dateRange).startOf('day').format('YYYY-MM-DD HH:mm:ss');
        this.createdAtTo = undefined;
      } else if (filter[columnProp].op === '≤') {
        this.createdAtFrom = undefined;
        this.createdAtTo = this.$moment(filter[columnProp].dateRange).endOf('day').format('YYYY-MM-DD HH:mm:ss');
      } else {
        this.createdAtFrom = this.$moment(filter[columnProp].dateRange[0]).startOf('day').format('YYYY-MM-DD HH:mm:ss');
        this.createdAtTo = this.$moment(filter[columnProp].dateRange[1]).endOf('day').format('YYYY-MM-DD HH:mm:ss');
      }
      this.columnsFilter = filter;
    },

    checkHistory() {
      this.date = this.$route.query.date;
      this.currentPaymentPageStatus = 'paid';
    },
    calcWidth() {
      const page = this.currentPaymentPageStatus === 'not_paid';
      const isUser = !this.$store.getters['auth/adminAcl'].is_superuser && page && this.$store.getters['auth/currentAcl'].payments_review_actions === 'allow';
      const isSuperUser = this.$store.getters['auth/adminAcl'].is_superuser && page;
      return isUser || isSuperUser ? 100 : 50;
    },
    reviewActions() {
      const isUser = !this.$store.getters['auth/adminAcl'].is_superuser && this.$store.getters['auth/currentAcl'].payments_review_actions === 'allow';
      const isSuperUser = this.$store.getters['auth/adminAcl'].is_superuser;
      return isUser || isSuperUser;
    },
    declineHandler(row, e) {
      e.preventDefault();
      e.stopPropagation();
      this.rowPaid = row;
      this.confirm.title = this.$t('payments.confirm.title_2');
      this.confirm.msg = `${this.$t('payments.confirm.msg_2', { affiliateEmail: this.rowPaid.affiliate_email })}`;
      this.confirm.type = 'warning';
      this.confirm.action = 'Decline';
      this.confirm.actionName = `${this.$t('payments.confirm.action_2')}`;
      this.$refs.confirm.open();
    },
    reviewHandler(row, e) {
      e.preventDefault();
      e.stopPropagation();
      this.rowPaid = row;
      this.confirm.title = this.$t('payments.confirm.title_3');
      this.confirm.msg = `${this.$t('payments.confirm.msg_3')}`;
      this.confirm.type = 'success';
      this.confirm.action = 'Review';
      this.confirm.actionName = `${this.$t('payments.confirm.action_3')}`;
      this.$refs.confirm.open();
    },
    reworkHandler(row, e) {
      e.preventDefault();
      e.stopPropagation();
      this.rowPaid = row;
      this.confirm.title = this.$t('payments.confirm.title_7');
      this.confirm.msg = `${this.$t('payments.confirm.msg_7')}`;
      this.confirm.type = 'success';
      this.confirm.action = 'Rework';
      this.confirm.actionName = `${this.$t('payments.confirm.action_7')}`;
      this.$refs.confirm.open();
    },
    actionPayment(action) {
      switch (action) {
      case 'Decline':
        this.declinePayment();
        this.$refs.confirm.close();
        break;
      case 'Review':
        this.reviewPayment();
        this.$refs.confirm.close();
        break;
      case 'Rework':
        this.reworkPayment();
        this.$refs.confirm.close();
        break;
      default:
        break;
      }
    },
    getAffiliateLink(row) {
      return `/affiliates/${row.affiliate_id}/info`;
    },

    handleTableRows(rows) {
      this.limit = rows;
      this.getPaymentsReview();
    },
    handleRowClick(row) {
      return `/payments/info/${row.id}?from=payments_payout`;
    },
    declinePayment() {
      this.isDataLoading = true;
      this.$api
        .declinePayment(this.rowPaid.id)
        .then(() => {
          this.getPaymentsReview();
        })
        .catch((error) => {
          this.isDataLoading = false;
          this.$noty.error(`${this.$t('payments.noty.req_fail', { statusCode: error.status })}`);
        });
    },
    reviewPayment() {
      this.isDataLoading = true;
      this.$api
        .reviewPayment(this.rowPaid.id)
        .then(() => {
          this.getPaymentsReview();
        })
        .catch((error) => {
          this.isDataLoading = false;
          this.$noty.error(`${this.$t('payments.noty.req_fail', { statusCode: error.status })}`);
        });
    },
    reworkPayment() {
      this.isDataLoading = true;
      this.$api
        .reworkPayment(this.rowPaid.id)
        .then(() => {
          this.getPaymentsReview();
        })
        .catch((error) => {
          this.isDataLoading = false;
          this.$noty.error(`${this.$t('payments.noty.req_fail', { statusCode: error.status })}`);
        });
    },
    computePaymentMethod() {
      return '';
    },
    getPaymentMethodClass(row) {
      return row;
    },

    getPaymentsReview() {
      if (!this.blockedRequest) {
        this.isDataLoading = true;
        this.blockedRequest = true;

        this._completedFilterUrl();
        const _filter = this._query();

        this.$api
          .getPaymentsHistory(_filter)
          .then((response) => {
            this.count = response.data.misc.count;
            this.data = this.$_.cloneDeep(response.data.payload.data);
            this.totals = this.$_.clone(response.data.payload.totals);
            this.permissions = this.$_.clone(response.data.misc.permissions);
          })
          .finally(() => {
            setTimeout(() => {
              this.blockedRequest = false;
            }, 300);
            this.isDataLoading = false;

            this.$refs.dataTable.setColumnFilters(this.columnsFilter);
          });
      }
    },


    _query() {
      let to = this.$moment(this.date).add(1, 'd');
      to = to.subtract(1, 's');
      return {
        limit: this.limit,
        offset: (this.limit * this.page) - this.limit,
        sort_column: this.sortProp,
        sort_dir: this.sortOrder,
        search: this.searchQuery,
        moderation_status: 'approved',
        payout_status: this.currentPaymentPageStatus ? this.currentPaymentPageStatus : '',
        paid_at_from: this.date ? this.$moment(this.date).format('YYYY-MM-DD HH:mm:ss') : '',
        paid_at_to: this.date ? to.format('YYYY-MM-DD HH:mm:ss') : '',
        created_at_from: this.createdAtFrom,
        created_at_to: this.createdAtTo,
      };
    },

    pageChange(page, size) {
      this.page = page;
      this.limit = size;
      this.getPaymentsReview();
    },

    formatDate(value) {
      return this.$moment(value).locale(this.$i18n.locale).format('DD MMM YYYY');
    },
    computePaymentPeriod(row) {
      return `${this.formatDate(row.payment_period_start)} - ${this.formatDate(row.payment_period_end)}`;
    },
    getCurrencyClass(value) {
      return value < 0 ? 'negative' : '';
    },
    handleSort({ prop, order }) {
      if (order === '') return;
      if (this.sortProp !== prop) {
        this.sortProp = prop || '';
      } else {
        this.sortOrder = order;
      }

      this.getPaymentsReview();
    },
    /*
    * Создаем filter URL
    * */
    _completedFilterUrl() {
      const _dataFilters = {
        limit: this.limit,
        page: this.page,
        sort_column: this.sortProp,
        sort_dir: this.sortOrder,
        search: this.searchQuery,
        columns_filter: this.columnsFilter,

      };

      updateUrl.updateGetParams(_dataFilters);
    },
  },
};
</script>

<style lang="scss">
  .el-date-picker__hidden {
    display: none !important;
  }

  .filter__datapicker-date {
    &.el-date-editor {
      margin: 8px 0;
      width: 230px !important;
      .el-input__inner {
        font-size: 12px;
      }
    }
    .el-input__icon {
      line-height: 30px;
    }
  }

  .filter__datapicker-daterange {
    &.el-date-editor {
      margin: 8px 0;
      width: 230px !important;
      .el-range-input {
        padding-top: 1px;
        font-size: 12px;
      }
    }
  }
</style>

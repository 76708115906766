<template>
  <div class="live-lastBets">
    <div class="content">
      <div class="column left">
        <div class="head">
          <div class="title">
            <div class="title-with-button">
              {{ $t('live.tables.top20eventsCalculated') }}
              <div class="filters">
                <ui-filter
                  ref="filterTop20Calculated"
                  v-model="top20calculated.filters"
                  :use-filters="['brandLive']"
                  class="btn ui-filter-new"
                  @input="getTop20EventsWithResult()"
                />
                <span
                  v-if="top20CalculatedObj"
                  class="reset_link"
                  @click="resetFiltersOnTop20Calculate"
                >{{ $t('ui.filters.inner.reset_filter') }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="table-wrapper">
          <ui-table
            v-loading="top20calculated.isDataLoading"
            :fields="top20calculated.fields"
            :data="top20calculated.data"
            :currency="top20calculated.currencyCode"
            :lazy-top="-42"
            lazy-loading
            disable-sort
            is-static
            i18n-path="live.list"
            element-loading-custom-class="data-loader-spinner"
            external-height="auto"
          />
        </div>
      </div>
      <div class="column right">
        <div class="head">
          <div class="title title__with-refresh">
            <div class="title-with-button">
              {{ $t('live.tables.top20eventsUncalculated') }}
              <div class="filters">
                <ui-filter
                  ref="filterTop20Uncalculated"
                  v-model="top20uncalculated.filters"
                  :use-filters="['brandLive']"
                  class="btn ui-filter-new"
                  @input="getTop20EventsWithoutResult()"
                />
                <span
                  v-if="top20UncalculatedObj"
                  class="reset_link"
                  @click="resetFiltersOnTop20Uncalculate"
                >{{ $t('ui.filters.inner.reset_filter') }}</span>
              </div>
            </div>
            <ui-button
              :disabled="dasabledRefresh"
              icon="sync-alt"
              color="green"
              lib="fa"
              class="btn"
              @click="handleRefresh"
            >
              {{ $t('reports.other.refresh') }}
            </ui-button>
          </div>
        </div>
        <div class="table-wrapper">
          <ui-table
            v-loading="top20uncalculated.isDataLoading"
            :fields="top20uncalculated.fields"
            :data="top20uncalculated.data"
            :currency="top20uncalculated.currencyCode"
            lazy-loading
            disable-sort
            is-static
            i18n-path="live.list"
            element-loading-custom-class="data-loader-spinner"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import updateUrl from '../../service/updateUrl';
import detectPermissions from '../../service/detectPermissions';

export default {
  name: 'LiveDashboard',
  data() {
    return {
      top20calculated: {
        fields: [
          {
            name: 'event_name',
            align: 'left',
            headerAlign: 'left',
            format: 'event',
          },
          {
            name: 'bets_count',
            align: 'right',
            headerAlign: 'left',
            width: '100',
          },
          {
            name: 'bets_sum',
            align: 'right',
            headerAlign: 'left',
            width: '100',
            class: 'bold',
            format: 'formatMoney',
          },
          {
            name: 'profit_sum',
            align: 'right',
            headerAlign: 'left',
            width: '100',
            format: 'formatMoney',
          },
        ],
        data: [],
        filters: {},
        sortProp: '',
        sortOrder: '',
        isDataLoading: false,
        query: '',
      },
      top20uncalculated: {
        fields: [
          {
            name: 'event_name',
            align: 'left',
            headerAlign: 'left',
          },
          {
            name: 'bets_count',
            align: 'right',
            headerAlign: 'left',
            width: '100',
          },
          {
            name: 'bets_sum',
            align: 'right',
            headerAlign: 'left',
            width: '100',
            format: 'formatMoney',
          },
        ],
        data: [],
        filters: {},
        sortProp: '',
        sortOrder: '',
        isDataLoading: false,
        query: '',
      },
      refresh: true,
      dasabledRefresh: false,
      counterId: '',
      refreshInterval: 30000,
    };
  },

  computed: {
    top20CalculatedObj() {
      return Object.keys(this.top20calculated.filters).length;
    },
    top20UncalculatedObj() {
      return Object.keys(this.top20uncalculated.filters).length;
    },
  },
  watch: {
    'top20calculated.filters': {
      handler(nv) {
        this.$ls.set(
          'live/top20calculated/filters',
          JSON.stringify(nv),
        );
      },
      deep: true,
    },
    'top20uncalculated.filters': {
      handler(nv) {
        this.$ls.set(
          'live/top20uncalculated/filters',
          JSON.stringify(nv),
        );
      },
      deep: true,
    },
    refresh(val) {
      if (val) {
        this.autoRefreshOn();
      } else {
        this.autoRefreshOff();
      }
    },
  },

  created() {
    if (detectPermissions.checkRequest('top_events_view')) {
      const _filterParams = updateUrl.getParseParams(this.$route.hash, ['top20calculated', 'top20uncalculated']);
      if (!this.$_.isEmpty(_filterParams)) {
        this.top20calculated.filters = this.$_.get(_filterParams, ['top20calculated']) || {};
        this.top20uncalculated.filters = this.$_.get(_filterParams, ['top20uncalculated']) || {};
      } else {
        this.top20calculated.filters = JSON.parse(this.$ls.get('live/top20calculated/filters')) || {};
        this.top20uncalculated.filters = JSON.parse(this.$ls.get('live/top20uncalculated/filters')) || {};
      }
    }
    detectPermissions.checkRoute('top_events_view');
  },

  mounted() {
    if (detectPermissions.checkRequest('top_events_view')) {
      this.getTop20EventsWithoutResult();
      this.getTop20EventsWithResult();
      this.autoRefreshOn();
    }
    detectPermissions.checkRoute('top_events_view');
  },
  beforeDestroy() {
    this.autoRefreshOff();
  },

  methods: {
    _formCompletedParams() {
      const _data = {
        top20calculated: this.top20calculated.filters,
        top20uncalculated: this.top20uncalculated.filters,
      };
      updateUrl.updateGetParams(_data);
    },
    resetFiltersOnTop20Calculate() {
      this.$refs.filterTop20Calculated.reset();
    },
    resetFiltersOnTop20Uncalculate() {
      this.$refs.filterTop20Uncalculated.reset();
    },


    autoRefreshOn() {
      const counterId = setInterval(() => {
        this.getTop20EventsWithoutResult();
        this.getTop20EventsWithResult();
      }, this.refreshInterval);
      this.counterId = counterId;
    },
    autoRefreshOff() {
      clearInterval(this.counterId);
    },
    handleRefresh() {
      this.getTop20EventsWithoutResult();
      this.getTop20EventsWithResult();
      this.dasabledRefresh = true;
      setTimeout(() => {
        this.dasabledRefresh = false;
      }, 8000);
    },

    async getTop20EventsWithoutResult() {
      this.top20uncalculated.isDataLoading = true;
      this._formCompletedParams();
      try {
        const param = {
          site_id: this.top20uncalculated.filters.live_site_id,
        };
        const response = await this.$api.getTop20EventsWithoutResult(param);
        this.top20uncalculated.data = this.$_.cloneDeep(response.data.payload);
        this.top20uncalculated.currencyCode = response.data.misc.currency_code;
      } finally {
        this.top20uncalculated.isDataLoading = false;
      }
    },

    getPaymentMethodClass(row) {
      return row;
    },
    computePaymentMethod() {
      return '';
    },

    async getTop20EventsWithResult() {
      this.top20calculated.isDataLoading = true;
      this._formCompletedParams();
      try {
        const param = {
          site_id: this.top20calculated.filters.live_site_id,
        };
        const response = await this.$api.getTop20EventsWithResult(param);
        this.top20calculated.data = this.$_.cloneDeep(response.data.payload);
        this.top20calculated.currencyCode = response.data.misc.currency_code;
      } finally {
        this.top20calculated.isDataLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .live .live-lastBets {
    padding-top: 16px;
  }
</style>

export default {
  payments: {
    name: 'Выплаты',
    page_title: {
      payments_moderation: {
        name: 'Модерация платежей — ',
        on_review: 'На рассмотрении',
        approved: 'Одобрено',
        declined: 'Отклонено',
      },
      payments_payout: {
        name: 'Проведение платежей — ',
        not_paid: 'Не выплачено',
        for_rework: 'На доработке',
        processing: 'В обработке',
        paid: 'Выплачено',
        failed: 'Неудачно',
      },
    },
    dashboard: {
      title: 'История выплат',
      fields: {
        date: {
          title: 'Дата',
        },
        affiliates_count: {
          title: 'Партнер',
        },
        payments_count: {
          title: 'Выплаты',
        },
        paid_amount: {
          title: 'Сумма',
        },
      },
    },
    outsiders: {
      title: 'Аутсайдеры',
      fields: {
        affiliate_email: {
          title: 'Партнер',
        },
        latest_payment_amount: {
          title: 'Сумма',
        },
        latest_payment_at: {
          title: 'Последняя выплата',
        },
        last_7_days_revenue: {
          title: 'Заработок за 7 дней',
        },
        last_14_days_revenue: {
          title: 'Заработок за 14 дней',
        },
        last_30_days_revenue: {
          title: 'Заработок за 30 дней',
        },
      },
    },
    history: {
      fields: {
        incrementing_id: {
          title: 'ID',
        },
        affiliate_email: {
          title: 'Партнер',
        },
        amount: {
          title: 'Сумма',
        },
        team_name: {
          title: 'Команда',
        },
        available_balance: {
          title: 'Баланс',
        },
        hold_balance: {
          title: 'Холд',
        },
        payment_period_end: {
          title: 'Период',
          tooltip: 'smth',
        },
        created_at: {
          title: 'Создан',
        },
        site_payment_method_name: {
          title: 'Способ',
        },
        payment_wallet: {
          title: 'Кошелек',
        },
        approved_by_admin_name: {
          title: 'Одобрен',
        },
      },
      notPaid: 'Не оплачено',
      paidAt: 'Оплачено',
      back: 'Назад',
      info: 'Информация',
      toReview: 'На рассмотрение',
      decline: 'Отклонить',
      affiliate: 'Партнера',
      balance: 'Баланс',
      hold: 'Холд',
      period: 'Период',
      method: 'Способ',
      approvedBy: 'Одобрен',
      actions: 'Действия',
      periodTooltip: 'Период tooltip',
      approvedTooltip: 'Одобрен tooltip',
      payments_dashboard: 'Панель выплат',
    },
    info: {
      players_selected: 'Выбрано 0 пользователей | Выбран {count} пользователь | Выбрано {count} пользователя | Выбрано {count} пользователей',
      payment_info: 'Информация о выплатах',
      payment_for: 'Выплата за',
      decline: 'Отклонить',
      approve: 'Одобрить',
      paidManually: 'Оплачено вручную',
      to_review: 'На рассмотрение',
      reworked: 'Переработано',
      created_at: 'Создан',
      affiliate_email: 'Партнер',
      approved_by: 'Одобрен',
      team: 'Команда',
      introduced_by: 'Приглашен',
      amount: 'Сумма',
      hold_balance: 'Баланс на удержании',
      available_balance: 'Доступный баланс',
      payment_method: 'Способ оплаты',
      wallet_number: 'Номер кошелька',
      payment_period: 'Период оплаты',
      statistics: 'Статистика',
      players: 'Игроки по CPA',
      hide_conversion: 'Скрыть статистику после конверсии',
      for_network: 'для сети',
      cancel: 'Отмена',
      charge: 'Списание',
      history: 'История',
      rev_share: 'RevShare',
      offer: 'Оффер',
      cpa: 'CPA',
      table_players: 'Игроки',
      am_balance: 'Сумма & Баланс',
      in_hold: 'На удержании',
      available: 'Доступно',
      period: 'Период',
      information: 'Информация',
      add_information: 'Дополнительная Информация',
      actions: 'Действия',
      to: 'Кому:',
      approve_tooltip: 'Необходим отчет',
      approve_wallet: 'Метод не задан',
    },
    uploadFilesPopup: {
      title: 'Добавить файл',
      label: 'Загрузка файла',
      'drop-file-here-or': 'Перетащите файл сюда или',
      'click-to-upload': 'кликните для загрузки файла.',
      cancel: 'Отменить',
      save: 'Сохранить',
    },
    noty: {
      req_fail: 'Запрос не выполнен {statusCode}',
      charge_soon: 'Списание будет создано в ближайшее время',
    },
    confirm: {
      title_1: 'Одобрить платеж?',
      msg_1: 'Вы уверены, что хотите одобрить платеж <span>{affiliateEmail}</span> ?',
      action_1: 'Одобрить',

      title_2: 'Отклонить платеж?',
      msg_2: 'Вы уверены, что хотите отклонить платеж <span>{affiliateEmail}</span> ?',
      action_2: 'Отклонить',

      title_3: 'Вернуть платеж на рассмотрение?',
      msg_3: 'Вы уверены, что хотите вернуть данный платеж на рассмотрение?',
      action_3: 'На рассмотрение',

      title_4: 'Отклонить платеж?',
      msg_4: 'Вы уверены, что хотите отклонить платеж?',
      action_4: 'Отклонить',

      title_5: 'Сделать списание за игроков?',
      msg_5: 'Вы уверены, что хотите сделать списание {id} по выбранным игрокам?',
      action_5: 'Списать',

      title_6: 'Одобрить и оплатить вручную?',
      msg_6: 'Вы уверены, что хотите одобрить и оплатить вручную <span>{affiliateEmail}</span> ?',
      action_6: 'Одобрить',

      title_7: 'Вернуться в «Не оплачено»?',
      msg_7: 'Вы уверены, что закончили вносить изменения в платежный запрос?',
      action_7: 'Да',

      decline: 'Отклонить',
    },
    route: {
      history: 'История',
      not_paid: 'Не оплачено',
      payments: 'Выплаты',
      review: 'На расмотрении',
      affiliate: 'Партнеры',
    },
    review: {
      title: 'Выплаты на рассмотрении',

      fields: {
        incrementing_id: {
          title: 'ID',
        },
        affiliate_email: {
          title: 'Email партнера',
        },
        team_name: {
          title: 'Команда',
        },
        amount: {
          title: 'Сумма',
        },
        available_balance: {
          title: 'Баланс',
        },
        hold_balance: {
          title: 'Холд',
        },
        payment_period_end: {
          title: 'Период',
          tooltip: 'Период tooltip',
        },
        site_payment_method_name: {
          title: 'Способ',
        },
        payment_wallet: {
          title: 'Кошелек',
        },
        created_at: {
          title: 'Создан',
        },
        affiliate_referred_by_info: {
          title: 'Приглашен',
        },
      },
    },
    moderation: {
      title: 'Модерация',
      on_review: 'На рассмотрении',
      approved: 'Одобрено',
      declined: 'Отклонено',
    },
    payout: {
      title: 'Проведение',
      not_paid: 'Не выплачено',
      processing: 'В обработке',
      paid: 'Выплачено',
      failed: 'Неудачно',
      for_rework: 'На доработке',
    },

    players: {
      fields: {
        site_player_id: {
          title: 'ID игрока',
        },
        external_id: {
          title: 'Внешний ID',
        },
        registration_timestamp: {
          title: 'Дата регистрации',
        },
        site_registration_type_name: {
          title: 'Тип регистрации',
        },
        latest_activity: {
          title: 'Последняя активность',
        },
        latest_activity_timestamp: {
          title: 'Последняя активность',
        },
        latest_deposit_timestamp: {
          title: 'Последний депозит',
        },
        chargebacks_count: {
          title: 'Количество списаний',
          tip: 'Количество',
        },
        chargebacks_sum: {
          title: 'Сумма списания',
          tip: 'Сумма',
        },
        first_deposit_timestamp: {
          title: 'Дата первого депозита',
        },
        conversion_timestamp: {
          title: 'Дата конверсии',
        },
        deposits_count: {
          title: 'Количество депозитов',
          tip: 'Количество',
        },
        deposits_sum: {
          title: 'Сумма депозитов',
          tip: 'Сумма',
        },
        withdrawals_count: {
          title: 'Количество выводов',
          tip: 'Количество',
        },
        withdrawals_sum: {
          title: 'Сумма выводов',
          tip: 'Сумма',
        },
        betting_profit: {
          title: 'Профит по ставкам',
        },
        cpa_revenue: {
          title: 'Заработок по CPA',
        },
        revshare_revenue: {
          title: 'Заработок по RS',
        },
        revenue: {
          title: 'Заработок',
        },
        subid1: {
          title: 'SubID 1',
        },
        subid2: {
          title: 'SubID 2',
        },
        subid3: {
          title: 'SubID 3',
        },
        subid4: {
          title: 'SubID 4',
        },
        subid5: {
          title: 'SubID 5',
        },
        total_profit: {
          title: 'Всего',
        },
        player_country_name: {
          title: 'Страна',
        },
        top_deposits_payment_system_name: {
          title: 'Топ ПС депозиты',
        },
        top_withdrawals_payment_system_name: {
          title: 'Топ ПС выводы',
        },
        sport_bets_count: {
          title: 'Количество ставок на спрот',
          tip: 'Количество',
        },
        sport_bets_sum: {
          title: 'Сумма ставок на спорт',
          tip: 'Сумма',
        },
        avg_sport_bets_odd: {
          title: 'Средний коэффициент ставок на спорт',
          tip: 'Средний коэффициент',
        },
        games_bets_count: {
          title: 'Количество ставок на казино',
          tip: 'Количество',
        },
        games_bets_sum: {
          title: 'Сумма ставок на казино',
          tip: 'Сумма',
        },
        days_since_reg: {
          title: 'Дней с регистрации',
        },
        days_active: {
          title: 'Количество активных дней',
        },
      },
    },
  },
};

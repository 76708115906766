export default {
  data() {
    return {
      fillingData: false,
    };
  },

  watch: {
    filters: {
      deep: true,
      handler(value) {
        this.$ls.set(`${this.$options.name}/params/filters`, JSON.stringify(value));
        this.addToHistory();
      },
    },

    limit(value) {
      this.$ls.set(`${this.$options.name}/params/limit`, value);
      this.addToHistory();
    },

    page() {
      this.addToHistory();
    },

    search() {
      this.addToHistory();
    },

    sortProp(value) {
      this.$ls.set(`${this.$options.name}/params/sort_column`, value);
      this.addToHistory();
    },

    sortOrder(value) {
      this.$ls.set(`${this.$options.name}/params/sort_dir`, value);
      this.addToHistory();
    },

    $route() {
      if (!this.fillingData) {
        this.fillData();
        this.$nextTick(this.fetch);
      }
    },
  },

  async created() {
    this.fillData();
    this.$nextTick(this.fetch);
  },

  methods: {
    addToHistory() {
      const query = this.generateQuery();
      const queryArray = Object.keys(query).reduce((acc, paramName) => {
        const paramValue = query[paramName];
        acc.push(`${paramName}=${paramValue}`);

        return acc;
      }, []);

      window.history.replaceState({}, null, `${window.location.origin}${window.location.pathname}?${queryArray.join('&')}`);
    },

    generateQuery() {
      const params = {};

      if (this.limit) {
        params.limit = this.limit;
      }

      if (this.page) {
        params.page = this.page;
      }

      if (this.search) {
        params.search = this.search;
      }

      if (this.sortProp) {
        params.sort_column = this.sortProp;
      }

      if (this.sortOrder) {
        params.sort_dir = this.sortOrder;
      }

      if (Object.keys(this.filters).length) {
        params.filters = JSON.stringify(this.filters);
      }

      return params;
    },

    fillData() {
      const { query } = this.$router.currentRoute;
      this.fillingData = true;

      const savedQuery = {
        filters: this.$ls.get(`${this.$options.name}/params/filters`) || {},
        limit: this.$ls.get(`${this.$options.name}/params/limit`) || undefined,
        sort_column: this.$ls.get(`${this.$options.name}/params/sort_column`) || undefined,
        sort_dir: this.$ls.get(`${this.$options.name}/params/sort_dir`) || undefined,
      };

      const mergedQuery = { ...savedQuery, ...query };

      if (mergedQuery.limit) {
        this.limit = Number(mergedQuery.limit);
      }

      if (mergedQuery.page) {
        this.page = Number(mergedQuery.page);
      }

      if (mergedQuery.search) {
        this.search = mergedQuery.search;
      }

      if (mergedQuery.sort_column) {
        this.sortProp = mergedQuery.sort_column;
      }

      if (mergedQuery.sort_dir) {
        this.sortOrder = mergedQuery.sort_dir;
      }

      let filters = {};

      if (mergedQuery.filters) {
        filters = typeof mergedQuery.filters === 'object' ? mergedQuery.filters : JSON.parse(mergedQuery.filters);
      }

      this.filters = Object.assign({}, filters);

      this.fillingData = false;
    },
  },
};

// payments views
import Payments from '@/views/Payments.vue';

import PaymentsDashboard from '@/views/Payments/PaymentsDashboard.vue';
import PaymentsReview from '@/views/Payments/PaymentsReview.vue';
import PaymentsHistory from '@/views/Payments/PaymentsHistory.vue';
import PaymentInfo from '@/views/Payments/PaymentInfo.vue';

export default [
  {
    path: '/payments',
    component: Payments,
    meta: {
      requiresAuth: true,
      view: 'payments',
    },
    children: [
      {
        path: 'dashboard',
        component: PaymentsDashboard,
        name: 'payments-dashboard',
        meta: {
          view: 'dashboard',
        },
      },
      {
        path: 'moderation/:status',
        component: PaymentsReview,
        name: 'payments_moderation',
        meta: {
          view: 'moderation',
        },
      },
      {
        path: 'payout/:status',
        component: PaymentsHistory,
        name: 'payments_payout',
        meta: {
          view: 'payout',
        },
      },
      {
        path: 'info/:id',
        component: PaymentInfo,
        name: 'payments-info',
      },
      {
        path: '',
        redirect: 'dashboard',
      },
      {
        path: '*',
        redirect: 'dashboard',
      },
    ],
  },
];

<template>
  <div class="the-pagination">
    <el-select
      v-if="showSizeSelect"
      v-model="currentPageSize"
      placeholder="Page Size"
      class="size-select"
      size="medium"
    >
      <template slot="prefix">
        <ui-icon
          :size="12"
          color="#7d7d7d"
          lib="fa"
          substyle="fas"
          name="bars"
          class="icon-rows"
        />
      </template>
      <el-option
        v-for="item in pageSizes"
        :key="item"
        :label="item"
        :value="item"
      />
    </el-select>
    <!-- <ui-select
      v-model="currentPageSize"
      :options="pageSizes"/> -->
    <ui-icon
      :class="{ disabled : currentPage <= 1 }"
      :size="16"
      name="angle-double-left"
      lib="fa"
      substyle="far"
      color="var(--btn-color-main)"
      class="btn first"
      @click.native="prevPage"
    />
    <ui-icon
      :class="{ disabled : currentPage <= 1 }"
      :size="16"
      name="chevron-left"
      lib="fa"
      substyle="far"
      color="var(--btn-color-main)"
      class="btn prev"
      @click.native="currentPage > 1 ? currentPage -= 1 : ''"
    />
    <span class="label-info">{{ $t('reports.payments.page') }}</span>
    <ui-input
      ref="input"
      v-model="currentPageInp"
      size="medium"
      type="text"
      class="page-input"
      autosize
    />
    <span class="label-info">{{ $t('reports.payments.page_of') }} {{ totalPages === 0 ? 1 : totalPages }}</span>
    <ui-icon
      :class="{ disabled : currentPage >= totalPages }"
      :size="16"
      name="chevron-right"
      lib="fa"
      substyle="far"
      color="var(--btn-color-main)"
      class="btn next"
      @click.native="nextPage"
    />
    <ui-icon
      :class="{ disabled : currentPage >= totalPages }"
      :size="16"
      name="angle-double-right"
      lib="fa"
      substyle="far"
      color="var(--btn-color-main)"
      class="btn last"
      @click.native="currentPage < totalPages ? currentPage = totalPages : ''"
    />
  </div>
</template>

<script>
export default {
  name: 'ThePagination',
  props: {
    page: {
      type: Number,
      required: true,
    },
    pageSize: {
      type: Number,
      required: true,
    },
    count: {
      type: [String, Number],
      required: true,
    },
    pageSizes: {
      type: Array,
      default() {
        return [
          10,
          20,
          30,
          50,
          100,
          250,
        ];
      },
    },
    showSizeSelect: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentPage: this.page,
      currentPageInp: this.page,
      currentPageSize: this.pageSize,
    };
  },
  computed: {
    totalPages() {
      return Math.ceil(this.count / this.pageSize);
    },
  },
  watch: {
    count() {
      if (parseInt(this.currentPage, 10) > this.totalPages) {
        this.currentPage = 1;
      }
    },
    page(nv) {
      if (nv !== this.currentPage) {
        this.currentPage = nv;
      }
    },
    pageSize(nv) {
      this.currentPageSize = nv;
    },
    currentPage(nv) {
      if (!nv || nv < 1 || nv > this.totalPages) {
        this.currentPage = 1;
      }
      this.currentPageInp = this.currentPage.toString();
      this.$emit('page-change', this.currentPage, this.currentPageSize);
    },
    currentPageInp(nv) {
      const v = parseInt(nv.replace(/[^0-9]/g, ''), 10);
      if (v !== this.currentPage) {
        this.currentPage = v;
      }
    },
    currentPageSize(nv) {
      const v = parseInt(nv, 10);
      this.currentPageSize = v;
      if (Math.ceil(this.count / this.currentPageSize) < this.currentPage) {
        this.currentPage = 1;
        return;
      }
      this.$emit('page-change', this.currentPage, this.currentPageSize);
    },
  },
  methods: {
    nextPage() {
      return this.currentPage < this.totalPages ? this.currentPage += 1 : '';
    },
    prevPage() {
      return this.currentPage > 1 ? this.currentPage = 1 : '';
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/colors.scss";

.the-pagination{
  display: flex;
  align-items: center;

  .btn{
    cursor: pointer;
    &.disabled{
      filter: grayscale(100%);
      opacity: .5;
      cursor: not-allowed;
    }
    &.prev{
      margin: 0 10px;
    }
    &.next{
      margin: 0 0 0 10px;
    }
    &.last{
      margin: 0 0 0 10px;
    }
  }
  .size-select{
    width: 80px;
    margin-right: 12px;
  }
  .page-input{
    width: 38px !important;
    height: 30px;
    margin: 0 4px;
  }
  .label-info{
    font-size: 14px;
    white-space: nowrap;
  }

}
</style>

<style lang="scss">
.the-pagination{
  .el-input{
    .el-input__inner{
      padding-left: 20px;
      text-align: center;
      font-size: 12px;
    }
    .el-input__prefix{
      display: flex;
      align-items: center;
      justify-content: center;
      padding-left: 2px;
    }

    &--medium .el-input__inner {
      height: 30px;
      line-height: 30px;
    }
  }
  .page-input{
    .input-element{
      text-align: center;
      padding: 0 6px !important;
    }
  }
  .el-input__suffix-inner{
    display: flex;
    height: 32px;
  }
  .el-select .el-input .el-select__caret{
    color: #7d7d7d;
    font-family: "Font Awesome 6 Pro" !important;
    &::before{
      position: relative;
      top: -1px;
      content: "\f106";
    }
    &.is-reverse::before{
      top: -3px;
    }
  }
}
</style>

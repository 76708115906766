<template>
  <div class="affiliate-payments">
    <div class="toolbar">
      <div class="wrapper">
        <div class="section">
          <el-date-picker
            ref="dateRange"
            v-model="dateRange"
            :picker-options="{
              firstDayOfWeek: 1,
              shortcuts: shortcutsDates,
            }"
            :start-placeholder="$t('reports.other.start_date')"
            :end-placeholder="$t('reports.other.end_date')"
            type="daterange"
            range-separator="-"
            format="dd MMM yyyy"
            class="date-picker"
          />
          <el-select
            v-model="filterValue"
            multiple
            collapse-tags
            :placeholder="$t('affiliates.affiliatePayments.statusFilter.placeholder')"
            class="custom-filter"
            popper-class="custom-filter-popper"
            @change="handleStatusChange()"
          >
            <el-option-group
              v-for="group in options"
              :key="group.label"
              :label="$t(`affiliates.affiliatePayments.statusFilter.${group.label}`)"
            >
              <el-option
                v-for="item in group.options"
                :key="item.value"
                :label="$t(`affiliates.affiliatePayments.statusFilter.${item.label}`)"
                :value="item.value"
                class="filter-item"
              />
            </el-option-group>
          </el-select>
        </div>
        <div class="section">
          <div class="pagination">
            <ui-pagination
              :page="page"
              :page-size="limit"
              :count="count"
              show-size-select
              @page-change="pageChange"
            />
          </div>
        </div>
      </div>
    </div>
    <div
      ref="wrapper"
      class="table-wrapper"
    >
      <ui-table
        v-loading="isDataLoading"
        :fields="fields"
        :data="data"
        :rows-count="limit"
        :sort="{
          prop: sortProp,
          order: sortOrder,
        }"
        lazy-loading

        i18n-path="affiliates.payments"
        element-loading-custom-class="data-loader-spinner"
        @sort="handleSort"
      >
        <template
          slot="append"
        >
          <el-table-column
            :width="44"
            align="right"
            header-align="right"
          >
            <template slot-scope="scope">
              <ActionIcon
                :tooltip="$t('affiliates.affiliatePayments.info')"
              >
                <router-link
                  :to="`/payments/info/${scope.row.id}`"
                  class="action-ico"
                >
                  <ui-icon
                    name="eye"
                    :color="$theme.accentColor"
                    lib="fa"
                  />
                </router-link>
              </ActionIcon>
            </template>
          </el-table-column>
        </template>
      </ui-table>
    </div>
  </div>
</template>

<script>
import { NAV_SET_SUB_VIEW } from '@/store/action_types/nav';
import { pageSizeMixin, resolvePageSize } from '@/service/pageSize';
import updateUrl from '../../../service/updateUrl';

const sizes = {
  1080: 20,
  1440: 25,
};

const viewName = 'affiliate/affiliate_payments/limit'; // для локалсторедж
const pageSize = resolvePageSize(viewName, {
  _default: 15,
  sizes,
});
export default {
  name: 'AffiliatePayments',
  mixins: [pageSizeMixin],
  data() {
    return {
      filterValue: ['on_review', 'approved', 'declined', 'not_paid', 'for_rework', 'processing', 'paid', 'failed'],
      options: [{
        label: 'moderation',
        options: [{
          value: 'on_review',
          label: 'on_review',
        }, {
          value: 'approved',
          label: 'approved',
        }, {
          value: 'declined',
          label: 'declined',
        }],
      }, {
        label: 'payout',
        options: [{
          value: 'not_paid',
          label: 'not_paid',
        }, {
          value: 'for_rework',
          label: 'for_rework',
        }, {
          value: 'processing',
          label: 'processing',
        }, {
          value: 'paid',
          label: 'paid',
        }, {
          value: 'failed',
          label: 'failed',
        }],
      }],


      viewName,
      sortProp: 'incrementing_id',
      sortOrder: 'desc',
      id: '',
      info: {},
      count: 0,
      page: 1,
      moderation_status: ['on_review', 'approved', 'declined'],
      payout_status: ['not_paid', 'for_rework', 'processing', 'paid', 'failed'],
      limit: pageSize,
      dateRange: [],
      shortcutsDates: [
        {
          text: this.$t('reports.date_picker.last_30_day'),
          range: [
            this.$moment()
              .startOf('day')
              .subtract(30, 'd'),
            this.$moment().endOf('day'),
          ],
          onClick(picker) {
            picker.$emit('pick', this.range);
          },
        },
        {
          text: this.$t('reports.date_picker.this_month'),
          range: [this.$moment().startOf('month'), this.$moment().endOf('day')],
          onClick(picker) {
            picker.$emit('pick', this.range);
          },
        },
        {
          text: this.$t('reports.date_picker.prev_month'),
          range: [
            this.$moment()
              .subtract(1, 'months')
              .startOf('month'),
            this.$moment()
              .subtract(1, 'months')
              .endOf('month'),
          ],
          onClick(picker) {
            picker.$emit('pick', this.range);
          },
        },
        {
          text: this.$t('reports.date_picker.last_90_day'),
          range: [
            this.$moment()
              .startOf('day')
              .subtract(90, 'd'),
            this.$moment().endOf('day'),
          ],
          onClick(picker) {
            picker.$emit('pick', this.range);
          },
        },
        {
          text: this.$t('reports.date_picker.last_year'),
          range: [
            this.$moment()
              .startOf('day')
              .subtract(1, 'y'),
            this.$moment().endOf('day'),
          ],
          onClick(picker) {
            picker.$emit('pick', this.range);
          },
        },
      ],
      dataQuery: {
        sort_column: 'incrementing_id',
        sort_dir: 'desc',
        moderation_status: ['on_review', 'approved', 'declined'],
        payout_status: ['not_paid', 'for_rework', 'processing', 'paid', 'failed'],
      },
      isDataLoading: false,
      fields: [
        {
          name: 'incrementing_id',
          align: 'left',
          sortable: 'custom',
          width: '70',
        },
        {
          name: 'created_at',
          align: 'left',
          width: '130',
          format: 'format-date',
          sortable: 'custom',
        },
        {
          name: 'amount',
          align: 'right',
          minWidth: '92',
          class: 'bold',
          format: 'formatMoney',
          computedClass: this.getCurrencyClass,
          sortable: 'custom',
        },
        {
          name: 'moderation_status',
          align: 'left',
          minWidth: '100',
          computedValue: this.formatStatus,
          unsortable: true,
          sortable: false,
        },
        {
          name: 'payout_status',
          align: 'left',
          minWidth: '100',
          computedValue: this.formatStatus,
          unsortable: true,
          sortable: false,
        },
        {
          name: 'payment_period_end',
          align: 'left',
          width: '170',
          computedValue: this.computePaymentPeriod,
          sortable: false,
        },
        {
          name: 'approved_by_admin_name',
          align: 'left',
          minWidth: '140',
          overflow: true,
          sortable: false,
        },
        {
          name: 'site_payment_method_name',
          align: 'center',
          headerAlign: 'center',
          width: '120',
          minWidth: '120',
          class: 'text',
          computedImg: row => row.site_payment_method_logo_base64,
          computedImgNull: (_, row) => row.site_payment_method_name || this.$t('affiliates.affiliatesInfo.not_specefied'),
        },
        {
          name: 'payment_wallet',
          align: 'left',
          headerAlign: 'center',
          width: '120',
          minWidth: '120',
          actionLib: 'fa',
          controlType: 'icon',
          computedClass: (_, row) => (row?.payment_wallet ? 'copy-reverse' : 'ui-jc-center-i cursor-default'),
          action: () => {},
          controlColorIco: () => this.$theme.accentColor,
          actionName: row => (row.payment_wallet ? 'clone' : ''),
          computedValueHtml: row => row.payment_wallet || this.$t('affiliates.affiliatesInfo.not_specefied'),
          click: this.copyWallet,
        },
        {
          name: 'paid_at',
          format: 'date-time',
          width: '134',
          align: 'left',
          headerAlign: 'left',
          sortable: 'custom',
        },
      ],
      actions: {
        align: 'center',
        operations: {
          accept: {
            class: 'act blue',
            callback: this.infoRedirect,
            callbackOptions: {},
          },
        },
      },

      data: [],
      totals: {},
      defaultSort: {},
      getPayments: Function,

    };
  },
  computed: {
    isInfoEmpty() {
      return this.$_.isEmpty(this.info);
    },
  },
  created() {
    /*
    * Считываем hash
    * */
    const _paramsHash = updateUrl.getParseParams(this.$route.hash);

    if (!this.$_.isEmpty(_paramsHash)) {
      if (this.$moment(this.$_.get(_paramsHash, ['dateStart'])).isValid() && this.$moment(this.$_.get(_paramsHash, ['dateEnd'])).isValid()) {
        this.dateRange[0] = this.$_.get(_paramsHash, ['dateStart']);
        this.dateRange[1] = this.$_.get(_paramsHash, ['dateEnd']);
      }
      this.dataQuery.sort_column = this.$_.get(_paramsHash, ['dataQuery', 'sort_column']) || 'incrementing_id';
      this.dataQuery.sort_dir = this.$_.get(_paramsHash, ['dataQuery', 'sort_dir']) || 'desc';
      this.dataQuery.moderation_status = this.$_.get(_paramsHash, ['dataQuery', 'moderation_status']) || this.dataQuery.moderation_status;
      this.dataQuery.payout_status = this.$_.get(_paramsHash, ['dataQuery', 'payout_status']) || this.dataQuery.payout_status;

      this.limit = Number(this.$_.get(_paramsHash, ['limit'])) || pageSize;
      this.page = Number(this.$_.get(_paramsHash, ['page'])) || 1;
    } else {
      this.dateRange = this.shortcutsDates[4].range;

      this.dataQuery.sort_column = this.$ls.get('affiliate/payments/sort_column') || 'incrementing_id';
      this.dataQuery.sort_dir = this.$ls.get('affiliate/payments/sort_dir') || 'desc';
      this.sortProp = this.$ls.get('affiliate/payments/sort_column') || 'incrementing_id';
      this.sortOrder = this.$ls.get('affiliate/payments/sort_dir') || 'desc';

      this.limit = +this.$ls.get('affiliate/affiliate_payments/limit') || pageSize;
    }

    this.getPayments = this.$_.debounce(this.getPaymentsD, 500);
    this.defaultSort = {
      prop: this.dataQuery.sort_column,
      order: `${this.dataQuery.sort_dir}ending`,
    };
    this.$watch(
      'dateRange',
      (range) => {
        if (!range) {
          this.dateRange = this.shortcutsDates[4].range;
        }
        this.getPayments();
      },
    );
    this.getPayments();
  },
  mounted() {
    this.id = this.$route.params.id;
    if (!this.id) {
      this.$router.push('/affiliates/list');
    }
    this.$api
      .getAffiliateInfo(this.id)
      .then((response) => {
        this.info = this.$_.cloneDeepWith(response.data.payload);
        if (this.info.account_status === 'pending') {
          this.$store.commit(`nav/${NAV_SET_SUB_VIEW}`, 'review');
        } else {
          this.$store.commit(`nav/${NAV_SET_SUB_VIEW}`, 'list');
        }
      });
  },

  methods: {
    async copyWallet(row) {
      if (!row.payment_wallet) return;

      try {
        await this.$copyText(row.payment_wallet);
        this.$noty.info(`${this.$t('reports.other.copied_to_clipboard')}`);
      } catch (error) {
        this.$noty.error(`${this.$t('reports.other.failed_to_copy')}`);
      }
    },
    handleStatusChange() {
      this.dataQuery.moderation_status = [];
      this.dataQuery.payout_status = [];
      this.filterValue.forEach((item) => {
        if (this.moderation_status.includes(item)) {
          this.dataQuery.moderation_status.push(item);
        } else {
          this.dataQuery.payout_status.push(item);
        }
      });
      this.getPayments();
    },
    infoRedirect(row) {
      this.$router.push(`/payments/info/${row.id}`);
    },
    getPaymentsD() {
      this.isDataLoading = true;
      const q = {
        affiliate_id: this.id,
        limit: this.limit,
        offset: (this.limit * this.page) - this.limit,
        created_at_from: this.dateRange[0]
          ? this.$moment(this.dateRange[0])
            .startOf('day')
            .format('YYYY-MM-DD HH:mm:ss')
          : '',
        created_at_to: this.dateRange[1]
          ? this.$moment(this.dateRange[1])
            .endOf('day')
            .format('YYYY-MM-DD HH:mm:ss')
          : '',
        ...this.dataQuery,
      };
      /*
      * Hash function
      * */
      this._completedFilterUrl();

      this.$api
        .getPaymentsHistory(q)
        .then((response) => {
          this.count = response.data.misc.count;
          this.data = this.$_.cloneDeep(response.data.payload.data);
          this.totals = this.$_.clone(response.data.payload.totals);
        })
        .finally(() => {
          this.isDataLoading = false;
        });
    },

    pageChange(page, size) {
      this.page = page;
      this.limit = size;
      this.getPayments();
    },

    formatDate(value) {
      const m = this.$moment(value);
      return m.isValid() ? m.format('DD MMM YYYY') : '-';
    },
    formatStatus(row, value) {
      return this.$t(`affiliates.affiliatePayments.statusFilter.${value}`);
    },
    computePaymentPeriod(row) {
      return `${this.formatDate(row.payment_period_start)} - ${this.formatDate(row.payment_period_end)}`;
    },
    getCurrencyClass(value) {
      return value < 0 ? 'negative' : '';
    },
    handleSort({ prop, order }) {
      if (order === '') return;
      if (this.dataQuery.sort_column !== prop) {
        this.sortProp = prop;
      } else {
        this.sortOrder = order;
      }

      this.dataQuery.sort_column = this.sortProp;
      this.dataQuery.sort_dir = this.sortOrder;
      this.$ls.set(
        'affiliate/payments/sort_column',
        this.dataQuery.sort_column,
      );
      this.$ls.set(
        'affiliate/payments/sort_dir',
        this.dataQuery.sort_dir,
      );
      this.getPayments();
    },
    /*
     * Создаем filter URL
     * */
    _completedFilterUrl() {
      const _dataFilters = {
        dateStart: this.$moment(this.dateRange[0]).format('YYYY-MM-DD'),
        dateEnd: this.$moment(this.dateRange[1]).format('YYYY-MM-DD'),
        dataQuery: this.dataQuery,
        limit: this.limit,
        page: this.page,
      };
      updateUrl.updateGetParams(_dataFilters);
    },
  },
};
</script>

export default {
  getSummaryReport(params) {
    return this.affiliatesApi.get('/payments-history/summary-report', { params });
  },
  getOutsidersReport(params) {
    return this.affiliatesApi.get('/payments-history/outsiders-report', { params });
  },
  getPaymentsHistory(params) {
    return this.affiliatesApi.get('/payments-history', { params });
  },
  getPaymentsHistoryCount(params) {
    return this.affiliatesApi.get('/payments-history/count', { params });
  },
  getPaymentInfo(id) {
    return this.affiliatesApi.get(`/payments-history/${id}`);
  },
  postPaymentInfoFiles(id, data) {
    return this.affiliatesApi.post(`/payments-history/${id}/files`, {
      file_ids: data,
    });
  },
  getPaymentInfoPlayersCount(id, params) {
    return this.affiliatesApi.get(`/payments-history/${id}/cpa-players/count`, { params });
  },
  getPaymentInfoPlayers(id, params) {
    return this.affiliatesApi.get(`/payments-history/${id}/cpa-players`, { params });
  },
  approvePayment(id) {
    return this.affiliatesApi.post(`/payments-history/${id}/approve`);
  },
  manualPayout(id) {
    return this.affiliatesApi.post(`/payments-history/${id}/manual-payout`);
  },
  declinePayment(id) {
    return this.affiliatesApi.post(`/payments-history/${id}/reject`);
  },
  reviewPayment(id) {
    return this.affiliatesApi.post(`/payments-history/${id}/return-to-approve`);
  },
  reworkPayment(id) {
    return this.affiliatesApi.post(`/payments-history/${id}/to-rework`);
  },
  reworkedPayment(id) {
    return this.affiliatesApi.post(`/payments-history/${id}/reworked`);
  },
  chargePaymentCpaRevenue(id, playerId) {
    return this.affiliatesApi.post(`/payments-history/${id}/charge-cpa-revenue`, {
      player_id: playerId,
    });
  },
  massChargePaymentCpaRevenue(id, playerId) {
    return this.affiliatesApi.post(`/payments-history/${id}/mass-charge-cpa-revenue`, {
      player_id: playerId,
    });
  },
};

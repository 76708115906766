<template>
  <div
    class="media-buying-list"
  >
    <ui-confirm
      ref="confirm"
      :width="480"
      :type="confirmType"
      :action-name="actionName"
      :action="confirmAction"
      :action-icon="actionIcon"
      :action-fa-icon="actionFaIcon"
      @save="onConfirm"
      @close="confirmClose"
    >
      <div
        slot="title"
        v-html="confirmTitle"
      />
      <div class="body__wrap">
        <i
          :class="confirmType"
          class="fas fa-ban"
        />
        <div
          class="body__msg"
          v-html="confirmMsg"
        />
      </div>
    </ui-confirm>
    <div
      class="toolbar"
      @keyup.enter="handleRefresh"
    >
      <div class="wrapper">
        <div class="section">
          <span class="title">{{ $t('mediaBuying.page_title.posts.name') }} {{ $t(`mediaBuying.page_title.posts.${currentBuyingPageStatus}`) }}</span>
          <ui-input
            v-model.trim="searchQuery"
            :width="180"
            class="select-label"
            is-search
            @search="handleRefresh"
          />
          <el-checkbox
            v-model="needsAttentionFirst"
            @change="getPosts"
          >
            {{ $t('mediaBuying.other.action_required') }}
          </el-checkbox>
        </div>
        <div class="section">
          <ui-pagination
            :page="page"
            :page-size="limit"
            :count="count"
            show-size-select
            @page-change="pageChange"
          />
        </div>
      </div>
    </div>
    <div
      ref="wrapper"
      class="table-wrapper"
    >
      <ui-table
        ref="dataTable"
        v-loading="isDataLoading"
        :fields="fields"
        :data="tableData"
        :currency="currencyCode"
        :rows-count="limit"
        :sort="{
          prop: sortProp,
          order: sortOrder,
        }"
        :random-value="randomValue"
        lazy-loading
        i18n-path="mediaBuying.list_table_title"
        element-loading-custom-class="data-loader-spinner"
        class="table"
        @sort="handleSort"
        @headerDragend="headerDragend"
        @update="resizeHeaderDragend()"
        @filter="handlerFilter"
      >
        <template slot="append">
          <el-table-column
            :width="calcWidth()"
            :resizable="false"
            fixed="right"
            align="center"
            header-align="center"
          >
            <template slot-scope="scope">
              <ActionIcon
                :tooltip="$t(scope.row.needs_attention
                  ? $t('mediaBuying.other.btn_unmark')
                  : $t('mediaBuying.other.btn_mark'))"
              >
                <span
                  class="action-ico"
                  @click="markAsRead(scope.row)"
                >
                  <ui-icon
                    v-if="scope.row.needs_attention"
                    name="circle-exclamation-check"
                    :color="$theme.accentColor"
                    lib="fa-6"
                    substyle="fa-solid"
                  />
                  <ui-icon
                    v-else
                    name="exclamation-circle"
                    :color="$theme.dangerColor"
                    lib="fa"
                  />
                </span>
              </ActionIcon>
              <ActionIcon
                :tooltip="$t('mediaBuying.actions.info')"
              >
                <router-link
                  :to="handleRowClick(scope.row)"
                  class="action-ico"
                >
                  <ui-icon
                    name="eye"
                    :color="$theme.accentColor"
                    lib="fa"
                  />
                </router-link>
              </ActionIcon>
              <ActionIcon
                :tooltip="$t('mediaBuying.actions.decline')"
              >
                <span
                  v-if="currentBuyingPageStatus === 'on_review' && reviewActions(scope.row)"
                  class="action-ico"
                  @click="declineHandler(scope.row)"
                >
                  <ui-icon
                    name="times"
                    :color="$theme.dangerColor"
                    lib="fa"
                  />
                </span>
              </ActionIcon>
            </template>
          </el-table-column>
        </template>
      </ui-table>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import numeral from 'numeral';
import { pageSizeMixin, resolvePageSize } from '../../service/pageSize';
import updateUrl from '../../service/updateUrl';
import detectPermissions from '../../service/detectPermissions';
import { tableFieldsExMethodsPost as tableFieldsEx } from './tableHelpers/fieldTable';
import dragendColumnMixin from '@/components/UIElements/UiTableHelper/dragendColumnMixin';
import TeamsListMixin from '@/views/MediaBuying/mixins/teams-list-mixin';


const sizes = {
  1080: 20,
  1440: 30,
};

const viewName = 'media-buying/posts/limit'; // для локалсторедж
const pageSize = resolvePageSize(viewName, {
  _default: 15,
  sizes,
});

export default {
  mixins: [TeamsListMixin, pageSizeMixin, dragendColumnMixin],
  data() {
    return {
      needsAttentionFirst: true,
      loadingMark: false,
      confirmType: '',
      actionName: '',
      confirmAction: '',
      actionFaIcon: '',
      actionIcon: '',
      confirmTitle: '',
      confirmMsg: '',
      viewName,
      isDataLoading: false,
      currentBuyingPageStatus: '',
      page: 1,
      limit: pageSize,
      sortProp: '',
      sortOrder: '',
      count: 0,
      searchQuery: '',
      fieldsLocalStorageKey: 'MediaBuying/posts/width_columns',
      tableFieldsEx,
      fields: [
        {
          name: '',
          fixed: 'left',
          align: 'right',
          sortable: false,
          width: '30',
          minWidth: '30',
          linkAppend: this.renderNeedAttentionIcon,
        },
        {
          name: 'incrementing_id',
          fixed: 'left',
          align: 'left',
          sortable: 'custom',
          width: '60',
          minWidth: '60',
        },
        {
          name: 'type',
          align: 'left',
          sortable: false,
          width: '90',
          minWidth: '90',
          computedValue: this.computedType,
        },
        {
          name: 'affiliate_email',
          align: 'left',
          sortable: false,
          width: '180',
          minWidth: '160',
          link: this.getAffiliateLink,
        },
        {
          name: 'team_name',
          width: '120',
          minWidth: '120',
        },
        {
          name: 'campaign_name',
          align: 'left',
          sortable: false,
          minWidth: '180',
          flexible: true,
        },
        {
          name: 'amount',
          align: 'right',
          width: '120',
          minWidth: '120',
          computedValue: this.formatMoney,
        },
        {
          name: 'amount_converted',
          align: 'right',
          width: '120',
          minWidth: '120',
          sortable: 'custom',
          computedValue: this.formatMoneyConverted,
          format: 'formatMoney',
        },
        {
          name: 'period',
          align: 'left',
          width: '250',
          minWidth: '250',
          computedValue: this.computedPeriod,
        },
        {
          name: 'created_at',
          width: '140',
          minWidth: '140',
          align: 'left',
          headerAlign: 'left',
          sortable: 'custom',
          format: 'date-time',
        },
        {
          name: 'updated_at',
          width: '140',
          minWidth: '140',
          align: 'left',
          headerAlign: 'left',
          sortable: 'custom',
          format: 'date-time',
        },
      ],
      tableData: [],
      currencyCode: '',
      permissions: {},
      blockedRequest: false,
      resizeHeaderDragendFlag: true,

      columnsFilter: {},
      createdAtFrom: undefined,
      createdAtTo: undefined,
    };
  },
  computed: {
    ...mapGetters({
      currentAcl: 'auth/currentAcl',
      adminAcl: 'auth/adminAcl',
      currencies: 'misc/currencies',
    }),
    currencyLabel() {
      return this.getCurrencyLabel(this.currencyCode);
    },
  },

  watch: {
    needsAttentionFirst(newVal) {
      this.$ls.set(`MediaBuying/posts/${this.currentBuyingPageStatus}/needs_attention_first`, newVal);
    },

    sortProp(newVal) {
      this.$ls.set(`MediaBuying/posts/${this.currentBuyingPageStatus}/sort_column`, newVal || 'incrementing_id');
    },
    sortOrder(newVal) {
      this.$ls.set(`MediaBuying/posts/${this.currentBuyingPageStatus}/sort_dir`, newVal || 'desc');
    },
    limit(newVal) {
      this.$ls.set(`MediaBuying/posts/${this.currentBuyingPageStatus}/limit`, newVal || pageSize);
    },

    columnsFilter(newVal) {
      this.$ls.set(`MediaBuying/posts/${this.currentBuyingPageStatus}/columns_filter`, newVal || {});
    },

    $route() {
      this.blockedRequest = true;
      this.currentBuyingPageStatus = this.$route.meta.view;
      const _urlData = updateUrl.getParseParams(this.$route.hash);
      this.searchQuery = '';
      this.page = Number(this.$_.get(_urlData, ['page'])) || 1;
      this.needsAttentionFirst = this.$ls.get(`MediaBuying/posts/${this.currentBuyingPageStatus}/needs_attention_first`) !== null
        ? this.$ls.get(`MediaBuying/posts/${this.currentBuyingPageStatus}/needs_attention_first`) : true;
      this.limit = this.$ls.get(`MediaBuying/posts/${this.currentBuyingPageStatus}/limit`) || pageSize;
      this.sortProp = this.$ls.get(`MediaBuying/posts/${this.currentBuyingPageStatus}/sort_column`) || 'incrementing_id';
      this.sortOrder = this.$ls.get(`MediaBuying/posts/${this.currentBuyingPageStatus}/sort_dir`) || 'desc';
      this.columnsFilter = this.$ls.get(`MediaBuying/posts/${this.currentBuyingPageStatus}/columns_filter`) || {};

      this.parseFilter(this.columnsFilter, this.$_.isEmpty(this.columnsFilter) === true ? 'reset' : undefined);

      this.getPosts();
    },
  },
  created() {
    this.blockedRequest = true;
    this.currentBuyingPageStatus = this.$route.meta.view;
    const _urlData = updateUrl.getParseParams(this.$route.hash);
    if (detectPermissions.checkRequest('media_buying_posts_view', 'media_buying_posts_statuses_visible', this.currentBuyingPageStatus)) {
      if (!this.$_.isEmpty(_urlData)) {
        const getUrlNeeds = this.$_.get(_urlData, ['needs_attention_first']);
        this.needsAttentionFirst = getUrlNeeds !== undefined ? JSON.parse(getUrlNeeds) : true;
        this.limit = Number(this.$_.get(_urlData, ['limit'])) || pageSize;
        this.page = Number(this.$_.get(_urlData, ['page'])) || 1;
        this.sortProp = this.$_.get(_urlData, ['sort_column']) || 'incrementing_id';
        this.sortOrder = this.$_.get(_urlData, ['sort_dir']) || 'desc';
        this.searchQuery = this.$_.get(_urlData, ['search']) || null;
        this.columnsFilter = this.$_.get(_urlData, ['columns_filter']) || {};
      } else {
        this.needsAttentionFirst = this.$ls.get(`MediaBuying/posts/${this.currentBuyingPageStatus}/needs_attention_first`) !== null
          ? this.$ls.get(`MediaBuying/posts/${this.currentBuyingPageStatus}/needs_attention_first`) : true;
        this.limit = this.$ls.get(`MediaBuying/posts/${this.currentBuyingPageStatus}/limit`) || pageSize;
        this.sortProp = this.$ls.get(`MediaBuying/posts/${this.currentBuyingPageStatus}/sort_column`) || 'incrementing_id';
        this.sortOrder = this.$ls.get(`MediaBuying/posts/${this.currentBuyingPageStatus}/sort_dir`) || 'desc';
        this.columnsFilter = this.$ls.get(`MediaBuying/posts/${this.currentBuyingPageStatus}/columns_filter`) || {};
      }
      this.parseFilter(this.columnsFilter, this.$_.isEmpty(this.columnsFilter) === true ? 'reset' : undefined);

      this.getPosts();
    }
    detectPermissions.checkRoute('media_buying_posts_view', 'media_buying_posts_statuses_visible', this.currentBuyingPageStatus);
  },
  methods: {
    getCurrencyLabel(code) {
      if (code === undefined) return '';

      const currency = this.currencies.find((el => el.code === code));

      return currency?.symbol || `${code} `;
    },
    async markAsRead(row) {
      this.loadingMark = true;
      if (row.needs_attention) {
        await this.$api.postMediaBuyingPostsMarkAsRead(row.id);
        this.getPosts('silent');
      } else {
        await this.$api.postMediaBuyingPostsMarkAsUnread(row.id);
        this.getPosts('silent');
      }
      this.loadingMark = false;
    },

    handlerFilter(filter, action) {
      this.parseFilter(filter, action);

      this.getPosts();
    },

    parseFilter(filter, action) {
      const columnProp = 'review_requested_at';
      if (action === 'reset') {
        this.createdAtFrom = undefined;
        this.createdAtTo = undefined;
        this.columnsFilter = {};
        return;
      }

      if (filter[columnProp].op === '≥') {
        this.createdAtFrom = this.$moment(filter[columnProp].dateRange).startOf('day').format('YYYY-MM-DD HH:mm:ss');
        this.createdAtTo = undefined;
      } else if (filter[columnProp].op === '≤') {
        this.createdAtFrom = undefined;
        this.createdAtTo = this.$moment(filter[columnProp].dateRange).endOf('day').format('YYYY-MM-DD HH:mm:ss');
      } else {
        this.createdAtFrom = this.$moment(filter[columnProp].dateRange[0]).startOf('day').format('YYYY-MM-DD HH:mm:ss');
        this.createdAtTo = this.$moment(filter[columnProp].dateRange[1]).endOf('day').format('YYYY-MM-DD HH:mm:ss');
      }
      this.columnsFilter = filter;
    },

    calcWidth() {
      const page = this.currentBuyingPageStatus === 'on_review';
      const isUser = !this.adminAcl.is_superuser && page && this.currentAcl.media_buying_posts_review === 'allow';
      const isSuperUser = this.adminAcl.is_superuser && page;
      return isUser || isSuperUser ? 102 : 74;
    },
    reviewActions(row) {
      const isUser = !this.adminAcl.is_superuser && this.permissions && this.permissions[row.id].review_actions;
      const isSuperUser = this.adminAcl.is_superuser;
      return isSuperUser || isUser;
    },
    declineHandler(row) {
      this.confirmAction = 'Decline';
      this.confirmMsg = `${this.$t('mediaBuying.info.popup.decline_post.text')}`;
      this.actionName = `${this.$t('mediaBuying.info.popup.decline_post.button')}`;
      this.confirmTitle = `${this.$t('mediaBuying.info.popup.decline_post.title')}`;
      this.confirmType = 'warning';
      this.actionFaIcon = 'ban';
      this.paymentId = row.id;
      this.$refs.confirm.open();
    },
    onConfirm() {
      switch (this.confirmAction) {
      case 'Decline':
        this.$refs.confirm.close();
        this.changeStatus('decline');
        break;
      default:
        break;
      }
    },
    confirmClose() {
      this.actionIcon = '';
      this.actionFaIcon = '';
    },
    async changeStatus(status) {
      await this.$api.postMediaBuyingPostsChangeStatus(this.paymentId, status);
      await this.getPosts();
    },
    computedType(row) {
      return this.$t(`mediaBuying.types.${row.type}`);
    },
    _query() {
      const _paramsFilter = {
        needs_attention_first: this.needsAttentionFirst,
        status: this.currentBuyingPageStatus,
        sort_column: this.sortProp,
        sort_dir: this.sortOrder,
        search: this.searchQuery,
        limit: this.limit,
        offset: (this.limit * this.page) - this.limit,
        created_at_from: this.createdAtFrom,
        created_at_to: this.createdAtTo,
      };
      return _paramsFilter;
    },
    pageChange(page, size) {
      if (this.blockedRequest) {
        return;
      }
      if (this.page !== page || this.limit !== size) {
        this.page = page;
        this.limit = size;
        this.$nextTick(() => {
          this.getPosts();
        });
      }
    },
    handleSort({ prop, order }) {
      if (this.blockedRequest) {
        return;
      }
      if (order === '') return;
      if (this.sortProp !== prop) {
        this.sortProp = prop;
      } else {
        this.sortOrder = order;
      }
      this.$nextTick(() => {
        this.getPosts();
      });
    },
    handleRefresh() {
      if (this.blockedRequest) {
        return;
      }
      this.page = 1;
      this.$nextTick(() => {
        this.getPosts();
      });
    },

    getPosts(action) {
      if (action !== 'silent') this.isDataLoading = true;
      this.errors = {};
      this._completedFilterUrl();
      const _filter = this._query();
      this.$api.getMediaBuyingPosts(_filter).then((response) => {
        this.tableData = response.data.payload;
        this.currencyCode = response.data.misc.currency_code;
        this.permissions = this.$_.clone(response.data.misc.permissions);
        this.count = response.data.misc.count;
      })
        .catch((error) => {
          this.errors = this.$_.cloneDeepWith(error.data.errors);
        })
        .finally(() => {
          setTimeout(() => {
            this.blockedRequest = false;
          }, 300);
          this.isDataLoading = false;
          this.$refs.dataTable.setColumnFilters(this.columnsFilter);
        });
    },

    _completedFilterUrl() {
      const _dataFilters = {
        needs_attention_first: this.needsAttentionFirst,
        limit: this.limit,
        page: this.page,
        sort_column: this.sortProp,
        sort_dir: this.sortOrder,
        search: this.searchQuery,
        columns_filter: this.columnsFilter,
      };
      updateUrl.updateGetParams(_dataFilters);
    },

    computedPeriod(row) {
      return `${this.formatDate(row.post_period_start)} - ${this.formatDate(row.post_period_end)}`;
    },

    formatDate(value) {
      return this.$moment(value).locale(this.$i18n.locale).format('DD MMM YYYY HH:mm');
    },
    getAffiliateLink(row) {
      const isUser = !this.adminAcl.is_superuser && this.permissions[row.id].view_profile;
      const isSuperUser = this.adminAcl.is_superuser;
      return isUser || isSuperUser ? `/affiliates/${row.affiliate_id}/info` : false;
    },
    handleRowClick(row) {
      return `/media-buying/posts/${row.id}?needs=success`;
    },
    formatMoney(row) {
      const formatValue = numeral(Math.abs(row.amount)).format('0,0.00');
      return `${this.getCurrencyLabel(row.currency_code)}${formatValue}`;
    },

    formatMoneyConverted(row) {
      const formatValue = numeral(Math.abs(row.amount_converted)).format('0,0.00');
      return `${this.currencyLabel}${formatValue}`;
    },
  },
};
</script>

<style lang="scss" scoped>
  .media-buying-list {
    .wrapper {
      width: 90%;
      min-width: 1140px;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }
  }
</style>

<template>
  <div
    :class="[
      isRemoved
        ? `dashboard-overview__metric-table-wrap--draggable dashboard-overview__metric-table-wrap--draggable${rowIndex}`
        : '',
      loading || isLoading ? 'dashboard-metric-table--loading' : '',
      !filterIsEmpty ? 'dashboard-overview__metric-table-wrap--filtered' : '',
    ]"
    class="dashboard-overview__metric-table-wrap dashboard-metric-table"
  >
    <div class="dashboard-metric-table__header">
      <adm-ui-header
        class="dashboard-metric-table__title"
        tag="h3"
        :tooltip="getTranslateMetrics(table.metric_name).description"
      >
        {{ table.name }}
      </adm-ui-header>
      <adm-ui-controls-widget v-if="isRemoved" :data="controlsData" />
    </div>
    <ui-table
      ref="table"
      :select-row="false"
      :fields="fields"
      :data="getTableData"
      :total="getTotals"
      :sort="{
        prop: sortProp,
        order: sortOrder,
      }"
      :min-table-height="280"
      :i18n-path="translateMap"
      class="table"
      :class="[computedTotalClass]"
      show-total
    />
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

export default {
  name: 'DashboardMetricTable',

  props: {
    table: {
      type: Object,
      required: true,
    },

    isRemoved: {
      type: Boolean,
      default: false,
    },

    rowIndex: {
      type: Number,
      default: null,
    },

    index: {
      type: Number,
      default: null,
    },

    isLoading: {
      type: Boolean,
      default: false,
    },

    period: {
      type: Array,
      default() {
        return [];
      },
    },
  },

  data() {
    return {
      tableData: {},
      tableTotal: this.getTotals,

      sortProp: 'sortProp',
      sortOrder: 'asc',
      report: {},
      loading: false,
    };
  },

  computed: {
    ...mapGetters('reports', ['reportsSettings']),
    ...mapState('dashboards', ['stateDashboardFilter']),

    filterIsEmpty() {
      if (this.table.filters === null) return true;
      const { search, filters } = this.table.filters;
      const countKey = Object.keys(this.table.filters).length;
      const func = (count, filter) => {
        const keyArray = Object.keys(filter);
        if (count === 1) {
          return keyArray.includes('filters') || keyArray.includes('search');
        }
        if (count === 2) {
          return keyArray.includes('filters') && keyArray.includes('search');
        }
        return false;
      };
      return (
        this.$_.isEmpty(search)
        && this.$_.isEmpty(filters)
        && func(countKey, this.table.filters)
      );
    },

    computedTotalClass() {
      if (this.report?.totals?.difference_percent) {
        const percent = this.report.totals.difference_percent;
        if (percent === null || percent === 0) return '';
        return percent < 0 ? 'negative-total' : 'positive-total';
      }
      return '';
    },

    translateMap() {
      return 'dashboards.metric_table';
    },

    controlsData() {
      if (!this.isRemoved) return [];
      return [
        {
          title: this.$t('dashboards.other.edit'),
          type: 'primary',
          icon: 'fas fa-pen',
          clickHandler: () => this.editMetric(this.rowIndex, 'table', this.index, this.table),
        },
        {
          title: this.$t('dashboards.other.delete'),
          type: 'danger',
          icon: 'fas fa-trash-alt',
          clickHandler: () => this.removeMetric(this.rowIndex, 'table', this.index),
        },
      ];
    },

    getTableData() {
      const { others, data } = this.$_.cloneDeep(this.report);

      if (others) {
        data.push({
          ...others,
          dimension: this.$t('dashboards.other.others'),
        });
      }

      return data;
    },

    getTotals() {
      if (!this.report?.totals) return {};
      const {
        current = null,
        previous = null,
        difference_percent = null,
      } = this.report.totals;

      return {
        current: current !== null ? current?.formatted : '—',
        previous: previous !== null ? previous?.formatted : '—',
        difference_percent:
          difference_percent !== null ? `${difference_percent}%` : '—',
      };
    },

    fields() {
      return [
        {
          name: 'dimension',
          headerAlign: 'left',
          align: 'left',
        },
        {
          name: 'current',
          align: 'right',
          headerAlign: 'left',
          width: '120',
          computedValue: this.valueFormatter,
        },
        {
          name: 'previous',
          headerAlign: 'left',
          align: 'right',
          width: '140',
          computedValue: this.valueFormatter,
        },
        {
          name: 'difference_percent',
          headerAlign: 'left',
          align: 'right',
          width: '110',
          computedValue: this.percentFormatter,
          computedClass: this.getComputedClass,
        },
      ];
    },

    paramsData() {
      const period = {
        from: this.$moment(this.period[0]).format('YYYY-MM-DD HH:mm:ss'),
        to: this.$moment(this.period[1]).format('YYYY-MM-DD HH:mm:ss'),
      };

      const panel = {
        ...this.table,
        enable_comparison: this.table.enable_comparison || false,
      };

      return { panel, filters: this.stateDashboardFilter, ...period };
    },
  },

  methods: {
    async getReport(type) {
      if (type !== 'getLocalData') {
        await this.getDashboardsStats(this.paramsData);
      } else {
        this.report = this.table.report;
      }

      this.loading = false;
    },

    async getDashboardsStats(params) {
      const {
        data: { payload },
      } = await this.$api.dashboardsStats(params);
      this.report = payload;
      this.$emit(
        'emitDataReport',
        this.rowIndex,
        'table',
        this.index,
        this.report,
      );
    },

    getComputedClass(value) {
      if (value === null || value === 0 || value === undefined) return '';
      return value < 0 ? 'negative' : 'positive';
    },

    valueFormatter(row, col) {
      if (col === undefined) return '—';
      return col?.formatted || col;
    },

    percentFormatter(row, col) {
      if (col === 0) return `${col}%`;
      return col ? `${col}%` : '—';
    },

    getTranslateMetrics(metric) {
      return this.reportsSettings.metrics.find(e => e.column_name === metric);
    },

    removeMetric(rowIndex, type, index) {
      this.$emit('removeMetric', rowIndex, type, index);
    },

    editMetric(rowIndex, type, index, data) {
      this.$emit('editMetric', rowIndex, type, index, data);
    },
  },
};
</script>

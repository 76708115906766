<template>
  <div class="media-buying-list">
    <div class="toolbar">
      <div class="wrapper">
        <div class="section">
          <span class="title">{{ $t('mediaBuying.page_title.media_buying_unreported_funds.name') }}</span>
          <ui-input
            v-model.trim="search"
            class="select-label"
            :width="180"
            :is-search="true"
            @search="getUnreportedFunds"
          />
        </div>
        <div class="section">
          <div class="pagination">
            <ui-pagination
              :page="page"
              :page-size="limit"
              :count="count"
              show-size-select
              @page-change="paginationHandler"
            />
          </div>
        </div>
      </div>
    </div>
    <ui-table
      v-loading="isDataLoading"
      :lazy-loading-delay="4000"
      :fields="fields"
      :currency="currencyCode"
      :data="data"
      :rows-count="limit"
      :sort="{
        prop: sortProp,
        order: sortOrder,
      }"
      lazy-loading
      class="table"
      i18n-path="mediaBuying.list_table_title"
      element-loading-custom-class="data-loader-spinner"
      @sort="sortingHandler"
    >
      <template
        slot="append"
      >
        <el-table-column width="45">
          <template slot-scope="scope">
            <ActionIcon
              :tooltip="$t('mediaBuying.actions.profile')"
            >
              <router-link
                v-if="calcPermissions(scope.row,'view')"
                :to="getAffiliateLink(scope.row)"
                class="action-ico"
              >
                <ui-icon
                  name="user"
                  :color="$theme.accentColor"
                  lib="fa"
                />
              </router-link>
            </ActionIcon>
          </template>
        </el-table-column>
      </template>
    </ui-table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { pageSizeMixin, resolvePageSize } from '../../service/pageSize';
import queryParamsMixin from '../mixins/query-params';

const sizes = {
  1080: 20,
  1440: 30,
};

const viewName = 'media-buying/unreported-funds/limit'; // for localstorage
const pageSize = resolvePageSize(viewName, {
  _default: 15,
  sizes,
});

export default {
  name: 'MediaBuyingUnreportedFunds',

  mixins: [pageSizeMixin, queryParamsMixin],

  data() {
    return {
      isDataLoading: false,

      search: '',
      count: 0,
      page: 1,
      limit: pageSize,
      sortProp: 'affiliate_email',
      sortOrder: 'desc',

      data: [],
      currencyCode: '',
      permissions: {},
      fields: [
        {
          name: 'affiliate_email',
          align: 'left',
          sortable: 'custom',
          link: this.getAffiliateLink,
        },
        {
          name: 'campaigns_without_posts_count',
          align: 'right',
          width: '250',
          sortable: 'custom',
        },
        {
          name: 'unreported_sum',
          align: 'right',
          width: '150',
          sortable: 'custom',
          format: 'formatMoney',
        },
        {
          name: 'reported_sum',
          align: 'right',
          width: '150',
          sortable: 'custom',
          format: 'formatMoney',
        },
      ],
    };
  },

  computed: {
    queryParams() {
      return {
        limit: this.limit,
        offset: (this.page - 1) * this.limit,
        search: this.search || null,
        sort_column: this.sortProp,
        sort_dir: this.sortOrder,
      };
    },
    ...mapGetters({
      currentAcl: 'auth/currentAcl',
      adminAcl: 'auth/adminAcl',
    }),
  },

  methods: {
    fetch() {
      return this.getUnreportedFunds();
    },

    async getUnreportedFunds() {
      this.isDataLoading = true;

      const { data: { payload, misc } } = await this.$api.getUnreportedFunds(this.queryParams);

      this.count = misc.count;
      this.currencyCode = misc.currency_code;
      this.data = payload;
      this.permissions = misc.permissions;

      this.isDataLoading = false;
    },

    getAffiliateLink(row) {
      const isUser = !this.adminAcl.is_superuser && this.permissions[row.affiliate_id].view;
      const isSuperUser = this.adminAcl.is_superuser;
      return isUser || isSuperUser ? `/affiliates/${row.affiliate_id}/info` : false;
    },

    calcPermissions(row, item) {
      return this.permissions[row.affiliate_id][item];
    },

    paginationHandler(page, size) {
      if (this.page !== page || this.limit !== size) {
        this.page = page;
        this.limit = size;

        this.getUnreportedFunds();
      }
    },

    sortingHandler({ prop, order }) {
      if (order === '') return;

      if (this.sortProp !== prop) {
        this.sortProp = prop;
      } else {
        this.sortOrder = order;
      }

      this.getUnreportedFunds();
    },
  },
};
</script>

<style lang="scss" scoped>
  .media-buying-list {
    .wrapper {
      width: 90%;
      min-width: 1140px;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }
  }
</style>

<template>
  <div class="predefined-page wrapper">
    <ui-confirm
      ref="confirm"
      class="confirm"
      :width="480"
      :type="confirmData.actionType"
      :action-name="confirmData.actionName"
      :close-action-name="confirmData.closeActionName"
      :action-icon="confirmData.actionIcon || 'check'"
      :has-dont-save-btn="confirmData.hasDontSave"
      @save="confirmData.handleSave"
      @dontSave="confirmData.handleDontSave"
    >
      <div slot="title" v-html="confirmData.title" />
      <div class="body__wrap">
        <i class="fas" :class="[confirmData.icon, confirmData.iconColor]" />
        <div class="body__msg msg" v-html="confirmData.msg" />
      </div>
    </ui-confirm>
    <div class="toolbar">
      <div class="section filters">
        <span class="title">{{ $t('settings.predefinedPage.title') }}</span>
      </div>
      <div class="section" :style="{ pointerEvents: isAvailableClick ? 'all' : 'none' }">
        <ui-dropdown-select
          v-if="isEdit"
          :items="languagesSelect"
          :disabled="!languagesSelect.length || !tableData.length"
          :search-placeholder="$t('crm.activities.actions.action_popup.search_languages_placeholder')"
          :not-found-message="$t('crm.activities.actions.action_params_popup.not_found')"
          placement="bottom-end"
          menu-class="crm-action-languages__menu"
          :debounce-timeout="200"
          @select="handeAddLanguage"
        >
          <template v-slot:activator="slotProps">
            <div class="ui-d-flex ui-ai-center">
              <slot name="actions" />
              <ui-button
                icon="globe"
                lib="fa"
                substyle="fas"
                color="green"
                :filled="slotProps.active"
                class="action-btn"
                :disabled="!languagesSelect.length || !tableData.length"
              >
                {{ $t('crm.activities.actions.add_translation') }}
                <ui-chevron
                  class="crm-action-languages__chevron"
                  :rotate="!slotProps.active"
                />
              </ui-button>
            </div>
          </template>
        </ui-dropdown-select>
        <ui-button
          v-if="isEdit"
          color="green"
          lib="fa"
          substyle="fas"
          icon="link"
          class="btn btn-add"
          @click="handleAddUrl"
        >
          {{ $t('settings.predefinedPage.addUrl') }}
        </ui-button>
        <ui-button
          v-if="!isEdit"
          color="green"
          lib="fa"
          substyle="fas"
          filled
          icon="pen"
          class="btn btn-add"
          @click="isEdit = true"
        >
          {{ $t('crm.buttons.edit') }}
        </ui-button>
        <ui-button
          v-if="isEdit"
          color="red"
          lib="fa"
          substyle="fas"
          icon="times"
          class="btn btn-add"
          @click="handleCancel"
        >
          {{ $t('crm.buttons.cancel') }}
        </ui-button>
        <ui-button
          v-if="isEdit"
          color="green"
          lib="fa"
          substyle="fas"
          filled
          icon="check"
          class="btn btn-add"
          @click="handleSave"
        >
          {{ $t('crm.buttons.save') }}
        </ui-button>
      </div>
    </div>
    <div class="brands">
      <div
        v-for="item in brands"
        :key="item.id"
        class="brands-item-info"
        :class="{'active': item.id === siteId}"
        @click="confirmChangeBrand(item.id)"
      >
        <img
          v-if="item.favicon_base64"
          :src="item.favicon_base64"
          :alt="item.name"
        >
        {{ item.name }}
      </div>
    </div>

    <ui-table
      v-loading="isDataLoading"
      :data="tableData"
      disable-sort
      lazy-loading
      element-loading-custom-class="data-loader-spinner"
      class="table"
      :class="{ edit: isEdit }"
      :computed-row-class="() => 'hover-sticky'"
      header-row-class="hover-sticky"
    >
      <template slot="append">
        <el-table-column v-if="isEdit" width="28" align="center" label="" :resizable="false">
          <template>
            <ui-icon
              name="grip-lines"
              :class="{ 'drag': isEdit, 'disabled': !isEdit }"
              color="#a6b2c4"
              lib="fa"
            />
          </template>
        </el-table-column>
        <el-table-column :label="$t('settings.predefinedPage.url')" resizable>
          <template slot-scope="scope">
            <input v-if="isEdit" class="editable-cell" :class="{ error: getError([scope.$index, 'page_url']) }" :value="scope.row.page_url" :readonly="!isEdit" @change="onChangeValue($event, [scope.$index, 'page_url'])">
            <div v-else class="editable-cell view-mode" :class="{ error: getError([scope.$index, 'page_url']) }">
              <span>{{ scope.row.page_url }}</span>
              <el-dropdown v-if="checkIsEmptySomeLanguage(scope.row.names)" trigger="click">
                <ui-icon
                  class="ui-m-sm-l"
                  name="exclamation-triangle"
                  :color="$theme.uiTheme.dangerColor"
                  lib="fa"
                />
                <el-dropdown-menu class="user-login-dropdown">
                  <el-dropdown-item>
                    <div>{{ $t('settings.predefinedPage.missingTranslations') }}</div>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </template>
        </el-table-column>
        <el-table-column v-for="header in headers" :key="header" :label="header" :resizable="true">
          <template #header>
            <div class="header-action">
              <span>{{ mappedLanguages[header] }}</span>
              <ActionIcon v-if="isEdit && header !== 'en'" :tooltip="$t('crm.buttons.delete')">
                <el-link
                  :underline="false"
                  @click="confirmDeleteColumn(header)"
                >
                  <ui-icon
                    class="ui-m-sm-l"
                    name="trash-alt"
                    lib="fa"
                  />
                </el-link>
              </ActionIcon>
            </div>
          </template>

          <template slot-scope="scope">
            <input v-if="isEdit" class="editable-cell" :class="{ error: getError([scope.$index, 'names', header]) }" :value="scope.row.names[header]" :readonly="!isEdit" @change="onChangeValue($event, [scope.$index, 'names', header])">
            <div v-else class="editable-cell view-mode" :class="{ error: getError([scope.$index, 'names', header]) }">
              <span>{{ scope.row.names[header] }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column :label="$t('settings.tokens.actions')" :resizable="false" width="74" align="center">
          <template slot-scope="scope">
            <ActionIcon :tooltip="$t('crm.buttons.delete')">
              <el-link
                type="danger"
                :underline="false"
                @click="confirmDelete(scope.row, scope.$index)"
              >
                <ui-icon
                  name="trash-alt"
                  :color="$theme.dangerColor"
                  lib="fa"
                />
              </el-link>
            </ActionIcon>
          </template>
        </el-table-column>
      </template>
    </ui-table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import sessionAction from '@/views/mixins/session-action';
import errorHandleMixin from '@/views/mixins/error-hadle';
import UiDropdownSelect from '@/views/CRM/components/UiDropdownSelect.vue';
import UiChevron from '@/views/CRM/components/UiChevron.vue';

const LS_KEY = 'settings/data';
const ENABLED_LANGUAGES = ['de', 'en', 'es', 'fr', 'tr', 'he', 'ru', 'pt', 'bn'];

export default {
  name: 'PredefinedPage',
  components: {
    UiChevron,
    UiDropdownSelect,
  },
  mixins: [sessionAction, errorHandleMixin],
  data() {
    return {
      siteId: null,
      isEdit: false,
      isAvailableClick: true,
      timer: null,
      oldData: [],
      languages: [],
      confirmData: {
        title: '',
        icon: '',
        iconColor: '',
        msg: '',
        handleSave: Function,
        hasDontSave: false,
        handleDontSave: () => {},
      },
      isDataLoading: false,
      tableData: [],
    };
  },

  computed: {
    ...mapGetters({
      adminAcl: 'auth/adminAcl',
      adminInfo: 'auth/adminInfo',
      brands: 'misc/brands',
    }),
    languagesSelect() {
      return this.languages
        .filter(item => !this.headers.includes(item.code))
        .map(item => ({
          ...item,
          param: item.code,
          label: `${item.name} (${item.name_native})`,
        }));
    },
    activeBrand() {
      const { name = '', favicon_base64 = '' } = this.brands.find(brand => brand.id === this.siteId) || {};

      return {
        name,
        favicon_base64,
      };
    },
    headers() {
      return this.$_.uniq(this.tableData.map(({ names }) => Object.keys(names)).flat()).sort((a, b) => {
        if (a === 'en') {
          return -1;
        }
        if (b === 'en') {
          return 1;
        }

        return this.mappedLanguages[a].localeCompare(this.mappedLanguages[b]);
      });
    },
    mappedLanguages() {
      return this.languages.reduce((acc, { code, name }) => {
        acc[code] = name;
        return acc;
      }, {});
    },
  },

  watch: {
    isEdit(value) {
      this.errors = {};
      this.isAvailableClick = false;
      clearTimeout(this.timer);

      this.timer = setTimeout(() => {
        this.isAvailableClick = true;
      }, 200);

      if (value) {
        this.oldData = this.$_.cloneDeep(this.tableData);
      } else {
        this.tableData = this.$_.cloneDeep(this.oldData);
        this.oldData = [];
      }
    },
  },
  created() {
    this.$ls.remove(LS_KEY);

    this.onSessionUnlock = () => {
      const data = this.$_.get(JSON.parse(this.$ls.get(LS_KEY)), this.adminInfo.id, {});
      const [siteId] = Object.keys(data);

      this.siteId = siteId || this.brands[0].id;

      this.onSelectBrand(siteId || this.brands[0].id).then(() => {
        if (siteId) {
          this.isEdit = true;
          this.oldData = this.$_.cloneDeep(this.tableData);
          this.tableData = data[siteId];
        }
      });
    };

    this.$api.getLanguages({ code: ENABLED_LANGUAGES }).then((res) => {
      this.languages = res.data.payload;
    });

    this.onSelectBrand(this.brands[0].id);
  },
  beforeRouteLeave(to, from, next) {
    if (this.isEdit && !this.$_.isEqual(this.tableData, this.oldData)) {
      this.confirmData = {
        title: this.$t('settings.predefinedPage.confirm.unsavedChanges.title'),
        icon: 'fa-exclamation-triangle',
        iconColor: 'warning',
        msg: this.$t('settings.predefinedPage.confirm.unsavedChanges.msg'),
        closeActionName: this.$t('dashboards.other.cancel'),
        actionName: this.$t('settings.buttons.save'),
        actionType: 'success',
        hasDontSave: true,
        handleSave: () => {
          this.handleSave();
          next();
        },
        handleDontSave: () => next(),
      };
      this.$refs.confirm.open();
    } else {
      next();
    }
  },
  methods: {
    checkIsEmptySomeLanguage(names) {
      return Object.values(names).some(name => !name);
    },
    onSelectBrand(id) {
      this.errors = {};
      this.siteId = id;
      this.isEdit = false;
      return this.initData();
    },
    handleAddUrl() {
      const data = {
        page_url: '',
        names: this.headers.reduce((acc, key) => {
          acc[key] = '';
          return acc;
        }, { en: '' }),
      };

      this.tableData = [data, ...this.tableData];
    },
    handeAddLanguage(key) {
      this.tableData = this.tableData.map(({ names, ...restParams }) => ({
        ...restParams,
        names: {
          ...names,
          [key]: '',
        },
      }));
    },
    handleDeleteLanguage(key) {
      this.errors = {};

      this.tableData = this.tableData.map(({ names, ...restParams }) => ({
        ...restParams,
        names: this.$_.omit(names, key),
      }));

      this.$refs.confirm.close();
    },
    handleDelete(index) {
      this.errors = {};

      const copiedData = this.$_.cloneDeep(this.tableData);

      copiedData.splice(index, 1);

      if (this.isEdit) {
        this.tableData = this.$_.cloneDeep(copiedData);
        return Promise.resolve();
      }

      return this.$api.putFinalPages({ siteId: this.siteId }, {
        site_final_page_urls: copiedData,
      }).then(() => this.initData());
    },
    handleCancel() {
      this.isEdit = false;
    },
    handleSave() {
      this.errors = {};
      this.$ls.set(LS_KEY, JSON.stringify({
        [this.adminInfo.id]: {
          [this.siteId]: this.tableData,
        },
      }));

      this.$api.putFinalPages({ siteId: this.siteId }, {
        site_final_page_urls: this.tableData,
      }).then(() => {
        this.$ls.remove(LS_KEY);
        this.oldData = this.$_.cloneDeep(this.tableData);
        this.isEdit = false;
      }).catch((e) => {
        this.errors = e.data.errors?.site_final_page_urls || {};

        this.errors.some(url => Object.values(url.names).some((name) => {
          if (name[0].message) {
            this.$noty.error(name[0].message);
            return true;
          }

          return false;
        }));
      });
    },
    onChangeValue(e, path) {
      this.$_.set(this.tableData, path, e.target.value);
    },
    confirmChangeBrand(id) {
      if (this.isEdit && !this.$_.isEqual(this.tableData, this.oldData)) {
        this.confirmData = {
          title: this.$t('settings.predefinedPage.confirm.unsavedChanges.title'),
          icon: 'fa-exclamation-triangle',
          iconColor: 'warning',
          msg: this.$t('settings.predefinedPage.confirm.unsavedChanges.msg'),
          closeActionName: this.$t('dashboards.other.cancel'),
          actionName: this.$t('settings.buttons.save'),
          actionType: 'success',
          hasDontSave: true,
          handleSave: () => {
            this.handleSave();
            this.onSelectBrand(id);
            this.$refs.confirm.close();
          },
          handleDontSave: () => {
            this.onSelectBrand(id);
            this.$refs.confirm.close();
          },
        };
        this.$refs.confirm.open();
      } else {
        this.onSelectBrand(id);
      }
    },
    confirmDelete(row, index) {
      this.confirmData = {
        title: this.$t('settings.predefinedPage.confirm.deleteUrl.title', { text: row.page_url }),
        icon: 'fa-trash-alt',
        iconColor: 'warning',
        msg: this.$t('settings.predefinedPage.confirm.deleteUrl.msg', { text: row.page_url, brandName: this.activeBrand.name }),
        actionName: this.$t('settings.tokens.delete'),
        actionType: 'delete',
        actionIcon: 'trash-alt',
        closeActionName: this.$t('dashboards.other.cancel'),
        handleDontSave: () => {},
        handleSave: () => this.handleDelete(index).then(() => {
          this.$refs.confirm.close();
        }),
      };
      this.$refs.confirm.open();
    },
    confirmDeleteColumn(key) {
      this.confirmData = {
        title: this.$t('settings.predefinedPage.confirm.deleteUrl.title', { text: this.mappedLanguages[key] }),
        icon: 'fa-trash-alt',
        iconColor: 'warning',
        msg: this.$t('settings.predefinedPage.confirm.deleteUrl.msg', { text: this.mappedLanguages[key], brandName: this.activeBrand.name }),
        actionName: this.$t('settings.tokens.delete'),
        actionType: 'delete',
        actionIcon: 'trash-alt',
        closeActionName: this.$t('dashboards.other.cancel'),
        handleDontSave: () => {},
        handleSave: () => this.handleDeleteLanguage(key),
      };
      this.$refs.confirm.open();
    },
    initData() {
      this.isDataLoading = true;
      return this.$api.getFinalPages({ siteId: this.siteId }).then((res) => {
        const { site_final_page_urls } = res.data.payload;
        this.tableData = site_final_page_urls;
      }).finally(() => this.isDataLoading = false);
    },
  },
};
</script>
<style lang='scss' scoped>
.predefined-page {
  .section {
    display: flex;

    div + div {
      margin-left: 8px;
    }
  }

  .drag {
    cursor: grab;
  }

  .disabled {
    cursor: not-allowed !important;
    opacity: .5;
  }

  /deep/.confirm {
    .msg {
      b {
        font-weight: 700;
      }
    }
  }

  &.wrapper{
    width: 90%;
    margin: 0 auto;
  }
  .body {
    display: flex;
    gap: 24px;
    line-height: normal;
  }
  .toolbar {
    margin: 16px 0;
    display: flex;
    justify-content: space-between;

    .title {
      font-size: 24px;
      margin-right: 16px;
      line-height: 30px;
      font-weight: 500;
    }
  }

  .table {
    &.edit /deep/ {
      th, tr td {
        &:nth-child(2) {
          position: sticky;
          left: 28px;
          z-index: 2;
        }
      }
    }

    /deep/ {
      .el-table{
        border-color: transparent;
        &::before{
          background-color: transparent;
        }
        &::after{
          background-color: transparent;
        }
        &__body{
          border: 1px solid #eaeaea;
          border-right: none;
          position: relative;
          &::after{
            content: '';
            position: absolute;
            background-color: #EBEEF5;
            z-index: 1;
            top: 0;
            right: 1px;
            width: 2px;
            height: 100%;
          }
        }
      }

      th, tr td {
        background-color: inherit;

        &:first-child {
          position: sticky;
          left: 0;
          z-index: 99;
        }

        &:nth-last-child(1 of :not(.gutter)) {
          position: sticky;
          right: 0;
          z-index: 99;
        }
      }

      tr td:last-child {
        border-left: 1px solid var(--border-color);
      }

      .el-table__row {
        .el-table__cell {
          padding: 0!important;

          .cell {
            height: inherit;
            padding: 0!important;
          }
        }
      }
    }

    .editable-cell {
      all: unset;
      padding: 0 8px;
      height: inherit;
      width: 100%;
      overflow: hidden;

      &.view-mode {
        display: flex;
        justify-content: space-between;
        height: auto;

        span {
          overflow: hidden;
          text-overflow: ellipsis;
          display: block;
          white-space: nowrap;
        }
      }

      &:focus:read-write {
        box-sizing: border-box;
        border: solid 1px #20815e;
      }

      &.error {
        box-sizing: border-box;
        border: solid 1px var(--danger-color);
      }
    }

    .header-action {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex: 1;
      max-width: 100%;

      span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .brands {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 16px 0 15px 0;
    margin-bottom: 16px;
    border-bottom: 1px solid var(--border-color);

    .brands-item-info {
      display: flex;
      align-items: center;
      cursor: pointer;
      color: #303634;
      font-size: 16px;
      padding: 4px 0 2px 0;
      margin-right: 24px;
      height: 24px;
      position: relative;

      &.pending {
        cursor: default;
      }

      &.active {
        font-weight: 500;

        &:after {
          content: '';
          left: 0;
          right: 0;
          height: 2px;
          background-color: var(--main-color);
          position: absolute;
          bottom: -16px;
        }
      }

      img {
        width: 16px;
        height: 16px;
        margin-right: 4px;
        border-radius: 4px;
      }
    }
  }

  /deep/ {
    .ui-confirm .ui-button.btn {
      text-transform: initial;
    }
  }
}
</style>

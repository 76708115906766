<template>
  <ui-dropdown-select
    :items="items"
    :search-placeholder="$t('crm.activities.actions.action_popup.search_languages_placeholder')"
    :not-found-message="$t('crm.activities.actions.action_params_popup.not_found')"
    :selected="selected"
    placement="bottom-end"
    menu-class="crm-action-languages__menu"
    :debounce-timeout="200"
    :disabled="disabled"
    @select="handleSelect"
  >
    <template v-slot:activator="slotProps">
      <div class="ui-d-flex ui-ai-center">
        <slot name="actions" />
        <ui-button
          icon="globe"
          lib="fa"
          substyle="fas"
          color="green"
          :filled="slotProps.active"
          class="action-btn"
          :disabled="disabled"
        >
          {{ $t('crm.activities.actions.add_translation') }}
          <ui-chevron
            class="crm-action-languages__chevron"
            :rotate="!slotProps.active"
          />
        </ui-button>
      </div>
    </template>
  </ui-dropdown-select>
</template>

<script>
import UiDropdownSelect from '@/views/CRM/components/UiDropdownSelect.vue';
import UiChevron from '@/views/CRM/components/UiChevron.vue';

export default {
  name: 'CrmActionLanguages',
  components: {
    UiDropdownSelect,
    UiChevron,
  },
  props: {
    selected: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    items() {
      const languages = this.$store.state.misc.languages || [];
      return languages.map(item => ({
        param: item.code,
        label: `${item.name} (${item.name_native})`,
      }));
    },
  },
  methods: {
    handleSelect(event) {
      this.$emit('select', event);
    },
  },
};
</script>

<style lang="scss">
  .crm-action-languages {
    &__chevron {
      line-height: 12.5px;
    }

    &__menu {
      &.el-dropdown-menu {
        width: 420px;
        max-height: 400px;
      }
    }
  }
</style>

<template>
  <div
    class="media-buying-list"
  >
    <ui-confirm
      ref="confirm"
      :width="480"
      :type="confirmType"
      :action-name="actionName"
      :action="confirmAction"
      :action-icon="actionIcon"
      :action-fa-icon="actionFaIcon"
      @save="onConfirm"
      @close="confirmClose"
    >
      <div
        slot="title"
        v-html="confirmTitle"
      />
      <div class="body__wrap">
        <i
          :class="confirmType"
          class="fas fa-ban"
        />
        <div
          class="body__msg"
          v-html="confirmMsg"
        />
      </div>
    </ui-confirm>


    <div
      class="toolbar"
      @keyup.enter="handleRefresh"
    >
      <div class="wrapper">
        <div class="section">
          <span class="title">{{ $t(`mediaBuying.page_title.${currentBuyingPageName}.name`) }} {{ $t(`mediaBuying.page_title.${currentBuyingPageName}.${currentBuyingPageStatus}`) }}</span>
          <ui-input
            v-model.trim="searchQuery"
            :width="180"
            class="select-label"
            is-search
            @search="handleRefresh"
          />
          <el-checkbox
            v-model="needsAttentionFirst"
            @change="getPayments"
          >
            {{ $t('mediaBuying.other.action_required') }}
          </el-checkbox>
        </div>
        <div class="section">
          <ui-pagination
            :page="page"
            :page-size="limit"
            :count="count"
            show-size-select
            @page-change="pageChange"
          />
        </div>
      </div>
    </div>
    <div
      ref="wrapper"
      class="table-wrapper"
    >
      <ui-table
        ref="dataTable"
        v-loading="isDataLoading"
        :fields="fields"
        :data="tableData"
        :currency="currencyCode"
        :rows-count="limit"
        :sort="{
          prop: sortProp,
          order: sortOrder,
        }"
        lazy-loading
        i18n-path="mediaBuying.list_table_title"
        element-loading-custom-class="data-loader-spinner"
        class="table"
        @sort="handleSort"
        @headerDragend="headerDragend"
        @update="resizeHeaderDragend()"
        @filter="handlerFilter"
      >
        <template slot="append">
          <el-table-column
            :width="calcWidth()"
            :resizable="false"
            fixed="right"
            align="center"
            header-align="center"
          >
            <template slot-scope="scope">
              <div class="row-value actions">
                <ActionIcon
                  :tooltip="$t(scope.row.needs_attention
                    ? $t('mediaBuying.other.btn_unmark')
                    : $t('mediaBuying.other.btn_mark'))"
                >
                  <span
                    class="action-ico"
                    @click="markAsRead(scope.row)"
                  >
                    <ui-icon
                      v-if="scope.row.needs_attention"
                      name="circle-exclamation-check"
                      :color="$theme.accentColor"
                      lib="fa-6"
                      substyle="fa-solid"
                    />
                    <ui-icon
                      v-else
                      name="exclamation-circle"
                      :color="$theme.dangerColor"
                      lib="fa"
                    />
                  </span>
                </ActionIcon>
                <ActionIcon
                  :tooltip="$t('mediaBuying.actions.info')"
                >
                  <router-link
                    :to="handleRowClick(scope.row)"
                    class="action-ico"
                  >
                    <ui-icon
                      name="eye"
                      :color="$theme.accentColor"
                      lib="fa"
                    />
                  </router-link>
                </ActionIcon>
                <ActionIcon
                  :tooltip="$t('mediaBuying.actions.decline')"
                >
                  <span
                    v-if="currentBuyingPageStatus === 'on_review' && reviewActions(scope.row)"
                    class="action-ico"
                    @click="declineHandler(scope.row)"
                  >
                    <ui-icon
                      name="times"
                      :color="$theme.dangerColor"
                      lib="fa"
                    />
                  </span>
                </ActionIcon>
                <ActionIcon
                  :tooltip="$t('mediaBuying.actions.failed')"
                >
                  <span
                    v-if="currentBuyingPageStatus === 'processing' && reviewActions(scope.row)"
                    class="action-ico"
                    @click="failedHandler(scope.row)"
                  >
                    <ui-icon
                      name="times"
                      :color="$theme.dangerColor"
                      lib="fa"
                    />
                  </span>
                </ActionIcon>
              </div>
            </template>
          </el-table-column>
        </template>
      </ui-table>
    </div>
  </div>
</template>

<script>
import numeral from 'numeral';
import { mapGetters } from 'vuex';
import { pageSizeMixin, resolvePageSize } from '../../service/pageSize';
import updateUrl from '../../service/updateUrl';
import detectPermissions from '../../service/detectPermissions';
import { tableFieldsExMethodsPayments as tableFieldsEx } from './tableHelpers/fieldTable';
import dragendColumnMixin from '@/components/UIElements/UiTableHelper/dragendColumnMixin';
import TeamsListMixin from '@/views/MediaBuying/mixins/teams-list-mixin';

const sizes = {
  1080: 20,
  1440: 30,
};

const viewName = 'media-buying/payments/limit'; // для локалсторедж
const pageSize = resolvePageSize(viewName, {
  _default: 15,
  sizes,
});

export default {
  mixins: [TeamsListMixin, pageSizeMixin, dragendColumnMixin],
  data() {
    return {
      viewName,
      needsAttentionFirst: true,
      loadingMark: false,
      confirmType: '',
      actionName: '',
      confirmAction: '',
      actionFaIcon: '',
      actionIcon: '',
      confirmTitle: '',
      confirmMsg: '',
      blockedRequest: false,
      isDataLoading: false,
      currentBuyingPageName: '',
      currentBuyingPageStatus: '',
      permissions: {},
      page: 1,
      limit: pageSize,
      sortProp: '',
      sortOrder: '',
      count: 0,
      currencyCode: '',
      searchQuery: '',
      paymentId: '',
      resizeHeaderDragendFlag: true,
      fieldsLocalStorageKey: 'MediaBuying/payments/width_columns',
      tableFieldsEx,
      fields: [
        {
          name: '',
          fixed: 'left',
          align: 'right',
          sortable: false,
          width: '30',
          minWidth: '30',
          linkAppend: this.renderNeedAttentionIcon,
        },
        {
          name: 'incrementing_id',
          fixed: 'left',
          align: 'left',
          sortable: 'custom',
          width: '60',
          minWidth: '60',
        },
        {
          name: 'affiliate_email',
          align: 'left',
          sortable: false,
          width: '180',
          minWidth: '180',
          link: this.getAffiliateLink,
        },
        {
          name: 'team_name',
          width: '120',
          minWidth: '120',
        },
        {
          name: 'campaign_name',
          align: 'left',
          sortable: false,
          minWidth: '180',
          flexible: true,
        },
        {
          name: 'amount',
          align: 'right',
          width: '120',
          minWidth: '120',
          sortable: false,
          computedValue: this.formatMoney,
        },
        {
          name: 'amount_converted',
          align: 'right',
          width: '120',
          minWidth: '120',
          sortable: 'custom',
          computedValue: this.formatMoneyConverted,
          format: 'formatMoney',
        },
        {
          name: 'payment_method_id',
          align: 'center',
          className: 'payment-center',
          headerAlign: 'center',
          width: '120',
          minWidth: '120',
          computedImg: this.computedPaymentMethodImg,
          computedImgNull: this.computedPaymentMethod,
        },
        {
          name: 'period',
          align: 'left',
          width: '250',
          minWidth: '250',
          computedValue: this.computedPeriod,
        },
        {
          name: 'created_at',
          width: '140',
          minWidth: '140',
          align: 'left',
          headerAlign: 'left',
          sortable: 'custom',
          format: 'date-time',
        },
        {
          name: 'updated_at',
          width: '140',
          minWidth: '140',
          align: 'left',
          headerAlign: 'left',
          sortable: 'custom',
          format: 'date-time',
        },
      ],
      tableData: [],
      columnsFilter: {},
      createdAtFrom: undefined,
      createdAtTo: undefined,
    };
  },
  computed: {
    ...mapGetters({
      currentAcl: 'auth/currentAcl',
      adminAcl: 'auth/adminAcl',
      currencies: 'misc/currencies',
    }),
    currencyLabel() {
      return this.getCurrencyLabel(this.currencyCode);
    },
  },
  watch: {
    needsAttentionFirst(newVal) {
      this.$ls.set(`MediaBuying/${this.currentBuyingPageName}/${this.currentBuyingPageStatus}/needs_attention_first`, newVal);
    },
    sortProp(newVal) {
      this.$ls.set(`MediaBuying/${this.currentBuyingPageName}/${this.currentBuyingPageStatus}/sort_column`, newVal || 'incrementing_id');
    },
    sortOrder(newVal) {
      this.$ls.set(`MediaBuying/${this.currentBuyingPageName}/${this.currentBuyingPageStatus}/sort_dir`, newVal || 'desc');
    },
    limit(newVal) {
      this.$ls.set(`MediaBuying/${this.currentBuyingPageName}/${this.currentBuyingPageStatus}/limit`, newVal || pageSize);
    },

    columnsFilter(newVal) {
      this.$ls.set(`MediaBuying/${this.currentBuyingPageName}/${this.currentBuyingPageStatus}/columns_filter`, newVal || {});
    },

    $route() {
      this.blockedRequest = true;
      this.currentBuyingPageName = this.$route.name;
      this.currentBuyingPageStatus = this.$route.meta.view;
      this.searchQuery = '';
      const _urlData = updateUrl.getParseParams(this.$route.hash);
      this.page = Number(this.$_.get(_urlData, ['page'])) || 1;
      this.needsAttentionFirst = this.$ls.get(`MediaBuying/${this.currentBuyingPageName}/${this.currentBuyingPageStatus}/needs_attention_first`) !== null
        ? this.$ls.get(`MediaBuying/${this.currentBuyingPageName}/${this.currentBuyingPageStatus}/needs_attention_first`) : true;
      this.limit = this.$ls.get(`MediaBuying/${this.currentBuyingPageName}/${this.currentBuyingPageStatus}/limit`) || pageSize;
      this.sortProp = this.$ls.get(`MediaBuying/${this.currentBuyingPageName}/${this.currentBuyingPageStatus}/sort_column`) || 'incrementing_id';
      this.sortOrder = this.$ls.get(`MediaBuying/${this.currentBuyingPageName}/${this.currentBuyingPageStatus}/sort_dir`) || 'desc';
      this.columnsFilter = this.$ls.get(`MediaBuying/${this.currentBuyingPageName}/${this.currentBuyingPageStatus}/columns_filter`) || {};

      this.parseFilter(this.columnsFilter, this.$_.isEmpty(this.columnsFilter) === true ? 'reset' : undefined);

      this.getPayments();
    },
  },
  created() {
    this.blockedRequest = true;
    this.currentBuyingPageName = this.$route.name;
    this.currentBuyingPageStatus = this.$route.meta.view;
    if (detectPermissions.checkRequest('media_buying_payments_view', this.currentBuyingPageName, this.currentBuyingPageStatus)) {
      const _urlData = updateUrl.getParseParams(this.$route.hash);
      if (!this.$_.isEmpty(_urlData)) {
        const getUrlNeeds = this.$_.get(_urlData, ['needs_attention_first']);
        this.needsAttentionFirst = getUrlNeeds !== undefined ? JSON.parse(getUrlNeeds) : true;
        this.limit = Number(this.$_.get(_urlData, ['limit'])) || pageSize;
        this.page = Number(this.$_.get(_urlData, ['page'])) || 1;
        this.sortProp = this.$_.get(_urlData, ['sort_column']) || 'incrementing_id';
        this.sortOrder = this.$_.get(_urlData, ['sort_dir']) || 'desc';
        this.searchQuery = this.$_.get(_urlData, ['search']) || null;
        this.columnsFilter = this.$_.get(_urlData, ['columns_filter']) || {};
      } else {
        this.needsAttentionFirst = this.$ls.get(`MediaBuying/${this.currentBuyingPageName}/${this.currentBuyingPageStatus}/needs_attention_first`) !== null
          ? this.$ls.get(`MediaBuying/${this.currentBuyingPageName}/${this.currentBuyingPageStatus}/needs_attention_first`) : true;
        this.limit = +this.$ls.get(`MediaBuying/${this.currentBuyingPageName}/${this.currentBuyingPageStatus}/limit`) || pageSize;
        this.sortProp = this.$ls.get(`MediaBuying/${this.currentBuyingPageName}/${this.currentBuyingPageStatus}/sort_column`) || 'incrementing_id';
        this.sortOrder = this.$ls.get(`MediaBuying/${this.currentBuyingPageName}/${this.currentBuyingPageStatus}/sort_dir`) || 'desc';
        this.columnsFilter = this.$ls.get(`Payments/${this.currentPaymentPageName}/${this.currentPaymentPageStatus}/columns_filter`) || {};
      }
      this.parseFilter(this.columnsFilter, this.$_.isEmpty(this.columnsFilter) === true ? 'reset' : undefined);

      this.getPayments();
    }
    detectPermissions.checkRoute('media_buying_payments_view', this.currentBuyingPageName, this.currentBuyingPageStatus);
  },
  methods: {
    getCurrencyLabel(code) {
      if (code === undefined) return '';

      const currency = this.currencies.find((el => el.code === code));

      return currency?.symbol || `${code} `;
    },
    async markAsRead(row) {
      this.loadingMark = true;
      if (row.needs_attention) {
        await this.$api.postMediaBuyingPaymentMarkAsRead(row.id);
        this.getPayments('silent');
      } else {
        await this.$api.postMediaBuyingPaymentMarkAsUnread(row.id);
        this.getPayments('silent');
      }
      this.loadingMark = false;
    },

    handlerFilter(filter, action) {
      this.parseFilter(filter, action);

      this.getPayments();
    },

    parseFilter(filter, action) {
      const columnProp = 'review_requested_at';
      if (action === 'reset') {
        this.createdAtFrom = undefined;
        this.createdAtTo = undefined;
        this.columnsFilter = {};
        return;
      }

      if (filter[columnProp].op === '≥') {
        this.createdAtFrom = this.$moment(filter[columnProp].dateRange).startOf('day').format('YYYY-MM-DD HH:mm:ss');
        this.createdAtTo = undefined;
      } else if (filter[columnProp].op === '≤') {
        this.createdAtFrom = undefined;
        this.createdAtTo = this.$moment(filter[columnProp].dateRange).endOf('day').format('YYYY-MM-DD HH:mm:ss');
      } else {
        this.createdAtFrom = this.$moment(filter[columnProp].dateRange[0]).startOf('day').format('YYYY-MM-DD HH:mm:ss');
        this.createdAtTo = this.$moment(filter[columnProp].dateRange[1]).endOf('day').format('YYYY-MM-DD HH:mm:ss');
      }
      this.columnsFilter = filter;
    },

    calcWidth() {
      const page = this.currentBuyingPageStatus === 'on_review' || this.currentBuyingPageStatus === 'processing';
      const isUser = !this.adminAcl.is_superuser && page && this.currentAcl.media_buying_payments_review === 'allow';
      const isSuperUser = this.adminAcl.is_superuser && page;
      return isUser || isSuperUser ? 105 : 75;
    },
    reviewActions(row) {
      const isUser = !this.adminAcl.is_superuser && this.permissions && this.permissions[row.id].review_actions;
      const isSuperUser = this.adminAcl.is_superuser;
      return isSuperUser || isUser;
    },
    formatMoney(row) {
      const formatValue = numeral(Math.abs(row.amount)).format('0,0.00');
      return `${this.getCurrencyLabel(row.currency_code)}${formatValue}`;
    },

    formatMoneyConverted(row) {
      const formatValue = numeral(Math.abs(row.amount_converted)).format('0,0.00');
      return `${this.currencyLabel}${formatValue}`;
    },

    hasPermission(permission) {
      return this.currentAcl[permission] === 'allow' || this.adminAcl.is_superuser;
    },

    declineHandler(row) {
      this.confirmAction = 'Decline';
      this.confirmMsg = `${this.$t('mediaBuying.info.popup.decline_request.text')}`;
      this.actionName = `${this.$t('mediaBuying.info.popup.decline_request.button')}`;
      this.confirmTitle = `${this.$t('mediaBuying.info.popup.decline_request.title')}`;
      this.confirmType = 'warning';
      this.actionFaIcon = 'ban';
      this.paymentId = row.id;
      this.$refs.confirm.open();
    },
    failedHandler(row) {
      this.confirmAction = 'Failed';
      this.confirmMsg = `${this.$t('mediaBuying.info.popup.failed_request.text')}`;
      this.actionName = `${this.$t('mediaBuying.info.popup.failed_request.button')}`;
      this.confirmTitle = `${this.$t('mediaBuying.info.popup.failed_request.title')}`;
      this.confirmType = 'warning';
      this.actionFaIcon = 'check';
      this.paymentId = row.id;
      this.$refs.confirm.open();
    },
    onConfirm() {
      switch (this.confirmAction) {
      case 'Decline':
        this.$refs.confirm.close();
        this.changeStatus('moderation', 'decline');
        break;
      case 'Failed':
        this.$refs.confirm.close();
        this.changeStatus('payout', 'failed');
        break;
      default:
        break;
      }
    },

    confirmClose() {
      this.actionIcon = '';
      this.actionFaIcon = '';
    },

    async changeStatus(section, status) {
      await this.$api.postMediaBuyingPaymentChangeStatus(this.paymentId, section, status);
      await this.getPayments();
    },

    handleRowClick(row) {
      return `/media-buying/payments/${row.id}?from=${this.currentBuyingPageName}&needs=success`;
    },

    getPayments(action) {
      if (action !== 'silent') this.isDataLoading = true;
      this.errors = {};
      this._completedFilterUrl();
      const _filter = this._query();
      this.$api.getMediaBuyingPayments(_filter).then((response) => {
        this.tableData = response.data.payload;
        this.permissions = this.$_.clone(response.data.misc.permissions);
        this.count = response.data.misc.count;
        this.currencyCode = response.data.misc.currency_code;
      })
        .catch((error) => {
          this.errors = this.$_.cloneDeepWith(error.data.errors);
        })
        .finally(() => {
          setTimeout(() => {
            this.blockedRequest = false;
          }, 300);
          this.isDataLoading = false;
          this.$refs.dataTable.setColumnFilters(this.columnsFilter);
        });
    },
    computedPaymentMethodImg(row) {
      return row.payment_method_logo_base64;
    },
    computedPaymentMethod(row, rows) {
      return rows.payment_method_name;
    },
    getAffiliateLink(row) {
      const isUser = !this.adminAcl.is_superuser && this.permissions[row.id].view_profile;
      const isSuperUser = this.adminAcl.is_superuser;
      return isUser || isSuperUser ? `/affiliates/${row.affiliate_id}/info` : false;
    },

    computedPeriod(row) {
      return `${this.formatDate(row.payment_period_start)} - ${this.formatDate(row.payment_period_end)}`;
    },

    formatDate(value) {
      return this.$moment(value).locale(this.$i18n.locale).format('DD MMM YYYY HH:mm');
    },

    _query() {
      const _paramsFilter = {
        needs_attention_first: this.needsAttentionFirst,
        sort_column: this.sortProp,
        sort_dir: this.sortOrder,
        search: this.searchQuery,
        limit: this.limit,
        offset: (this.limit * this.page) - this.limit,
        created_at_from: this.createdAtFrom,
        created_at_to: this.createdAtTo,
      };
      if (this.currentBuyingPageName === 'media_buying_payments_moderation') {
        _paramsFilter.moderation_status = this.currentBuyingPageStatus;
      } else {
        _paramsFilter.moderation_status = 'approved';
        _paramsFilter.payout_status = this.currentBuyingPageStatus;
      }
      return _paramsFilter;
    },
    pageChange(page, size) {
      if (this.blockedRequest) {
        return;
      }
      if (this.page !== page || this.limit !== size) {
        this.page = page;
        this.limit = size;
        this.$nextTick(() => {
          this.getPayments();
        });
      }
    },
    handleSort({ prop, order }) {
      if (this.blockedRequest) {
        return;
      }
      if (order === '') return;
      if (this.sortProp !== prop) {
        this.sortProp = prop;
      } else {
        this.sortOrder = order;
      }
      this.$nextTick(() => {
        this.getPayments();
      });
    },
    handleRefresh() {
      if (this.blockedRequest) {
        return;
      }
      this.page = 1;
      this.$nextTick(() => {
        this.getPayments();
      });
    },
    _completedFilterUrl() {
      const _dataFilters = {
        needs_attention_first: this.needsAttentionFirst,
        limit: this.limit,
        page: this.page,
        sort_column: this.sortProp,
        sort_dir: this.sortOrder,
        search: this.searchQuery,
        columns_filter: this.columnsFilter,
      };
      updateUrl.updateGetParams(_dataFilters);
    },
  },
};
</script>

<style lang="scss" scoped>
  .media-buying-list {
    .wrapper {
      width: 90%;
      min-width: 1140px;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }
  }
</style>

export default {
  ui: {
    filters: {
      playerFilter: 'Игроки',
      playerCountryNameFilter: 'Страна игрока',
      playerRegionNameFilter: 'Регион игрока',
      trafficRegionNameFilter: 'Трафик страны',
      affiliate: 'Партнер',
      affiliateLastBets: 'Партнер',
      affiliateLastDeposits: 'Партнер',
      affiliatePaymentsReport: 'Партнер',
      affiliateReport: 'Партнер',
      pinnedAffiliate: 'Закреплен за партнером',
      lastActivity: 'Последняя активность',
      profitCategory: 'Категория',
      deposits: 'Депозиты',
      player: 'Игроки',
      playerReport: 'Игроки',
      country: 'Страна',
      countryPaymentsReport: 'Страна',
      playerCountry: 'Страна игрока',
      playerCountryLastDeposits: 'Страна игрока',
      playerCountryLastBets: 'Страна игрока',
      playerCountryPaymentsReport: 'Страна игрока',
      trafficType: 'Тип трафика',
      contacts: 'Контакты',
      brandLive: 'Бренд',
      brand: 'Бренд',
      autoDnsManagement: 'Автоматическое управление DNS',
      dnsHostingId: 'Провайдер хостинга DNS',
      autoNsManagement: 'Автоматическое управление NS',
      domainRegistrarId: 'Провайдер регистратора домена',
      dnsManagement: 'Управление DNS',
      nsManagement: 'Управление NS',
      sslIssuance: 'Выдача SSL',
      platformServiceOrigin: 'Источник сервера платформы',
      platformServer: 'Сервер платформы',
      domainRegistrarAccount: 'Учетная запись регистратора домена',
      dnsHostingAccount: 'Учетная запись хостинга DNS',
      marketingMaterialType: 'Тип материала',
      marketingMaterialStatus: 'Статус материала',
      availabilityMaterials: 'Доступность',
      languageCode: 'Язык',
      blockedInAnyCountry: 'Заблокирован в любой стране',
      usedInAnyGroup: 'Используется в любой группе',
      inner: {
        filter: 'Фильтр',
        days: 'Дни',
        apply_filter: 'Применить фильтр',
        reset_filter: 'Сбросить фильтр',
        cancel: 'Отменить',
      },
      select: {
        only_content: 'Только с контактами',
        all: 'Все',
        custom: 'Пользовательский',
        without: 'Без',
        only_first_dep: 'Только первые депозиты',
        more_one: 'Больше одного',
        only_with_dep: 'Только с депозитами',
        no: 'Нет',
        yes: 'Да',
        disabled: 'Выключено',
        enabled: 'Включено',
        partners: 'Партнеры',
        direct: 'Прямой',
        and_more: '- 100% и больше',
        auto: 'Авто',
        manual: 'Ручной',
        html5_banner: 'HTML5 баннер',
        simple_banner: 'Простой баннер',
        landing: 'Лендинг',
        active: 'Активный',
        inactive: 'Не активный',
        public: 'Публичный',
        private: 'Приватный',
      },
    },
    buttons: {
      add: 'Добавить',
      delete: 'Удалить',
    },
    input: {
      search: 'Поиск ...',
    },
    select: {
      no_match: 'Нет данных',
      no_options: 'Нет вариантов',
      default: 'Выбрать пункт',
      filter_option: 'Параметры фильтра',
    },
    table: {
      no_data: 'Нет данных',
      total: 'Общий',
      value: 'Значение',
      reset: 'Сбросить',
      apply: 'Применить',
      from: 'От',
      to: 'До',
      exclude: 'Исключить выбранный сегмент',
      exclude2: 'Исключить граничные числа',
    },
    shortcuts: {
      relative_time_ranges: 'Относительные временные диапазоны',
      other_quick_ranges: 'Другие диапазоны',
      last_24_hours: 'Последние 24 часа',
      last_2_days: 'Последние 2 дня',
      last_7_days: 'Последние 7 дней',
      last_30_days: 'Последние 30 дней',
      last_90_days: 'Последние 90 дней',
      last_6_months: 'Последние 6 месяцев',
      last_1_year: 'Последний год',
      today: 'Весь сегодняшний день',
      today_so_far: 'Сегодня',
      yesterday: 'Вчера',
      day_before_yesterday: 'Позавчера',
      this_day_last_week: 'Этот день неделю назад',
      this_week: 'Вся текущая неделя',
      this_week_so_far: 'Эта неделя',
      previous_week: 'Прошлая неделя',
      previous_month: 'Прошлый месяц',
      previous_year: 'Прошлый год',
      this_month: 'Весь текущий месяц',
      this_month_so_far: 'Этот месяц',
      this_year: 'Весь текущий год',
      this_year_so_far: 'Этот год',
    },
  },
};

<template>
  <div class="wrapper">
    <ui-confirm
      ref="confirm"
      :width="500"
      class="popup"
      :close-action-name="$t('affiliates.offer_popup.confirm_close.closeAction')"
      :action-name="$t('crm.buttons.save')"
      action-fa-icon="check"
      @save="onSave"
    >
      <div slot="title">
        {{ $t(confirm.title) }}
      </div>
      <div>
        <div v-if="confirm.hint" class="hint">
          {{ $t(confirm.hint) }}
        </div>
        <div v-for="(field, i) in confirm.fields" :key="i" class="ui-fg-1 form-field">
          <div v-if="field.renderValue">
            <div class="ui-d-flex header row-bg">
              <span>{{ $t(field.title) }}</span>
              <div class="ui-d-flex ui-ai-center">
                {{ field.renderValue() }}
              </div>
            </div>
          </div>
          <div v-if="field.type === 'boolean'">
            <div class="ui-d-flex header row-bg">
              <span>{{ $t(field.title) }}</span>
              <div class="ui-d-flex ui-ai-center">
                <span :style="{ color: confirm.data[field.key] ? $theme.accentColor : $theme.dangerColor}" class="toggle-label">
                  {{ $t(field.label(confirm.data[field.key])) }}
                </span>
                <el-switch
                  v-model="confirm.data[field.key]"
                  class="ui-m-sm-l"
                  :active-color="$theme.accentColor"
                  :inactive-color="$theme.dangerColor"
                />
              </div>
            </div>
          </div>
          <div v-if="field.type === 'text'">
            <div class="ui-d-flex header">
              <span>{{ $t(field.title) }}</span>
              <el-tooltip
                v-if="field.hint"
                :content="$t(field.hint)"
                placement="top"
                :open-delay="300"
              >
                <ui-icon
                  name="question-circle"
                  class="ui-m-sm-l question-circle"
                  color="var(--tooltip-icon)"
                  :size="14"
                  lib="fa-6"
                  substyle="fa-solid"
                />
              </el-tooltip>
            </div>
            <ui-input
              v-model="confirm.data[field.key]"
              class="form-input"
              autosize
              size="big"
              :placeholder="$t(field.title)"
              type="text"
              :post-text="field.renderPost && field.renderPost(confirm.data)"
              :error="getError(field.key)"
            />
          </div>
          <div v-if="field.type === 'number'">
            <div class="header">
              <span>{{ $t(field.title) }}</span>
            </div>
            <ui-input
              v-model="confirm.data[field.key]"
              class="form-input"
              autosize
              size="big"
              :placeholder="$t(field.title)"
              type="text"
              :error="getError(field.key)"
              @change="preventSymbol"
            />
          </div>
          <div v-if="field.type === 'select'">
            <div class="title select-title">
              <span>{{ $t(field.title) }}</span>
            </div>
            <el-select
              v-model="confirm.data[field.key]"
              placeholder="Select"
              class="select"
            >
              <el-option
                v-for="item in field.options"
                :key="item"
                :label="$t(`settings.systemSettings.popup.${item}`)"
                :value="item"
              />
            </el-select>
          </div>
        </div>
      </div>
    </ui-confirm>
    <div class="toolbar">
      <span class="title">{{ $t('settings.systemSettings.title') }}</span>
    </div>
    <div v-loading="isLoading" class="body" :class="{ lazyLoading }">
      <div v-for="field in fields" :key="field.header.key" class="card">
        <div class="title">
          <div class="title-content">
            <div class="ui-d-flex">
              <span>{{ $t(field.header.title) }}</span>
              <el-tooltip
                v-if="field.header.hint"
                :content="$t(field.header.hint)"
                placement="top"
                :open-delay="300"
              >
                <ui-icon
                  name="question-circle"
                  class="ui-m-sm-l question-circle"
                  color="var(--tooltip-icon)"
                  :size="14"
                  lib="fa-6"
                  substyle="fa-solid"
                />
              </el-tooltip>
            </div>
            <span
              style="color: #20815e"
              class="action"
              @click="handleEdit(field.header.key)"
            >
              <ui-icon
                name="pen"
                color="#20815e"
                lib="fa"
              />
              {{ $t(`affiliates.affiliatesInfo.edit`) }}
            </span>
          </div>
        </div>
        <div class="card-content">
          <div class="card">
            <div v-for="row in field.rows" :key="row.key" class="field">
              <div v-if="row.title" class="ui-d-flex block-title">
                <span v-if="row.messanger" class="messenger" :class="row.messanger" />
                <ui-icon
                  v-if="row.preIcon"
                  :size="14"
                  :name="row.preIcon"
                  class="ui-m-md-r"
                  lib="fa"
                  :color="$theme.accentColor"
                />
                {{ $t(row.title) }}
                <el-tooltip
                  v-if="row.hint"
                  :content="$t(row.hint)"
                  placement="top"
                  :open-delay="300"
                >
                  <ui-icon
                    name="question-circle"
                    class="ui-m-sm-l question-circle"
                    color="var(--tooltip-icon)"
                    :size="14"
                    lib="fa-6"
                    substyle="fa-solid"
                  />
                </el-tooltip>
              </div>
              <div v-if="row.key" class="block">
                <span v-if="!data[row.key] && row.defaultText" :style="{color: 'var(--tooltip-icon)'}">{{ $t(row.defaultText) }}</span>
                <span v-else>{{ data[row.key] === null ? '' : data[row.key] }}</span>
              </div>
              <div v-if="row.renderValue" class="block" v-html="row.renderValue(data)" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import errorHandleMixin from '@/views/mixins/error-hadle';
import sessionAction from '@/views/mixins/session-action';

export default {
  name: 'SystemSettings',
  mixins: [errorHandleMixin, sessionAction],
  data() {
    return {
      isLoading: true,
      lazyLoading: true,
      data: {
        affiliate_program_name: '',
        transactional_email_from_mailbox: '',
        transactional_email_reply_to_address: '',
        transactional_email_from_domain: '',
        tos_url: '',
        privacy_policy_url: '',
        panel_contact_skype: '',
        panel_contact_telegram: '',
        panel_contact_email: '',
        affiliates_referral_enabled: false,
        service_domain_changes_notifications_enabled: false,
        affiliates_referral_percent: 0,
        affiliates_referral_payment_type: '',
        affiliates_payments_net: '',
        affiliates_payments_negative_carryover: false,
      },
      confirm: {
        title: '',
        data: {},
        fields: [],
      },
      popup: {
        affiliateProgramName: {
          title: 'settings.systemSettings.popup.affiliateProgram',
          fields: [
            {
              title: 'settings.systemSettings.affiliateProgramName',
              key: 'affiliate_program_name',
              type: 'text',
            },
          ],
          action: params => this.$api.putCustomersAffiliateProgramName(params),
        },
        emailSettings: {
          title: 'settings.systemSettings.emailSettings',
          fields: [
            {
              title: 'settings.systemSettings.transactionalEmailSender',
              key: 'transactional_email_from_mailbox',
              type: 'text',
              renderPost: () => `@${this.data.transactional_email_from_domain}`,
              hint: 'settings.systemSettings.serviceEmailHint',
            },
            {
              title: 'settings.systemSettings.replyTo',
              key: 'transactional_email_reply_to_address',
              type: 'text',
              hint: 'settings.systemSettings.supportEmailHint',
            },
          ],
          action: ({ transactional_email_reply_to_address, transactional_email_from_mailbox }) => this.$api.putCustomersEmailSettings({
            transactional_email_reply_to_address,
            transactional_email_from_mailbox,
          }),
        },
        affiliatesTelegramBot: {
          title: 'settings.systemSettings.affiliatesTelegramBot',
          hint: 'settings.systemSettings.affiliatesTelegramBotHint',
          fields: [
            {
              title: 'settings.systemSettings.botUsername',
              key: 'affiliates_telegram_bot_username',
              type: 'text',
            },
            {
              title: 'settings.systemSettings.botToken',
              key: 'affiliates_telegram_bot_token',
              type: 'text',
            },
          ],
          action: ({ affiliates_telegram_bot_token, affiliates_telegram_bot_username }) => this.$api.putCustomersAffiliateTelegramBotSettings({
            ...(affiliates_telegram_bot_token && { affiliates_telegram_bot_token }),
            ...(affiliates_telegram_bot_username && { affiliates_telegram_bot_username }),
          }),
        },
        policies: {
          title: 'settings.systemSettings.policies',
          hint: 'settings.systemSettings.policiesHint',
          fields: [
            {
              title: 'settings.systemSettings.termsOfServiceURL',
              key: 'tos_url',
              type: 'text',
            },
            {
              title: 'settings.systemSettings.privacyPolicyURL',
              key: 'privacy_policy_url',
              type: 'text',
            },
          ],
          action: params => this.$api.putCustomersPrivacyPolicySettings(params),
        },
        supportContacts: {
          title: 'settings.systemSettings.supportContacts',
          hint: 'settings.systemSettings.supportContactsHint',
          fields: [
            {
              title: 'settings.systemSettings.skype',
              key: 'panel_contact_skype',
              type: 'text',
            },
            {
              title: 'settings.systemSettings.telegram',
              key: 'panel_contact_telegram',
              type: 'text',
            },
            {
              title: 'settings.systemSettings.email',
              key: 'panel_contact_email',
              type: 'text',
            },
          ],
          action: params => this.$api.putCustomersSupportContactsSettings(params),
        },
        referralProgramDefaultSettings: {
          title: 'settings.systemSettings.referralProgramDefaultSettings',
          fields: [
            {
              title: 'settings.systemSettings.popup.referralProgram',
              key: 'affiliates_referral_enabled',
              label: isEnabled => (isEnabled ? 'settings.systemSettings.enabled' : 'settings.systemSettings.disabled'),
              type: 'boolean',
            },
            {
              title: 'settings.systemSettings.referralPercent',
              key: 'affiliates_referral_percent',
              type: 'number',
            },
            {
              title: 'settings.systemSettings.popup.revenueCalculation',
              key: 'affiliates_referral_payment_type',
              type: 'select',
              options: ['ggr', 'ngr'],
            },
          ],
          action: params => this.$api.putCustomersAffiliateReferralProgramSettings(params),
        },
        paymentsDefaultSettings: {
          title: 'settings.systemSettings.paymentsDefaultSettings',
          fields: [
            {
              title: 'settings.systemSettings.popup.paymentPeriod',
              key: 'affiliates_payments_net',
              type: 'select',
              options: ['net7', 'net15', 'net30', 'net60', 'manual'],
            },
            {
              title: 'settings.systemSettings.negativeCarryover',
              key: 'affiliates_payments_negative_carryover',
              label: isEnabled => (isEnabled ? 'settings.systemSettings.yes' : 'settings.systemSettings.no'),
              type: 'boolean',
            },

          ],
          action: params => this.$api.putCustomersAffiliatePaymentsSettings(params),
        },
        domainChangeNotifications: {
          title: 'settings.systemSettings.domainChangeNotifications',
          fields: [
            {
              title: 'settings.systemSettings.enableNotifications',
              key: 'service_domain_changes_notifications_enabled',
              label: isEnabled => (isEnabled ? 'settings.systemSettings.on' : 'settings.systemSettings.off'),
              type: 'boolean',
            },

          ],
          action: params => this.$api.putCustomersDefaultDomainsNotificationsSettings(params),
        },
      },

      fields: [
        {
          header: {
            title: 'settings.systemSettings.affiliateProgramName',
            key: 'affiliateProgramName',
          },
          rows: [
            {
              renderValue: ({ affiliate_program_name }) => `<span style="font-size: 24px; font-weight: 500">${affiliate_program_name}</span>`,
            },
          ],
        },
        {
          header: {
            title: 'settings.systemSettings.emailSettings',
            key: 'emailSettings',
          },
          rows: [
            {
              title: 'settings.systemSettings.transactionalEmailSender',
              renderValue: ({ transactional_email_from_mailbox, transactional_email_from_domain }) => (transactional_email_from_mailbox ? `<span>${transactional_email_from_mailbox}@${transactional_email_from_domain}</span>` : this.$t('user_info.not_specified')),
              hint: 'settings.systemSettings.serviceEmailHint',
            },
            {
              title: 'settings.systemSettings.replyTo',
              key: 'transactional_email_reply_to_address',
              hint: 'settings.systemSettings.supportEmailHint',
              defaultText: 'user_info.not_specified',
            },
          ],
        },
        {
          header: {
            title: 'settings.systemSettings.affiliatesTelegramBot',
            key: 'affiliatesTelegramBot',
            hint: 'settings.systemSettings.affiliatesTelegramBotHint',
          },
          rows: [
            {
              title: 'settings.systemSettings.botUsername',
              key: 'affiliates_telegram_bot_username',
              defaultText: 'user_info.not_specified',
            },
            {
              title: 'settings.systemSettings.botToken',
              key: 'affiliates_telegram_bot_token',
              defaultText: 'user_info.not_specified',
            },
          ],
        },
        {
          header: {
            title: 'settings.systemSettings.policies',
            key: 'policies',
            hint: 'settings.systemSettings.policiesHint',
          },
          rows: [
            {
              title: 'settings.systemSettings.termsOfServiceURL',
              renderValue: ({ tos_url }) => (tos_url ? `<a href="${tos_url}" target="_blank" style="color: ${this.$theme.accentColor}; text-decoration: none">${tos_url}</a>` : `<span style="color: var(--tooltip-icon)">${this.$t('user_info.not_specified')}</span>`),
            },
            {
              title: 'settings.systemSettings.privacyPolicyURL',
              renderValue: ({ privacy_policy_url }) => (privacy_policy_url ? `<a href="${privacy_policy_url}" target="_blank" style="color: ${this.$theme.accentColor}; text-decoration: none">${privacy_policy_url}</span>` : `<span style="color: var(--tooltip-icon)">${this.$t('user_info.not_specified')}</span>`),
            },
          ],
        },
        {
          header: {
            title: 'settings.systemSettings.supportContacts',
            key: 'supportContacts',
            hint: 'settings.systemSettings.supportContactsHint',
          },
          rows: [
            {
              title: 'settings.systemSettings.skype',
              messanger: 'skype',
              key: 'panel_contact_skype',
              defaultText: 'user_info.not_specified',
            },
            {
              title: 'settings.systemSettings.telegram',
              messanger: 'telegram',
              key: 'panel_contact_telegram',
              defaultText: 'user_info.not_specified',
            },
            {
              title: 'settings.systemSettings.email',
              preIcon: 'envelope',
              key: 'panel_contact_email',
              defaultText: 'user_info.not_specified',
            },
          ],
        },
        {
          header: {
            title: 'settings.systemSettings.referralProgramDefaultSettings',
            key: 'referralProgramDefaultSettings',
          },
          rows: [
            {
              title: 'settings.systemSettings.referralProgram',
              renderValue: ({ affiliates_referral_enabled }) => `<span style="color: ${affiliates_referral_enabled ? this.$theme.accentColor : this.$theme.dangerColor}">
                ${affiliates_referral_enabled ? this.$t('settings.systemSettings.enabled') : this.$t('settings.systemSettings.disabled')}
              </span>`,
            },
            {
              title: 'settings.systemSettings.referralPercent',
              renderValue: ({ affiliates_referral_percent, affiliates_referral_payment_type = '' }) => `${affiliates_referral_percent}% ${affiliates_referral_payment_type.toUpperCase()}`,
            },
          ],
        },
        {
          header: {
            title: 'settings.systemSettings.paymentsDefaultSettings',
            key: 'paymentsDefaultSettings',
          },
          rows: [
            {
              title: 'settings.systemSettings.period',
              renderValue: ({ affiliates_payments_net }) => affiliates_payments_net && `<span>${this.$t(`settings.systemSettings.popup.${affiliates_payments_net}`)}</span>`,
            },
            {
              title: 'settings.systemSettings.negativeCarryover',
              renderValue: ({ affiliates_payments_negative_carryover }) => `<span style="color: ${affiliates_payments_negative_carryover ? this.$theme.accentColor : this.$theme.dangerColor}">
                ${affiliates_payments_negative_carryover ? this.$t('settings.systemSettings.yes') : this.$t('settings.systemSettings.no')}
              </span>`,
            },
          ],
        },
        {
          header: {
            title: 'settings.systemSettings.domainChangeNotifications',
            key: 'domainChangeNotifications',
          },
          rows: [
            {
              title: 'settings.systemSettings.enableNotifications',
              renderValue: ({ service_domain_changes_notifications_enabled }) => `<span style="color: ${service_domain_changes_notifications_enabled ? this.$theme.accentColor : this.$theme.dangerColor}">
                ${service_domain_changes_notifications_enabled ? this.$t('settings.systemSettings.on') : this.$t('settings.systemSettings.off')}
              </span>`,
            },
          ],
        },
      ],
    };
  },
  computed: {},
  watch: {},
  mounted() {
    this.fetchData();
    this.$nextTick(() => {
      setTimeout(() => {
        this.lazyLoading = false;
      }, 200);
    });
  },
  created() {
    this.onSessionUnlock = () => {
      this.fetchData();
    };
  },
  methods: {
    preventSymbol(e) {
      if (!(/^[0-9]*\.?[0-9]*$/).test(e.key) && ![8, 46].includes(e.keyCode)) {
        e.preventDefault();
      }
    },
    fetchData() {
      this.isLoading = true;
      return Promise.all([
        this.$api.getCustomersAffiliateProgramName().then(res => res.data.payload),
        this.$api.getCustomersEmailSettings().then(res => res.data.payload),
        this.$api.getCustomersAffiliateTelegramBotSettings().then(res => res.data.payload),
        this.$api.getCustomersPrivacyPolicySettings().then(res => res.data.payload),
        this.$api.getCustomersSupportContactsSettings().then(res => res.data.payload),
        this.$api.getCustomersAffiliateReferralProgramSettings().then(res => res.data.payload),
        this.$api.getCustomersAffiliatePaymentsSettings().then(res => res.data.payload),
        this.$api.getCustomersDefaultDomainsNotificationsSettings().then(res => res.data.payload),
      ]).then((res) => {
        this.data = res.reduce((acc, item) => ({
          ...acc,
          ...item,
        }), {});
      }).finally(() => this.isLoading = false);
    },
    onSave() {
      const data = Object.entries(this.confirm.data).reduce((acc, [key, value]) => {
        if (key === 'affiliates_referral_percent') {
          acc[key] = +value;
        } else {
          acc[key] = value;
        }

        return acc;
      }, {});

      this.confirm.action(data)
        .then(() => {
          this.$refs.confirm.close();
          this.fetchData();
        })
        .catch((err) => {
          this.errors = err.data.errors;
        });
    },
    handleEdit(val) {
      this.confirm = {
        ...this.popup[val],
        data: this.popup[val].fields.reduce((acc, { key }) => {
          acc[key] = this.data[key];
          return acc;
        }, {}),
      };
      this.errors = {};
      this.$refs.confirm.open();
    },
  },
};
</script>
<style lang="scss" scoped>
.wrapper{
  width: 50%;
  min-width: 556px;
  margin: 0 auto;

  .popup {
    z-index: 999;

    .form-field + .form-field {
      margin-top: 16px;
    }

    .select {
      width: 100%;
    }

    .header {
      font-size: 14px;
      margin-bottom: 5px;
    }

    .select-title {
      font-size: 14px;
      margin-bottom: 5px;
    }

    .hint {
      font-size: 14px;
      font-style: normal;
      line-height: 1.71;
      margin-bottom: 16px;
    }

    .row-bg {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-left: 8px;
      padding-right: 8px;
      border-radius: 4px;
      background-color: var(--crm-panel-bg);
      height: 32px;
      white-space: nowrap;

      font-size: 14px;
      font-weight: 500;

      .toggle-label {
        font-weight: 400;
        font-size: 12px;
      }
    }

    .form-input {
      input[type="number"]::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      /deep/ .input-post-text {
        font-size: 14px;
      }
    }
  }
}

.toolbar {
  margin: 16px 0;

  .title {
    font-size: 24px;
    line-height: 1.25;
    font-weight: 500;
  }
}

.body {
  display: flex;
  line-height: normal;
  flex-direction: column;
  position: relative;

  &.lazyLoading {
    display: none;
  }

  .card {
    margin-bottom: 8px;
  }

  .field {
    .block {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .block-title {
      white-space: nowrap;
    }

    div + div {
      margin-left: 64px;
    }
  }
}
</style>

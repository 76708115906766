export default {
  affiliates: {
    name: 'Партнеры',
    filters: {
      status: 'Статус',
      country: 'Страна',
      onlyMultiaccounts: 'Только мультиаккаунты',
      introducedBy: 'Приглашен',
      subAffiliatesProgram: 'Суб-партнерская программа',
      mediaBuying: 'Медиабаинг',
      createPayments: 'Создание платежей',
      paymentMethod: 'Платежный метод',
      wallet: 'Кошелек',
      paymentPeriod: 'Платежный период',
      negativeCarryover: 'Негативный баланс',
      promoCode: 'Промокод',
    },
    menu: {
      list: 'Список партнеров',
      review: 'Партнеры на рассмотрении',
      requests: 'Заявки партнеров',
      groups: 'Группы',
    },
    time: {
      days: '0 дней | {count} день | {count} дня | {count} дней',
      hours: '0 часов | {count} час | {count} часа | {count} часов',
      milliseconds: '0 милисекунд | {count} милисекунда | {count} милисекунд | {count} часов',
      minutes: '0 минуты | {count} минута | {count} минут | {count} минут',
      months: '0 месяцев | {count} месяц | {count} месяцев | {count} месяцев',
      seconds: '0 секунды | {count} секунда | {count} секунд | {count} секунд',
      years: '0 лет | {count} год | {count} лет | {count} лет',
    },
    delete_offer: {
      title: 'Удалить оффер',
      msg: 'Вы пытаетесь удалить оффер, к которому прикреплены игроки. Выберите другой оффер партнера или открепите всех игроков.',
      offer: 'Оффер',
      select_offer: 'Выбрать оффер',
      required: 'Обязательное поле',
      or: 'или',
      unpin_player: 'Открепить',
      select_aff: 'Выбрать партнера',
      cancel: 'Отменить',
      confirm: 'Подтвердить',
    },
    public_profile: {
      title: 'Публичный профиль',
      no_data: 'Нет публичного профиля',
      confirm: {
        title: 'Удаление публичного профиля',
        msg: 'Вы уверены, что хотите удалить публичный профиль?',
      },
      edit: {
        title: 'Редактирование публичной информации',
        name: 'Имя',
        misc: 'Прочее',
        misc_placeholder: 'Публичная заметка',
        add_messenger: 'Добавить другой мессенджер',
        messenger: 'Мессенджер',
        messenger_id: 'Мессенджер ID',
      },
    },
    offer_popup: {
      range_min: 'Мин',
      range_max: 'Макс',
      copy_of: 'Копия',
      rule: 'Правило',
      Add: 'Добавить',
      Edit: 'Отредактировать',
      offer: 'Оффер',
      general: 'Общий',
      offer_label: 'Название оффера',
      brands: 'Бренды',
      brands_all: 'Все',
      brands_only_selected: 'Только выбранные',
      add_brands: 'Выберите бренды',
      offer_placeholder: 'Название оффера',
      g_daily_cap: 'Общий лимит в день',
      g_hold: 'Общий холд',
      rules: 'Правила',
      add_rules: 'Добавить новое правило',
      rule_label: 'Название',
      rule_placeholder: 'Правило',
      rev_share: 'RevShare',
      sport: 'Спорт',
      sport_casino: 'Спорт & Казино',
      seporate: 'Раздельный',
      casino: 'Казино',
      cpa: 'CPA',
      inherit: 'Наслед.',
      daily_cap: 'Лимит',
      cap_players: 'Лимит игроков',
      per: 'за',
      hold: 'Холд',
      day: 'Дни',
      filters: 'Фильтры',
      add_filter: 'Добавить фильтр',
      country: 'Страны',
      avalible: 'Разрешены',
      deny: 'Не разрешены',
      add_country: 'Добавить страну',
      sub_id: 'Sub ID',
      not_equals: 'Не разрешены',
      equals: 'Разрешены',
      info_subid: 'Нажмите Enter после ввода SubID',
      sub_id_placeholder: 'Добавить Sub ID',
      payment_rules: 'Правила оплаты',
      cpa_conditions: 'Условия оплаты',
      add_rule: 'Добавить правило',
      add_condition: 'Добавить условие',
      add_cpa_payout: 'Добавить выплату CPA',
      cpa_payout: 'CPA выплата',
      deposits: 'Депозиты',
      deposit_count: 'Количество депозитов',
      deposit_sum: 'Сумма депозитов',
      deposit_amount: 'Сумма одного депозита',
      sport_bet: 'Ставки на спорт',
      bet_count: 'Количество ставок',
      bet_sum: 'Сумма ставок',
      bet_amount: 'Сумма одной ставки',
      bet_odd: 'Коэффициент',
      casino_bets: 'Ставки на казино',
      cancel: 'Отменить',
      save: 'Сохранить',
      apply_now: 'Применить сейчас',
      apply: 'Применить',
      eff_date_time: 'Начиная с',
      schedule_changes: 'Сохранить',
      payout: 'Выплата',
      only_selected: 'Только выбранные',
      except_selected: 'Except Selected',
      add_level: 'Добавить уровень',
      separate: 'Разделять',
      empty_cpa_conditions: 'Должно быть установлено хотя бы одно условие CPA',
      confirm_rule: {
        title: 'Удалить правило оффера?',
        msg: 'Вы уверены, что хотите удалить правило?',
        action: 'удалить правило',
      },
      confirm_filter: {
        title: 'Удалить фильтр оффера?',
        msg: 'Вы уверены, что хотите удалить фильтр?',
        action: 'удалить фильтр',
      },
      confirm_payment: {
        title: 'Удалить условие оффера?',
        msg: 'Вы уверены, что хотите удалить условие оффера?',
        action: 'удалить условие',
      },
      confirm_cpa_payout: {
        title: 'Удалить выплату CPA?',
        msg: 'Вы уверены, что хотите удалить выплату CPA?',
        action: 'уалить выплату CPA',
      },
      confirm_close: {
        title: 'Закрыть без сохранения?',
        msg: 'Вы уверены, что хотите закрыть без сохранения?',
        action: 'Закрыть',
        closeAction: 'Отменить',
      },
    },
    transfer_popup: {
      from: 'От партнера',
      to: 'К партнеру',
      placeholder_affiliate: 'Выберите партнера',
      sub_affiliates: 'Суб-партнеры',
      all: 'Все',
      selected: 'Выбранные',
      country: 'Страна',
      placeholder_country: 'Выберите страны',
      earning: 'Заработок за 30 дней',
      amount: 'Сумма',
      balance: 'Баланс',
      registration_date: 'Дата регистрации',
      emptyAffiliates: '<span class="bold">Выберите партнера</span>&nbsp;для переноса суб-партнеров',
      transferSubAffiliates: 'Перенос суб-партнеров',
      transferSubAffiliatesCounter: '<span class="bold">суб-партнеров</span> будут перенесены',
      transfer: 'Перенести',
    },
    offers: {
      revshare: {
        label: 'Revenue Share',
        fields: {
          rev_share_percent: 'revshare',
          hold: 'холд',
        },
      },
      cpa: {
        label: 'CPA',
        fields: {
          payout: 'выплата',
          daily_cap: 'лимит в день',
          hold: 'холд',
        },
      },
      deposits: {
        label: 'Депозиты',
        fields: {
          deposits_count: 'Deposits count',
          deposits_sum: 'Общая сумма депозитов',
          deposit_value: 'Сумма одного депозита',
        },
      },
      sports: {
        label: 'Ставки на спорт',
        fields: {
          bets_count: 'Bets count',
          bets_sum: 'Общая сумма ставок',
          bet_value: 'Сумма одной ставки',
          bet_coefficient: 'коэффициент',
        },
      },
      games: {
        label: 'Ставки на казино',
        fields: {
          bet_games_count: 'Bet Games count',
          bet_games_sum: 'Общая сумма ставок',
          bet_game_value: 'Сумма одной ставки',
        },
      },
    },
    sublist: {
      fields: {
        messenger_id: {
          title: 'Messenger',
        },
        email: {
          title: 'E-Mail',
        },
        first_name: {
          title: 'Имя',
        },
        name: {
          title: 'Имя',
        },
        created_at: {
          title: 'Дата регистрации',
        },
      },
    },
    dedicated_domains: {
      fields: {
        incrementing_id: {
          title: 'ID',
        },
        domain_name: {
          title: 'Домен',
        },
        affiliate_offer_name: {
          title: 'Название оффера',
        },
        created_at: {
          title: 'Дата регистрации',
        },
      },
    },
    promocodes_list: {
      fields: {
        promo_code: {
          title: 'Промокод',
        },
        admin_name: {
          title: 'Админ',
        },
        created_at: {
          title: 'Создан',
        },
      },
    },
    list: {
      fields: {
        incrementing_id: {
          title: 'ID',
        },
        email: {
          title: 'Email',
        },
        group_id: {
          title: 'Группа',
        },
        country_code: {
          title: 'Страна',
        },
        team_name: {
          title: 'Команда',
        },
        referred_by_email: {
          title: 'Приглашен',
        },
        last_month_revenue: {
          title: 'Заработок за 30 дней',
        },
        created_at: {
          title: 'Дата регистрации',
        },
        site: {
          title: 'Сайт',
        },
        site_url: {
          title: 'Сайт',
        },
        available_balance: {
          title: 'Баланс',
        },
        hold_balance: {
          title: 'Холд',
        },
        offer_name: {
          title: 'Название оффера',
        },
        timestamp: {
          title: 'Дата',
        },
        description: {
          title: 'Описание',
        },
        action_timestamp: {
          title: 'Дата',
        },
        action_name: {
          title: 'Описание',
        },
        action: {
          title: 'Action',
        },
        ip_address: {
          title: 'IP Адрес',
        },
        initiator: {
          title: 'Инициатор',
        },
        initiator_id: {
          title: 'ID Инициатора',
        },
        os: {
          title: 'ОС',
        },
        device: {
          title: 'Устройство',
        },
        grouping: {
          title: 'Дата',
        },
        transaction_type: {
          title: 'Тип транзакции',
        },
        transaction_description: {
          title: 'Описание',
        },
        amount_to_hold: {
          title: 'Зачислено (в холд)',
        },
        amount_to_available: {
          title: 'Зачислено (подтверждено)',
        },
        result_balance_hold: {
          title: 'Баланс (на проверке)',
        },
        result_balance_available: {
          title: 'Доступный остаток',
        },
      },
    },
    payments: {
      fields: {
        incrementing_id: {
          title: 'ID',
        },
        created_at: {
          title: 'Создан',
        },
        amount: {
          title: 'Количество',
        },
        moderation_status: {
          title: 'Модерация',
        },
        payout_status: {
          title: 'Проведение',
        },
        payment_period_end: {
          title: 'Период оплаты',
        },
        approved_by_admin_name: {
          title: 'Одобрен',
        },
        site_payment_method_name: {
          title: 'Метод',
        },
        payment_wallet: {
          title: 'Кошелек',
        },
        paid_at: {
          title: 'Оплачено',
        },
      },
    },
    listView: {
      toolbar: [
        {
          search: {
            placeholder: 'Поиск ...',
          },
          groupFilter: {
            label: 'Группа',
            placeholder: 'Все',
          },
          countryFilter: {
            label: 'Страна',
            placeholder: 'Все',
          },
          refreshBtn: {
            text: 'Обновить',
          },
        },
        {
          statusFilter: {
            active: 'Активные',
            on_review: 'На рассмотрении',
            declined: 'Отклонено',
            not_paid: 'Не выплачено',
            for_rework: 'На доработке',
            processing: 'В обработке',
            paid: 'Выплачено',
            failed: 'Неудачно',
            approved: 'Одобрен',
            blocked: 'Заблок.',
            rejected: 'Отклонен',
            multiaccounts: 'Только мультиакк.',
          },
          export: {
            label: 'Выгрузить',
          },
          exportBtn: {
            text: 'Выгрузить',
          },
        },
      ],
      table: {
        headers: {
          email: 'Email',
          group_id: 'Группа',
          country_code: 'Страна',
          last_month_revenue: 'Заработок за 30 дней',
          created_at: 'Дата регистрации',
          site: 'Сайт',
          available_balance: 'Баланс',
          hold_balance: 'Холд',
        },
      },
    },
    cardView: {
      emptyField: 'Не указано',
      loadingData: 'Загрузка данных',
      nav: {
        info: 'Информация',
        sub: 'Суб-партнеры',
        offers: 'Офферы',
        promocodes: 'Промокоды',
        bonuses: 'Бонусы',
        payments: 'История платежей',
        estpayment: 'Отчет о платежах',
        dedication: 'Выделенные домены',
      },
      toolbar: {
        backLinks: ['Список партнеров', 'Партнеры на рассмотрении', 'История баланса', 'История'],
        btns: {
          block: 'Заблокировать партнера',
          activate_manual: 'Активировать вручную',
          create_payment: 'Создать платеж',
          unblock: 'Разблокировать партнера',
          login: 'Войти',
          statistics: 'Статистика',
          reset: 'Сбросить пароль',
          resetTotp: 'Сбросьте 2FA',
          edit: 'Редактировать',
          cancel: 'Отменить',
          save: 'Сохранить изменения',
          decline: 'Отклонить',
          note_decline: 'Отклонить с уведомлением',
          approve: 'Одобрить',
          addOffer: 'Добавить Оффер',
          close: 'Закрыть',
          dontSave: 'Не сохранять',
          revoke: 'На рассмотрение',
          addSubAffiliate: 'Добавить суб-партнера',
        },
      },
      info: {
        system: 'Система',
        balance: 'Баланс',
        holdBalance: 'Баланс на удержании',
        paymentMethod: 'Способы оплаты',
        walletNumber: 'Номер кошелька',
        wallet: 'Кошелек',
        paymentPeriod: 'Период оплаты',
        lastLogin: 'Последний вход',
        lastLoginEmpty: 'Не входил',
        regDae: 'Дата регистрации',
        historyTitle: 'История',
        noHistoryTitle: 'Нет истории',
        historyLink: 'Полная история',
        group: 'Группа',
        birthDay: 'Дата рождения',
        introduced: 'Приглашен',
        introducedEmpty: 'К партнеру не привязан суб-партнер',
        team: 'Команда',
        brands: 'Доступные бренды и Комиссии',
        country: 'Страна',
        city: 'Город',
        adress: ['Adress Line 1', 'Adress Line 2'],
        site: 'Site url',
        site_urls: 'Список ссылок',
        add_url: 'Добавить URL',

        email: 'Email',
        fname: 'Имя',
        surname: 'Фамилия',
        company: 'Название компании',
        phone: 'Номер телефона',
        messenger: 'Messenger',
        msgid: 'Messenger ID',
        negative_carryover: 'Негативный баланс',
        payments_enabled: 'Создание выплат',
        personal_info: 'Личная информация',
        change_mail: 'Изменить email',
        status_yes: 'Да',
        status_no: 'Нет',
        postal_code: 'Индекс',
        address: 'Адрес',
      },
      notes: {
        title: 'Заметки',
        addNote: 'Добавить Заметку',
        newNote: 'Текст',
        noNotes: 'Нет заметки',
        types: {
          all: 'Все',
          user: 'Пользоват.',
          system: 'Система',
        },
      },
    },
    offersView: {
      title: 'Офферы',
      requests: {
        btns: {
          decline: 'Отклонить',
          declineNotification: 'Отклонить с уведомлением',
          accept: 'Принять',
        },
      },
      offers: {
        btns: {
          unpin: 'Открепить',
          edit: 'Ред.',
        },
      },
      labels: {
        revenueShare: 'Revenue Share',
        cpa: 'CPA',
        revshare: 'Revshare',
        hold: 'Холд',
        payout: 'Выплата',
        cap: 'Лимит в день',
        depositAmount: 'Сумма одного депозита',
        depositSum: 'Сумма депозитов',
        depositNumbers: 'Количество депозитов',
        betAmount: 'Сумма одной ставки',
        betSum: 'Сумма ставок',
        betNumbers: 'Количество ставок (макс)',
        countryFilter: 'Фильтр по странам',
        trafficName: 'Название источника трафика',
        trafficURL: 'URL источника трафика',
      },
      filter: {
        title: 'Доступные страны',
        list: {
          country_code: 'Страны',
          subid1: 'Sub ID 1',
          subid2: 'Sub ID 2',
          subid3: 'Sub ID 3',
          subid4: 'Sub ID 4',
          subid5: 'Sub ID 5',
        },
        rule: {
          deposits: 'Депозиты',
          sport_bets: 'Ставки на спорт',
          casino_bets: 'Ставки на казино',
        },
        quick: {
          asia: 'Азия',
          latin: 'Латинская Америка',
          all: 'Все',
          cis: 'Содружество Независимых Государств',
        },
      },
      holdRanges: {
        seconds: 'Секунды',
        minutes: 'Минуты',
        hours: 'Часы',
        days: 'Дни',
        months: 'Месяцы',
        weeks: 'Недели',
      },
    },
    affiliatesActivity: {
      activityLog: 'Журнал активности',
      affiliateInfo: 'Информация о партнере',
      dateRange: 'Диапазон',
      week: 'Неделя',
      week_2: '2 недели',
      weeks: 'Недели',
      month: 'Месяц',
      date: 'Дата',
      description: 'Описание',
      action: 'Действие',
      ipAddress: 'IP адрес',
      initiatorRole: 'Initiator Role',
      initiatorName: 'Initiator Name',
      initiatorId: 'Initiator ID',
      panel: 'Из панели',
      admin: 'Из админки',
      overall: 'Общий',
    },
    affiliatesInfo: {
      currency: {
        label: 'Валюта',
        popup: {
          title: 'Сменить валюту',
        },
      },
      multiaccounts: 'Мультиаккаунты',
      login: 'Войти',
      beta: 'Бета',
      paymentinfo: 'Информация о платежах',
      save: 'Сохранить',
      edit: 'Ред.',
      editFees: 'Ред. Комиссии',
      history: 'История',
      openInfo: 'Открыть информацию',
      unpin: 'Открепить',
      pin: 'Закрепить',
      block_title: 'Заблокировать {affiliateEmail}',
      block_action: 'Заблокировать партнера',
      blockText: 'Вы уверены, что хотите заблокировать <span>{affiliateEmail}</span>?',
      unblock_title: 'Разблокировать {affiliateEmail}',
      unblock_action: 'Разблокировать партнера',
      unblockText: 'Вы уверены, что хотите разблокировать <span>{affiliateEmail}</span>?',
      approve: 'Одобрить',
      approve_title: 'Одобрить {affiliateEmail}',
      approve_action: 'Одобрить партнера',
      approveText: 'Вы уверены, что хотите одобрить партнера <span>{affiliateEmail}</span>?',
      review_title: 'Отправить {affiliateEmail} на рассмотрение',
      toReviewText: 'Вы уверены, что хотите отправить партнера <span>{affiliateEmail}</span> на рассмотрение?',
      review_action: 'Отправить на рассмотрение',
      decline: 'Отклонить партнера',
      decline_and_notify: 'Отклонить и уведомить партнера',
      declineText: 'Вы уверены, что хотите отклонить <span>{affiliateEmail}</span>?',
      declineAndNotifyText: 'Вы уверены, что хотите отклонить и уведомить <span>{affiliateEmail}</span>?',
      affiliate: 'партнера',
      operation: 'Действия',
      inp_sum: 'Ввести сумму',
      sum: 'Сумма',
      note: 'Заметка',
      note_about_user: 'Заметка о пользователе',
      reason: 'Причина',
      deposit: 'депозит',
      withdraw: 'вывод',
      total: 'Общий',
      in_hold: 'На удержании',
      available: 'Доступно',
      method: 'Метод',
      wallet: 'Кошелек',
      not_specefied: 'Не указано',
      period: 'Период',
      login_reg: 'Вход & Регистрация',
      report_setting: 'Настройки отчетов',
      media_buying_setting: 'Медиабаинг',
      cancel: 'Отменить',
      cpa_profit: 'Профит CPA',
      cpa_profit_for_aff: 'Профит CPA по суб-партнерам',
      refshare_profit: 'Профит RevShare',
      refshare_profit_for_aff: 'Профит RevShare по суб-партнерам',
      media_buying_access: 'Доступ в раздел Медиабаинг',
      rotation_groups_setting: 'Ротационная группа',
      set_as_main: 'Назначить главной',
      notifications_setting: 'Уведомления',
      max_limits: 'Макс. Ограничения',
      max_default: 'По умолчанию',
      max_api_keys: 'Ключи API',
      max_promo_codes: 'Промокоды',
      max_traffic_sources: 'Источники трафика',
      max_postbacks_per_traffic_source: 'Постбэков на источник трафика',
      max_tags_per_traffic_source: 'Тэгов на источник трафика',
      no_brands: 'Доступные офферы отсутствуют',
      fees: {
        title: 'Комиссии',
        default: 'По умолч.',
        default_value: 'Значение по умолчанию: {value}',
        deposit: 'Депозиты',
        withdrawal: 'Выводы',
        brand: 'Бренд',
        deposits_cpa_commission: 'Депозиты по CPA',
        withdrawals_cpa_commission: 'Выводы по CPA',
        deposits_rs_commission: 'Депозиты по RS',
        withdrawals_rs_commission: 'Выводы по RS',
      },
    },
    affiliate_notifications: {
      type: {
        telegram: 'Telegram',
        email: 'E-mail',
      },
      disabled: 'Выключено',
      popup: {
        title: 'Уведомления',
        notifications_enabled: 'Отправить уведомление',
        select_label: 'Отправить',
      },
    },
    confirm: {
      limits_popup_title: 'Редактировать максимальные лимиты',
      fees_popup_title: 'Редактировать Комиссии',

      change_email_title: 'Изменить Email на {name}',
      change_email_label: 'Введите новый email ниже и нажмите «Изменить email»',
      change_email_placeholder: 'Email',

      edit_balance: 'Редактировать баланс',
      save: 'Сохранить',
      edit: 'Редактировать',

      title_1: 'Редактировать баланс партнера',
      msg_1: 'Чтобы отредатировать баланс <span>{affiliateEmail}</span> пожалуйста выберите тип действия и введите необходимую сумму и заметки. Все поля обязательны.',

      title_2: 'Подтвердить редактирование баланса партнера',
      msg_2: 'Вы уверены, что хотите отредактировать баланс <span>{affiliateEmail}</span>?',
      action_2: 'Да',

      title_3: 'Редактировать информацию о платежах',
      msg_3: 'Выбрать новый способ оплаты и период для <span>{affiliateEmail}</span>?',

      title_5: 'Создать платеж?',
      msg_5: 'Вы уверены, что хотите создать платеж для <span>{affiliateEmail}</span>?',
      action_5: 'Создать платеж',

      title_6: 'Открепить партнера от {refferedEmail}',
      msg_6: 'Вы уверены, что хотите открепить партнера от <span>{refferedEmail}</span>?',
      msg_6_2: 'Вы уверены, что хотите открепить партнера?',
      msg_6_3: 'Вы уверены, что хотите закрепить партнера?',
      action_6: 'Открепить',

      title_8_1: 'Отклонить и уведомить {affiliateEmail}?',
      title_8_2: 'Отклонить {affiliateEmail}?',

      title_9: 'Успех',
      msg_9: 'Платеж был <span> успешно создан </span>',
      action_9: 'Готово',

      title_10: 'Невозможно создать платеж',
      action_10: 'Закрыть',

      resetTotp_title: 'Сбросить 2FA?',
      resetTotp_action: 'Сбросить 2FA',
      resetTotp_text: 'Вы уверены, что хотите сбросить 2FA для <span>{name}<span>?',

      delete_note_title: 'Удалить заметку?',
      delete_note_text: 'Вы уверены, что хотите удалить заметку?',
      delete_note_action: 'Удалить',

      AFFILIATE_ACCOUNT_STATUS_IS_NOT_SUITABLE_ERROR: 'Аккаунт не подтвержден',
      PAYMENT_CANNOT_BE_CREATED_EARLIER_ERROR: 'Платеж не может быть создан раньше чем',
      AFFILIATE_HAS_NO_WALLET_ERROR: 'Кошелек не указан',
      AMOUNT_LOWER_THAN_MINIMUM_PAYMENT_ERROR: 'Недостаточно средств для создания платежа',
      SITE_PAYMENT_METHOD_IS_DISABLED_ERROR: 'Платежный метод отключен',
      AFFILIATE_PAYMENTS_DISABLED_ERROR: 'Создание платежей для этого партнера отключено',
      payment_success: 'Платеж скоро будет создан!',
      payment_fail: 'Не удалось создать платеж!',
      aff_email_success: 'Email партнера успешно активирован!',
      aff_email_fail: 'Не удалось активировать email партнера!',
    },
    // $t('affiliates.affiliatesOffers.')
    affiliatesOffers: {
      actions: 'Действия',
      getLinks: 'Получить ссылки',
      copyLinks: 'Копировать ссылки',
      old: 'old',
      betCoefficient: 'Коэффициент (МИН)',
      offerName: 'Название оффера',
      declineOffer: 'Отклонить запрос на оффер',
      unpinOffer: 'Открепить оффер',
      from: 'from',
      trafficAcceptable: 'Принимается весь трафик',
      allowed: 'Разрешены',
      unallowed: 'Не разрешены',
      except: 'Кроме',
      countries: 'СТРАНЫ',
      failedCopyLink: 'Не удалось скопировать ссылку',
      copiedClipboard: 'Скопировано в буфер обмена',
      offer_detail: 'Добавить оффер для подробного просмотра',
      edit_offer_title: 'Отменить запланированные изменения?',
      edit_offer_msg: 'Изменения условий оффера отменят запланированные изменения, которые должны были вступить в силу',
      edit_offer_action: 'Отменить',
      unpin: 'Открепить',
      edit: 'Редактировать',
      cpa: 'CPA',
      global_daily_cap: 'Общий лимит в день',
      global_daily_cap_val: 'Не указано | {count} | {count}',
      global_hold: 'Общий холд',
      global_hold_val: 'Не указано | {count} | {count}',
      rule_candiion: 'Правила & Условия',
      rule: 'Правило',
      revshare: 'RevShare',
      rev_sport: 'RevShare Спорт',
      rev_casino: 'RevShare Казино',
      payout: 'Выплата',
      cap_players: 'Лимит игроков',
      cap_players_per_days: '{count} за 0 дней | {count} за {days} день | {count} за {days} дня | {count} за {days} дней',
      daily_cap: 'Лимит в день',
      hold: 'Холд',
      sub_id: 'Sub ID',
      not_equals: 'Не разрешены',
      equals: 'Разрешены',
      deposit: 'Депозиты',
      deposit_count: 'Количество депозитов',
      min: 'Мин: {count}',
      max: 'Макс: {count}',
      deposit_total: 'Общая сумма депозитов',
      one_deposit: 'Сумма одного депозита',
      sport_bet: 'Ставки на спорт',
      bet_count: 'Количество ставок',
      bet_sum: 'Сумма ставок',
      one_bet_amount: 'Сумма одной ставки',
      bet_odd: 'Коэффициент',
      casino_bet: 'Ставки на казино',
      not_set: 'Не указано',
      pand_change: 'Запланированные изменения',
      cancel_pand_change: 'Отменить запланированные изменения',
      percent: '{count}%',
      uncountable: '{count}',
      pending_date: '<span>Запланированные изменения</span> {date}',
      available_brands: 'Доступные бренды',
      available_brands_links: 'Доступные бренды и ссылки',
    },
    affiliatesGroups: {
      addGroup: 'Добавить группу',
      removeGroup: 'Удалить группу',
      members: 'Участники',
    },
    affiliateSub: {
      subAffProg: 'Реферальная программа',
      active: 'Активна',
      deactivate: 'Откл.',
      inactive: 'Отключена',
      activate: 'Актив.',
      change: 'Изменить',
      percentOfRevenue: 'Реферальный процент',
      default: 'По умолчанию',
      unpinSubAffiliate: 'Открепить суб-партнера',
      pinSubAffiliate: 'Закрепить суб-партнера',
      save: 'Сохранить',
      new_percent: 'Новый процент',
      revenue_calculation: 'Расчет дохода',
      pin_sub_aff: 'Закрепить суб-партнеров',
      pin_sub_aff_to: 'Закрепить суб-партнера за {email}',
      pin_to_aff: 'Закрепить {email} за партнером',
      confirm: {
        title_1: 'Изменение процента прибыли',
        msg_1: 'Введите новый процент заработка ниже',

        title_2: 'Отключить реферальную программу?',
        msg_2: 'Вы уверены, что хотите отключить реферальную программу для <span>{affiliateEmail}</span>?<br/><span class="bold">В этом случае все суб-партнеры будут отвязаны от партнера.</span>',
        action_2: 'Отключить',

        title_3: 'Активировать реферальную программу?',
        msg_3: 'Вы уверены, что хотите активировать реферальную программу для <span>{affiliateEmail}</span>?',
        action_3: 'Активировать',

        title_4: 'Закрепить {subAffiliateEmail} за {affiliateEmail}',
        msg_4: 'Вы уверены, что хотите закрепить <span>{subAffiliateEmail}</span> за <span>{affiliateEmail}</span>?',
        action_4: 'Закрепить',

        title_5: 'Открепить {subAffiliateEmail} от {affiliateEmail}',
        msg_5: 'Вы уверены, что хотите открепить <span>{subAffiliateEmail}</span> от <span>{affiliateEmail}</span>?',
        action_5: 'Открепить',

        title_6: 'Открепить {subAffiliateEmail} от {affiliateEmail}',
        msg_6: 'Вы уверены, что хотите открепить оффер <span>{subAffiliateEmail}</span> от <span>{affiliateEmail}</span>?',
        action_6: 'Открепить',

        title_7: 'Открепить {subAffiliateEmail} от {affiliateEmail}',
        msg_7: 'Вы уверены, что хотите открепить оффер <span>{subAffiliateEmail}</span> от <span>{affiliateEmail}</span> и закрепить игроков за оффером <span>{offer}</span>?',
        action_7: 'Открепить',
      },
    },
    affiliatePromocode: {
      add_promocode: 'Добавить промокод',
      edit_promocode: 'Редактировать',
      delete_promocode: 'Удалить',
      statistics_promocode: 'Статистика',
      popup: {
        add_title: 'Добавить промокод',
        add_button: 'Добавить промокод',
        add_text: 'Введите промокод и выберите оффер ниже',
        edit_title: 'Редактировать промокод',
        edit_button: 'Сохранить',
        edit_text: 'Редактировать промокод и выбрать новый оффер',
        delete_title: 'Удалить промокод',
        delete_button: 'Удалить промокод',
        delete_text: 'Вы уверены, что хотите удалить промокод <span>{name}</span>?',
        offer_text: 'Оффер',
        label_text: 'Промокод',
        cancel: 'Отменить',
      },
    },
    affiliatePayments: {
      info: 'Инфо',
      statusFilter: {
        placeholder: 'Все статусы',
        moderation: 'Модерация платежей',
        payout: 'Проведение платежей',
        on_review: 'На рассмотрении',
        approved: 'Одобрено',
        declined: 'Отклонено',
        not_paid: 'Не выплачено',
        for_rework: 'На доработке',
        processing: 'В обработке',
        paid: 'Выплачено',
        failed: 'Неудачно',
        multiaccounts: 'Мультиаккаунты',
      },
      columns: {
        created_at: {
          title: 'Создан',
          fullTitle: 'Создан',
          tip: '',
          tooltip: '',
        },
        amount: {
          title: 'Количество',
          fullTitle: 'Количество',
          tip: '',
          tooltip: '',
        },
        status: {
          title: 'Статус',
          fullTitle: 'Статус',
          tip: '',
          tooltip: '',
        },
        payment_period_end: {
          title: 'Период оплаты',
          fullTitle: 'Период оплаты',
          tip: '',
          tooltip: '',
        },
        approved_by_admin_name: {
          title: 'Одобрен',
          fullTitle: 'Одобрен',
          tip: '',
          tooltip: '',
        },
        payment_method: {
          title: 'Способ',
          fullTitle: 'Способ',
          tip: '',
          tooltip: '',
        },
        payment_wallet: {
          title: 'Кошелек',
          fullTitle: 'Кошелек',
          tip: '',
          tooltip: '',
        },
        paid_at: {
          title: 'Оплачено',
          fullTitle: 'Оплачено',
          tip: '',
          tooltip: '',
        },
      },
    },
    balanceHistory: {
      transactions: {
        add: 'Доход',
        chargeback: 'Возврат',
        withdraw: 'Вывод',
        withdraw_abort: 'Вывод отменен',
        add_bonus: 'Бонус',
      },
      columns: {
        grouping: {
          title: 'Дата',
          fullTitle: 'Дата',
          tip: '',
          tooltip: '',
        },
        transaction_type: {
          title: 'Вид транзакции',
          fullTitle: 'Вид транзакции',
          tip: '',
          tooltip: '',
        },
        transaction_description: {
          title: 'Описание',
          fullTitle: 'Описание транзакции',
          tip: '',
          tooltip: '',
        },
        amount_to_hold: {
          title: 'На удержание',
          fullTitle: 'На удержание',
          tip: '',
          tooltip: '',
        },
        amount_to_available: {
          title: 'Доступно',
          fullTitle: 'Доступно',
          tip: '',
          tooltip: '',
        },
        result_balance_hold: {
          title: 'Баланс на удержании',
          fullTitle: 'Баланс на удержании',
          tip: '',
          tooltip: '',
        },
        result_balance_available: {
          title: 'Доступный баланс',
          fullTitle: 'Доступный баланс',
          tip: '',
          tooltip: '',
        },
      },
    },
    resetPassword: {
      newPassword: 'Новый пароль',
      repeatPassword: 'Повторить новый пароль',
      error_1: 'Пароли не совпадают',


      title_1: 'Сбросить пароль для {affiliateEmail}',
      msg_1: 'Ввести новый пароль для <span>{affiliateEmail}</span> и подтвердить',
      action_1: 'Сбросить пароль',
    },
    estimatedPayment: {
      title: 'Примерная оплата',
      total: 'Общий',
      next_payment: 'Следующая оплата',
      offer_static: 'Статистика по офферу',
      players: 'Игроки',
      offer: 'Оффер',
      revshare: 'RevShare',
      cpa: 'CPA',
      cpa_player: 'Игроки по CPA',
      hide_stats: 'Скрыть статистику после конверсии',
      all_offer: 'Все офферы',
      col_select: 'Выбрать колонки',
      for_net: 'для сети',
      playersFilter: {
        to_be_paid_in_next_payment: {
          title: 'В следующем платеже',
        },
        to_be_paid_after_next_payment: {
          title: 'После следующего платежа',
        },
        all_unpaid_players: {
          title: 'Неоплаченные игроки',
        },
        all_paid_players: {
          title: 'Оплаченные игроки',
        },
        all_players: {
          title: 'Все игроки',
        },
      },
      cpaPlayers: {
        fields: {
          site_player_id: {
            title: 'ID игрока',
          },
          external_id: {
            title: 'Внешний ID',
          },
          subid1: {
            title: 'SubID 1',
          },
          subid2: {
            title: 'SubID 2',
          },
          subid3: {
            title: 'SubID 3',
          },
          subid4: {
            title: 'SubID 4',
          },
          subid5: {
            title: 'SubID 5',
          },
          player_country_name: {
            title: 'Страна',
          },
          registration_timestamp: {
            title: 'Дата регистрации',
          },
          site_registration_type_name: {
            title: 'Тип регистрации',
          },
          latest_activity_timestamp: {
            title: 'Последняя активность',
          },
          latest_deposit_timestamp: {
            title: 'Последний депозит',
          },
          first_deposit_timestamp: {
            title: 'Дата первого депозита',
          },
          conversion_timestamp: {
            title: 'Дата конверсии',
          },
          hold_expiration_timestamp: {
            title: 'Дата выхода из холда',
          },
          top_deposits_payment_system_name: {
            title: 'ТОП ПС депозиты ',
          },
          top_withdrawals_payment_system_name: {
            title: 'ТОП ПС выводы',
          },
          deposits_count: {
            title: 'Количество депозитов',
            tip: 'количество',
          },
          deposits_sum: {
            title: 'Сумма депозитов',
            tip: 'Сумма',
          },
          withdrawals_count: {
            title: 'Количество выводов',
            tip: 'Количество',
          },
          withdrawals_sum: {
            title: 'Сумма выводов',
            tip: 'Сумма',
          },
          sport_bets_count: {
            title: 'Количество ставок на спорт',
            tip: 'Количество',
          },
          sport_bets_sum: {
            title: 'Сумма ставок на спорт',
            tip: 'Сумма',
          },
          avg_sport_bets_odd: {
            title: 'Средний коэффициент ставок на спорт',
            tip: 'Средний коэффициент',
          },
          median_sport_bets_odd: {
            title: 'Промежуточный коэффициент ставок на спорт',
            tip: 'Промежуточный коэффициент',
          },
          games_bets_count: {
            title: 'Количество ставок на казино',
            tip: 'Количество',
          },
          games_bets_sum: {
            title: 'Сумма ставок на казино',
            tip: 'Сумма',
          },
          betting_profit: {
            title: 'Прибыль со ставок',
          },
          cpa_revenue: {
            title: 'Заработок по CPA',
          },
          revshare_revenue: {
            title: 'Заработок по RS',
          },
          revenue: {
            title: 'Заработок',
          },
          total_profit: {
            title: 'Общий',
          },
          days_since_reg: {
            title: 'Дни с регистрации',
          },
          days_active: {
            title: 'Количество активных дней',
          },
        },
      },
    },
    confirn_rotation_set_main: {
      title: 'Сделать группу основной?',
      text: 'Вы уверены, что хотите установить эту группу как основную?',
    },
    confirn_rotation_delete: {
      title: 'Отвязать группу?',
      text: 'Вы уверены, что хотите отвязать эту группу от партнера?',
    },

    media_buying_setting_card: {
      mb_settings: 'Настройки медиабаинга',
      pending_balance: 'Текущий баланс',
      balance_limit: 'Лимит баланса',
      available_balance: 'Доступный баланс',
      amount_up_to: 'Сумма до',
      auto_approve_post: 'Автоодобрение публикаций',
      cost_per_player_up: 'Стоимость за игрока до',
      cost_per_player: 'Стоимость за игрока',
      amount: 'Сумма',
      disabled: 'Выключено',
      enabled: 'Включено',
    },
  },
};

<template>
  <div class="currencies wrapper">
    <add-currency-popup
      ref="addCurrencyPopup"
      :currencies.sync="currencies"
      :excluded.sync="excluded"
      @save="initData"
    />
    <ui-confirm
      ref="confirm"
      class="confirm"
      :width="480"
      :type="confirmData.actionType"
      :action-name="confirmData.actionName"
      action-icon="check"
      @save="confirmData.handleSave"
    >
      <div slot="title" v-html="confirmData.title" />
      <div class="body__wrap">
        <i class="fas" :class="[confirmData.icon, confirmData.iconColor]" />
        <div class="body__msg msg" v-html="confirmData.msg" />
      </div>
    </ui-confirm>
    <div class="toolbar">
      <div class="section filters">
        <span class="title">{{ $t('settings.currencies.title') }}</span>
      </div>
      <div class="section">
        <ui-button
          color="green"
          lib="fa"
          substyle="fas"
          filled
          icon="plus"
          class="btn btn-add"
          @click="handleAddCurrencyPopup"
        >
          {{ $t('settings.currencies.addCurrency') }}
        </ui-button>
      </div>
    </div>

    <!--    :sort="{-->
    <!--    prop: sortProp,-->
    <!--    order: sortOrder,-->
    <!--    }"-->
    <!--    @sort="handleSort"-->
    <ui-table
      v-loading="isDataLoading"
      :fields="fields"
      :data="tableData"
      lazy-loading
      disable-sort
      i18n-path="settings.currencies"
      element-loading-custom-class="data-loader-spinner"
      class="table"
    >
      <template
        slot="append"
      >
        <el-table-column width="74">
          <template slot="header" slot-scope="scope">
            <div :key="scope.$index" class="table-action-header">
              {{ $t('settings.tokens.actions') }}
            </div>
          </template>
          <template slot-scope="scope">
            <ActionIcon
              :tooltip="$t('settings.systemDomains.confirm.setAsDefault')"
            >
              <el-link
                class="action-ico"
                type="success"
                :underline="false"
                :disabled="scope.row.is_default"
                @click="handleMakeDefault(scope.row)"
              >
                <ui-icon
                  name="house-circle-check"
                  :color="$theme.accentColor"
                  lib="fa"
                />
              </el-link>
            </ActionIcon>
            <ActionIcon
              :tooltip="$t('crm.buttons.delete')"
            >
              <el-link
                class="action-ico"
                type="danger"
                :underline="false"
                :disabled="scope.row.is_default"
                @click="handleDelete(scope.row)"
              >
                <ui-icon
                  name="trash-alt"
                  :color="$theme.dangerColor"
                  lib="fa"
                />
              </el-link>
            </ActionIcon>
          </template>
        </el-table-column>
      </template>
    </ui-table>
  </div>
</template>

<script>
import AddCurrencyPopup from './components/AddCurrencyPopup.vue';
import sessionAction from '@/views/mixins/session-action';
// import updateUrl from '@/service/updateUrl';

const LS_PATH = 'Currencies/List';

export default {
  name: 'Currencies',
  components: {
    AddCurrencyPopup,
  },
  mixins: [sessionAction],
  data() {
    return {
      confirmData: {
        title: '',
        icon: '',
        iconColor: '',
        msg: '',
        handleSave: Function,
      },
      currencies: [],
      fields: [
        {
          name: 'code',
          minWidth: '192',
          width: '50',
          align: 'left',
          class: 'text',
          resizable: true,
          // sortable: 'custom',
        },
        {
          name: 'name',
          minWidth: '192',
          width: '500',
          align: 'left',
          class: 'text',
          resizable: true,
          // sortable: 'custom',
        },
        {
          name: 'type',
          align: 'left',
          class: 'text',
          resizable: false,
          renderValue: val => `${val.charAt(0).toUpperCase()}${val.slice(1)}`,
        },
      ],
      options: {
        sort_column: '',
        sort_dir: '',
      },
      sortProp: '',
      sortOrder: '',

      isDataLoading: false,
      tableData: [],
    };
  },

  computed: {
    excluded() {
      return this.tableData.map(el => el.code);
    },
  },
  watch: {
    sortProp() {
      this.options.sort_column = this.sortProp;
      /*
       * Прверяем сортировку чтобы не было шибки с сервера 422
       * */
      if (
        this.fields.some(
          item => item.name === this.options.sort_column && item.sortable === 'custom',
        )
      ) {
        // this.$ls.set(`${LS_PATH}/sort_column`, newVal || 'name');
      }
    },
    sortOrder(newVal) {
      this.options.sort_dir = this.sortOrder;
      this.$ls.set(`${LS_PATH}/sort_dir`, newVal || 'asc');
    },
  },
  created() {
    this.fetchData = this.$_.debounce(this.fetchDataD, 300);
    this.onSessionUnlock = this.initData;
    this.initData();
  },
  methods: {
    handleMakeDefault(row) {
      this.confirmData = {
        title: this.$t('settings.currencies.confirm.setAsDefaultCurrency'),
        icon: 'fa-house-circle-check',
        iconColor: 'success',
        msg: this.$t('settings.currencies.confirm.confirmSetAsDefault', { currency: row.name }),
        actionName: this.$t('settings.currencies.confirm.setAsDefault'),
        actionType: 'success',
        handleSave: () => {
          this.$api.putCurrencySetDefault({ code: row.code }).then(() => {
            this.initData();
            this.$refs.confirm.close();
          });
        },
      };
      this.$refs.confirm.open();
    },
    handleAddCurrencyPopup() {
      this.$refs.addCurrencyPopup.open();
    },
    handleDelete(row) {
      this.confirmData = {
        title: this.$t('settings.currencies.confirm.deleteCurrency'),
        icon: 'fa-exclamation-triangle',
        iconColor: 'warning',
        msg: this.$t('settings.currencies.confirm.confirmDelete', { name: row.name }),
        actionName: this.$t('settings.tokens.delete'),
        actionType: 'warning',
        handleSave: () => {
          this.$api.deleteCurrency(row.code).then(() => {
            this.initData();
            this.$refs.confirm.close();
          });
        },
      };
      this.$refs.confirm.open();
    },
    _query() {
      return this.options;
    },
    handleSort({ prop, order }) {
      if (order === '') return;
      if (this.sortProp !== prop) {
        this.sortProp = prop;
      } else {
        this.sortOrder = order;
      }
      this.fetchData();
    },
    initData() {
      // const _urlData = updateUrl.getParseParams(this.$route.hash);

      this.$api.getCurrencies().then((res) => {
        this.currencies = res.data.payload;
      });

      // if (!this.$_.isEmpty(_urlData)) {
      //   /*
      //    * Приводим объект к дефолтным данным
      //    * */
      //   this.$_.merge(this.options, _urlData);
      //
      //   this.sortProp = this.$_.get(_urlData, ['sort_column'], 'name');
      //   this.sortOrder = this.$_.get(_urlData, ['sort_dir'], 'asc');
      // } else {
      //   this.sortProp = this.$ls.get(`${LS_PATH}/sort_column`)
      //     ? this.$ls.get(`${LS_PATH}/sort_column`)
      //     : 'name';
      //   this.sortOrder = this.$ls.get(`${LS_PATH}/sort_dir`)
      //     ? this.$ls.get(`${LS_PATH}/sort_dir`)
      //     : 'asc';
      // }

      this.fetchDataD();
    },
    fetchDataD() {
      this._completedFilterUrl();

      /* подготовка опций для мультиаккаунтов */
      const options = this._query();
      this.isLoading = true;
      return this.$api.getCustomerCurrencies(options).then((res) => {
        this.tableData = res.data.payload;
      }).finally(() => this.isLoading = false);
    },
    _completedFilterUrl() {
      // const _dataFilters = this._query();
      //
      // updateUrl.updateGetParams(_dataFilters);
    },
  },
};
</script>
<style lang='scss' scoped>
.currencies {
  /deep/.confirm {
    .msg {
      b {
        font-weight: 700;
      }
    }
  }

  &.wrapper{
    width: 90%;
    margin: 0 auto;
  }
  .body {
    display: flex;
    gap: 24px;
    line-height: normal;
  }
  .toolbar {
    margin: 16px 0;
    display: flex;
    justify-content: space-between;

    .title {
      font-size: 24px;
      margin-right: 16px;
      line-height: 30px;
      font-weight: 500;
    }
  }

  .table {
    /deep/ {
      .el-table{
        border-color: transparent;
        &::before{
          background-color: transparent;
        }
        &::after{
          background-color: transparent;
        }
        &__body{
          border: 1px solid #eaeaea;
          border-right: none;
          position: relative;
          &::after{
            content: '';
            position: absolute;
            background-color: #EBEEF5;
            z-index: 1;
            top: 0;
            right: 1px;
            width: 2px;
            height: 100%;
          }
        }
      }

      .table-action-header {
        display: flex;
        flex: 1;
        justify-content: center;
      }

      .default-tag {
        margin-left: 4px;
        height: 16px;
        padding: 0 5px 0 8px;
        border-radius: 8px;
        background-color: var(--main-color);
        color: #fff;
        font-size: 10px;
        font-weight: 500;

        i {
          margin-right: 2px;
        }
      }

      .row-value {
        display: block!important;
      }
    }
  }

  /deep/ {
    .ui-confirm .ui-button.btn {
      text-transform: initial;
    }
  }
}
</style>

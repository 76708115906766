<template>
  <div
    v-if="isOpen"
    class="add-payment-method-popup"
  >
    <div class="popup">
      <div class="head">
        <span class="name">
          {{ isEdit
            ? `${$t('settings.systemDomains.confirm.editSystemDomains')} - ${mappedCountries[data.country_code]}`
            : $t('settings.systemDomains.confirm.addSystemDomains')
          }}
        </span>
        <ui-icon
          :size="20"
          lib="fa"
          substyle="fas"
          class="close"
          name="times"
          @click.native="close"
        />
      </div>
      <adm-ui-scrollbar
        wrap-style="max-height: 75vh; height: auto;"
        :native="false"
      >
        <div
          v-loading="isLoading"
          class="body"
        >
          <div class="ui-fg-1">
            <div v-if="!isEdit" class="field ui-m-xl-b">
              <span class="label-text">{{ $t('settings.systemDomains.confirm.country_code') }}</span>
              <el-select
                v-model="data.country_code"
                :placeholder="$t('settings.systemDomains.confirm.notSelected')"
                filterable
                class="select"
              >
                <el-option
                  v-for="item in filteredCountries"
                  :key="item.code"
                  :label="item.name"
                  :value="item.code"
                />
              </el-select>
              <div v-if="getError('required_affiliate_fields')" class="field-error">
                {{ getError('required_affiliate_fields') }}
              </div>
            </div>
            <ui-input
              v-model="data.front_domain"
              class="form-input ui-m-xl-b"
              :label="$t('settings.systemDomains.confirm.front_domain')"
              autosize
              size="big"
              :placeholder="$t('settings.systemDomains.confirm.front_domain_placeholder')"
              type="text"
              :error="getError('front_domain')"
            />
            <ui-input
              v-model="data.panel_domain"
              class="form-input ui-m-xl-b"
              :label="$t('settings.systemDomains.confirm.panel_domain')"
              autosize
              size="big"
              :placeholder="$t('settings.systemDomains.confirm.panel_domain_placeholder')"
              type="text"
              :error="getError('panel_domain')"
            />
            <ui-input
              v-model="data.admin_domain"
              class="form-input ui-m-xl-b"
              :label="$t('settings.systemDomains.confirm.admin_domain')"
              autosize
              size="big"
              :placeholder="$t('settings.systemDomains.confirm.admin_domain_placeholder')"
              type="text"
              :error="getError('admin_domain')"
            />
          </div>
        </div>
      </adm-ui-scrollbar>
      <div class="foot">
        <ui-button
          color="red"
          lib="fa"
          substyle="fas"
          icon="times"
          :disabled="isLoading"
          @click="close()"
        >
          {{ $t('crm.buttons.cancel') }}
        </ui-button>
        <ui-button
          :color="$theme.mainColor"
          :disabled="isLoading"
          filled
          lib="fa"
          substyle="fas"
          icon="check"
          @click="handleSave()"
        >
          {{ $t('crm.buttons.save') }}
        </ui-button>
      </div>
    </div>
    <div class="wrapper" />
  </div>
</template>


<script>
import { mapGetters } from 'vuex';
import errorHandleMixin from '@/views/mixins/error-hadle';

export default {
  name: 'AddSystemDomainPopup',
  components: {
  },
  mixins: [
    errorHandleMixin,
  ],
  data() {
    return {
      isOpen: false,
      isLoading: false,
      isEdit: false,
      selectedCountries: [],
      data: {
        ...this.getDefaultForm(),
      },
    };
  },

  computed: {
    ...mapGetters({
      countries: 'misc/countries',
    }),
    filteredCountries() {
      return this.countries
        .map(country => ({
          ...country,
          code: country.code.toLowerCase(),
        }))
        .filter(({ code }) => !this.selectedCountries.includes(code));
    },
    mappedCountries() {
      return this.countries.reduce((acc, { code, name }) => {
        acc[code.toLowerCase()] = name;
        return acc;
      }, {});
    },
  },
  created() {},
  mounted() {},
  methods: {
    open(data) {
      const { selectedCountries, ...restParams } = data;
      this.isOpen = true;
      this.data = this.$_.cloneDeep(restParams) || this.getDefaultForm();
      this.selectedCountries = selectedCountries;
      this.isEdit = !this.$_.isEmpty(restParams);

      if (!this.isEdit) {
        this.$set(this.data, 'country_code', this.filteredCountries[0].code);
      }

      this.errors = {};
    },
    close() {
      this.isOpen = false;
    },
    handleSave() {
      this.errors = {};

      this.$api.putCustomerDomains(this.data)
        .then(() => {
          this.close();
          this.$emit('save');
        })
        .catch((e) => {
          this.errors = e.data.errors;
        });
    },
    getDefaultForm() {
      return {
        country_code: '',
        front_domain: '',
        panel_domain: '',
        admin_domain: '',
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.add-payment-method-popup {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 900;

  .wrapper {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0.5;
    background-color: #303634;
    z-index: 901;
    width: 100%;
  }

  .row-bg {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 8px;
    padding-right: 8px;
    border-radius: 4px;
    background-color: var(--crm-panel-bg);
    height: 32px;

    font-size: 14px;
    font-weight: 500;
  }

  .popup {
    display: flex;
    flex-direction: column;
    z-index: 902;
    top: 8vh;
    width: 480px;
    border-radius: 8px;
    background-color: white;
    box-sizing: border-box;


    .head {
      padding: 16px 24px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #eaeaea;
      .name {
        font-size: 20px;
        font-weight: 500;
        display: flex;
        align-items: center;
        margin-right: 16px;
      }
      .close {
        font-size: 15px;
        cursor: pointer;
        &:hover {
          color: var(--main-color) !important;
        }
      }
    }

    .body {
      padding: 16px 24px;
      display: flex;
      position: relative;
      max-height: 84vh;
      min-height: 215px;
      overflow-y: auto;

      .content-header{
        margin-bottom: 16px;
        display: flex;
        align-items: flex-start;
      }

      .select-wrap {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        margin-right: 16px;

        &_grow{
          flex-grow: 1;
        }

        &__label{
          font-size: 14px;
          margin-bottom: 5px;
        }
        &:last-child{
          margin-right: 0;
        }
      }

      .card__title{
        .card-content{
          .label{
            font-size: 24px;
            font-weight: 500;
            line-height: 1.25;
          }
        }
      }
    }

    .foot {
      display: flex;
      justify-content: flex-end;
      border-top: 1px solid #eaeaea;
      padding: 16px 24px;
      .ui-button + .ui-button{
        margin-left: 8px;
      }
    }
  }

  .label-text {
    font-size: 14px;
    color: var(--crm-panel-text);
    margin-bottom: 5px;
    display: block;
  }

  .select {
    width: 100%;

    /deep/ {
      .el-input__suffix-inner {
        align-items: center;
        display: flex;
      }
    }

    &.error {
      /deep/ {
        .el-input__inner {
          border-color: var(--danger-color);
        }
      }
    }
  }

  .field-error {
    text-align: right;
  }

  .form-input {
    margin-bottom: 16px;

    /deep/ {
      .label-text {
        font-size: 14px;
        color: var(--crm-panel-text);
        margin-bottom: 5px;
        display: block;
      }

      .input-error {
        text-transform: initial!important;
        font-weight: 400!important;
      }
      input[type="number"]::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
  }
}
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"affiliates-list"},[_c('transfer-affiliates-popup',{ref:"transferAffiliatesPopup",attrs:{"props-errors":_vm.transferPopupErrors,"currency":_vm.currencyCode},on:{"confirm":_vm.transferAffiliates}}),_c('div',{staticClass:"toolbar",on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.handleRefresh.apply(null, arguments)}}},[_c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"section filters"},[_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('affiliates.name')))]),_c('ui-input',{staticClass:"select-label",attrs:{"width":180,"is-search":""},on:{"search":_vm.handleRefresh},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"searchQuery"}}),_c('affiliates-filter',{ref:"filter",staticClass:"btn ui-filter-new",attrs:{"props-errors":_vm.errors},on:{"update:propsErrors":function($event){_vm.errors=$event},"update:props-errors":function($event){_vm.errors=$event},"submit":_vm.getAffiliatesFilter},model:{value:(_vm.filters),callback:function ($$v) {_vm.filters=$$v},expression:"filters"}})],1),_c('div',{staticClass:"section"},[_c('ui-pagination',{attrs:{"page":_vm.page,"page-size":_vm.limit,"count":_vm.count,"show-size-select":""},on:{"page-change":_vm.pageChange}}),(_vm.hasPermission('affiliates_sub_affiliates_edit_settings'))?_c('ui-button',{staticClass:"btn",attrs:{"lib":"fa","substyle":"fas","icon":"right-left"},on:{"click":_vm.handleOpenTransferPopup}}):_vm._e(),_c('export-data',{attrs:{"data":_vm.configExport},on:{"exportData":_vm.exportData}})],1)])]),_c('ui-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.isDataLoading),expression:"isDataLoading"}],staticClass:"table",attrs:{"fields":_vm.fields,"currency":_vm.currencyCode,"data":_vm.tableData,"rows-count":_vm.limit,"sort":{
      prop: _vm.sortProp,
      order: _vm.sortOrder,
    },"lazy-loading":"","i18n-path":"affiliates.list","element-loading-custom-class":"data-loader-spinner"},on:{"sort":_vm.handleSort}},[(_vm.hasPermission('reports_view') && _vm.affiliateStatisticSupported)?_c('template',{slot:"append"},[_c('el-table-column',{attrs:{"align":"center","width":"45"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('ActionIcon',{attrs:{"tooltip":_vm.$t('affiliates.cardView.toolbar.btns.statistics')}},[(_vm.isViewReportsItem(scope.row))?_c('router-link',{staticClass:"action-ico",attrs:{"to":{
                path: '/reports/custom',
                query: {
                  affiliate_stats: _vm.$qs.stringify(_vm.affiliateStats(scope.row), {
                    arrayFormat: 'repeat',
                  }),
                },
              }}},[_c('ui-icon',{attrs:{"name":"chart-bar","color":"#3b94e3","lib":"fa"}})],1):_vm._e()],1)]}}],null,false,2965616683)})],1):_vm._e()],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }